import moment from "moment";
import { IColumns, IColumnsTotal } from "../../component/table/table-grouping/TableGrouping";
import { IBaoCao, IDiaPhuong, ISearchBaoCao, ITongCong, iConfigTable } from "../model/model";
import { THANG_HIEN_TAI } from "../../../Constant";
import TextValidator from "../../component/input-field/TextValidator";

export const INITIAL_CONFIG_TABLE: iConfigTable = {
    totalElement: 0,
    totalPages: 0,
    numberOfElements: 0,
}

export const TK_TUAN_THANG = [
    {
        name: "Tuần",
        code: 1
    },
    {
        name: "Tháng",
        code: 2
    },
]

export const LOAI_THONG_KE = [
    ...TK_TUAN_THANG,
    {
        name: "Tùy chọn",
        code: 3
    }
]

export const LOAI_TUY_CHON = [
    {
        name: "Tháng",
        code: 1,
        dateFormat: "MM/yyyy",  // month/year format
        showMonthYearPicker: true
    },
    {
        name: "Quý",
        code: 2,
        dateFormat: "QQQ yyyy",  // quarter of the year format
        showQuarterYearPicker: true
    },
    {
        name: "Năm",
        code: 3,
        dateFormat: "yyyy",  // year format
        showYearPicker: true
    },
];

export const HINH_THUC_DIEU_TRI = [
    {
        name: "Ngoại trú",
        code: 1
    },
    {
        name: "Nội trú",
        code: 2
    },
    {
        name: "Cả Hai",
        code: 0
    }
]

export const DK_CHAN_DOAN = [
    {
        name: "Vào viện",
        code: 1
    },
    {
        name: "Ra viện",
        code: 2
    },
    {
        name: "Cả hai",
        code: 0
    }
]

export const columnBaoCaoTuan = (intl: any): IColumns[] => [
    {
        name: "STT",
        field: "",
        render: (row: any, index: number, stt: number) => <span>{stt}</span>,
        cellStyle: {
            fontSize: "16px"
        },
    },
    {
        name: `${intl.formatMessage({ id: "LOCAL" })}`,
        field: "tenDiaPhuong",
        headerStyle: {
            minWidth: "250px"
        },
        cellStyle: {
            fontSize: "16px"
        },
    },
    {
        name: `${intl.formatMessage({ id: "NUMBER_PATIENT_HAS_DESEASE" })}`,
        field: "",
        child: [
            {
                name: "SXDH và SXHD có dấu hiệu cảnh báo",
                field: "sxhCanhBao",
                headerStyle: {
                    minWidth: "80px"
                },
                cellStyle: {
                    fontSize: "16px"
                },
                child: [
                    {
                        name: `${intl.formatMessage({ id: "TOTAL_PATIENT_HAS_DESEASE" })}`,
                        field: "tongSoDauHieu",
                        headerStyle: {
                            minWidth: "80px"
                        },
                        cellStyle: {
                            fontSize: "16px"
                        }
                    },
                    {
                        name: `${intl.formatMessage({ id: "YOUNGER_15" })}`,
                        field: "tongSoDuoi15",
                        headerStyle: {
                            minWidth: "80px"
                        },
                        cellStyle: {
                            fontSize: "16px"
                        }
                    },
                    {
                        name: `${intl.formatMessage({ id: "TOTAL_PATIENT_PLUS_HAS_DESEASE" })}`,
                        field: "congDonDauHieu",
                        headerStyle: {
                            minWidth: "80px"
                        },
                        cellStyle: {
                            fontSize: "16px"
                        }
                    },
                ]
            },
            {
                name: `${intl.formatMessage({ id: "THE_WORST_EDENGUE_PATIENT" })}`,
                field: "sxhNang",
                headerStyle: {
                    minWidth: "80px"
                },
                cellStyle: {
                    fontSize: "16px"
                },
                child: [
                    {
                        name: `${intl.formatMessage({ id: "TOTAL_PATIENT_HAS_DESEASE" })}`,
                        field: "tongSoNang",
                        headerStyle: {
                            minWidth: "80px"
                        },
                        cellStyle: {
                            fontSize: "16px"
                        }
                    },
                    {
                        name: `${intl.formatMessage({ id: "YOUNGER_15" })}`,
                        field: "tongSoNangDuoi15",
                        headerStyle: {
                            minWidth: "80px"
                        },
                        cellStyle: {
                            fontSize: "16px"
                        }
                    },
                    {
                        name: `${intl.formatMessage({ id: "TOTAL_PATIENT_PLUS_HAS_DESEASE" })}`,
                        field: "congDonNang",
                        headerStyle: {
                            minWidth: "80px"
                        },
                        cellStyle: {
                            fontSize: "16px"
                        }
                    },
                ]
            },
            {
                name: `${intl.formatMessage({ id: "TOTAL_PATIENT_HAS_DESEASE" })}`,
                field: "tongSo",
                headerStyle: {
                    minWidth: "80px"
                },
                cellStyle: {
                    fontSize: "16px"
                }
            },
            {
                name: `${intl.formatMessage({ id: "TOTAL_PATIENT_PLUS_HAS_DESEASE" })}`,
                field: "congDon",
                headerStyle: {
                    minWidth: "80px"
                },
                cellStyle: {
                    fontSize: "16px"
                }
            },
        ]
    },
    {
        name: `${intl.formatMessage({ id: "NUMBER_DEAD" })}`,
        field: "",
        child: [
            {
                name: `${intl.formatMessage({ id: "TOTAL_PATIENT_DEAD" })}`,
                field: "tongSoTuVong",
                headerStyle: {
                    minWidth: "80px"
                },
                cellStyle: {
                    fontSize: "16px"
                }
            },
            {
                name: `${intl.formatMessage({ id: "YOUNGER_15" })}`,
                field: "tongSoTuVongDuoi15",
                headerStyle: {
                    minWidth: "80px"
                },
                cellStyle: {
                    fontSize: "16px"
                }
            },
            {
                name: `${intl.formatMessage({ id: "TOTAL_PATIENT_DEAD" })}`,
                field: "congDonTuVong",
                headerStyle: {
                    minWidth: "80px"
                },
                cellStyle: {
                    fontSize: "16px"
                }
            },

        ]
    },
];

export const columnNhapBaoCao = (props: any) => {
    const { handleChangeInput, values, errors, touched, intl } = props
    return [
        {
            name: `${intl.formatMessage({ id: "ORDER" })}`,
            field: "",
            render: (row: any, index: number, stt: number) => <span>{stt}</span>,
            cellStyle: {
                fontSize: "16px"
            },
        },
        {
            name: `${intl.formatMessage({ id: "LOCAL" })}`,
            field: "tenXa",
            headerStyle: {
                minWidth: "250px"
            },
            cellStyle: {
                fontSize: "16px"
            },
        },
        {
            name: `${intl.formatMessage({ id: "NUMBER_PATIENT_HAS_DESEASE" })}`,
            field: "",
            child: [
                {
                    name: `${intl.formatMessage({ id: "EDENGUE_WARNING" })}`,
                    field: "sxhCanhBao",
                    headerStyle: {
                        minWidth: "80px"
                    },
                    cellStyle: {
                        fontSize: "16px"
                    },
                    child: [
                        {
                            name: `${intl.formatMessage({ id: "TOTAL_PATIENT_HAS_DESEASE" })}`,
                            field: "tongSoDauHieu",
                            headerStyle: {
                                minWidth: "80px"
                            },
                            cellStyle: {
                                fontSize: "16px"
                            },
                            render: (row: any, index: number) => {
                                return (
                                    (
                                        <TextValidator
                                            key={values?.listBaoCao[index]?.tongSoDauHieu}
                                            type="text"
                                            name={`listBaoCao[${index}].tongSoDauHieu`}
                                            onBlur={(event: any) => handleChangeInput(`listBaoCao[${index}].tongSoDauHieu`, event?.target?.value)}
                                            defaultValue={values?.listBaoCao[index]?.tongSoDauHieu}
                                            errorTooltip
                                            touched={touched?.listBaoCao?.[index]?.tongSoDauHieu}
                                            errors={errors?.listBaoCao?.[index]?.tongSoDauHieu}
                                        />)
                                )
                            },
                        },
                        {
                            name: `${intl.formatMessage({ id: "YOUNGER_15" })}`,
                            field: "tongSoDuoi15",
                            headerStyle: {
                                minWidth: "80px"
                            },
                            cellStyle: {
                                fontSize: "16px"
                            },
                            render: (row: any, index: number) => (
                                <TextValidator
                                    type="text"
                                    key={values?.listBaoCao[index]?.tongSoDuoi15}
                                    name={`listBaoCao[${index}].tongSoDuoi15`}
                                    onBlur={(event:any)=>handleChangeInput(`listBaoCao[${index}].tongSoDuoi15`,event?.target?.value)}
                                    defaultValue={values?.listBaoCao[index]?.tongSoDuoi15}
                                    errorTooltip
                                    touched={touched?.listBaoCao?.[index]?.tongSoDuoi15}
                                    errors={errors?.listBaoCao?.[index]?.tongSoDuoi15}
                                />),
                        },
                        {
                            name: `${intl.formatMessage({ id: "TOTAL_PATIENT_PLUS_HAS_DESEASE" })}`,
                            field: "congDonDauHieu",
                            headerStyle: {
                                minWidth: "80px"
                            },
                            cellStyle: {
                                fontSize: "16px"
                            },
                            render: (row: any, index: number) => (
                                <TextValidator
                                    type="text"
                                    key={values?.listBaoCao[index]?.congDonDauHieu}
                                    name={`listBaoCao[${index}].congDonDauHieu`}
                                    onBlur={(event:any)=>handleChangeInput(`listBaoCao[${index}].congDonDauHieu`,event?.target?.value)}
                                    defaultValue={values?.listBaoCao[index]?.congDonDauHieu}
                                    errorTooltip
                                    touched={touched?.listBaoCao?.[index]?.congDonDauHieu}
                                    errors={errors?.listBaoCao?.[index]?.congDonDauHieu}
                                />),
                        },
                    ]
                },
                {
                    name: `${intl.formatMessage({ id: "THE_WORST_EDENGUE_PATIENT" })}`,
                    field: "sxhNang",
                    headerStyle: {
                        minWidth: "80px"
                    },
                    cellStyle: {
                        fontSize: "16px"
                    },
                    child: [
                        {
                            name: `${intl.formatMessage({ id: "TOTAL_PATIENT_HAS_DESEASE" })}`,
                            field: "tongSoNang",
                            headerStyle: {
                                minWidth: "80px"
                            },
                            cellStyle: {
                                fontSize: "16px"
                            },
                            render: (row: any, index: number) => (
                                <TextValidator
                                    type="text"
                                    key={values?.listBaoCao[index]?.tongSoNang}
                                    name={`listBaoCao[${index}].tongSoNang`}
                                    onBlur={(event:any)=>handleChangeInput(`listBaoCao[${index}].tongSoNang`,event?.target?.value)}
                                    defaultValue={values?.listBaoCao[index]?.tongSoNang}
                                    errorTooltip
                                    touched={touched?.listBaoCao?.[index]?.tongSoNang}
                                    errors={errors?.listBaoCao?.[index]?.tongSoNang}
                                />),
                        },
                        {
                            name: `${intl.formatMessage({ id: "YOUNGER_15" })}`,
                            field: "tongSoNangDuoi15",
                            headerStyle: {
                                minWidth: "80px"
                            },
                            cellStyle: {
                                fontSize: "16px"
                            },
                            render: (row: any, index: number) => (
                                <TextValidator
                                    type="text"
                                    key={values?.listBaoCao[index]?.tongSoNangDuoi15}
                                    name={`listBaoCao[${index}].tongSoNangDuoi15`}
                                    onBlur={(event:any)=>handleChangeInput(`listBaoCao[${index}].tongSoNangDuoi15`,event?.target?.value)}
                                    defaultValue={values?.listBaoCao[index]?.tongSoNangDuoi15}
                                    errorTooltip
                                    touched={touched?.listBaoCao?.[index]?.tongSoNangDuoi15}
                                    errors={errors?.listBaoCao?.[index]?.tongSoNangDuoi15}
                                />),
                        },
                        {
                            name: `${intl.formatMessage({ id: "TOTAL_PATIENT_PLUS_HAS_DESEASE" })}`,
                            field: "congDonNang",
                            headerStyle: {
                                minWidth: "80px"
                            },
                            cellStyle: {
                                fontSize: "16px"
                            },
                            render: (row: any, index: number) => (
                                <TextValidator
                                    type="text"
                                    key={values?.listBaoCao[index]?.congDonNang}
                                    name={`listBaoCao[${index}].congDonNang`}
                                    onBlur={(event:any)=>handleChangeInput(`listBaoCao[${index}].congDonNang`,event?.target?.value)}
                                    defaultValue={values?.listBaoCao[index]?.congDonNang}
                                    errorTooltip
                                    touched={touched?.listBaoCao?.[index]?.congDonNang}
                                    errors={errors?.listBaoCao?.[index]?.congDonNang}
                                />),
                        },
                    ]
                },
                {
                    name: `${intl.formatMessage({ id: "TOTAL_PATIENT_HAS_DESEASE" })}`,
                    field: "tongSo",
                    headerStyle: {
                        minWidth: "80px"
                    },
                    cellStyle: {
                        fontSize: "16px"
                    },
                    render: (row: any, index: number) => (
                        <TextValidator
                            type="text"
                            key={values?.listBaoCao[index]?.tongSo}
                            name={`listBaoCao[${index}].tongSo`}
                            onBlur={(event:any)=>handleChangeInput(`listBaoCao[${index}].tongSo`,event?.target?.value)}
                            defaultValue={values?.listBaoCao[index]?.tongSo}
                            errorTooltip
                            touched={touched?.listBaoCao?.[index]?.tongSo}
                            errors={errors?.listBaoCao?.[index]?.tongSo}
                        />),
                },
                {
                    name: `${intl.formatMessage({ id: "TOTAL_PATIENT_PLUS_HAS_DESEASE" })}`,
                    field: "congDon",
                    headerStyle: {
                        minWidth: "80px"
                    },
                    cellStyle: {
                        fontSize: "16px"
                    },
                    render: (row: any, index: number) => (
                        <TextValidator
                            type="text"
                            key={values?.listBaoCao[index]?.congDon}
                            name={`listBaoCao[${index}].congDon`}
                            onBlur={(event:any)=>handleChangeInput(`listBaoCao[${index}].congDon`,event?.target?.value)}
                            defaultValue={values?.listBaoCao[index]?.congDon}
                            errorTooltip
                            touched={touched?.listBaoCao?.[index]?.congDon}
                            errors={errors?.listBaoCao?.[index]?.congDon}
                        />),
                },
            ]
        },
        {
            name: `${intl.formatMessage({ id: "NUMBER_DEAD" })}`,
            field: "",
            child: [
                {
                    name: `${intl.formatMessage({ id: "TOTAL_PATIENT_DEAD" })}`,
                    field: "tongSoTuVong",
                    headerStyle: {
                        minWidth: "80px"
                    },
                    cellStyle: {
                        fontSize: "16px"
                    },
                    render: (row: any, index: number) => (
                        <TextValidator
                            type="text"
                            key={values?.listBaoCao[index]?.tongSoTuVong}
                            name={`listBaoCao[${index}].tongSoTuVong`}
                            onBlur={(event:any)=>handleChangeInput(`listBaoCao[${index}].tongSoTuVong`,event?.target?.value)}
                            defaultValue={values?.listBaoCao[index]?.tongSoTuVong}
                            errorTooltip
                            touched={touched?.listBaoCao?.[index]?.tongSoTuVong}
                            errors={errors?.listBaoCao?.[index]?.tongSoTuVong}
                        />),
                },
                {
                    name: `${intl.formatMessage({ id: "YOUNGER_15" })}`,
                    field: "tongSoTuVongDuoi15",
                    headerStyle: {
                        minWidth: "80px"
                    },
                    cellStyle: {
                        fontSize: "16px"
                    },
                    render: (row: any, index: number) => (
                        <TextValidator
                            type="text"
                            key={values?.listBaoCao[index]?.tongSoTuVongDuoi15}
                            name={`listBaoCao[${index}].tongSoTuVongDuoi15`}
                            onBlur={(event:any)=>handleChangeInput(`listBaoCao[${index}].tongSoTuVongDuoi15`,event?.target?.value)}
                            defaultValue={values?.listBaoCao[index]?.tongSoTuVongDuoi15}
                            errorTooltip
                            touched={touched?.listBaoCao?.[index]?.tongSoTuVongDuoi15}
                            errors={errors?.listBaoCao?.[index]?.tongSoTuVongDuoi15}
                        />),
                },
                {
                    name: `${intl.formatMessage({ id: "TOTAL_PATIENT_PLUS_DEAD" })}`,
                    field: "congDonTuVong",
                    headerStyle: {
                        minWidth: "80px"
                    },
                    cellStyle: {
                        fontSize: "16px"
                    },
                    render: (row: any, index: number) => (
                        <TextValidator
                            type="text"
                            key={values?.listBaoCao[index]?.congDonTuVong}
                            name={`listBaoCao[${index}].congDonTuVong`}
                            onBlur={(event:any)=>handleChangeInput(`listBaoCao[${index}].congDonTuVong`,event?.target?.value)}
                            defaultValue={values?.listBaoCao[index]?.congDonTuVong}
                            errorTooltip
                            touched={touched?.listBaoCao?.[index]?.congDonTuVong}
                            errors={errors?.listBaoCao?.[index]?.congDonTuVong}
                        />),
                },

            ]
        },
    ];
}

export const columnTotalBaoCao: IColumnsTotal[] = [
    {
        colSpan: 2,
        isTitle: true,
        field: "title",
        cellStyle: {
            fontSize: "15px",
            fontWeight: "bold",
            textAlign: "left",
            paddingLeft: "16px"
        },
        render: () => "Tổng cộng"
    },
    {
        field: "tongSoDauHieu",
        cellStyle: {
            fontSize: "15px",
            textAlign: "center"
        }
    },
    {
        field: "tongSoDuoi15",
        cellStyle: {
            fontSize: "15px",
            textAlign: "center"
        }
    },
    {
        field: "congDonDauHieu",
        cellStyle: {
            fontSize: "15px",
            textAlign: "center"
        }
    },
    {
        field: "tongSoNang",
        cellStyle: {
            fontSize: "15px",
            textAlign: "center"
        }
    },
    {
        field: "tongSoNangDuoi15",
        cellStyle: {
            fontSize: "15px",
            textAlign: "center"
        }
    },
    {
        field: "congDonNang",
        cellStyle: {
            fontSize: "15px",
            textAlign: "center"
        }
    },
    {
        field: "tongSo",
        cellStyle: {
            fontSize: "15px",
            textAlign: "center"
        }
    },
    {
        field: "congDon",
        cellStyle: {
            fontSize: "15px",
            textAlign: "center"
        }
    },
    {
        field: "tongSoTuVong",
        cellStyle: {
            fontSize: "15px",
            textAlign: "center"
        }
    },
    {
        field: "tongSoTuVongDuoi15",
        cellStyle: {
            fontSize: "15px",
            textAlign: "center"
        }
    },
    {
        field: "congDonTuVong",
        cellStyle: {
            fontSize: "15px",
            textAlign: "center"
        }
    },
];

const listBaoCaoDiaPhuong: IDiaPhuong[] = [
  {
    tenDiaPhuong: "",
    diaPhuongId: 0,
    tongSo: 0,
    tongSoDauHieu: 0,
    tongSoDuoi15: 0,
    tongSoNang: 0,
    tongSoNangDuoi15: 0,
    tongSoTuVong: 0,
    tongSoTuVongDuoi15: 0,
    congDon: 0,
    congDonDauHieu: 0,
    congDonNang: 0,
    congDonTuVong: 0,
  },
];

const tongCong: ITongCong = {
    tenDiaPhuong: "",
    diaPhuongId: 0,
    tongSo: 0,
    tongSoDauHieu: 0,
    tongSoDuoi15: 0,
    tongSoNang: 0,
    tongSoNangDuoi15: 0,
    tongSoTuVong: 0,
    tongSoTuVongDuoi15: 0,
    congDon: 0,
    congDonDauHieu: 0,
    congDonNang: 0,
    congDonTuVong: 0
};

export const initBaoCao: IBaoCao = {
    tenTuyenBaoCao: "",
    listBaoCaoDiaPhuong: listBaoCaoDiaPhuong,
    tongCong: tongCong
};

export const ngayDauThang = (nam: number, thang: number | string) => {
    return moment(`${nam}-${thang}-01`).startOf('month').format('YYYY-MM-DD');
}

export const ngayCuoiThang = (nam: number, thang: number | string) => {
    return moment(`${nam}-${thang}-01`).endOf('month').format('YYYY-MM-DD');
}

export const SEARCH_OBJECT_INIT: ISearchBaoCao = {
    loaiBaoCao: 1,
    loaiTuyChon: LOAI_TUY_CHON[1],
    tinhIds: null,
    huyenIds: null,
    xaIds: null,
    tuan: null,
    thang: Number(THANG_HIEN_TAI),
    nam: moment().year(),
    tuNgay: null,
    denNgay: null,
    capDoRoot: null,
    hinhThucDieuTri: HINH_THUC_DIEU_TRI[2].code,
    dieuTriChanDoan: DK_CHAN_DOAN[2].code
}

export const INIT_DATA_BAO_CAO = {
    tongSo: "",
    tongSoDauHieu: "",
    tongSoDuoi15: "",
    tongSoNang: "",
    tongSoNangDuoi15: "",
    tongSoTuVong: "",
    tongSoTuVongDuoi15: "",
    congDon: "",
    congDonDauHieu: "",
    congDonNang: "",
    congDonTuVong: ""
}
