import { useEffect, useRef, useState } from "react";
import { useLoading } from "../../../../AppContext";
import { KEY_LOCALSTORAGE } from "../../../auth/core/_consts";
import { InitContext } from "../../../utils/InitContext";
import { localStorageItem } from "../../../utils/LocalStorage";
import "../../dashboard.scss";
import { IDataDashboard } from "../../model/model";
import { getInforDashboard } from "../../services/services";
import DashboardHeader from "../DashboardHeader";
import DashboardGiamSatDetail from "./DashboardGiamSatDetail";

interface IProps {}

const DashboardGiamSat = (props: IProps) => {
  const { setPageLoading } = useLoading()
  const userData = localStorageItem.get(KEY_LOCALSTORAGE.USER_INFOMATION)
  const [address, setAddress] = useState({
    id: null,
    isActive: null,
    maTinh: null,
    tenTinh: userData?.tenTinh,
    tinhId: userData?.tinhId,
    huyenId: userData?.huyenId,
    tenHuyen: userData?.tenHuyen,
    tenXa: userData?.tenXa,
    xaId: userData?.xaId,
    thonId: null,
    tenThon: null,
    vungMienId: null,
  });
  const [data, setData] = useState({} as IDataDashboard);
  const paramsRef = useRef<any>({})

  const init = async () => {
  try {
      setPageLoading(true)
      paramsRef.current = {
        provinceName: address?.tenTinh,
        districtName: address?.tenHuyen,
        communeName: address?.tenXa,
        provinceId: address?.tinhId,
        communeId: address?.xaId,
        districtId: address?.huyenId,
      };
      Promise.allSettled([
        getInforDashboard(paramsRef.current, "dashboard/surveillance-info"),
        getInforDashboard(paramsRef.current, "dashboard/list-surveillance-area"),
        getInforDashboard(paramsRef.current, "/case/list-case-by-area"),
        getInforDashboard(paramsRef.current, "cluster/list-cluster-by-area"),
      ])
        .then(results => {
          const [inforTQGS, inforDSDPGS, infoMapData, infoODich] = results;
          setData((prev: any) =>
          ({
            inforTQGS: inforTQGS.status === 'fulfilled' ? inforTQGS.value?.data : prev.inforTQGS,
            inforDSDPGS: inforDSDPGS.status === 'fulfilled' ? inforDSDPGS.value?.data : prev.inforDSDPGS,
            infoMapData: infoMapData.status === 'fulfilled' ? infoMapData.value?.data : prev.infoMapData,
            infoODich: infoODich.status === 'fulfilled' ? infoODich.value?.data : prev.infoODich,
          })
          )
        })
    } catch (error) {
      console.error(error)
    } finally {
      setTimeout(() => setPageLoading(false), 100);
    }
  }

  useEffect(() => { // expand map
    const element = document.getElementById("icon-expand");
    const elementMap = document.getElementsByClassName("tab-content");

    if (elementMap.length > 0) {
        const map = elementMap[0] as HTMLElement; // Ensure it's treated as an HTMLElement
        const elementExpand = element as HTMLElement; // Ensure it's treated as an HTMLElement

        const handleClick = () => {
            const requestFullscreen =
                map.requestFullscreen?.bind(map) ||
                (map as any).mozRequestFullScreen?.bind(map) || // Firefox
                (map as any).webkitRequestFullscreen?.bind(map) || // Chrome, Safari and Opera
                (map as any).msRequestFullscreen?.bind(map); // IE/Edge

            if (requestFullscreen) {
                requestFullscreen();
            }
        };

        elementExpand?.addEventListener('click', handleClick);

        const handleFullscreenChange = () => {
            if (!document.fullscreenElement) {
                console.log('Exited fullscreen mode');
            }
        };

        document.addEventListener('fullscreenchange', handleFullscreenChange);

        return () => {
          elementExpand.removeEventListener('click', handleClick);
            document.removeEventListener('fullscreenchange', handleFullscreenChange);
        };
    }
}, []);



  useEffect(() => {
    init()
  }, [address])

  return (
    <InitContext.Provider value={{ address, setAddress, data }}>
      <div className="dash-board-container">
        <DashboardHeader hasIntervention={false} src='/media/logos/edengue.png' label='BẢNG THÔNG TIN Giám Sát DỊCH SỐT XUẤT HUYẾT' address='TP cần thơ' />
        <DashboardGiamSatDetail/>
      </div>
    </InitContext.Provider>
  )
}

export default DashboardGiamSat;
