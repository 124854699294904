import axios from "axios";
import { paramsConfig } from "../../utils/ParamsUtils";
import { ISearchObjModel } from "../model/model";
const API_PATH = process.env.REACT_APP_API_URL;

export const getDanhMuc = (searchObject: ISearchObjModel, url:string) => {
    let API_URL = API_PATH + url;
    return axios.get(API_URL, paramsConfig(searchObject));
};

export const addDanhMuc = (data: any, url: string) => {
    let API_URL = API_PATH + url;
    return axios.post(API_URL, data);
};

export const updateDanhMuc = (data: any, url: string) => {
    let API_URL = API_PATH + url;
    return axios.put(API_URL, data);
};

export const deleteDanhMuc = (url: string) => {
    let API_URL = API_PATH + url;
    return axios.delete(API_URL);
};

export const getVaiTroByUser = () => {
    let API_URL = API_PATH + "vai-tro/all-is-active-by-role";
    return axios.get(API_URL);
}

export const getListDiaChi = (params: any) => {
    let API_URL = API_PATH + "xa/auto-complete-dia-chi"
    return axios.get(API_URL, paramsConfig(params));
};

export const exportExcel = (endPoint: string, params: any) => {
    let API_URL = API_PATH + endPoint
    return axios.get(API_URL, paramsConfig(params));
};
