import { OCTAutocomplete, OCTTextValidator } from "@oceantech/oceantech-ui"
import { Button, Col, Row } from "react-bootstrap"
import { KTSVG } from "../../../../../_metronic/helpers"
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useFormikContext } from "formik";
import { ISearchObjectModel } from "../../models/TimKiemTruongHopBenhModels";
import { GENDER_OPTION, KQ_XET_NGHIEM, PHAN_LOAI_QUAN_LY, SEARCH_OBJECT_INIT, TINH_TRANG_HIEN_NAY } from "../constants/constants";
import LabelRequired from "../../../component/LabelRequired";
import { getListCoSoBaoCao, getListCoSoDieuTri, getListCoSoXetNghiem, getListHuyenByTinhId, getListNgheNghiep, getListTinh, getListXaByHuyenId } from "../../../services";
import { localStorageItem } from "../../../utils/LocalStorage";
import { KEY_LOCALSTORAGE } from "../../../auth/core/_consts";
import AsyncAutoComplete from "../../../component/input-field/AsyncAutoComplete";
import TextValidator from "../../../component/input-field/text-validator";
import { useIntl } from "react-intl";

const SearchAdvanceForm = () => {
    const [openSearchAdvance, setOpenSearchAdvance] = useState<boolean>(false);
    const location = useLocation();
    const { values, handleChange, errors, touched, setFieldValue, setValues } = useFormikContext<ISearchObjectModel>();
    const userData = localStorageItem.get(KEY_LOCALSTORAGE.USER_INFOMATION)
    const intl = useIntl()

    const handleResetForm = () => {
        setValues({
            ...SEARCH_OBJECT_INIT,
            tinh: userData?.tinhInfo || null,
            huyen: userData?.huyenInfo || null,
            xa: userData?.xaInfo || null,
            gioiTinh: null,
            ngheNghiep: null,
        });
    }

    useEffect(() => {
        setValues({
            ...values,
            tinh: userData?.tinhInfo || null,
            huyen: userData?.huyenInfo || null,
            xa: userData?.xaInfo || null,
        })
    }, [])

    return (
        <>
            {location.pathname === "/tim-kiem-truong-hop-benh" && (
                <div className="spaces mt-14 rounded">
                    <div className="spaces my-10 fw-700 fs-16 color-dark-red text-uppercase">
                    {intl.formatMessage({ id: "SEARCH_PATIENT" })}
                    </div>
                    <div>
                        <Row>
                            <Col xs={12} lg={8}>
                                <div className="flex flex-middle">
                                    <OCTTextValidator
                                        name="keyword"
                                        placeholder={intl.formatMessage({ id: "SEARCHBY_NAME_PATIENTNUMBER_AND_MORE" })}
                                        onChange={handleChange}
                                        className="spaces width-100"
                                        value={values?.keyword || ""}
                                    />
                                </div>
                            </Col>
                            <Col xs={12} lg={4}>
                                <div className="flex flex-middle flex-wrap gap-2 search-action">
                                    <Button className="button-primary spaces height-100 flex flex-middle" type='submit'>
                                        <KTSVG
                                            path="/media/svg/icons/search.svg"
                                            svgClassName="spaces h-14 w-14 color-white"
                                        />
                                        {intl.formatMessage({ id: "SEARCH" })}
                                    </Button>
                                    <Button
                                        className="button-primary spaces height-100 flex flex-middle"
                                        onClick={() => setOpenSearchAdvance((prev) => !prev)}
                                    >
                                        {openSearchAdvance ? (
                                            <KTSVG
                                                path="/media/svg/icons/chevron-up.svg"
                                                svgClassName="spaces h-14 w-14 color-white"
                                            />
                                        ) : (
                                            <KTSVG
                                                path="/media/svg/icons/chevron-down.svg"
                                                svgClassName="spaces h-14 w-14 color-white"
                                            />
                                        )}
                                        {intl.formatMessage({ id: "ADVANCE_SEARCH" })}
                                    </Button>
                                    <Button
                                        className="button-primary spaces height-100 flex flex-middle"
                                        onClick={handleResetForm}
                                    >
                                        <KTSVG
                                            path="/media/svg/icons/recycle.svg"
                                            svgClassName="spaces h-14 w-14 color-white"
                                        />
                                        {intl.formatMessage({ id: "RESELECT" })}
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            )}

            <div className="spaces mt-14 form-search">
                <Row>
                    <Col
                        xs={12}
                        sm={6}
                        md={location.pathname === "/danh-sach-truong-hop-benh" ? 4 : 4}
                        xl={location.pathname === "/danh-sach-truong-hop-benh" ? 4 : 2}
                    >
                        <LabelRequired
                            label={intl.formatMessage({ id: "FULLNAME" })}
                            className="spaces fw-500"
                        />
                        <OCTTextValidator
                            name="hoTen"
                            type="text"
                            onChange={handleChange}
                            value={values.hoTen || ""}
                            errors={errors?.hoTen}
                            touched={touched?.hoTen}
                            placeholder={intl.formatMessage({ id: "FULLNAME" })}
                        />
                    </Col>
                    <Col
                        xs={12}
                        sm={6}
                        md={location.pathname === "/danh-sach-truong-hop-benh" ? 6 : 2}
                        lg={4}
                        xl={location.pathname === "/danh-sach-truong-hop-benh" ? 4 : 2}
                    >
                        <LabelRequired
                            label={intl.formatMessage({ id: "GENDER" })}
                            className="spaces fw-500"
                        />
                        <OCTAutocomplete
                            menuPlacement="bottom"
                            onChange={(selectedOption) => {
                                setFieldValue("gioiTinh", selectedOption?.code)
                            }}
                            className="spaces h-30"
                            name="gioiTinh"
                            options={GENDER_OPTION}
                            valueSearch={"code"}
                            value={values?.gioiTinh || null}
                        />
                    </Col>
                    <Col
                        xs={12}
                        sm={6}
                        md={location.pathname === "/danh-sach-truong-hop-benh" ? 6 : 4}
                        lg={4}
                        xl={location.pathname === "/danh-sach-truong-hop-benh" ? 4 : 2}
                    >
                        <LabelRequired
                            label={intl.formatMessage({ id: "WORK" })}
                            className="spaces fw-500"
                        />
                        <OCTAutocomplete
                            menuPlacement="bottom"
                            onChange={(selectedOption) =>
                                setFieldValue("ngheNghiep", selectedOption)
                            }
                            className="spaces h-30"
                            name="ngheNghiep"
                            options={[]}
                            value={values?.ngheNghiep || null}
                            getOptionLabel={(option) => option?.tenNghe}
                            searchObject={{}}
                            searchFunction={getListNgheNghiep}
                            urlData="data.data"
                        />
                    </Col>
                    <Col
                        xs={12}
                        sm={6}
                        md={location.pathname === "/danh-sach-truong-hop-benh" ? 4 : 4}
                        lg={location.pathname === "/danh-sach-truong-hop-benh" ? 4: 4}
                        xl={location.pathname === "/danh-sach-truong-hop-benh" ? 4 : 2}
                    >
                        <LabelRequired
                            label={intl.formatMessage({ id: "MANAGEMENT_CLASSIFY" })}
                            className="spaces fw-500"
                        />
                        <OCTAutocomplete
                            menuPlacement="bottom"
                            onChange={(selectedOption) =>
                                setFieldValue("phanLoaiQuanLy", selectedOption)
                            }
                            className="spaces h-30"
                            name="phanLoaiQuanLy"
                            options={PHAN_LOAI_QUAN_LY}
                            value={values?.phanLoaiQuanLy}
                        />
                    </Col>
                    <Col
                        xs={12}
                        sm={6}
                        md={location.pathname === "/danh-sach-truong-hop-benh" ? 8 : 4}
                        lg={location.pathname === "/danh-sach-truong-hop-benh" ? 8 : 4}
                        xl={location.pathname === "/danh-sach-truong-hop-benh" ? 8 : 4}
                    >
                        <LabelRequired
                            label={intl.formatMessage({ id: "STATUS_PRESENT" })}
                            className="spaces fw-500"
                        />
                        <OCTAutocomplete
                            isMulti
                            getOptionLabel={(option) => option?.name}
                            getOptionValue={option => option?.code}
                            menuPlacement="bottom"
                            onChange={(selectedOption) =>
                                setFieldValue("listTinhTrangHienNay", selectedOption)
                            }
                            className="spaces h-30"
                            name="listTinhTrangHienNay"
                            options={TINH_TRANG_HIEN_NAY}
                            value={values?.listTinhTrangHienNay}
                        />
                    </Col>
                </Row>
                <div className="location">
                    <div className="spaces fs-16 fw-600 my-14">
                    {intl.formatMessage({ id: "HOUSE_ADDRESS_PATIENT" })}/ {intl.formatMessage({ id: "FACILITY_ADDRESS_REPORT" })}
                    </div>
                    <Row>
                        <Col xs={12} sm={6} md={4} lg={4} xl={location.pathname === "/danh-sach-truong-hop-benh" ? 4 : 2}>
                            <LabelRequired
                                label={intl.formatMessage({ id: "PROVINCE" })}
                                className="spaces fw-500"
                            />
                            <OCTAutocomplete
                                menuPlacement="bottom"
                                maxMenuHeight={200}
                                onChange={(selectedOption) =>
                                    setValues({
                                        ...values,
                                        tinh: selectedOption,
                                        huyen: null,
                                        xa: null,
                                    })
                                }
                                className="spaces h-30"
                                name="tinh"
                                options={[]}
                                value={values.tinh}
                                isDisabled={
                                    !!userData?.tinhId
                                }
                                getOptionLabel={(option) => option?.tenTinh}
                                searchObject={{}}
                                searchFunction={getListTinh}
                                urlData='data.data'
                            />
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={4} xl={location.pathname === "/danh-sach-truong-hop-benh" ? 4 : 2}>
                            <LabelRequired
                                label={intl.formatMessage({ id: "DISTRICT" })}
                                className="spaces fw-500"
                            />
                            <OCTAutocomplete
                                menuPlacement="bottom"
                                maxMenuHeight={200}
                                onChange={(selectedOption) =>
                                    setValues({
                                        ...values,
                                        huyen: selectedOption,
                                        xa: null,
                                    })
                                }
                                className="spaces h-30"
                                name="huyen"
                                options={[]}
                                value={values?.huyen}
                                isDisabled={
                                    !!userData?.huyenId || !Boolean(values?.tinh)
                                }
                                getOptionLabel={(option) => option.tenHuyen}
                                searchObject={{}}
                                searchFunction={() =>
                                    values?.tinh?.id &&
                                    getListHuyenByTinhId(values?.tinh?.id)
                                }
                                dependencies={[values?.tinh]}
                                urlData='data.data'
                            />
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={4} xl={location.pathname === "/danh-sach-truong-hop-benh" ? 4 : 2}>
                            <LabelRequired
                                label={intl.formatMessage({ id: "COMMUNE" })}
                                className="spaces fw-500"
                            />
                            <OCTAutocomplete
                                menuPlacement="bottom"
                                maxMenuHeight={200}
                                onChange={(selectedOption) =>
                                    setFieldValue('xa',selectedOption)
                                }
                                className="spaces h-30"
                                name="xa"
                                options={[]}
                                value={values?.xa}
                                isDisabled={
                                    !!userData?.xaId || !Boolean(values?.huyen)
                                }
                                getOptionLabel={(option) => option.tenXa}
                                searchObject={{}}
                                searchFunction={() =>
                                    values?.huyen?.id  &&
                                    getListXaByHuyenId(values?.huyen?.id)
                                }
                                dependencies={[values?.huyen]}
                                urlData='data.data'
                            />
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={4} xl={location.pathname === "/danh-sach-truong-hop-benh" ? 4 : 2}>
                            <LabelRequired
                                label={intl.formatMessage({ id: "TYPE_REPORT_FROM_DATE" })}
                                className="spaces fw-500"
                            />
                            <TextValidator
                                name="tuNgayNhapBaoCao"
                                type="date"
                                onChange={handleChange}
                                value={values.tuNgayNhapBaoCao || ""}
                                errors={errors?.tuNgayNhapBaoCao}
                                touched={touched?.tuNgayNhapBaoCao}
                            />
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={4} xl={location.pathname === "/danh-sach-truong-hop-benh" ? 4 : 2}>
                            <LabelRequired
                                label={intl.formatMessage({ id: "TYPE_REPORT_TO_DATE" })}
                                className="fw-500"
                            />
                            <TextValidator
                                name="denNgayNhapBaoCao"
                                type="date"
                                onChange={handleChange}
                                value={values.denNgayNhapBaoCao || ""}
                                errors={errors?.denNgayNhapBaoCao}
                                touched={touched?.denNgayNhapBaoCao}
                            />
                        </Col>
                        <Col
                            xs={12}
                            sm={6}
                            md={location.pathname === "/danh-sach-truong-hop-benh" ? 6 : 4}
                            lg={4}
                            xl={location.pathname === "/danh-sach-truong-hop-benh" ? 4 : 2}
                        >
                            <AsyncAutoComplete
                                menuPlacement="top"
                                params={{}}
                                label={intl.formatMessage({ id: "REPORT_FACILITY" })}
                                displayField='tenCoSo'
                                placeholder={intl.formatMessage({ id: "REPORT_FACILITY" })}
                                service={getListCoSoBaoCao}
                                handleChange={(value) => setFieldValue("coSoGhiNhan", value)}
                                value={values?.coSoGhiNhan || ""}
                            />
                        </Col>
                    </Row>
                </div>
                {(openSearchAdvance || location.pathname === "/danh-sach-truong-hop-benh") && (
                    <div className="searchAdvance">
                        <div className="spaces mt-14">
                            <Row>
                                <Col xs={12} sm={6} md={4} lg={2}>
                                    <LabelRequired
                                        label={intl.formatMessage({ id: "DATE_OF_DESEASE_ONSET_FROM" })}
                                        className="fw-500"
                                    />
                                    <TextValidator
                                        name="tuNgayKhoiPhat"
                                        type="date"
                                        onChange={handleChange}
                                        value={values.tuNgayKhoiPhat || ""}
                                        errors={errors?.tuNgayKhoiPhat}
                                        touched={touched?.tuNgayKhoiPhat}
                                    />
                                </Col>
                                <Col xs={12} sm={6} md={4} lg={2}>
                                    <LabelRequired
                                        label={intl.formatMessage({ id: "DATE_OF_DESEASE_ONSET_TO" })}
                                        className="fw-500"
                                    />
                                    <TextValidator
                                        name="denNgayKhoiPhat"
                                        type="date"
                                        onChange={handleChange}
                                        value={values.denNgayKhoiPhat || ""}
                                        errors={errors?.denNgayKhoiPhat}
                                        touched={touched?.denNgayKhoiPhat}
                                    />
                                </Col>
                                <Col xs={12} sm={6} md={4} lg={2}>
                                    <LabelRequired
                                        label={intl.formatMessage({ id: "DATE_TO_HOSPITAL_FROM" })}
                                        className="fw-500"
                                    />
                                    <TextValidator
                                        name="tuNgayNhapVien"
                                        type="date"
                                        onChange={handleChange}
                                        value={values.tuNgayNhapVien || ""}
                                        errors={errors?.tuNgayNhapVien}
                                        touched={touched?.tuNgayNhapVien}
                                    />
                                </Col>
                                <Col xs={12} sm={6} md={4} lg={2}>
                                    <LabelRequired
                                        label={intl.formatMessage({ id: "DATE_TO_HOSPITAL_TO" })}
                                        className="fw-500"
                                    />
                                    <TextValidator
                                        name="denNgayNhapVien"
                                        type="date"
                                        onChange={handleChange}
                                        value={values.denNgayNhapVien || ""}
                                        errors={errors?.denNgayNhapVien}
                                        touched={touched?.denNgayNhapVien}
                                    />
                                </Col>
                                <Col xs={12} sm={6} md={4} lg={2}>
                                    <LabelRequired
                                        label={intl.formatMessage({ id: "DATE_OUT_HOSPITAL_DEAD_FROM" })}
                                        className="fw-500"
                                    />
                                    <TextValidator
                                        name="tuNgayRaVien"
                                        type="date"
                                        onChange={handleChange}
                                        value={values.tuNgayRaVien || ""}
                                        errors={errors?.tuNgayRaVien}
                                        touched={touched?.tuNgayRaVien}
                                    />
                                </Col>
                                <Col xs={12} sm={6} md={4} lg={2}>
                                    <LabelRequired
                                        label={intl.formatMessage({ id: "DATE_OUT_HOSPITAL_DEAD_TO" })}
                                        className="fw-500"
                                    />
                                    <TextValidator
                                        name="denNgayRaVien"
                                        type="date"
                                        onChange={handleChange}
                                        value={values.denNgayRaVien || ""}
                                        errors={errors?.denNgayRaVien}
                                        touched={touched?.denNgayRaVien}
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div className="spaces mt-14">
                            <Row>
                                <Col xs={12} sm={6} md={4} lg={2}>
                                    <LabelRequired
                                        label={intl.formatMessage({ id: "DATE_GET_SPECIMEN_FROM" })}
                                        className="fw-500"
                                    />
                                    <TextValidator
                                        name="tuNgayLayMau"
                                        type="date"
                                        onChange={handleChange}
                                        value={values.tuNgayLayMau || ""}
                                        errors={errors?.tuNgayLayMau}
                                        touched={touched?.tuNgayLayMau}
                                    />
                                </Col>
                                <Col xs={12} sm={6} md={4} lg={2}>
                                    <LabelRequired
                                        label={intl.formatMessage({ id: "DATE_GET_SPECIMEN_TO" })}
                                        className="fw-500"
                                    />
                                    <TextValidator
                                        name="denNgayLayMau"
                                        type="date"
                                        onChange={handleChange}
                                        value={values.denNgayLayMau || ""}
                                        errors={errors?.denNgayLayMau}
                                        touched={touched?.denNgayLayMau}
                                    />
                                </Col>
                                <Col xs={12} sm={6} md={4} lg={2}>
                                    <LabelRequired
                                        label={intl.formatMessage({ id: "DATE_GET_RESULT_FROM" })}
                                        className="fw-500"
                                    />
                                    <TextValidator
                                        name="tuNgayTraKetQuaXn"
                                        type="date"
                                        onChange={handleChange}
                                        value={values.tuNgayTraKetQuaXn || ""}
                                        errors={errors?.tuNgayTraKetQuaXn}
                                        touched={touched?.tuNgayTraKetQuaXn}
                                    />
                                </Col>
                                <Col xs={12} sm={6} md={4} lg={2}>
                                    <LabelRequired
                                        label={intl.formatMessage({ id: "DATE_GET_RESULT_TO" })}
                                        className="fw-500"
                                    />
                                    <TextValidator
                                        name="denNgayTraKetQuaXn"
                                        type="date"
                                        onChange={handleChange}
                                        value={values.denNgayTraKetQuaXn || ""}
                                        errors={errors?.denNgayTraKetQuaXn}
                                        touched={touched?.denNgayTraKetQuaXn}
                                    />
                                </Col>
                                <Col xs={12} sm={6} md={4} lg={location.pathname === "/danh-sach-truong-hop-benh" ? 4 : 2}>
                                    <LabelRequired
                                        label={intl.formatMessage({ id: "SPECIMEN_RESULT" })}
                                        className="spaces fw-500"
                                    />
                                    <OCTAutocomplete
                                        onChange={(selectedOption) =>
                                            setFieldValue("kqXetNghiem", selectedOption?.code)
                                        }
                                        className="spaces h-30"
                                        name="kqXetNghiem"
                                        options={KQ_XET_NGHIEM}
                                        value={values?.kqXetNghiem}
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div className="spaces mt-14">
                            <Row>
                                <Col xs={12} sm={6} md={location.pathname === "/danh-sach-truong-hop-benh" ? 6 : 4} lg={4}>
                                    <AsyncAutoComplete
                                        menuPlacement="top"
                                        params={{}}
                                        label={intl.formatMessage({ id: "SPECIMEN_FACILITY" })}
                                        displayField='tenCoSo'
                                        service={getListCoSoXetNghiem}
                                        handleChange={(value) => setFieldValue("donViThucHienXn", value)}
                                        value={values?.donViThucHienXn || ""}
                                    />
                                </Col>
                                <Col xs={12} sm={6} md={location.pathname === "/danh-sach-truong-hop-benh" ? 6 : 4} lg={4}>
                                    <AsyncAutoComplete
                                        menuPlacement="top"
                                        params={{}}
                                        label={intl.formatMessage({ id: "TREATMENT_FACILITY" })}
                                        displayField='tenCoSo'
                                        service={getListCoSoDieuTri}
                                        handleChange={(value) => setFieldValue("coSoDieuTri", value)}
                                        value={values?.coSoDieuTri || ""}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}

export default SearchAdvanceForm
