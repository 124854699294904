import { createContext, FC, useContext, useEffect, useState } from 'react'
import { useLang } from './Metronici18n'
import { IntlProvider } from 'react-intl'
import '@formatjs/intl-relativetimeformat/polyfill'
import '@formatjs/intl-relativetimeformat/locale-data/en'
import '@formatjs/intl-relativetimeformat/locale-data/de'
import '@formatjs/intl-relativetimeformat/locale-data/es'
import '@formatjs/intl-relativetimeformat/locale-data/fr'
import '@formatjs/intl-relativetimeformat/locale-data/ja'
import '@formatjs/intl-relativetimeformat/locale-data/zh'
import '@formatjs/intl-relativetimeformat/locale-data/vi'
import deMessages from './messages/de.json'
import enMessages from './messages/en.json'
import esMessages from './messages/es.json'
import frMessages from './messages/fr.json'
import jaMessages from './messages/ja.json'
import zhMessages from './messages/zh.json'
import viMessages from './messages/vi.json'
import { WithChildren } from '../helpers'
import axios from 'axios'

const allMessages = {
  de: deMessages,
  "en-US": enMessages,
  es: esMessages,
  fr: frMessages,
  ja: jaMessages,
  zh: zhMessages,
  "vi-VN": viMessages
}

type MessagesType = {
  [key: string]: string
}

const MessagesContext = createContext<{
  messages: MessagesType;
  setMessages: any;
}>({
  messages: {},
  setMessages: () => { }
});


const I18nProvider: FC<WithChildren> = ({ children }) => {
  const API_PATH = process.env.REACT_APP_API_URL;
  const locale = useLang()
  const [messages, setMessages] = useState(allMessages[locale])

  useEffect(() => {
    if(!locale) return;
    init(locale)
  }, [locale])

  const init = async (locale:any) => {
    let API_URL = API_PATH + 'common/file-json-language';
    let res = await axios.get(API_URL, { params: { prefix: locale } });
    setMessages((prevState: any) => ({ ...prevState, ...res?.data }))
  }

  return (
    <MessagesContext.Provider value={{ messages, setMessages }}>
      <IntlProvider locale={locale} messages={messages}>
        {children}
      </IntlProvider>
    </MessagesContext.Provider>
  )
}
const useMessages = () => useContext(MessagesContext)

export { I18nProvider, useMessages }
