import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import TabMenu from "../../../component/tabs/TabMenu";
import { KEY_TAB_CHART, tabDetailChart } from "../../constant/constants";
import "../../dashboard.scss";

interface IProps {}
const DetailChart = (props:IProps) => {
  const [activeTab, setActiveTab] = useState(KEY_TAB_CHART.BIEU_DO)

  const intl = useIntl()
  return (
    <div className="spaces d-flex flex-column justify-content-between">
      <div className="postion-parent-tabs chart-tab-container">
        <TabMenu
          className="custom-position-tabs"
          danhsachTabs={tabDetailChart(intl)}
          setCurrentTab={setActiveTab}
          defaultActiveKey={activeTab}
        />
      </div>
    </div>
  )
}

export default DetailChart;
