import { Form, Formik, FormikHelpers } from "formik";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import TabMenu from "../../../component/tabs/TabMenu";
import { hanhChinhSchema, KeyTab, tabConfig, tabTruongHopBenh } from "../constants/constant";
import { TruongHopBenh } from "../model/Model";
import { AddTruongHopBenh, updateTruongHopBenh } from "../servives/Services";
import { useLoading } from "../../../../AppContext";
import { useIntl } from "react-intl";

type TProps = {
    handleClose: () => void;
    dataRow?: any;
    updatePageData?: () => void;
    getValueForm?:(values: TruongHopBenh) => void
};

const NhapTruongHopBenhModal = (props: TProps) => {
    const { dataRow, handleClose, updatePageData, getValueForm } = props;
    const [activeTab, setActiveTab] = useState(KeyTab.TT_HANH_CHINH)
    const [validationSchema, setValidationSchema] = useState(hanhChinhSchema);
    const [prevTab, setPrevTab] = useState<any>(null)
    const {setPageLoading} = useLoading()
    const intl = useIntl()

    useEffect(() => {
        const { schema, prevTab } = tabConfig[activeTab] || {};
        setValidationSchema(schema || null);
        setPrevTab(prevTab || null);
    }, [activeTab]);

    const formatData = (data: TruongHopBenh): TruongHopBenh => {
        type keyTHB = keyof typeof data;
        type keyChilren = keyof typeof data[keyTHB];

        for (let keyTHB in data) {
            for (let key in data[keyTHB as keyTHB]) {
                if ((data[keyTHB as keyTHB][key as keyChilren] as any) === "") {
                    data[keyTHB as keyTHB][key as keyChilren] = null;
                }
            }
        }
        const {
			donViXetNghiemObject,
			coSoDieuTri,
			coSoQuanLy,
			benhVienChuyenToi,
			donViCongTacNbc,
			benhVienChuyenToiTen,
            capDoBenh,
			capDoBenhRaVien,
			capDoBenhTen,
			coSoQuanLyTen,
			coSoDieuTriTen,
			capDoBenhRaVienTen,
			donViXetNghiemTen,
			donViCongTacNbcTen,
			...thbRest
		} = data.truongHopBenh;

        const {
            ngheNghiep,
            danToc,
            huyenHienNay,
            tinhHienNay,
            xaHienNay,
            tinhThuongTru,
            huyenThuongTru,
            xaThuongTru,
            danTocTen,
            ngheNghiepTen,
            huyenTenHienNay,
            huyenTenThuongTru,
            xaTenThuongTru,
            xaTenHienNay,
            tinhTenHienNay,
            tinhTenThuongTru,
            khuPhoThuongTru,
            khuPhoHienNay,
            ...dtbmRest
        } = data.doiTuongMacBenh;

        return {
            truongHopBenh: {
                ...thbRest,
                capDoBenhId: data?.truongHopBenh?.capDoBenh?.id,
                benhVienChuyenToiId: data?.truongHopBenh?.benhVienChuyenToi?.id,
                donViXetNghiem: data?.truongHopBenh?.donViXetNghiemObject?.id,
                coSoDieuTriId: data?.truongHopBenh?.coSoDieuTri?.id,
                coSoQuanLyId: data?.truongHopBenh?.coSoQuanLy?.id,
                donViCongTacNbcId: data?.truongHopBenh?.donViCongTacNbc?.id,
                capDoBenhRaVienId: data?.truongHopBenh?.capDoBenhRaVien?.id
            },
            doiTuongMacBenh: {
                ...dtbmRest,
                ngheNghiepId: data?.doiTuongMacBenh?.ngheNghiep?.id,
                danTocId: data?.doiTuongMacBenh?.danToc?.id,
                huyenIdHienNay: data?.doiTuongMacBenh?.huyenHienNay?.id,
                xaIdHienNay: data?.doiTuongMacBenh?.xaHienNay?.xaId,
                tinhIdHienNay: data?.doiTuongMacBenh?.tinhHienNay?.id,
                tinhIdThuongTru: data?.doiTuongMacBenh?.tinhThuongTru?.id,
                huyenIdThuongTru: data?.doiTuongMacBenh?.huyenThuongTru?.id,
                xaIdThuongTru: data?.doiTuongMacBenh?.xaThuongTru?.xaId,
                thonIdHienNay: data?.doiTuongMacBenh?.khuPhoHienNay?.id,
                thonIdThuongTru: data?.doiTuongMacBenh?.khuPhoThuongTru?.id,
                copyDiaChiHienTai: Number(data?.doiTuongMacBenh?.copyDiaChiHienTai)
            }
        }
    }

    const handleSubmit = async (values: TruongHopBenh, formikHelpers: FormikHelpers<TruongHopBenh>) => {
        const { nextTab } = tabConfig[activeTab] || {};
        const id = values?.truongHopBenh?.truongHopBenhId
        const formData = formatData(values)

        if (nextTab) return setActiveTab(nextTab);

        if (getValueForm) {
            getValueForm(formData);
            handleClose();
            toast.success(`${intl.formatMessage({ id: "UPDATE_SUCCESS" })}`);
            return;
        }

        try {
            setPageLoading(true);
            id ? await updateTruongHopBenh(id, formData) : await AddTruongHopBenh(formData);

            updatePageData?.();
            toast.success(`${id ? intl.formatMessage({ id: "UPDATE_SUCCESS" }) : intl.formatMessage({ id: "ADD_SUCCESS" })}`);
            handleClose();
        } catch (error) {
            toast.error(error as string);
        }
        finally {
            setPageLoading(false)
        }
    };

    const handleCheckIsValid = (isValid: boolean) => {
        !isValid && toast.warning(intl.formatMessage({ id: "SENTENCE_REQUIREMENT" }))
    }

    return (
        <Modal
            show
            onHide={handleClose}
            centered
            animation
            size="xl"
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <span className="spaces pl-16">
                        {(dataRow?.truongHopBenh.truongHopBenhId || getValueForm) ? intl.formatMessage({ id: "UPDATE" }) : intl.formatMessage({ id: "ADD" })}
                    </span>
                </Modal.Title>
            </Modal.Header>
            <Formik
                initialValues={dataRow}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({ isValid }) => (
                    <Form noValidate >
                        <Modal.Body className="noiDungTruongHopBenh">
                            <TabMenu
                                danhsachTabs={tabTruongHopBenh(intl)}
                                setCurrentTab={setActiveTab}
                                defaultActiveKey={activeTab}
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <div className='d-flex justify-content-between w-100'>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div className='fw-bold'>{intl.formatMessage({ id: "NOTE" })}: <span className='text-danger'>* </span> {intl.formatMessage({ id: "IS_REQUIREMENT" })}</div>
                                </div>
                                <div className="d-flex gap-2">
                                    {
                                        prevTab &&
                                        <Button
                                            className="button-primary"
                                            onClick={() => setActiveTab(prevTab)}>
                                            {intl.formatMessage({ id: "BACK" })}
                                        </Button>
                                    }
                                    <Button
                                        onClick={() => {
                                            handleCheckIsValid(isValid)
                                        }}
                                        className="button-primary"
                                        type="submit">
                                        {activeTab === KeyTab.TT_GHI_NHAN ? intl.formatMessage({ id: "SAVE" }): intl.formatMessage({ id: "CONTINUE" })}
                                    </Button>
                                </div>
                            </div>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
}

export default NhapTruongHopBenhModal;
