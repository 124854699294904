import { OCTTextValidator } from "@oceantech/oceantech-ui"
import { useFormikContext } from "formik"
import { IThongTinODich } from "../models/quanLyODichModels"
import { useIntl } from "react-intl"

const TienXuDichTeBox = () => {
    const { values, handleChange } = useFormikContext<IThongTinODich>()
    const existTHB = Boolean(values?.doiTuongMacBenh?.doiTuongMacBenhId)
    const intl = useIntl()
    return (
        <div className="section-container">
            <div className="spaces mb-10 fs-18 fw-bold text-uppercase color-dark-red">
                {intl.formatMessage({ id: "EPIDEMIOLOGY_HISTORY" })}
            </div>
            <div className="border-top spaces pt-10">
                <OCTTextValidator
                    lable={intl.formatMessage({ id: "EPIDEMIOLOGY_HISTORY" })}
                    type="text"
                    as="textarea"
                    rows={2}
                    value={values?.truongHopBenh?.tienSuDichTe}
                    name={"truongHopBenh.tienSuDichTe"}
                    onChange={handleChange}
                    disabled={existTHB}
                />
            </div>
        </div>
    )
}

export default TienXuDichTeBox
