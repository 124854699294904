import { HINH_THUC_CO_SO } from "../../quan-ly-truong-hop-benh/danh-sach-truong-hop-benh/constants/constant";


export const NAME_PROVINCE = "NAME_1";
export const NAME_DISTRICT = "NAME_2";
export const NAME_COMMUNE = "NAME_3";
export const NAME_RES_PROVINCE = "tenTinh";
export const NAME_RES_DISTRICT = "tenHuyen";
export const NAME_RES_COMMUNE = "tenXa";

export const CAP_USER = {
  TRUNG_UONG: HINH_THUC_CO_SO.TuyenTrungUong,
  TINH: HINH_THUC_CO_SO.TuyenTinh,
  HUYEN: HINH_THUC_CO_SO.TuyenHuyen,
  XA: HINH_THUC_CO_SO.TuyenXa
}

export const LEVEL_AREA = {
  TRUNG_UONG: 3,
  TINH: 2,
  HUYEN: 1,
  XA: 0
}

export const configMapByHinhThuc = {
  [CAP_USER.TRUNG_UONG]: {
    zoomDefault: 8,
    fieldViewLabel: NAME_PROVINCE,
    levelArea: LEVEL_AREA.TRUNG_UONG,
    responseField:NAME_RES_PROVINCE

  },
  [CAP_USER.TINH]: {
    zoomDefault: 11,
    fieldViewLabel: NAME_DISTRICT,
    levelArea: LEVEL_AREA.TINH,
    responseField:NAME_RES_DISTRICT

  },
  [CAP_USER.HUYEN]: {
    zoomDefault: 13,
    fieldViewLabel: NAME_COMMUNE,
    levelArea: LEVEL_AREA.HUYEN,
    responseField: NAME_RES_COMMUNE

  },
  [CAP_USER.XA]: {
    zoomDefault: 15,
    fieldViewLabel: NAME_COMMUNE,
    levelArea: LEVEL_AREA.XA,
    responseField: NAME_RES_COMMUNE
  },
}