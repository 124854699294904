import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';

type Iprops = {
    config: any
    defaultActiveKey?: string
    height?: string
}
const CustomChart = (props: Iprops) => {
    const { config, height = "100vh" } = props

    const chartRef = useRef(null);

    useEffect(() => {
        if (!chartRef.current) return;

        const myChart = echarts.init(chartRef.current, "dark");
        myChart.setOption({
            ...config,
        });

        return () => {
            myChart.dispose();
        };
    }, [config, props?.defaultActiveKey]);

    return <div ref={chartRef} style={{ width: '100%', height }} />;
};

export default CustomChart;
