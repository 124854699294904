import { OCTAutocomplete, OCTTextValidator } from "@oceantech/oceantech-ui";
import { useFormikContext } from "formik";
import { Col, Row } from "react-bootstrap";
import { VUNG_MIEN_OPTION } from "../../constant/constants";
import { IDanhMucDiaPhuong } from "../../model/model";
import { useIntl } from "react-intl";

const FormTinh = () => {

    const {
        values,
        handleChange,
        errors, touched,
        setValues,
    } = useFormikContext<IDanhMucDiaPhuong>()
    const intl = useIntl()

    const handleChangeVungMien = (option: any) => {
        setValues({ ...values, vungMienId: option?.code })
    }

    return <Row>
        <Col>
            <OCTAutocomplete
                lable={intl.formatMessage({ id: "NAME_AREA" })}
                menuPlacement="top"
                getOptionLabel={(option) => option?.name}
                options={VUNG_MIEN_OPTION}
                name='vungMienId'
                valueSearch={"code"}
                searchObject={{}}
                onChange={handleChangeVungMien}
                isRequired
                value={values.vungMienId}
                errors={errors?.vungMienId}
                touched={touched?.vungMienId}
            />
        </Col>
        <Col>
            <OCTTextValidator
                lable={intl.formatMessage({ id: "NAME_PROVINCE" })}
                name="tenTinh"
                isRequired
                value={values.tenTinh}
                onChange={handleChange}
                errors={errors?.tenTinh}
                touched={touched?.tenTinh}
            />
        </Col>
    </Row>
}
export default FormTinh
