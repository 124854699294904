import { useIntl } from "react-intl"
import TienSuBenh from "../../quan-ly-truong-hop-benh/danh-sach-truong-hop-benh/components/TienSuBenhTab"

const TienXuBenhNhanBox = () => {
    const intl = useIntl()
    return (
        <div className="section-container h-100">
            <div className="spaces mb-10 fs-18 fw-bold text-uppercase color-dark-red">
                {intl.formatMessage({ id: "MEDICAL_HISTORY" })}
            </div>
           <TienSuBenh noTitle/>
        </div>
    )
}

export default TienXuBenhNhanBox
