import axios from "axios";
import { CheckTrungParams, ISearchObjectModel } from "../../models/TimKiemTruongHopBenhModels";
const API_PATH = process.env.REACT_APP_API_URL;

export const paramsConfig = (searchObject: object) => {
  const config = { params: searchObject };
  return config;
};

export const searchThbByPage = (searchObject: ISearchObjectModel) => {
  const url = API_PATH + "truong-hop-benh/search";
  return axios.get(url, paramsConfig(searchObject));
};

export const searchThbOdichByPage = (searchObject: ISearchObjectModel) => {
    const url = API_PATH + "truong-hop-benh/search/o-dich";
    return axios.get(url, paramsConfig(searchObject));
};

export const checkTrungTruongHopBenh = (searchObject: CheckTrungParams) => {
  const url = API_PATH + "truong-hop-benh/check-trung";
  return axios.get(url, paramsConfig(searchObject));
}

export const getOutbreaks = (searchObject: ISearchObjectModel) => {
  const url = API_PATH + "o-dich/search";
  return axios.get(url, paramsConfig(searchObject));
};

export const assingPatientToOutbreak = (data: any) => {
  const url = API_PATH + `o-dich/o-dich/${data?.idODich}/truong-hop-benh/${data?.idThb}`;
  return axios.put(url, {});
};

export const createOutbreakWithId = (id: any) => {
  let url = API_PATH + `o-dich/${id}`;
  return axios.post(url);
}

export const unAssignPatient = (id: string) => {
  let url = API_PATH + `o-dich/truong-hop-benh/${id}`;
  return axios.delete(url);
}
