import { FC, Suspense, lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { WithChildren } from "../../_metronic/helpers";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import { PERMISSIONS, PERMISSION_ABILITY } from "../Constant";
import { HomePage } from "../pages/Homepage/HomePage";
import { MenuTestPage } from "../pages/MenuTestPage";
import DanhSachTruongHopBenh from "../modules/quan-ly-truong-hop-benh/danh-sach-truong-hop-benh/DanhSachTruongHopBenh";
import TimKiemTruongHopBenh from "../modules/quan-ly-truong-hop-benh/tim-kiem-truong-hop-benh/TimKiemTruongHopBenh";
import DanhSachODich from "../modules/quan-ly-o-dich/DanhSachODich";
import ThemMoiODich from "../modules/quan-ly-o-dich/ThemMoiODich";
import BaoCaoTuan from "../modules/bao-cao/BaoCaoTuan";
import NhapTuExcel from "../modules/quan-ly-truong-hop-benh/danh-sach-truong-hop-benh/components/NhapTuExcel";
import { hasAuthority } from "../modules/utils/FunctionUtils";
import DanhMucDiaPhuong from "../modules/danh-muc/components/danh-muc-dia-phuong/DanhMucDiaPhuong";
import DanhMucNguoiDung from "../modules/danh-muc/components/danh-muc-nguoi-dung";
import DanhMucCoSo from "../modules/danh-muc/components/danh-muc-co-so";
import NhapMoiBaoCao from "../modules/bao-cao/components/nhap-moi-bao-cao/NhapMoiBaoCao";
import DashboardDuBao from "../modules/Dashboard/components/thong-tin-du-bao/DashboardDuBao";
import DashboardGiamSat from './../modules/Dashboard/components/thong-tin-giam-sat/DashboardGiamSat';
import NhapDuongCongChuan from "../modules/danh-muc/components/nhap-duong-cong-chuan/NhapDuongCongChuan";


interface PrivateRouteProps {
  auth: string;
  ability: string;
  component: React.ComponentType<any>;
  redirect: string;
}


interface PrivateRouteProps {
  auth: string;
  ability: string;
  component: React.ComponentType<any>;
  redirect: string;
}

const PrivateRoutes = () => {

  const PrivateRoute: React.FC<PrivateRouteProps> = ({ auth, ability, component: Component, redirect, }) => {
    //khi có phân quyền sẽ check
    return hasAuthority(auth, ability) ? (<Component />) : (<Navigate to={redirect} />);
    // return true ? (<Component />) : (<Navigate to={redirect} />);
  };

  return (
    <Routes>
      <Route index element={<Navigate to="/home" />} />
      <Route path="/*" element={<HomePage />} />
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path="auth/*" element={<Navigate to="quan-ly-ca-benh" />} />
        <Route path="/dashboard" element={<PrivateRoute auth={PERMISSIONS.D_GIAM_SAT} ability={PERMISSION_ABILITY.VIEW} component={DashboardGiamSat} redirect="/home" />} />
        <Route path="/danh-sach-truong-hop-benh" element={<PrivateRoute auth={PERMISSIONS.THB} ability={PERMISSION_ABILITY.VIEW} component={DanhSachTruongHopBenh} redirect="/home" />} />
        <Route path="/tim-kiem-truong-hop-benh" element={<PrivateRoute auth={PERMISSIONS.THB} ability={PERMISSION_ABILITY.VIEW} component={TimKiemTruongHopBenh} redirect="/home" />} />
        <Route path="/danh-sach-o-dich" element={<PrivateRoute auth={PERMISSIONS.ODICH} ability={PERMISSION_ABILITY.VIEW} component={DanhSachODich} redirect="/home" />} />
        <Route path="/them-moi-o-dich" element={<PrivateRoute auth={PERMISSIONS.ODICH} ability={PERMISSION_ABILITY.CREATE} component={ThemMoiODich} redirect="/home" />} />
        <Route path="/bao-cao-tuan" element={<PrivateRoute auth={PERMISSIONS.BAOCAO} ability={PERMISSION_ABILITY.VIEW} component={BaoCaoTuan} redirect="/home" />} />
        <Route path="/dashboard-du-bao" element={<PrivateRoute auth={PERMISSIONS.D_DU_BAO} ability={PERMISSION_ABILITY.VIEW} component={DashboardDuBao} redirect="/home" />} />
        <Route path="/nhap-moi-bao-cao" element={<PrivateRoute auth={PERMISSIONS.BAOCAO} ability={PERMISSION_ABILITY.CREATE} component={NhapMoiBaoCao} redirect="/home" />} />
        <Route path="/danh-muc-dia-phuong" element={<PrivateRoute auth={PERMISSIONS.DM_XA} ability={PERMISSION_ABILITY.VIEW} component={DanhMucDiaPhuong} redirect="/home" />} />
        <Route path="/danh-muc-nguoi-dung" element={<PrivateRoute auth={PERMISSIONS.DM_NGUOI_DUNG} ability={PERMISSION_ABILITY.VIEW} component={DanhMucNguoiDung} redirect="/home" />} />
        <Route path="/danh-muc-co-so" element={<PrivateRoute auth={PERMISSIONS.DM_CO_SO} ability={PERMISSION_ABILITY.VIEW} component={DanhMucCoSo} redirect="/home" />} />
        <Route path="/nhap-duong-cong-chuan" element={<PrivateRoute auth={PERMISSIONS.THB} ability={PERMISSION_ABILITY.VIEW} component={NhapDuongCongChuan} redirect="/home" />} />
        <Route path="/nhap-tu-excel" element={<PrivateRoute auth={PERMISSIONS.THB} ability={PERMISSION_ABILITY.VIEW} component={NhapTuExcel} redirect="/home" />} />
        <Route path="/chinh-sua-o-dich/:id" element={<PrivateRoute auth={PERMISSIONS.ODICH} ability={PERMISSION_ABILITY.CREATE} component={ThemMoiODich} redirect="/home" />} />
        {/* Pages */}
        <Route path="menu-test" element={<MenuTestPage />} />
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
};

export const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--kt-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
