import { useNavigate } from "react-router-dom";
import { checkMenuByPermissions } from "../../../../../app/modules/utils/FunctionUtils";
import { TMenu } from "../../../../../app/pages/Homepage/listMenu";
import { useIntl } from "react-intl";
import "./index.scss";

const MobileMenu = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const checkedMenu = checkMenuByPermissions();

  return (
    <div style={{ backgroundColor: "#343a40" }} className="mobie_menu header-list-nav position-absolute zIndex-2 d-flex flex-column w-100 p-2 color-white">
      {checkedMenu?.map((item: TMenu, index: number) => (
        <div key={index} className="item w-100 text-center border-bottom">
          <div className="fs-2">
          {intl.formatMessage({ id: item?.title })}
          </div>
          <ul className="list-group">
            {item.subMenu?.map((value, subIndex) => (
              <li key={subIndex} onClick={() => navigate(value?.to)}>
                <a className="dropdown-item" href="#" onClick={(e) => e.preventDefault()}>
                  {value?.title}
                </a>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default MobileMenu;
