import { useFormikContext } from "formik";
import { TruongHopBenh } from "../model/Model";
import CustomMap from "../../../component/map/CustomMap";
import { useIntl } from "react-intl";
import OCTMap from "../../../component/oct-map/OCTMap";

const ToaDoCaBenh = (props: any) => {
    const { values } = useFormikContext<TruongHopBenh>();
    const intl = useIntl()
    const toadoCaBenh: any =
        values.truongHopBenh.kinhDo && values.truongHopBenh.viDo
            ? [values.truongHopBenh.viDo, values.truongHopBenh.kinhDo]
            : null;
    return toadoCaBenh ? (
        <div className="spaces mt-10">
            <OCTMap
                key={Math.random()}
                height='calc(100vh - 320px)'
                width='100%'
                zoom={14}
                center={toadoCaBenh as any}
                tileLayerUrl='https://maps.vietmap.vn/api/dm/{z}/{x}/{y}@2x.png?apikey=0344e4a08f31899b9fc0ac0db97f4685e6169cd63532164b'
                defaultMarkerPosition={toadoCaBenh as any}
            />
        </div>
    ) : (
        <div className="text-center text-primary fs-2 p-10">{intl.formatMessage({ id: "NO_COORDINATES" })}</div>
    );
};

export default ToaDoCaBenh;
