import {Suspense, useState} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit} from './modules/auth'
import 'react-toastify/dist/ReactToastify.css'
import {ToastContainer, toast, Zoom} from 'react-toastify'
import LoadingIndicator from './modules/component/loading/LoadingIndicator'
import { AppContext } from './AppContext'
import { localStorageItem } from './modules/utils/LocalStorage'
import { KEY_LOCALSTORAGE } from './modules/auth/core/_consts'

const App = () => {
  const [pageLoading, setPageLoading] = useState<boolean>(false);
  const userData = localStorageItem.get(KEY_LOCALSTORAGE.USER_INFOMATION)

  return (
    <AppContext.Provider value={{ pageLoading, setPageLoading, userData }}>
      <LoadingIndicator show={pageLoading} />
      <Suspense fallback={<LayoutSplashScreen />}>
        <AuthInit>
          <I18nProvider>
            <LayoutProvider>
              <Outlet />
              <MasterInit />
              <ToastContainer
                autoClose={3000}
                position={toast.POSITION.TOP_RIGHT}
                transition={Zoom}
                theme='colored'
                pauseOnHover={false}
              />
            </LayoutProvider>
          </I18nProvider>
        </AuthInit>
      </Suspense>
    </AppContext.Provider>
  )
}

export {App}
