import { useInitContext } from '../../../utils/InitContext'

type Props = {}

const DanhSachDiaPhươngGS = (props: Props) => {
    const { data } = useInitContext()
    const { inforDSDPGS } = data

    return (
        <div className='bg-color-tab spaces'>
            {
                inforDSDPGS?.map((item: any, index: number) => (
                    <div id={`infor-diaphong-${index}`} className='d-flex spaces gap-6 pl-4 pr-12 py-4 mb-4 bg-dark'>
                        <div style={{ backgroundColor: item?.location?.color }} className='spaces w-20'></div>
                        <div className='text-gray-700 d-flex flex-column spaces gap-2 flex-1 '>
                            <div className='spaces d-flex justify-content-between'>
                                <span><span className='text-uppercase text-white'>{item?.location?.name}</span></span>
                                <span>
                                    {item?.statics?.[1]?.label}
                                    <span style={{ color: item?.statics?.[1]?.values?.[0].color }} className='text-uppercase'>{item?.statics?.[1]?.values?.[0]?.number}/</span>
                                    <span style={{ color: item?.statics?.[1]?.values?.[1].color }} className='text-uppercase'>{item?.statics?.[1]?.values?.[1]?.number}</span>
                                </span>
                            </div>
                            <div>{item?.statics?.[0]?.label} <span className='text-warning'>{item?.statics?.[1]?.values?.[0].number}</span></div>
                            <div className='d-flex justify-content-between '>
                                <div>{item?.statics?.[2]?.label}
                                    <span style={{ color: item?.statics?.[2]?.values?.[0].color }} className='text-uppercase'>{item?.statics?.[2]?.values?.[0]?.number}/</span>
                                    <span style={{ color: item?.statics?.[2]?.values?.[1].color }} className='text-uppercase'>{item?.statics?.[2]?.values?.[1]?.number}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default DanhSachDiaPhươngGS
