import { OCTKTSVG, OCTTable } from "@oceantech/oceantech-ui"
import { useEffect, useRef, useState } from "react"
import { Button } from "react-bootstrap"
import { toast } from "react-toastify"
import { useLoading } from "../../../../AppContext"
import CustomDialog from "../../../component/dialogs/CustomDialog"
import { RESPONSE_STATUS_CODE } from "../../../utils/Constant"
import { arrProvince, INIT_VALUE_DIA_PHUONG, KEY_TAB, STATUS_DIA_PHUONG, tinhSchema } from "../../constant/constants"
import { IDanhMucDiaPhuong, IResponseDanhMucDiaPhuong, ITabPropsDanhMucDiaPhuong } from "../../model/model"
import { addDanhMuc, deleteDanhMuc, exportExcel, getDanhMuc, updateDanhMuc } from "../../services/services"
import FormTinh from "../danh-muc-dia-phuong/FormTinh"
import SearchField from "../searchField"
import { useIntl } from "react-intl"
import { exportToExcel, exportToExcelV2, exportToFile } from "../../../utils/FunctionUtils"

const DanhMucTinh = (props: ITabPropsDanhMucDiaPhuong) => {
  const { defaultActiveKey } = props;
  const { setPageLoading } = useLoading()
  const [data, setData] = useState<IResponseDanhMucDiaPhuong>()
  const [params, setParams] = useState({ pageNumber: 1, pageSize: 10, TenTinh: "" })
  const [openDialogAdd, setOpenDialogAdd] = useState(false)
  const [dataForm, setDataForm] = useState(INIT_VALUE_DIA_PHUONG)
  const [reload, setReload] = useState(false)
  const [keySearch, setKeySearch] = useState<string>("")
  const intl = useIntl()

  useEffect(() => {
    KEY_TAB.TINH === defaultActiveKey && getProvinces()
  }, [params, defaultActiveKey, reload])

  const getProvinces = async () => {
    try {
      setPageLoading(true);
      let response = await getDanhMuc(params, "tinh/page");
      if (response?.data?.code !== RESPONSE_STATUS_CODE.SUCCESS) return;
      setData(response?.data?.data)
    } catch (error) {
      toast.error("Đã có lỗi!")
      console.error("ERROR", error)
    } finally {
      setPageLoading(false)
    }
  }

  const handleOpenForm = (row: IDanhMucDiaPhuong) => {
    setDataForm(row)
    setOpenDialogAdd(true)
  }

  const handleSubmit = async (values: IDanhMucDiaPhuong) => {
    const { tinh, huyen, xa, vungMien, ...dataRest } = values
    try {
      setPageLoading(true)
      let response = dataRest?.id
        ? await updateDanhMuc(dataRest, `tinh/${dataRest?.id}`)
        : await addDanhMuc(dataRest, "tinh");
      if (![RESPONSE_STATUS_CODE.SUCCESS, RESPONSE_STATUS_CODE.CREATED].includes(response?.data?.code)) return;
      toast.success("Thành công!")
      setReload(prev => !prev)
      setOpenDialogAdd(false)
    }
    catch (error) {
      toast.error("Đã có lỗi!")
      console.error("ERROR", error)
    } finally {
      setPageLoading(false)
    }
  }

  const handleActiveDiaPhuong = async (id: string, rowData: IDanhMucDiaPhuong) => {
    try {
      setPageLoading(true)
      !rowData.isActive ? await deleteDanhMuc(`tinh/${id}`) : await updateDanhMuc({ ...rowData, isActive: STATUS_DIA_PHUONG.ACTIVE }, `tinh/${id}`)
      setReload(prev => !prev)
      toast.success("Thành công!")
    } catch (error) {
      console.error("ERROR", error)
    } finally {
      setPageLoading(false)
    }
  }

  const handlerSearch = () => {
    setParams({ ...params, TenTinh: keySearch })
  }

  return (
    <div className="section-container spaces mt-15">
      <div className="spaces d-flex justify-content-between">
        <SearchField keySearch={keySearch} setKeySearch={setKeySearch} handlerSearch={handlerSearch}/>
        <div className="d-flex gap-2">
          <Button
            className="button-primary"
            onClick={() => {
              setDataForm(INIT_VALUE_DIA_PHUONG)
              setOpenDialogAdd(true)
            }}
          >
            <OCTKTSVG path='/media/svg/icons/plus.svg' svgClassName='spaces h-14 w-14 color-white' />
            {intl.formatMessage({ id: "ADD" })}
          </Button>
          <Button
            className="button-primary"
            onClick={() => exportToFile(
              {
                exportAPI: () => exportExcel('tinh/export-search', params),
                fileName: "Danh sách tỉnh",
                setPageLoading
              }
            )}
          >
            {intl.formatMessage({ id: "EXCEL_EXPORT" })}
          </Button>
        </div>
      </div>
      <OCTTable
        id="profile"
        height={500}
        data={data?.data || []}
        columns={arrProvince({
          handleOpenForm,
          handleActiveDiaPhuong,
          intl
        })}
        searchObject={params}
        setSearchObject={(obj) => setParams(prevState => ({ ...obj, pageNumber: obj?.pageIndex, pageSize: obj?.pageSize ?? prevState?.pageSize }))}
        fixedColumnsCount={0}
        notDelete={true}
        notEdit={true}
        noToolbar={true}
        totalPages={data?.totalPages}
        totalElements={data?.total}
        noPagination={Number(data?.total) > 10 ? false : true}
      />

      {openDialogAdd &&
        <CustomDialog
          handleSubmit={handleSubmit}
          isShow={openDialogAdd} setIsShow={setOpenDialogAdd}
          initValue={dataForm}
          validationSchema={tinhSchema}
        >
          <FormTinh />
        </CustomDialog>
      }
    </div>
  )
}

export default DanhMucTinh;
