import { OCTAutocomplete, OCTTextValidator } from '@oceantech/oceantech-ui';
import { useFormikContext } from 'formik';
import { useContext, useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Col, Row } from '../../../component/Grid';
import RadioGroup from '../../../component/input-field/RadioGroup';
import { getListDanToc, getListHuyenByTinhId, getListNgheNghiep, getListThonByXaId, getListTinh, getListXaByHuyenId } from '../../../services';
import { calculateAge } from '../../../utils/AppFunction';
import { handleChangeHuyen, handleChangeTinh, handleChangeXa, handleSetConfigTable, haveInfomation, heightMenuAutocomplete } from '../../../utils/FunctionUtils';
import { CheckTrungParams, INIT_VALUE_CHECK_TRUNG } from '../../models/TimKiemTruongHopBenhModels';
import { checkTrungTruongHopBenh } from '../../tim-kiem-truong-hop-benh/services/TimKiemThbServices';
import { CMND_CHECK_TRUNG, GENDER_OPT, INIT_TRUONG_HOP_BENH, renderTrangThaiPhanHoi } from '../constants/constant';
import { TruongHopBenh } from '../model/Model';
import { toast } from 'react-toastify';
import DanhSachTHBModal from './DanhSachTHB';
import { formatDataViewTHB } from './../../../utils/FunctionUtils';
import { localStorageItem } from '../../../utils/LocalStorage';
import { KEY_LOCALSTORAGE } from '../../../auth/core/_consts';
import { regex } from '../../../constant';
import { VIEW_COLUMN } from '../../../utils/Constant';
import { useLoading } from '../../../../AppContext';
import { useIntl } from 'react-intl';

type Props = {
    onlyView?: boolean,
    viewColumn?: number
}

const ThongTinHanhChinhTab = ({ onlyView, viewColumn }: Props) => {
    const {
        values,
        handleChange,
        errors, touched,
        setFieldValue,
        setValues,
    } = useFormikContext<TruongHopBenh>()

    const [dataCheckTrung, setDataCheckTrung] = useState<CheckTrungParams>(INIT_VALUE_CHECK_TRUNG)
    const { setPageLoading } = useLoading();
    const [dSCheckTrung, setDSCheckTrung] = useState<any[]>([])
    const [openModalDS, setOpenModalDS] = useState(false)
    const [configTable, setConfigTable] = useState<any>({});
    const ageRef = useRef<number>();
    const userData = localStorageItem.get(KEY_LOCALSTORAGE.USER_INFOMATION)
    const SIZE_COLUMN = useRef<number>();
    const intl = useIntl()

    useEffect(() => {
        SIZE_COLUMN.current = viewColumn === VIEW_COLUMN.TWO_COLUMN ? 6 : 12
    }, [viewColumn])

    const handleCheckTrung = async (params: CheckTrungParams) => {
        try {
            setPageLoading(true)
            const { data } = await checkTrungTruongHopBenh(params)
            if (data?.data?.data?.length > 0) {
                const dataTemp = data?.data?.data.map((item: any, index: number) => {
                    return index === 0 ? { ...item, isChecked: true } : item;
                })
                setDSCheckTrung(dataTemp)
                handleSetConfigTable(setConfigTable, data?.data)
                setOpenModalDS(true)
            }
            toast.success('Kiểm tra thông tin trùng thành công')
        } catch (error) {
            console.error('error', error)
        }
        finally {
            setPageLoading(false)
        }
    }

    const handleSetDataCheckTrung = (value: string, name: string) => {
        if (
			dataCheckTrung[name as keyof CheckTrungParams] === value ||
			(name === CMND_CHECK_TRUNG && !new RegExp(regex?.cccd).test(value))
		) {
			return;
		}


        setDataCheckTrung({
            ...dataCheckTrung,
            [name]: value
        });
    };

    const handleChangeGioiTinh = (event: any) => {
        setFieldValue('doiTuongMacBenh.gioiTinh', event?.target?.value)
        handleSetDataCheckTrung(event.target.value, 'GioiTinh')
    }

    const handleChangeXaHienNay = (option: any) => {
        handleChangeXa(setValues, 'doiTuongMacBenh', 'xaHienNay', "khuPhoHienNay", option)
        setDataCheckTrung({
            ...dataCheckTrung,
            XaId: option?.xaId
        })
    }

    const handleChangeHuyenHienNay = (option: any) => {
        handleChangeHuyen(setValues, 'doiTuongMacBenh', 'huyenHienNay', 'xaHienNay', "khuPhoHienNay", option)
        setDataCheckTrung({
            ...dataCheckTrung,
            HuyenId: option?.id
        })
    }

    const handleChangeTinhHienNay = (option: any) => {
        handleChangeTinh(setValues, 'doiTuongMacBenh', 'tinhHienNay', 'huyenHienNay', "xaHienNay", "khuPhoHienNay", option)
        setDataCheckTrung({
            ...dataCheckTrung,
            TinhId: option?.id
        })
    }

    const handleChangeHaveCmnd = (event: any) => {
        haveInfomation(setValues, 'doiTuongMacBenh', 'haveCmnd', 'cmnd', event)
        setDataCheckTrung({
            ...dataCheckTrung,
            HaveCmnd: !event?.target?.checked,
            Cmnd: ""
        })
    }

    const handleSelectTHBTrung = (data: TruongHopBenh) => {
        setValues(formatDataViewTHB(data))
    }

    const checkTrungCondition = (values: TruongHopBenh, dataCheckTrung: CheckTrungParams) => {
        if (values?.doiTuongMacBenh?.doiTuongMacBenhId) {
            return;
        }

        if (dataCheckTrung.Cmnd && dataCheckTrung.HaveCmnd) {
            handleCheckTrung(dataCheckTrung);
            return;

        }

        if (dataCheckTrung.HoTen && dataCheckTrung.NgaySinh && dataCheckTrung.XaId && !dataCheckTrung.HaveCmnd) {
            handleCheckTrung(dataCheckTrung);
            return;
        }
    };

    useEffect(() => {
        checkTrungCondition(values, dataCheckTrung);
    }, [dataCheckTrung]);

    useEffect(() => {
        if (!values?.doiTuongMacBenh?.doiTuongMacBenhId) {
            setValues({
                ...values,
                doiTuongMacBenh: {
                    ...values?.doiTuongMacBenh,
                    tinhHienNay: userData?.tinhInfo,
                    huyenHienNay: userData?.huyenInfo,
                    xaHienNay: userData?.xaInfo
                }
            })
        }

        setDataCheckTrung({
            ...dataCheckTrung,
            TinhId: userData?.tinhInfo?.id,
            HuyenId: userData?.huyenInfo?.id,
            XaId: userData?.xaInfo?.xaId
        })

    }, [])

    useEffect(() => {
        if (!values?.doiTuongMacBenh?.copyDiaChiHienTai) { return }
        setValues({
            ...values,
            doiTuongMacBenh: {
                ...values?.doiTuongMacBenh,
                diaChiThuongTru: values?.doiTuongMacBenh?.diaChiHienNay,
                tinhThuongTru: values?.doiTuongMacBenh?.tinhHienNay,
                huyenThuongTru: values?.doiTuongMacBenh?.huyenHienNay,
                xaThuongTru: values?.doiTuongMacBenh?.xaHienNay,
                khuPhoThuongTru: values?.doiTuongMacBenh?.khuPhoHienNay
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        values?.doiTuongMacBenh?.diaChiHienNay,
        values?.doiTuongMacBenh?.copyDiaChiHienTai,
        values?.doiTuongMacBenh?.tinhHienNay,
        values?.doiTuongMacBenh?.huyenHienNay,
        values?.doiTuongMacBenh?.xaHienNay,
        values?.doiTuongMacBenh?.khuPhoHienNay,
        values?.doiTuongMacBenh?.khuPhoHienNay
    ])

    return (
        <Row>
            <Col xl={6}>
                {values?.doiTuongMacBenh?.maSo && (
                    <div className="fw-bold spaces mt-18">
                        {intl.formatMessage({ id: "CODE_NUMBER" })}: {values?.doiTuongMacBenh?.maSo}
                    </div>
                )}
            </Col>
            <Col xl={6}>
                <div className="spaces mt-18">
                    {renderTrangThaiPhanHoi(
                        values?.truongHopBenh?.trangThaiPhanHoi as number,
                        values?.truongHopBenh?.ngayTao as string,
                        true,
                        intl
                    )}
                </div>
            </Col>
            <Row className='spaces px-0'>
                <Col className='spaces pr-0' xl={SIZE_COLUMN.current} >
                    <Row xl={12}>
                        <Col xl={6}>
                            <OCTTextValidator
                                lable={intl.formatMessage({ id: "FULLNAME" })}
                                name="doiTuongMacBenh.hoTen"
                                isRequired
                                value={values?.doiTuongMacBenh?.hoTen}
                                onChange={handleChange}
                                errors={errors?.doiTuongMacBenh?.hoTen}
                                touched={touched?.doiTuongMacBenh?.hoTen}
                                disabled={onlyView}
                                onBlur={() => handleSetDataCheckTrung(String(values.doiTuongMacBenh.hoTen), 'HoTen')}
                            />
                        </Col>
                        <Col xl={4}>
                            <OCTTextValidator
                                lable={intl.formatMessage({ id: "DATE_OF_BIRTH" })}
                                type="date"
                                isRequired
                                name="doiTuongMacBenh.ngaySinh"
                                value={values?.doiTuongMacBenh?.ngaySinh}
                                onChange={handleChange}
                                errors={errors?.doiTuongMacBenh?.ngaySinh}
                                touched={touched?.doiTuongMacBenh?.ngaySinh}
                                disabled={onlyView}
                                onBlur={() => {
                                    handleSetDataCheckTrung(String(values.doiTuongMacBenh.ngaySinh), 'NgaySinh')
                                    ageRef.current = calculateAge(values?.doiTuongMacBenh?.ngaySinh)
                                }}
                            />
                        </Col>
                        <Col xl={2}>
                            <OCTTextValidator
                                lable={intl.formatMessage({ id: "AGE" })}
                                type="text"
                                name="tuoi"
                                value={ageRef.current || ""}
                                disabled
                            />
                        </Col>
                    </Row>
                </Col>

                <Col className='spaces pr-0' xl={SIZE_COLUMN.current}>
                    <Row xl={12}>
                        <Col xl={6}>
                            <RadioGroup
                                name='doiTuongMacBenh.gioiTinh'
                                isRequired
                                lable={intl.formatMessage({ id: "GENDER" })}
                                value={values.doiTuongMacBenh?.gioiTinh}
                                radioItemList={GENDER_OPT(intl)}
                                handleChange={(event) => { handleChangeGioiTinh(event) }}
                                disabledFields={onlyView ? GENDER_OPT(intl).map(item => item.code) : []}
                            />
                        </Col>
                        <Col xl={6}>
                            <OCTAutocomplete
                                lable={intl.formatMessage({ id: "WORK" })}
                                searchFunction={getListNgheNghiep}
                                urlData='data.data'
                                getOptionLabel={(option) => option?.tenNghe}
                                options={[]}
                                value={values.doiTuongMacBenh?.ngheNghiep}
                                onChange={(option) => {
                                    setFieldValue("doiTuongMacBenh.ngheNghiep", option)
                                }}
                                searchObject={{}}
                                isRequired
                                errors={errors.doiTuongMacBenh?.ngheNghiep}
                                touched={touched.doiTuongMacBenh?.ngheNghiep}
                                isDisabled={onlyView}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row className='spaces px-0'>
                <Col className='spaces pr-0' xl={SIZE_COLUMN.current}>
                    <Row xl={12}>
                        <Col xl={6}>
                            <OCTAutocomplete
                                lable={intl.formatMessage({ id: "ETHNICITY" })}
                                searchFunction={getListDanToc}
                                urlData='data.data'
                                getOptionLabel={(option) => option?.tenDanToc}
                                options={[]}
                                value={values.doiTuongMacBenh?.danToc}
                                onChange={(option) => setFieldValue("doiTuongMacBenh.danToc", option)}
                                searchObject={{}}
                                isRequired
                                errors={errors.doiTuongMacBenh?.danToc}
                                touched={touched.doiTuongMacBenh?.danToc}
                                isDisabled={onlyView}
                            />
                        </Col>
                        <Col xl={6}>
                            <OCTTextValidator
                                lable={intl.formatMessage({ id: "ID_NUMBER" })}
                                type="text"
                                isRequired
                                name="doiTuongMacBenh.cmnd"
                                value={values.doiTuongMacBenh?.cmnd}
                                onChange={handleChange}
                                errors={errors?.doiTuongMacBenh?.cmnd}
                                touched={touched?.doiTuongMacBenh?.cmnd}
                                disabled={!values.doiTuongMacBenh?.haveCmnd || onlyView}
                                onBlur={() => handleSetDataCheckTrung(String(values.doiTuongMacBenh.cmnd), 'Cmnd')}
                            />
                            <Form.Check
                                disabled={onlyView}
                                className='mt-2'
                                name='doiTuongMacBenh.haveCmnd'
                                label={intl.formatMessage({ id: "NO_ID_NUMBER" })}
                                checked={!values.doiTuongMacBenh?.haveCmnd}
                                onChange={handleChangeHaveCmnd}
                            />
                        </Col>
                    </Row>

                </Col>

                <Col className='spaces pr-0' xl={SIZE_COLUMN.current}>
                    <Row xl={12}>
                        <Col xl={6}>
                            <OCTTextValidator
                                lable={intl.formatMessage({ id: "PHONE_NUMBER" })}
                                type="text"
                                isRequired
                                name="doiTuongMacBenh.dienThoai"
                                value={values.doiTuongMacBenh?.dienThoai}
                                onChange={handleChange}
                                errors={errors?.doiTuongMacBenh?.dienThoai}
                                touched={touched?.doiTuongMacBenh?.dienThoai}
                                disabled={!values.doiTuongMacBenh?.haveDienThoai || onlyView}

                            />
                            <Form.Check
                                disabled={onlyView}
                                className='mt-2'
                                label={intl.formatMessage({ id: "NO_PHONE_NUMBER" })}
                                name='doiTuongMacBenh.haveDienThoai'
                                checked={!values.doiTuongMacBenh?.haveDienThoai}
                                onChange={(event) => {
                                    haveInfomation(setValues, "doiTuongMacBenh", "haveDienThoai", "dienThoai", event)
                                }}
                            />
                        </Col>
                        <Col xl={6}>
                            <OCTTextValidator
                                lable={intl.formatMessage({ id: "WORKPLACE_STUDY" })}
                                type="text"
                                name="doiTuongMacBenh.noiLamViecHocTap"
                                value={values.doiTuongMacBenh?.noiLamViecHocTap}
                                onChange={handleChange}
                                disabled={onlyView}

                            />
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row className='spaces px-0'>
                <Col xs={12}>
                    <div className='fw-bold'>{intl.formatMessage({ id: "ADDRESS_INFOMATION_PRESENT" })}</div>
                </Col>
            </Row>

            <Row className='spaces px-0'>
                <Col className='spaces pr-0' xl={SIZE_COLUMN.current}>
                    <Row xl={12}>
                        <Col xl={6}>
                            <OCTTextValidator
                                lable={intl.formatMessage({ id: "ADDRESS_PRESENT" })}
                                type="text"
                                name="doiTuongMacBenh.diaChiHienNay"
                                onChange={handleChange}
                                isRequired
                                value={values.doiTuongMacBenh?.diaChiHienNay}
                                errors={errors?.doiTuongMacBenh?.diaChiHienNay}
                                touched={touched?.doiTuongMacBenh?.diaChiHienNay}
                                disabled={onlyView}

                            />
                        </Col>
                        <Col xl={6}>
                            <OCTAutocomplete
                                lable={intl.formatMessage({ id: "PROVINCE_ADDRESS_PRESENT" })}
                                menuPlacement="bottom"
                                styles={{ ...heightMenuAutocomplete("200px") }}
                                searchFunction={getListTinh}
                                urlData='data.data'
                                getOptionLabel={(option) => option?.tenTinh}
                                options={[]}
                                name='doiTuongMacBenh.tinhHienNay'
                                searchObject={{}}
                                onChange={(option) => handleChangeTinhHienNay(option)}
                                isRequired
                                value={values.doiTuongMacBenh?.tinhHienNay}
                                errors={errors.doiTuongMacBenh?.tinhHienNay || ""}
                                touched={touched.doiTuongMacBenh?.tinhHienNay}
                                isDisabled={onlyView || !!userData?.tinhId}

                            />
                        </Col>
                    </Row>
                </Col>

                <Col className='spaces pr-0' xl={SIZE_COLUMN.current}>
                    <Row xl={12}>
                        <Col xl={4}>
                            <OCTAutocomplete
                                menuPlacement="bottom"
                                lable={intl.formatMessage({ id: "DISTRICT_ADDRESS_PRESENT" })}
                                styles={{ ...heightMenuAutocomplete("200px") }}
                                searchFunction={() => getListHuyenByTinhId(values.doiTuongMacBenh?.tinhHienNay?.id)}
                                urlData='data.data'
                                getOptionLabel={(option) => option?.tenHuyen}
                                options={[]}
                                searchObject={{}}
                                value={values.doiTuongMacBenh?.huyenHienNay}
                                isDisabled={onlyView || !values?.doiTuongMacBenh?.tinhHienNay}
                                onChange={(option) => handleChangeHuyenHienNay(option)}
                                dependencies={[values.doiTuongMacBenh?.tinhHienNay]}
                                isRequired
                                errors={errors.doiTuongMacBenh?.huyenHienNay}
                                touched={touched.doiTuongMacBenh?.huyenHienNay}
                            />
                        </Col>
                        <Col xl={4}>
                            <OCTAutocomplete
                                menuPlacement="bottom"
                                styles={{ ...heightMenuAutocomplete("200px") }}
                                lable={intl.formatMessage({ id: "COMMUNE_ADDRESS_PRESENT" })}
                                searchFunction={() => getListXaByHuyenId(values.doiTuongMacBenh?.huyenHienNay?.id)}
                                urlData='data.data'
                                getOptionLabel={(option) => option?.tenXa}
                                options={[]}
                                searchObject={{}}
                                value={values.doiTuongMacBenh?.xaHienNay}
                                isDisabled={onlyView || !values?.doiTuongMacBenh?.huyenHienNay}
                                onChange={handleChangeXaHienNay}
                                dependencies={[values?.doiTuongMacBenh?.huyenHienNay]}
                                isRequired
                                errors={errors.doiTuongMacBenh?.xaHienNay}
                                touched={touched.doiTuongMacBenh?.xaHienNay}
                            />
                        </Col>
                        <Col xl={4}>
                            <OCTAutocomplete
                                menuPlacement="auto"
                                lable={intl.formatMessage({ id: "VILLAGE_ADDRESS_PRESENT" })}
                                searchFunction={() => getListThonByXaId(
                                values.doiTuongMacBenh?.xaHienNay?.id || values.doiTuongMacBenh?.xaHienNay?.xaId)}
                                urlData='data.data'
                                getOptionLabel={(option) => option?.tenThon}
                                options={[]}
                                searchObject={{}}
                                value={values.doiTuongMacBenh?.khuPhoHienNay}
                                isDisabled={!values.doiTuongMacBenh?.xaHienNay || onlyView}
                                onChange={(option) => setFieldValue("doiTuongMacBenh.khuPhoHienNay", option)}
                                errors={errors.doiTuongMacBenh?.khuPhoHienNay}
                                touched={touched.doiTuongMacBenh?.khuPhoHienNay}
                                isRequired
                                dependencies={[values.doiTuongMacBenh?.xaHienNay]}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row className='spaces px-0'>
                <Col xs={12}>
                    <div className='fw-bold'>{intl.formatMessage({ id: "HOME_ADDRESS_INFOMATION" })}</div>
                    {!onlyView &&
                        <Form.Check
                            disabled={onlyView}
                            className='mt-2'
                            label={intl.formatMessage({ id: "LIKE_ADDRESS_PRESENT" })}
                            name='doiTuongMacBenh.copyDiaChiHienTai'
                            checked={!!values.doiTuongMacBenh?.copyDiaChiHienTai}
                            onChange={() => {
                                setFieldValue("doiTuongMacBenh.copyDiaChiHienTai", !values.doiTuongMacBenh?.copyDiaChiHienTai);
                            }}
                        />
                    }
                </Col>
            </Row>

            <Row className='spaces px-0'>
                <Col className='spaces pr-0' xl={SIZE_COLUMN.current}>
                    <Row xl={12}>
                        <Col xl={6}>
                            <OCTTextValidator
                                lable={intl.formatMessage({ id: "HOME_ADDRESS" })}
                                type="text"
                                value={values.doiTuongMacBenh?.diaChiThuongTru}
                                name="doiTuongMacBenh.diaChiThuongTru"
                                onChange={handleChange}
                                errors={errors.doiTuongMacBenh?.diaChiThuongTru}
                                touched={touched.doiTuongMacBenh?.diaChiThuongTru}
                                disabled={onlyView || values?.doiTuongMacBenh?.copyDiaChiHienTai}
                            />
                        </Col>
                        <Col xl={6}>
                            <OCTAutocomplete
                                lable={intl.formatMessage({ id: "HOME_PROVINCE_ADDRESS" })}
                                menuPlacement="top"
                                searchFunction={getListTinh}
                                urlData='data.data'
                                getOptionLabel={(option) => option?.tenTinh}
                                options={[]}
                                name='doiTuongMacBenh.tinhThuongTru'
                                searchObject={{}}
                                onChange={(option) => {
                                    handleChangeTinh(setValues, 'doiTuongMacBenh', 'tinhThuongTru', 'huyenThuongTru', "xaThuongTru", "khuPhoThuongTru", option)
                                }}
                                isRequired={!!values?.doiTuongMacBenh?.diaChiThuongTru}
                                isDisabled={onlyView || !!values?.doiTuongMacBenh?.copyDiaChiHienTai}
                                value={values.doiTuongMacBenh?.tinhThuongTru}
                                errors={errors.doiTuongMacBenh?.tinhThuongTru}
                                touched={touched.doiTuongMacBenh?.tinhThuongTru}
                            />
                        </Col>
                    </Row>
                </Col>

                <Col className='spaces pr-0' xl={SIZE_COLUMN.current}>
                    <Row xl={12}>
                        <Col xl={4}>
                            <OCTAutocomplete
                                menuPlacement="top"
                                lable={intl.formatMessage({ id: "HOME_DISTRICT_ADDRESS" })}
                                searchFunction={() => getListHuyenByTinhId(values.doiTuongMacBenh?.tinhThuongTru?.id)}
                                urlData='data.data'
                                getOptionLabel={(option) => option?.tenHuyen}
                                options={[]}
                                searchObject={{}}
                                value={values.doiTuongMacBenh?.huyenThuongTru}
                                isDisabled={!values.doiTuongMacBenh?.tinhThuongTru || onlyView || !!values?.doiTuongMacBenh?.copyDiaChiHienTai}
                                onChange={(option) => {
                                    handleChangeHuyen(setValues, 'doiTuongMacBenh', 'huyenThuongTru', 'xaThuongTru', "khuPhoThuongTru", option)
                                }}
                                isRequired={!!values?.doiTuongMacBenh?.diaChiThuongTru}
                                errors={errors.doiTuongMacBenh?.huyenThuongTru}
                                touched={touched.doiTuongMacBenh?.huyenThuongTru}
                                dependencies={[values.doiTuongMacBenh?.tinhThuongTru]}
                            />
                        </Col>
                        <Col xl={4}>
                            <OCTAutocomplete
                                menuPlacement="top"
                                lable={intl.formatMessage({ id: "HOME_COMMUNE_ADDRESS" })}
                                searchFunction={() => getListXaByHuyenId(values.doiTuongMacBenh?.huyenThuongTru?.id)}
                                urlData='data.data'
                                getOptionLabel={(option) => option?.tenXa}
                                options={[]}
                                searchObject={{}}
                                value={values.doiTuongMacBenh?.xaThuongTru}
                                isDisabled={!values.doiTuongMacBenh?.huyenThuongTru || onlyView || !!values?.doiTuongMacBenh?.copyDiaChiHienTai}
                                onChange={(option) => {
                                    handleChangeXa(setValues, 'doiTuongMacBenh', 'xaThuongTru', "khuPhoThuongTru", option)
                                }}
                                isRequired={!!values?.doiTuongMacBenh?.diaChiThuongTru}
                                errors={errors.doiTuongMacBenh?.xaThuongTru}
                                touched={touched.doiTuongMacBenh?.xaThuongTru}
                                dependencies={[values.doiTuongMacBenh?.huyenThuongTru]}
                            />
                        </Col>
                        <Col xl={4}>
                            <OCTAutocomplete
                                menuPlacement="top"
                                lable={intl.formatMessage({ id: "HOME_VILLAGE_ADDRESS" })}
                                searchFunction={() => getListThonByXaId(values.doiTuongMacBenh?.xaThuongTru?.id)}
                                urlData='data.data'
                                getOptionLabel={(option) => option?.tenThon}
                                options={[]}
                                searchObject={{}}
                                value={values.doiTuongMacBenh?.khuPhoThuongTru}
                                isDisabled={!values.doiTuongMacBenh?.xaThuongTru || onlyView || !!values?.doiTuongMacBenh?.copyDiaChiHienTai}
                                onChange={(option) => setFieldValue('doiTuongMacBenh.khuPhoThuongTru', option)}
                                isRequired={!!values?.doiTuongMacBenh?.diaChiThuongTru}
                                errors={errors.doiTuongMacBenh?.khuPhoThuongTru}
                                touched={touched.doiTuongMacBenh?.khuPhoThuongTru}
                                dependencies={[values.doiTuongMacBenh?.xaThuongTru]}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
            {
                openModalDS &&
                <DanhSachTHBModal
                    data={dSCheckTrung}
                    handleClose={() => setOpenModalDS(false)}
                    configTable={configTable}
                    setSearchObject={setDataCheckTrung}
                    setDataSelected={handleSelectTHBTrung}
                />
            }
        </Row>
    )
}

export default ThongTinHanhChinhTab
