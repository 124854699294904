import axios from "axios";
const API_PATH = process.env.REACT_APP_API_URL;

export const getDataBaoCao = (searchObject: any) => {
    const url = API_PATH + "bao-cao/thong-tu-54";
    return axios.post(url, searchObject);
};

export const kiemTraBaoCao = (searchObject: any) => {
    const url = API_PATH + "bao-cao/kiem-tra-bao-cao-ton-tai";
    return axios.get(url, {params: searchObject});
};

export const nhapBaoCao = (data: any) => {
    const url = API_PATH + "bao-cao/nhap-bao-cao";
    return axios.post(url, data);
};
export const capNhatBaoCao = (data: any,id:number) => {
    const url = API_PATH + `bao-cao/cap-nhat-bao-cao/${id}`;
    return axios.put(url, data);
};

export const exportBaoCao = (searchObject: any) => {
    const url = API_PATH + "bao-cao/export-thong-tu-54";
    return axios.post(url, searchObject);
};

export const getBaoCaoTuanById = (id: any) => {
    const url = API_PATH + `bao-cao/tuan/${id}`;
    return axios.get(url);
};

export const getBaoCaoThangById = (id: any) => {
    const url = API_PATH + `bao-cao/thang/${id}`;
    return axios.get(url);
};