import clsx from 'clsx'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import { HeaderUserMenu } from '../../../partials'
import {useLayout} from '../../core'
import { KEY_LOCALSTORAGE } from '../../../../app/modules/auth/core/_consts'
import { useEffect, useState } from 'react'
import { localStorageItem } from '../../../../app/modules/utils/LocalStorage'

const btnIconClass = 'svg-icon-1'

const Navbar = () => {
  const {config} = useLayout();
  const accessTokenDecode = localStorage.getItem(KEY_LOCALSTORAGE?.ACCESS_TOKEN_DECODE);
  const userData = localStorageItem.get(KEY_LOCALSTORAGE.USER_INFOMATION)
  const [accessTokenInfo, setAccessTokenInfo] = useState<any>();

  useEffect(() => {
    if (!accessTokenDecode) return;
    setAccessTokenInfo(JSON.parse(accessTokenDecode));
  },[accessTokenDecode])

  return (
    <div className='app-navbar flex-shrink-0'>
      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div> */}

      <div className={clsx('app-navbar-item')}>
        {/* <div className="notification cursor-pointer p-4">
          <KTSVG path={'/media/icons/notification.svg'} className={`svg-icon-2`} />
        </div> */}
        <div className="text-uppercase text-warning">{userData?.tenCoSo} </div>
        <div className="text-warning">-</div>
        <div className="text-white">{userData?.username}</div>
        <div
          className={clsx('cursor-pointer')}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <img src={toAbsoluteUrl('/media/avatars/blank.png')} alt='' width={24} height={24} className='avatar rounded-circle'/>
        </div>
        <div className='header-user-info color-white'>
        </div>
        <HeaderUserMenu />
      </div>

      {config.app?.header?.default?.menu?.display && (
        <div className='app-navbar-item d-lg-none' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-35px h-35px'
            id='kt_app_header_menu_toggle'
          >
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className={btnIconClass} />
          </div>
        </div>
      )}
    </div>
  )
}

export {Navbar}
