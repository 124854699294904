import { OCTAutocomplete } from '@oceantech/oceantech-ui'
import { Form, Formik, FormikHelpers } from 'formik'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { useLoading } from '../../../../AppContext'
import { NAM_HIEN_TAI, THANG_HIEN_TAI } from '../../../../Constant'
import { KEY_LOCALSTORAGE } from '../../../auth/core/_consts'
import Autocomplete from '../../../component/input-field/autocomplete/Autocomplete'
import TextValidator from '../../../component/input-field/text-validator'
import LabelRequired from '../../../component/LabelRequired'
import TableGrouping from '../../../component/table/table-grouping/TableGrouping'
import { getListHuyenByTinhId, getListNgayTrongTuan, getListTinh, getListXaByHuyenId } from '../../../services'
import { LIST_MONTH } from '../../../utils/Constant'
import { getListYear } from '../../../utils/FunctionUtils'
import { localStorageItem } from '../../../utils/LocalStorage'
import { columnNhapBaoCao, columnTotalBaoCao, INIT_DATA_BAO_CAO, LOAI_THONG_KE, ngayCuoiThang, ngayDauThang, TK_TUAN_THANG } from '../../constant/constants'
import { ITaoBaoCao } from '../../model/model'
import { capNhatBaoCao, getBaoCaoThangById, getBaoCaoTuanById, kiemTraBaoCao, nhapBaoCao } from '../../services/services'
import { getDayAndWeekByThang, getDayAndWeekByYear } from '../../utils/functionUtils'
import { useIntl } from 'react-intl'
const NhapMoiBaoCao = () => {
    const userData = localStorageItem.get(KEY_LOCALSTORAGE.USER_INFOMATION)
    const { setPageLoading } = useLoading()
    const [isLuuBaoCao, setIsLuuBaoCao] = useState(false)
    const intl = useIntl()

    const createGreaterThanTest = (compareToField: string, errorMessage: string) => {
        return Yup.string().required("Nhập số nguyên dương").test(
            `greater-than-${compareToField}`,
            errorMessage,
            function (value) {
                const { path, createError } = this;
                const compareValue = this.parent[compareToField];
                return Number(value) >= compareValue || createError({ path, message: errorMessage });
            }
        );
    };

    const schema = Yup.object().shape({
        tinh: Yup.object().required("Chọn tỉnh nhập báo cáo").nullable(),
        huyen: Yup.object().when("tinh", {
            is: (value: any) => value,
            then: Yup.object().required("Chọn huyện nhập báo cáo").nullable(),
            otherwise: Yup.object().nullable().notRequired()
        }),
        listBaoCao: Yup.lazy(value =>
            isLuuBaoCao ? Yup.array().of(
                Yup.object().shape({
                    tongSoDauHieu: createGreaterThanTest('tongSoDuoi15', 'Tổng số dấu hiệu phải >= tổng số dưới 15'),
                    tongSoDuoi15: Yup.string().required("Nhập số nguyên dương"),
                    congDonDauHieu: createGreaterThanTest('tongSoDauHieu', 'Cộng dồn dấu hiệu phải >= tống số dấu hiệu'),
                    tongSoNang: createGreaterThanTest('tongSoNangDuoi15', 'Tổng sô nặng phải >= tổng số nặng dưới 15'),
                    tongSoNangDuoi15: Yup.string().required("Nhập số nguyên dương"),
                    congDonNang: createGreaterThanTest('tongSoNang', 'Cộng dồn nặng phải >= Tổng số nặng'),
                    tongSo: createGreaterThanTest('tongSo', 'Cộng dồn phải >= Tổng số'),
                    congDon: Yup.string().required("Nhập số nguyên dương"),
                    tongSoTuVong: createGreaterThanTest('tongSoTuVongDuoi15', 'Tống số tử vong phải >= Tống số tử vong dưới 15'),
                    tongSoTuVongDuoi15: Yup.string().required("Nhập số nguyên dương"),
                    congDonTuVong: createGreaterThanTest('tongSoTuVong', 'Cộng dồn tử vong phải >= Tổng số tử <vong></vong>'),
                })
            ) : Yup.array()
        )

    })

    const INIT_TAO_BAO_CAO: ITaoBaoCao = {
        loaiBaoCao: 2,
        thang: Number(THANG_HIEN_TAI),
        nam: NAM_HIEN_TAI,
        tuNgay: moment().startOf('month').format('YYYY-MM-DD'),
        denNgay: moment().endOf('month').format('YYYY-MM-DD'),
        tinh: userData?.tinhInfo,
        huyen: userData?.huyenInfo,
        huyenId: null,
        listBaoCao: [],
        isActive: 0 //hoat dong
    }

    const [initDataForm, setInitDataForm] = useState<ITaoBaoCao>(INIT_TAO_BAO_CAO)

    const handleGetDayAndWeek = (nam: number, thang: string, setValues: React.Dispatch<React.SetStateAction<ITaoBaoCao>>,
        typeBaoCao?: number | null
    ) => {
        typeBaoCao === LOAI_THONG_KE[0].code
            ? getDayAndWeekByYear(nam, setValues, typeBaoCao)
            : getDayAndWeekByThang(nam, Number(thang), setValues, typeBaoCao);
    };

    const formatDataSend = (values: ITaoBaoCao) => {
        return {
            nam: values.nam,
            thang: values.thang || null,
            tuan: Number(values.tuan?.value || values.tuan) || null,
            loaiBaoCao: values.loaiBaoCao,
            huyenId: values.huyen?.id,
        }
    }

    const handleNhapBaoCao = async (values: ITaoBaoCao, formikHelpers: FormikHelpers<ITaoBaoCao>) => {
        const dataCheck = formatDataSend(values)
        try {
            setPageLoading(true)
            const res = await kiemTraBaoCao(dataCheck)
            if (!res?.data?.data) {
                const newDataBaoCao = values?.xaIds.map((item: any) => ({
                    tenXa: item.tenXa,
                    xaId: item.xaId,
                    ...INIT_DATA_BAO_CAO
                }))
                formikHelpers.setValues({
                    ...values,
                    id: null,
                    listBaoCao: newDataBaoCao
                })
                return;
            }

            const res2 = values?.loaiBaoCao === TK_TUAN_THANG[0].code ? await getBaoCaoTuanById(res?.data?.data) : await getBaoCaoThangById(res?.data?.data)
            formikHelpers.setValues({
                ...values,
                id: res2.data.data.id,
                listBaoCao: res2?.data?.data?.listBaoCao
            })
            toast.success("Báo cáo đã tồn tại. Chỉnh sửa dữ liệu báo cáo trong bảng dưới.")
        }
        catch (error) {
            console.error(error)
        }
        finally {
            setPageLoading(false)
        }
    }

    const handleLuuBaoCao = async (values: ITaoBaoCao, formikHelpers: FormikHelpers<ITaoBaoCao>) => {
        const dataCheck = {
            ...formatDataSend(values),
            listBaoCao: values.listBaoCao
        }

        try {
            setPageLoading(true)
            values?.id ? await capNhatBaoCao(dataCheck, values?.id) : await nhapBaoCao(dataCheck)
            formikHelpers.setValues({
                ...values,
                id: null,
                listBaoCao: []
            })
            toast.success("Thành công!")
        }
        catch (error) {
            console.error(error)
            toast.error("Lỗi hệ thống !")
        }
        finally {
            setPageLoading(false)
        }
    }
    
    const handleGetListXa = async () => {
        if(!userData?.huyenId) return;
        const res = await getListXaByHuyenId(userData?.huyenId as number)
        setInitDataForm((prevValues: ITaoBaoCao) => ({
            ...prevValues,
            xaIds: res?.data?.data || []
        }))
    }

    useEffect(() => {
        handleGetListXa()
    }, [])

    return (
        <Formik
            initialValues={initDataForm}
            onSubmit={isLuuBaoCao ? handleLuuBaoCao : handleNhapBaoCao}
            enableReinitialize
            validationSchema={schema}
        >
            {({ values, handleChange, setValues, errors, touched, setFieldValue, }) => {

                const handleChangWeek = async (nam: number, tuan: number | string) => {
                    try {
                        setFieldValue("tuan", tuan);
                        const { data } = await getListNgayTrongTuan({ nam, tuan });
                        setValues((prevValues: ITaoBaoCao) => ({
                            ...prevValues,
                            tuNgay: data?.tungay && moment(data?.tungay, "DD-MM-YYYY").format("YYYY-MM-DD"),
                            denNgay: data?.denngay && moment(data?.denngay, "DD-MM-YYYY").format("YYYY-MM-DD"),
                        }))
                    } catch (err) {
                        console.error(err);
                    }
                }

                const handleChangWeekByThang = async (nam: number, thang: number | string) => {
                    setValues((prevValues: ITaoBaoCao) => ({
                        ...prevValues,
                        tuNgay: ngayDauThang(nam, thang),
                        thang: thang,
                        denNgay: ngayCuoiThang(nam, thang)
                    }))
                };

                const handleChangeInput = (name: string, value: any) => {
                    setFieldValue(name, (!isNaN(parseInt(value)) && parseInt(value) >= 0) ? parseInt(value) : "")
                }
                return <Form noValidate>
                    <div className="section-container spaces py-20 my-16">
                        <div className="spaces d-flex justify-content-between ">
                            <span className="spaces fs-18 fw-bold color-dark-red text-uppercase">{intl.formatMessage({ id: "TYPE_TOTAL_PATIENT_BY_REPORT" })}</span>
                        </div>
                        <Row>
                            <Col xs={12} sm={6} md={4} lg={3} className="spaces mt-5">
                                <Autocomplete
                                    isClearable={false}
                                    lable={intl.formatMessage({ id: "REPORT_FACILITY" })}
                                    getOptionLabel={(option) => option?.name}
                                    getOptionValue={(option) => option?.code}
                                    options={TK_TUAN_THANG || []}
                                    searchObject={{}}
                                    onChange={(selectedOption) => {
                                        handleGetDayAndWeek(NAM_HIEN_TAI, THANG_HIEN_TAI, setValues, selectedOption?.code);
                                    }}
                                    name="loaiBaoCao"
                                    value={values?.loaiBaoCao}
                                />
                            </Col>
                            <Col xs={12} sm={6} md={4} lg={3} className="spaces mt-5">
                                <OCTAutocomplete
                                    lable={intl.formatMessage({ id: "PROVINCE" })}
                                    urlData='data.data'
                                    getOptionLabel={(option) => option?.tenTinh}
                                    options={[]}
                                    value={values.tinh}
                                    searchFunction={getListTinh}
                                    name='province'
                                    searchObject={{}}
                                    isDisabled={!!userData?.tinhId}
                                    onChange={(option) => setValues((prev: any) => ({ ...prev, tinh: option, huyen: null, xaIds: null }))}
                                    errors={errors?.tinh}
                                    touched={touched?.tinh}
                                />
                            </Col>
                            <Col xs={12} sm={6} md={4} lg={3} className="spaces mt-5">
                                <OCTAutocomplete
                                    lable={intl.formatMessage({ id: "DISTRICT" })}
                                    searchFunction={() => getListHuyenByTinhId(values?.tinh?.id)}
                                    urlData='data.data'
                                    getOptionLabel={(option) => option?.tenHuyen}
                                    options={[]}
                                    value={values.huyen}
                                    name='district'
                                    searchObject={{}}
                                    dependencies={[values?.tinh]}
                                    isDisabled={!!userData?.huyenId || !values?.tinh}
                                    onChange={async (option) => {
                                        const res = await getListXaByHuyenId(option?.id as number)
                                        setValues({ ...values, huyen: option, xaIds: res.data.data })
                                    }}
                                    errors={errors?.huyen}
                                    touched={touched?.huyen}
                                />
                            </Col>
                            <Col xs={12} sm={6} md={4} lg={3} className="spaces mt-5">
                                <Autocomplete
                                    lable={intl.formatMessage({ id: "COMMUNE" })}
                                    multiCheckBox
                                    getOptionLabel={(option) => option?.tenXa}
                                    getOptionValue={(option) => option?.xaId}
                                    options={[]}
                                    name='xaIds'
                                    searchObject={{}}
                                    isDisabled
                                    value={values?.xaIds}
                                    errors={errors?.xaIds}
                                    touched={touched?.xaIds}
                                    dependencies={[values?.huyen]}
                                    valueSearch={"tenXa"}
                                />
                            </Col>
                            <Col xs={12} sm={6} md={4} lg={3} className="spaces mt-5">
                                <Autocomplete
                                    lable={intl.formatMessage({ id: "YEAR" })}
                                    isClearable={false}
                                    className="spaces zIndex-3"
                                    getOptionLabel={(option) => option?.value}
                                    getOptionValue={(option) => option?.code}
                                    options={getListYear(1955, moment().year())}
                                    name='nam'
                                    searchObject={{}}
                                    onChange={(selectedOption) => {
                                        handleGetDayAndWeek(selectedOption?.value, THANG_HIEN_TAI, setValues, values?.loaiBaoCao);
                                    }}
                                    value={values?.nam}
                                    errors={errors?.nam}
                                    touched={touched?.nam}
                                />
                            </Col>
                            <Col xs={12} sm={6} md={4} lg={3} className="spaces mt-5">
                                {
                                    values?.loaiBaoCao === LOAI_THONG_KE[0].code ? <Autocomplete
                                        lable={intl.formatMessage({ id: "WEEKLY" })}
                                        urlData='data'
                                        isClearable={false}
                                        getOptionLabel={(option) => option?.text}
                                        getOptionValue={(option) => option?.value}
                                        options={values?.listTuan || []}
                                        name='tuan'
                                        searchObject={{}}
                                        onChange={(selectedOption) => {
                                            handleChangWeek(values.nam, selectedOption?.value);
                                        }}
                                        value={values?.tuan}
                                        errors={errors?.tuan}
                                        touched={touched?.tuan}
                                    />
                                        :
                                        <Autocomplete
                                            lable={intl.formatMessage({ id: "MONTH" })}
                                            isClearable={false}
                                            getOptionLabel={(option) => option?.name}
                                            getOptionValue={(option) => option?.code}
                                            options={LIST_MONTH || []}
                                            name='thang'
                                            searchObject={{}}
                                            onChange={(selectedOption) => {
                                                handleChangWeekByThang(values.nam, selectedOption?.code);
                                            }}
                                            value={values?.thang}
                                            errors={errors?.thang}
                                            touched={touched?.thang}
                                        />
                                }
                            </Col>
                            <Col xs={12} sm={6} md={4} lg={3} className="spaces mt-5">
                                <LabelRequired
                                    label={intl.formatMessage({ id: "DAY_FROM" })}
                                    className="fw-500"
                                />
                                <TextValidator
                                    name="tuNgay"
                                    type="date"
                                    onChange={handleChange}
                                    value={values.tuNgay || ""}
                                    errors={errors?.tuNgay}
                                    touched={touched?.tuNgay}
                                    disabled
                                />
                            </Col>
                            <Col xs={12} sm={6} md={4} lg={3} className="spaces mt-5">
                                <LabelRequired
                                    label={intl.formatMessage({ id: "DAY_TO" })}
                                    className="fw-500"
                                />
                                <TextValidator
                                    name="denNgay"
                                    type="date"
                                    onChange={handleChange}
                                    value={values.denNgay || ""}
                                    errors={errors?.denNgay}
                                    touched={touched?.denNgay}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="d-flex justify-content-center spaces mt-20">
                                <Button
                                    className="button-primary"
                                    type="submit"
                                    onClick={() => {
                                        setIsLuuBaoCao(false)
                                    }}
                                >
                                    {intl.formatMessage({ id: "TYPE_REPORT" })}
                                </Button>
                            </Col>
                        </Row>
                    </div>
                    {
                        !!values.listBaoCao.length && (<div className="section-container spaces py-16 my-16">
                            <div className="d-flex justify-content-end spaces my-20">
                                <Button
                                    className="button-primary"
                                    onClick={() => {
                                        setValues({
                                            ...values,
                                            listBaoCao: values?.listBaoCao?.map((item: any) => {
                                                for (let key in item) {
                                                    item[key] = item[key] || 0;
                                                }
                                                return { ...item };
                                            })
                                        })
                                    }}
                                >
                                    {intl.formatMessage({ id: "TYPE_NUMBER_ZERO" })}
                                </Button>
                            </div>
                            <TableGrouping
                                id="form-nhap-bao-cao"
                                columns={columnNhapBaoCao({ handleChangeInput, values, errors, touched, intl })}
                                data={values.listBaoCao}
                                className="table-custom spaces mt-20 table-form-nhap-bao-cao"
                                columnsTotal={columnTotalBaoCao}
                                showTotalRow
                            />
                            <div className="d-flex justify-content-center spaces my-20">
                                <Button
                                    className="button-primary"
                                    type="submit"
                                    onClick={() => { setIsLuuBaoCao(true) }}
                                >
                                    {intl.formatMessage({ id: "SAVE_REPORT" })}
                                </Button>
                            </div>
                        </div>)
                    }
                </Form>
            }
            }
        </Formik>
    )
}

export default NhapMoiBaoCao
