import { OCTAutocomplete, OCTKTSVG, OCTTable, OCTTextValidator } from "@oceantech/oceantech-ui";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useLoading } from "../../../../AppContext";
import { KEY_LOCALSTORAGE } from "../../../auth/core/_consts";
import LabelRequired from "../../../component/LabelRequired";
import CustomDialog from "../../../component/dialogs/CustomDialog";
import { regex } from "../../../constant";
import { getListHuyenByTinhId, getListTinh, getListXaByHuyenId } from "../../../services";
import { RESPONSE_STATUS_CODE } from "../../../utils/Constant";
import { localStorageItem } from "../../../utils/LocalStorage";
import { arrCoSo, configByCoSo, CS_STATUS, INIT_SEARCH_CO_SO, STATUS_DIA_PHUONG } from "../../constant/constants";
import { ICoSo, IResponseDanhMucDiaPhuong } from "../../model/model";
import { addDanhMuc, getDanhMuc, getVaiTroByUser, updateDanhMuc } from "../../services/services";
import AddCoSoForm from "./AddCoSoForm";
import { handleChangePagination } from "../../../utils/PageUtils";
import { useIntl } from "react-intl";

const DanhMucCoSo = () => {
    const { setPageLoading } = useLoading()
    const [data, setData] = useState<IResponseDanhMucDiaPhuong<ICoSo>>()
    const userData = localStorageItem.get(KEY_LOCALSTORAGE.USER_INFOMATION)
    const [openDialogAdd, setOpenDialogAdd] = useState(false)
    const [reload, setReload] = useState(false)
    const initParams = {
        ...INIT_SEARCH_CO_SO,
        tinh: userData.tinhInfo,
        huyen: userData?.huyenInfo,
        xa: userData?.xaInfo,
    }
    const [dataForm, setDataForm] = useState<any>(initParams)
    const [params, setParams] = useState(initParams)
    const intl = useIntl()

    const formAddSchema = Yup.object().shape({
        tenCoSo: Yup.string().required("Bắt buộc nhập").nullable(),
        maCoSo: Yup.string().required("Bắt buộc nhập").nullable(),
        capDonViObject: Yup.object().required("Bắt buộc nhập").nullable(),
        tinh: Yup.object().when("capDonViObject", {
            is: (value: any) => configByCoSo[value?.hinhThuc]?.required.tinh,
            then: Yup.object().required("Bắt buộc nhập").nullable(),
        }).nullable(),
        huyen: Yup.object().when("capDonViObject", {
            is: (value: any) => configByCoSo[value?.hinhThuc]?.required.huyen,
            then: Yup.object().required("Bắt buộc nhap").nullable(),
        }).nullable(),
        xa: Yup.object().when("capDonViObject", {
            is: (value: any) => configByCoSo[value?.hinhThuc]?.required.xa,
            then: Yup.object().required("Bắt buộc nhap").nullable(),
        }).nullable(),
        soDienThoai: Yup.string().matches(regex.phone, "Số điện thoại không hợp lệ").nullable(),
        email: Yup.string().matches(regex.email, "Số điện thoại không hợp lệ").nullable(),
        website: Yup.string().matches(regex.website, "Website không hợp lệ").nullable()
    })

    const getLisCoSo = async (values: any) => {
        try {
            const { tinh, huyen, xa, ...searchinitParams } = values
            setPageLoading(true);
            let response = await getDanhMuc({
                ...searchinitParams,
                tinhId: tinh?.id,
                huyenId: huyen?.id,
                xaId: xa?.xaId
            }, "co-so/page");

            if (response?.data?.code !== RESPONSE_STATUS_CODE.SUCCESS) return;
            setData(response?.data?.data)
        } catch (error) {
            toast.error("Đã có lỗi!")
            console.error("ERROR", error)
        } finally {
            setPageLoading(false)
        }
    }

    const formatDataView = (data: ICoSo): ICoSo => {
        return {
            ...data,
            tinh: data?.tenTinh ? {
                id: data?.tinhId,
                tenTinh: data?.tenTinh
            } : null,
            huyen: data?.tenHuyen ? {
                id: data?.huyenId,
                tenHuyen: data?.tenHuyen
            } : null,
            xa: data?.tenXa ? {
                xaId: data?.xaId,
                tenXa: data?.tenXa
            } : null,
            capDonViObject: data?.tenPhanLoai ? {
                hinhThuc: data?.hinhThuc,
                tenVaiTro: data?.tenPhanLoai,
                id: data?.dmVaiTroId
            } : null,
            diaChiFullObject: data?.diaChiFull ? {
                tinhId: data?.diaChiTinhId,
                huyenId: data?.diaChiHuyenId,
                xaId: data?.diaChiXaId,
                name: data?.diaChiFull
            } : null

        }
    }

    const handleOpenForm = (row: any, format: boolean = true) => {
        setDataForm(format ? formatDataView(row) : row)
        setOpenDialogAdd(true)
    }

    const handleSearch = async (values: any) => {
        setParams(values)
        try {
            setPageLoading(true)
            await getLisCoSo(values)
        }
        catch (error) {

        } finally {
            setPageLoading(false)
        }
    }

    const formatDataSend = (data: any) => {
        const { tinh, huyen, xa, pageNumber, pageIndex, capDonViObject, diaChiFullObject, ...dataRest } = data
        return {
            ...dataRest,
            tinhId: tinh?.id,
            huyenId: huyen?.id,
            xaId: xa?.xaId,
            vaiTroId: capDonViObject?.id,
            hinhThuc: capDonViObject?.hinhThuc,
            diaChiTinhId: diaChiFullObject?.tinhId,
            diaChiHuyenId: diaChiFullObject?.tinhId,
            diaChiXaId: diaChiFullObject?.xaId,
            diaChiFull: diaChiFullObject?.name
        }
    }

    const handleSubmitForm = async (values: any) => {
        try {
            const dataSend = formatDataSend(values)
            values?.id ? await updateDanhMuc(dataSend, `co-so/${values?.id}`) : await addDanhMuc(dataSend, 'co-so')
            toast.success("Thành công!")
            setOpenDialogAdd(false)
            setReload(prev => !prev)
        }
        catch (error) {
            toast.error("Đã có lỗi!")
        } finally {
            setPageLoading(false)
        }
    }

    const handleActiveCoSo = async (id: string, rowData: ICoSo) => {
        try {
            setPageLoading(true)
            const dataSend = formatDataSend(formatDataView(rowData))
            await updateDanhMuc({ ...dataSend, trangThai: !!rowData.trangThai ? STATUS_DIA_PHUONG.ACTIVE : STATUS_DIA_PHUONG.INACTIVE }, `co-so/${id}`)
            setReload(prev => !prev)
            toast.success(`${rowData.trangThai ? "Kích hoạt" : "Hủy kích hoạt"} thành công!`)
        } catch (error) {
            console.error("ERROR", error)
        } finally {
            setPageLoading(false)
        }
    }

    useEffect(() => {
        getLisCoSo(params)
    }, [reload, params.pageNumber, params.pageSize])

    return (
        <div className="spaces mt-15">
            <Formik
                initialValues={initParams}
                onSubmit={handleSearch}
                enableReinitialize
            >
                {({ values, handleChange, setValues, setFieldValue }) => {
                    return (
                        <Form noValidate className="section-container spaces py-20">
                            <div className="spaces d-flex justify-content-between ">
                                <span className="spaces fs-18 fw-bold color-dark-red text-uppercase">{intl.formatMessage({ id: "SEARCH_FACILITY" })}</span>
                                <div className="d-flex spaces gap-6">
                                    <Button
                                        className="button-primary"
                                        type="submit"
                                    >
                                        <OCTKTSVG path='/media/svg/icons/search.svg' svgClassName='spaces h-14 w-14 color-white' />
                                        {intl.formatMessage({ id: "SEARCH" })}
                                    </Button>
                                    <Button
                                        className="button-primary"
                                        onClick={() => handleOpenForm(initParams, false)}
                                    >
                                        <OCTKTSVG path='/media/svg/icons/plus.svg' svgClassName='spaces h-14 w-14 color-white' />
                                        {intl.formatMessage({ id: "ADD" })}
                                    </Button>
                                    <Button
                                        className="button-primary"
                                        onClick={() => {
                                            setDataForm(initParams)
                                            setOpenDialogAdd(true)
                                        }}
                                    >
                                       {intl.formatMessage({ id: "EXCEL_EXPORT" })}
                                    </Button>
                                </div>
                            </div>
                            <Row>
                                <Col xs={12} sm={6} md={4} lg={3} className="spaces mt-5">
                                    <LabelRequired
                                        label={intl.formatMessage({ id: "NAME_FACILITY" })}
                                        className="fw-500"
                                    />
                                    <OCTTextValidator
                                        name="tenCoSo"
                                        type="text"
                                        onChange={handleChange}
                                    />
                                </Col>
                                <Col xs={12} sm={6} md={4} lg={3} className="spaces mt-5">
                                    <LabelRequired
                                        label={intl.formatMessage({ id: "CODE_FACILITY" })}
                                        className="fw-500"
                                    />
                                    <OCTTextValidator
                                        name="maCoSo"
                                        onChange={handleChange}
                                    />
                                </Col>
                                <Col xs={12} sm={6} md={4} lg={3} className="spaces mt-5">
                                    <LabelRequired
                                        label={intl.formatMessage({ id: "PHONE_NUMBER" })}
                                        className="fw-500"
                                    />
                                    <OCTTextValidator
                                        name="soDienThoai"
                                        type="text"
                                        onChange={handleChange}
                                    />
                                </Col>
                                <Col xs={12} sm={6} md={4} lg={3} className="spaces mt-5">
                                    <OCTAutocomplete
                                        lable={intl.formatMessage({ id: "STATUS" })}
                                        urlData='data.data'
                                        getOptionLabel={(option) => option?.name}
                                        options={CS_STATUS}
                                        name='trangThai'
                                        onChange={(option) => setFieldValue("trangThai", option?.code)}
                                    />
                                </Col>
                                <Col xs={12} sm={6} md={4} lg={3} className="spaces mt-5">
                                    <OCTAutocomplete
                                        lable={intl.formatMessage({ id: "CLASSIFY" })}
                                        urlData='data.data'
                                        getOptionLabel={(option) => option?.tenVaiTro}
                                        searchFunction={getVaiTroByUser}
                                        options={[]}
                                        name='type'
                                        onChange={(option) => setFieldValue("hinhThuc", option?.hinhThuc)}
                                    />
                                </Col>
                                <Col xs={12} sm={6} md={4} lg={3} className="spaces mt-5">
                                    <OCTAutocomplete
                                        lable={intl.formatMessage({ id: "PROVINCE" })}
                                        urlData='data.data'
                                        getOptionLabel={(option) => option?.tenTinh}
                                        options={[]}
                                        value={values.tinh}
                                        searchFunction={getListTinh}
                                        name='province'
                                        searchObject={{}}
                                        isDisabled={!!userData?.tinhId}
                                        onChange={(option) => setValues(prev => ({ ...prev, tinh: option, huyen: null, xa: null }))}
                                    />
                                </Col>
                                <Col xs={12} sm={6} md={4} lg={3} className="spaces mt-5">
                                    <OCTAutocomplete
                                        lable={intl.formatMessage({ id: "DISTRICT" })}
                                        searchFunction={() => getListHuyenByTinhId(values?.tinh?.id)}
                                        urlData='data.data'
                                        getOptionLabel={(option) => option?.tenHuyen}
                                        options={[]}
                                        value={values.huyen}
                                        name='district'
                                        searchObject={{}}
                                        dependencies={[values?.tinh]}
                                        isDisabled={!!userData?.huyenId || !values?.tinh}
                                        onChange={(option) => setValues({ ...values, huyen: option, xa: null })}
                                    />
                                </Col>
                                <Col xs={12} sm={6} md={4} lg={3} className="spaces mt-5">
                                    <OCTAutocomplete
                                        lable={intl.formatMessage({ id: "COMMUNE" })}
                                        searchFunction={() => getListXaByHuyenId(values?.huyen?.id)}
                                        urlData='data.data'
                                        getOptionLabel={(option) => option?.tenXa}
                                        options={[]}
                                        value={values.xa}
                                        name='commune'
                                        searchObject={{}}
                                        dependencies={[values.huyen]}
                                        isDisabled={!!userData?.xaId || !values?.huyen}
                                        onChange={(option) => setValues({ ...values, xa: option })}

                                    />
                                </Col>
                            </Row>
                        </Form>)
                }}
            </Formik>
            {openDialogAdd &&
                <CustomDialog
                    handleSubmit={handleSubmitForm}
                    isShow={openDialogAdd} setIsShow={setOpenDialogAdd}
                    initValue={dataForm}
                    title={intl.formatMessage({ id: "FACILITY" })}
                    validationSchema={formAddSchema}
                >
                    <AddCoSoForm />
                </CustomDialog>
            }
            <div className="section-container spaces mt-15">
                <OCTTable
                    id="profile"
                    height={500}
                    data={data?.data || []}
                    columns={arrCoSo({
                        handleOpenForm,
                        handleActiveCoSo,
                        intl
                    })}
                    searchObject={params}
                    setSearchObject={(table) => handleChangePagination(table, setParams)}
                    fixedColumnsCount={0}
                    notDelete={true}
                    notEdit={true}
                    noToolbar={true}
                    totalPages={data?.totalPages}
                    totalElements={data?.total}
                    noPagination={Number(data?.total) > 10 ? false : true}
                />
            </div>
        </div>
    )
}

export default DanhMucCoSo;
