import { OCTAutocomplete, OCTTable } from '@oceantech/oceantech-ui'
import { Form, Formik } from 'formik'
import { Button, Col, Row } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { KEY_LOCALSTORAGE } from '../../../auth/core/_consts'
import DateRangePicker from '../../../component/date-picker/DateRangePicker'
import Autocomplete from '../../../component/input-field/autocomplete/Autocomplete'
import { getListHuyenByTinhId, getListTinh, getListXaByHuyenId } from '../../../services'
import { localStorageItem } from '../../../utils/LocalStorage'
import { arrCoSo, TYPE_DC_CHUAN } from '../../constant/constants'
import { OCTTextValidator } from '@oceantech/oceantech-ui';
import moment from 'moment'
import * as Yup from "yup"
import { addDuongCongChuan, deleteDuongCongChuan, getDuongCongChuanById, getListDuongCongChuan, updateDuongCongChuan } from './services'
import { useLoading } from '../../../../AppContext'
import { toast } from 'react-toastify'
import { RESPONSE_STATUS_CODE } from '../../../utils/Constant'
import { useEffect, useState } from 'react'
import { DuongCongChuanColumns } from './constants'
import ConfirmDialog from '../../../component/confirm-dialog/ConfirmDialog'

const NhapDuongCongChuan = () => {
  let averageCases = []
  for (let index = 0; index < 53; index++) {
    averageCases[index] = { [index]: null }
  }

  const [reload, setReload] = useState(false);
  const [data, setData] = useState([]);
  const [record, setRecord] = useState(null);
  const intl = useIntl()
  const userData = localStorageItem.get(KEY_LOCALSTORAGE.USER_INFOMATION)
  const { setPageLoading } = useLoading()
  let address = {
    postal: [userData?.apInfo?.tenAp, userData?.xaInfo?.tenXa, userData?.huyenInfo?.tenHuyen, userData?.tinhInfo?.tenTinh, "Việt Nam"].filter(value => value).join(", "),
    level4: userData?.apInfo?.tenAp || null,
    level3: userData?.xaInfo?.tenXa || null,
    level2: userData?.huyenInfo?.tenHuyen || null,
    level1: userData?.tinhInfo?.tenTinh || null,
    level0: "Việt Nam"
  }
  const [initValues, setInitValues] = useState({
    tinh: userData?.tinhInfo,
    huyen: userData?.huyenInfo,
    xa: userData?.xaInfo,
    loaiDuLieu: TYPE_DC_CHUAN(intl)[0].code,
    fromYear: moment().subtract(3, 'years').year(),
    toYear: moment().year(),
    averageCases: averageCases,
  })
  const [id, setId] = useState<string>("");
  const [openConfirmDialog, setOpenConfirmDialog] = useState({ open: false, id: "" });


  const init = async () => {
    let response = await getListDuongCongChuan(address)
    setData(response?.data)
  }

  useEffect(() => {
    init()
  }, [reload])

  useEffect(() => {
    console.log("data", data);

  }, [data])

  const validationSchema = Yup.object().shape({
    averageCases: Yup.array()
      .nullable()
      .of(
        Yup.object().test(
          'dynamic-key-validation',
          'No negative numbers allowed',
          (obj) => {
            if (obj?.[Object.keys(obj).pop() as any] < 0) return false;
            return true;
          }
        ).nullable().test(
          'dynamic-key-validation',
          'This field is required',
          (obj) => {
            if (obj?.[Object.keys(obj).pop() as any] === "" ||
              obj?.[Object.keys(obj).pop() as any] === null ||
              obj?.[Object.keys(obj).pop() as any] === undefined) return false;
            return true;
          }
        )
      )
  });

  const handlerSave = async (values: any) => {
    let objData = {
      id: id ? id : null,
      type: values?.loaiDuLieu,
      fromYear: values?.fromYear,
      toYear: values?.toYear,
      averageCases: values?.averageCases?.reduce((acc: any, cur: any) => {
        return { ...acc, ...cur };
      }, {} as Record<number, number>),
      address
    }
    try {
      setPageLoading(true)
      let response = !id ? await addDuongCongChuan(objData) : await updateDuongCongChuan(objData)
      if (response?.status === RESPONSE_STATUS_CODE.SUCCESS) {
        setReload(prev => !prev)
        return id ? toast.success(intl.formatMessage({ id: "UPDATE_SUCCESS" }))  : toast.success(intl.formatMessage({ id: "ADD_SUCCESS" }))
      }
    } catch (error) {
      toast.error(intl.formatMessage({ id: "ERROR" }))
      console.error("ERROR", error)
    } finally {
      id && setId("")
      setPageLoading(false)
    }

  }

  const handlerUpdate = async (id: string) => {
    setId(id)
    let respone = await getDuongCongChuanById(id);
    let averageCases = Object.entries(respone?.data?.averageCases)
      .sort(([keyA], [keyB]) => Number(keyA) - Number(keyB))
      .map(([index, value]) => ({ [index]: value }))
    setInitValues((prev: any) => ({ ...prev, averageCases }))
  }

  const handlerDelete = async (id: string) => {
    try {
      setPageLoading(true)
      let response = await deleteDuongCongChuan(id)
      if (response?.status === RESPONSE_STATUS_CODE.SUCCESS) {
        setReload(prev => !prev)
        return toast.success(intl.formatMessage({ id: "DELETE_SUCCESS" }))
      }
    } catch (error) {
      toast.error(intl.formatMessage({ id: "ERROR" }))
      console.error("ERROR", error)
    } finally {
      setOpenConfirmDialog((pre: any) => ({ ...pre, open: false }))
      setPageLoading(false)
    }
  }

  return (
    <Formik
      initialValues={initValues}
      onSubmit={(e) => handlerSave(e)}
      enableReinitialize
      validationSchema={validationSchema}
    >
      {({ values, handleChange, setValues, errors, touched, setFieldValue }) => {
        const weekCount = 53;
        const monthCount = 12;
        const loaiDuLieu = values?.loaiDuLieu
        const isWeekType = loaiDuLieu === TYPE_DC_CHUAN(intl)[0].code
        const startYear = moment(values?.fromYear, "YYYY").year();
        const endYear = moment(values?.toYear, "YYYY").year();
        return <Form noValidate>
          <div className="section-container spaces py-20 my-16">
            <div className="spaces d-flex justify-content-between ">
              <span className="spaces fs-18 fw-bold color-dark-red text-uppercase">{intl.formatMessage({ id: "TYPE_DATA_STANDARD" })}</span>
            </div>
            <Row>
              <Col xs={12} sm={6} md={4} lg={2} className="spaces mt-5">
                <Autocomplete
                  isClearable={false}
                  lable={intl.formatMessage({ id: "DATA_TYPE" })}
                  getOptionLabel={(option) => option?.name}
                  getOptionValue={(option) => option?.code}
                  options={TYPE_DC_CHUAN(intl)}
                  searchObject={{}}
                  value={values.loaiDuLieu}
                  onChange={(selectedOption) => setValues((prev: any) => ({ ...prev, loaiDuLieu: selectedOption?.code }))}
                  name="loaiDuLieu"
                />
              </Col>
              <Col xs={12} sm={6} md={4} lg={2} className="spaces mt-5">
                <OCTAutocomplete
                  lable={intl.formatMessage({ id: "PROVINCE" })}
                  urlData='data.data'
                  getOptionLabel={(option) => option?.tenTinh}
                  options={[]}
                  value={values.tinh}
                  searchFunction={getListTinh}
                  name='province'
                  searchObject={{}}
                  isDisabled={!!userData?.tinhId}
                  onChange={(option) => setValues((prev: any) => ({ ...prev, tinh: option, huyen: null, xa: null }))}
                  errors={errors?.tinh}
                  touched={touched?.tinh}
                />
              </Col>
              <Col xs={12} sm={6} md={4} lg={2} className="spaces mt-5">
                <OCTAutocomplete
                  lable={intl.formatMessage({ id: "DISTRICT" })}
                  searchFunction={() => getListHuyenByTinhId(values?.tinh?.id)}
                  urlData='data.data'
                  getOptionLabel={(option) => option?.tenHuyen}
                  options={[]}
                  value={values.huyen}
                  name='district'
                  searchObject={{}}
                  dependencies={[values?.tinh]}
                  isDisabled={!!userData?.huyenId || !values?.tinh}
                  onChange={(option) => {
                    setValues({ ...values, huyen: option, xa: null })
                  }}
                  errors={errors?.huyen}
                  touched={touched?.huyen}
                />
              </Col>
              <Col xs={12} sm={6} md={4} lg={2} className="spaces mt-5">
                <OCTAutocomplete
                  lable={intl.formatMessage({ id: "COMMUNE" })}
                  searchFunction={() => getListXaByHuyenId(values?.huyen?.id)}
                  urlData='data.data'
                  getOptionLabel={(option) => option?.tenXa}
                  options={[]}
                  value={values.xa}
                  name='district'
                  searchObject={{}}
                  dependencies={[values?.huyen]}
                  isDisabled={!!userData?.huyenId || !values?.huyen}
                  onChange={(option) => {
                    setValues({ ...values, xa: option })
                  }}
                  errors={errors?.huyen}
                  touched={touched?.huyen}
                />
              </Col>
              <Col xs={12} sm={6} md={4} lg={2} className="spaces mt-5">
                <DateRangePicker
                  showYearPicker
                  dateFormat={"YYYY"}
                  label={intl.formatMessage({ id: "TIME_ZONE" })}
                  startDate={values.fromYear as any}
                  endDate={values.toYear as any}
                  handleChange={([startDate, endDate]: any) => {
                    setValues((prev: any) => ({ ...prev, fromYear: startDate, toYear: endDate }))
                  }}
                />
              </Col>
            </Row>
          </div>
          <div className="section-container">
            {(startYear && endYear) ? <div> {intl.formatMessage({ id: "TIME" })}: <span className="fw-bold text-primary">{startYear} - {endYear}</span></div> : ""}
            {
              <Row>
                {Array.from({ length: isWeekType ? weekCount : monthCount }).map((_, i) => (
                  <Col xs={12} sm={6} md={4} lg={3} xl={2} xxl={1} key={i} className="mt-2">
                    <OCTTextValidator
                      lable={`${isWeekType ?  intl.formatMessage({ id: "WEEKLY" }) :  intl.formatMessage({ id: "MONTH" })} ${i + 1}`}
                      name={`averageCases[${i}].${i + 1}`}
                      value={(values?.averageCases as any)?.[i]?.[i + 1]}
                      errors={(errors?.averageCases as any)?.[i]}
                      touched={true}
                      onChange={handleChange}
                      type='number'
                      isRequired
                    />
                  </Col>
                ))}
              </Row>
            }
            <div className='d-flex justify-content-center p-4 gap-2'>
              <Button
                className="button-primary"
                onClick={() => {
                  const averageCases: any = Array.from({ length: loaiDuLieu === TYPE_DC_CHUAN(intl)[0].code ? weekCount : monthCount }, (_, i) => ({
                    [`${i + 1}`]: (values?.averageCases as any)?.[i]?.[`${i + 1}`] || 0
                  }));
                  setValues({ ...values, averageCases });
                }}
              >
                 {intl.formatMessage({ id: "TYPE_NUMBER_ZERO" })}
              </Button>
              <Button
                className="button-primary"
                type="submit"
              >
                {intl.formatMessage({ id: "SAVE" })}
              </Button>
            </div>
          </div>
          <div className="section-container spaces mt-15">
            <OCTTable
              id="profile"
              data={data}
              columns={DuongCongChuanColumns(intl, handlerUpdate, setOpenConfirmDialog)}
              fixedColumnsCount={0}
              notDelete={true}
              notEdit={true}
              noToolbar={true}
              unSelectedAll={true}
              noPagination
            />
            </div>
            {
                openConfirmDialog.open && (
                    <ConfirmDialog
                        show={openConfirmDialog.open}
                        onCloseClick={() => setOpenConfirmDialog((prev: any) => ({ ...prev, open: false }))}
                        onCancelClick={() => setOpenConfirmDialog((prev: any) => ({ ...prev, open: false }))}
                        onYesClick={() => handlerDelete(openConfirmDialog.id)}
                        title={"DELETE"}
                        message={"Are you sure ?"}
                        yes={intl.formatMessage({ id: "DELETE" })}
                        cancel={intl.formatMessage({ id: "CANCLE" })}
                    />

                )
            }
        </Form>
      }
      }
    </Formik>
  )
}

export default NhapDuongCongChuan
