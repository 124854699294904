import clsx from 'clsx'
import React from 'react'
import { Col, Row } from 'react-bootstrap'

type Props = {
  listData: any
}

const ThongTinTongQuan = ({ listData }: Props) => {
  return (
    <Row className="spaces tt-tong-quan me-0 mb-0">
      {
        listData?.map((item: any, index: number) =>
          <Col className={clsx("d-flex flex-column spaces mb-5", { "ps-6": index })}>
            <Row className="spaces d-flex justify-content-center text-gray-700 bg-dark p-5" >
              {item.lable || item.label}
            </Row>
            <Row className="spaces bg-dark p-5 flex-grow-1">
              <div className="spaces d-flex flex-column align-items-center justify-content-center">
                <div className="number-map">
                  {
                    item?.values?.length > 1 ?
                      <>
                        <span style={{ color: item?.values[0]?.color }} className="spaces fs-32">{item?.values[0]?.number}</span>
                        <span className="spaces fs-32">/</span>
                        <span style={{ color: item?.values[1]?.color }} className="spaces fs-32">{item?.values[1]?.number}</span>
                      </>
                      :
                      <span style={{ color: item?.values[0]?.color }} className="spaces fs-32">{item?.values[0]?.number}</span>
                  }
                </div>
              </div>
            </Row>
          </Col>
        )
      }
    </Row>
  )
}

export default ThongTinTongQuan