import axios from "axios";
import { paramsConfig } from "../../utils/ParamsUtils";

export const getAddress = async (searchObject?: any) => {
  // let apiKey = "n41gd6GJ1v16tMMmTb875o7fIOotQQCe";
  // let API_URL = `https://api.tomtom.com/search/2/search/${searchObject?.text}.json?key=${apiKey}&language=vi-VN`;
  // return axios.get(API_URL);

  //Vietmap
  let apiKey = "0344e4a08f31899b9fc0ac0db97f4685e6169cd63532164b";
  let API_URL = `https://maps.vietmap.vn/api/autocomplete/v3?apikey=${apiKey}`;
  return axios.get(API_URL, { params: searchObject });
};

export const getAddressById = async (id?: string) => {
  let apiKey = "0344e4a08f31899b9fc0ac0db97f4685e6169cd63532164b";
  let API_URL = `https://maps.vietmap.vn/api/place/v3?apikey=${apiKey}&refid=${id}`;
  return axios.get(API_URL);
};
