import { useFormikContext } from 'formik';
import L, { LeafletEvent } from 'leaflet';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Button, Modal, Col, Row } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { useAppContext } from '../../../AppContext';
import { configMapByHinhThuc, NAME_COMMUNE, NAME_DISTRICT, NAME_PROVINCE } from '../../Dashboard/components/config';
import { BenhReport } from '../../quan-ly-truong-hop-benh/danh-sach-truong-hop-benh/constants/constant';
import OCTMap from '../oct-map/OCTMap';
import { cmpNormalizeString } from '../../utils/FunctionUtils';
import AsyncAutoComplete from '../input-field/AsyncAutoComplete';
import { getAddress, getAddressById } from './services';
import useLoadGeoJson from '../../../hook/useLoadGeoJson';

type Props = {
    handleClose: () => void
}

function ModalChonToaDo({ handleClose }: Props) {
    const [position, setPosition] = useState<any>(null);
    const [patientPosition, setPatientPosition] = useState([1, 1]);
    const { userData } = useAppContext();
	const [ProvinceGeoJson, setProvinceJeoJson] = useState({})
    const [DistrictGeoJson, setDistrictJeoJson] = useState({})
	const [CommuneGeoJson, setCommuneJeoJson] = useState({})
    const [geoJsonData, setGeoJsonData] = useState<any>([])
    const [coordinates, setCoordinates] = useState<any>([])

    useLoadGeoJson(setProvinceJeoJson, setDistrictJeoJson, setCommuneJeoJson)

    const getGeoJsonData = (nameTinh?: string | null, nameHuyen?: string | null, nameXa?: string | null) => {
        const geoData = !nameTinh
            ? (ProvinceGeoJson as any).features
            : (nameHuyen || nameXa)
                ? (CommuneGeoJson as any).features
                : (DistrictGeoJson as any).features;


        return geoData?.filter((item: any) => {
            const isMatchTinh = nameTinh ? cmpNormalizeString(item.properties[NAME_PROVINCE], nameTinh) : true;
            const isMatchHuyen = nameHuyen ? cmpNormalizeString(item.properties[NAME_DISTRICT], nameHuyen) : true;
            const isMatchXa = nameXa ? cmpNormalizeString(item.properties[NAME_COMMUNE], nameXa) : true;

            return (isMatchTinh && isMatchHuyen && isMatchXa)
        })
    }

    useEffect(() => {
        const geoJsonData = getGeoJsonData(userData.tenTinh, userData.tenHuyen, userData.tenXa)
        setGeoJsonData(geoJsonData);
      }, [userData, ProvinceGeoJson])

    useLayoutEffect(() => {
        setCoordinates(geoJsonData?.[0]?.geometry?.coordinates?.[0]?.[0])
    }, [geoJsonData, ProvinceGeoJson])


    const { setValues, values } = useFormikContext<BenhReport>()
    const intl = useIntl()

    const handleXacNhan = () => {
        if (position) {
            handleClose()
            setValues({
                ...values,
                kinhDo: position[1],
                viDo: position[0],
            })
        }
        else {
            toast.warning('Vui lý chọn tọa độ trường hợp bệnh')
        }
    }
    const initConfig = configMapByHinhThuc[userData?.phanLoaiCoSo]

    const highlightFeature = (e: LeafletEvent) => {
        const layer = e.target;
        const properties = layer.feature.properties;
        const fieldName = properties[initConfig.fieldViewLabel];
        const tooltipContent = `<b>${fieldName}</b>`;
        layer.setStyle({
            weight: 2,
            color: 'orange',
            fillOpacity: 0.2,
        });

        if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
            layer.bringToFront();
        }

        layer.bindTooltip(tooltipContent, { permanent: false, direction: "top" }).openTooltip();

        setTimeout(() => {
            layer.closeTooltip();
        }, 3000);
    };

    const resetHighlight = (e: LeafletEvent) => {
        const layer = e.target;
        layer.setStyle({
            weight: 1,
            color: '#058bb4',
            fillOpacity: 0.1,
        })
    };

    const handlerGetAddressPatient = async (value: any) => {
        // Vietmap
        let response = {} as any;
        if (value?.ref_id) { response = await getAddressById(value?.ref_id) }
        setPatientPosition([response?.data?.lat, response?.data?.lng])
        // Vietmap
        // Bên Khác
        // setPatientPosition([value?.position?.lat, value?.position?.lon])
    }

    return (
        <Modal
            show
            onHide={handleClose}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <h3>{intl.formatMessage({ id: "COORDINATES" })}</h3>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col xs={12} sm={6} md={6} lg={6} className="spaces mt-5 mb-10">
                        <AsyncAutoComplete
                            params={{}}
                            fieldSearch='text'
                            getOptionLabel={(option: any) => option?.display} //Vietmap
                            // getOptionLabel={(option: any) => option?.address?.freeformAddress}//Bên khác
                            getOptionValue={(option: any) => option?.position}
                            placeholder='Search Google Maps'
                            service={getAddress}
                            handleChange={(value) => handlerGetAddressPatient(value)}
                            // optionsResponse='results' //Bên Khác
                        />
                    </Col>
                </Row>
                {coordinates &&
                    <OCTMap
                        height='calc(100vh - 280px)'
                        width='100%'
                        keyGeoJson={geoJsonData}
                        tileLayerUrl='https://maps.vietmap.vn/api/dm/{z}/{x}/{y}@2x.png?apikey=0344e4a08f31899b9fc0ac0db97f4685e6169cd63532164b'
                        geoJsonData={geoJsonData}
                        center={[coordinates?.[1] || 0, coordinates?.[0] || 0]}
                        setStyleGeoJson={{
                            weight: 1,
                            opacity: 1,
                            color: "#058bb4"
                        } as any}
                        getPositionMarker={setPosition}
                        zoom={initConfig?.zoomDefault}
                        handleMouseOverFeature={highlightFeature}
                        handleMouseOutFeature={resetHighlight}
                        defaultMarkerPosition={patientPosition as any}
                    />
                }
            </Modal.Body>
            <Modal.Footer>
                <Button
                    className="button-primary"
                    onClick={handleXacNhan}
                >
                    {intl.formatMessage({ id: "CONFIRM" })}
                </Button>
                <Button
                    className="button-primary"
                    onClick={handleClose}
                >
                    {intl.formatMessage({ id: "CANCLE" })}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalChonToaDo
