import { OCTKTSVG, OCTTable, OCTAutocomplete } from "@oceantech/oceantech-ui"
import { useEffect, useState } from "react"
import { Form, Formik } from "formik";
import { Button, Row, Col } from "react-bootstrap"
import { toast } from "react-toastify"
import { useLoading } from "../../../../AppContext"
import CustomDialog from "../../../component/dialogs/CustomDialog"
import { arrUsers, INIT_USER, STATUS_DIA_PHUONG, userSchema, TRANG_THAI, configByCoSo } from "../../constant/constants"
import { IDanhMucUser, IResponseDanhMucDiaPhuong, ITabPropsDanhMucDiaPhuong, IUser, phanLoai } from "../../model/model"
import { addDanhMuc, deleteDanhMuc, getDanhMuc, getVaiTroByUser, updateDanhMuc } from "../../services/services"
import LabelRequired from "../../../component/LabelRequired"
import TextValidator from "../../../component/input-field/TextValidator"
import AddDialogUser from "./addDialogUser";
import { getListHuyenByTinhId, getListTinh, getListXaByHuyenId } from "../../../services";
import { handlerChange, TYPE } from "../../utils/utils";
import { localStorageItem } from "../../../utils/LocalStorage";
import { KEY_LOCALSTORAGE } from "../../../auth/core/_consts";
import { useIntl } from "react-intl";

const DanhMucNguoiDung = (props: ITabPropsDanhMucDiaPhuong) => {
  const userData = localStorageItem.get(KEY_LOCALSTORAGE.USER_INFOMATION)
  const { setPageLoading } = useLoading()
  const [data, setData] = useState<IResponseDanhMucDiaPhuong>()
  const [params, setParams] = useState({
    pageNumber: 1,
    pageSize: 10,
    tinhId: userData?.tinhId,
    huyenId: userData.huyenId,
    xaId: userData?.xaId
  })
  const [openDialogAdd, setOpenDialogAdd] = useState(false)
  const [dataForm, setDataForm] = useState(INIT_USER)
  const [reload, setReload] = useState(false)
  const [classify, setClassify] = useState<phanLoai>({} as phanLoai);
  const [fieldControl, setFieldControl] = useState(configByCoSo[userData?.phanLoaiCoSo])
  const intl = useIntl()

  useEffect(() => {
    if (classify?.hinhThuc) {
      setFieldControl(configByCoSo[classify?.hinhThuc])
    }
  }, [classify?.hinhThuc])

  useEffect(() => {
    getListUser()
  }, [params,reload])

  const getListUser = async () => {
    try {
      setPageLoading(true);
      let response = await getDanhMuc(params, "user/search");
      setData(response?.data?.data || [])
    } catch (error) {
      toast.error("Đã có lỗi!")
      console.error("ERROR", error)
    } finally {
      setPageLoading(false)
    }
  }

  const formatDataView = (row: any) => ({
    ...row,
    donViObject: row?.tenCoSo && { id: row.coSoId, tenCoSo: row.tenCoSo },
    tinhObject: row?.tenTinh && { id: row.tinhId, tenTinh: row.tenTinh },
    huyenObject: row?.tenHuyen && { id: row.huyenId, tenHuyen: row.tenHuyen },
    xaObject: row?.tenXa && { id: row.xaId, tenXa: row.tenXa },
    classify: { tenVaiTro: row.tenVaiTro }
  });

  const handleOpenForm = (row: IDanhMucUser) => {
    setDataForm(formatDataView({ ...INIT_USER, ...row, isChangePassword: !row?.id }))
    setOpenDialogAdd(true)
  }

  const formatDataSend = (values: IDanhMucUser) => {
    return {
      hoTen: values?.hoTen,
      tenDangNhap: values?.tenDangNhap,
      email: values?.email,
      soDienThoai: values?.dienThoai,
      matKhau: values?.password,
      nhapLaiMatKhau: values?.nhapLaiMatKhau,
      isActive: values?.isActive,
      coSoId: values?.donViObject?.id,
      isChangePassword: values?.isChangePassword,
      tinhId: values?.tinhObject?.id,
      huyenId: values?.huyenObject?.id,
      xaId: values?.xaObject?.id
    }
  }

  const handleSubmit = async (values: IDanhMucUser) => {
    try {
      setPageLoading(true)
      dataForm?.id
        ? await updateDanhMuc(formatDataSend(values), `user/${values?.id}`)
        : await addDanhMuc(formatDataSend(values), "user");
      toast.success("Thành công!")
      setReload(prev => !prev)
      setOpenDialogAdd(false)
    }
    catch (error) {
      toast.error("Đã có lỗi!")
      console.error("ERROR", error)
    } finally {
      setPageLoading(false)
    }
  }

  const handleActiveUser = async (id: string, rowData: IDanhMucUser) => {
    console.log(rowData.isActive)
    try {
      setPageLoading(true)
      await updateDanhMuc({ ...formatDataSend(formatDataView(rowData)), isActive: !rowData?.isActive ? STATUS_DIA_PHUONG.INACTIVE : STATUS_DIA_PHUONG.ACTIVE }, `user/${id}`)
      setReload(prev => !prev)
      toast.success("Thành công!")
    } catch (error) {
      console.error("ERROR", error)
    } finally {
      setPageLoading(false)
    }
  }

  const handlerSearch = (values: any) => {
    console.log("values", values)
    setParams(prev =>
    ({
      ...prev,
      tenDangNhap: values?.tenDangNhap,
      email: values?.email,
      dienThoai: values?.dienThoai,
      tinhId: values?.tinhObject?.tinhId,
      huyenId: values?.huyenObject?.huyenId,
      xaId: values?.xaObject?.xaId,
      trangThai: values?.isActive?.code,
      hinhThuc: values?.classify?.hinhThuc,
    })
    )
  }

  return (
    <div className="spaces mt-15">
      <Formik
        initialValues={{} as IDanhMucUser}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ values, errors, touched, handleChange, setValues, setFieldValue }) => (
          <Form noValidate className="section-container">
            <div className="spaces d-flex justify-content-between">
              <span className="spaces fs-18 fw-bold color-dark-red text-uppercase">{intl.formatMessage({ id: "SEARCH_USER" })}</span>
              <div className="spaces d-flex">
                <div className="spaces mr-10">
                  <Button
                    className="button-primary"
                    onClick={() => handlerSearch(values)}
                  >
                    <OCTKTSVG path='/media/svg/icons/search.svg' svgClassName='spaces h-14 w-14 color-white' />
                    {intl.formatMessage({ id: "SEARCH" })}
                  </Button>
                </div>
                <div className="spaces mr-10">
                  <Button
                    className="button-primary"
                    onClick={() => {
                      setDataForm(INIT_USER)
                      setOpenDialogAdd(true)
                    }}
                  >
                    <OCTKTSVG path='/media/svg/icons/plus.svg' svgClassName='spaces h-14 w-14 color-white' />
                    {intl.formatMessage({ id: "ADD" })}
                  </Button>
                </div>
                <Button
                  className="button-primary"
                  onClick={() => {
                    setDataForm(INIT_USER)
                    setOpenDialogAdd(true)
                  }}
                >
                  {/* <OCTKTSVG path='/media/svg/icons/plus.svg' svgClassName='spaces h-14 w-14 color-white' /> */}
                  {intl.formatMessage({ id: "EXCEL_EXPORT" })}
                </Button>
              </div>
            </div>
            <Row>
              <Col xs={12} sm={6} md={4} lg={3} className="spaces mt-5">
                <LabelRequired
                  label={intl.formatMessage({ id: "USER_NAME" })}
                  className="fw-500"
                />
                <TextValidator
                  name="tenDangNhap"
                  type="text"
                  onChange={handleChange}
                />
              </Col>
              <Col xs={12} sm={6} md={4} lg={3} className="spaces mt-5">
                <LabelRequired
                  label={intl.formatMessage({ id: "EMAIL" })}
                  className="fw-500"
                />
                <TextValidator
                  name="email"
                  type="email"
                  onChange={handleChange}
                />
              </Col>
              <Col xs={12} sm={6} md={4} lg={3} className="spaces mt-5">
                <LabelRequired
                  label={intl.formatMessage({ id: "PHONE_NUMBER" })}
                  className="fw-500"
                />
                <TextValidator
                  name="dienThoai"
                  type="text"
                  onChange={handleChange}
                />
              </Col>
              <Col xs={12} sm={6} md={4} lg={3} className="spaces mt-5">
                <OCTAutocomplete
                  lable={intl.formatMessage({ id: "STATUS" })}
                  getOptionLabel={(option) => option?.name}
                  getOptionValue={(option) => option?.code}
                  options={TRANG_THAI}
                  name='isActive'
                  onChange={(value) => setFieldValue("isActive", value)}
                />
              </Col>
              <Col xs={12} sm={6} md={4} lg={3} className="spaces mt-5">
                <OCTAutocomplete
                  lable={intl.formatMessage({ id: "CLASSIFY" })}
                  searchFunction={getVaiTroByUser}
                  urlData='data.data'
                  getOptionLabel={(option) => option?.tenVaiTro}
                  getOptionValue={(option) => option?.id}
                  options={[]}
                  name='classify'
                  searchObject={{}}
                  onChange={(value) => {
                    setClassify(value);
                    handlerChange(value, setValues, TYPE.CLASSIFY)
                  }}
                />
              </Col>
              <Col xs={12} sm={6} md={4} lg={3} className="spaces mt-5">
                <OCTAutocomplete
                  lable={intl.formatMessage({ id: "PROVINCE" })}
                  searchFunction={getListTinh}
                  urlData='data.data'
                  getOptionLabel={(option) => option?.tenTinh}
                  options={[]}
                  name='tinhId'
                  searchObject={{}}
                  onChange={(value) => handlerChange(value, setValues, TYPE.PROVINCE)}
                  isDisabled={!values!.classify?.id}
                  dependencies={[values!.classify?.id]}
                />
              </Col>
              <Col xs={12} sm={6} md={4} lg={3} className="spaces mt-5">
                <OCTAutocomplete
                  lable={intl.formatMessage({ id: "DISTRICT" })}
                  searchFunction={() => getListHuyenByTinhId(values?.tinhObject?.tinhId)}
                  urlData='data.data'
                  getOptionLabel={(option) => option?.tenHuyen}
                  options={[]}
                  name='huyenId'
                  searchObject={{}}
                  onChange={(value) => handlerChange(value, setValues, TYPE.DISTRICT)}
                  dependencies={[values?.tinhId]}
                  isDisabled={!values?.tinhObject?.tinhId || !values!.classify?.id || fieldControl?.disabled?.huyen}
                />
              </Col>
              <Col xs={12} sm={6} md={4} lg={3} className="spaces mt-5">
                <OCTAutocomplete
                  lable={intl.formatMessage({ id: "COMMUNE" })}
                  searchFunction={() => getListXaByHuyenId(values?.huyenObject?.huyenId)}
                  urlData='data.data'
                  getOptionLabel={(option) => option?.tenXa}
                  options={[]}
                  name='xaId'
                  searchObject={{}}
                  onChange={(value) => handlerChange(value, setValues, TYPE.COMMUNE)}
                  dependencies={[values?.huyenId]}
                  isDisabled={!values?.huyenObject?.huyenId || !values!.classify?.id || fieldControl?.disabled?.xa}
                />
              </Col>
            </Row>

            {openDialogAdd &&
              <CustomDialog
                handleSubmit={handleSubmit}
                isShow={openDialogAdd}
                setIsShow={setOpenDialogAdd}
                initValue={dataForm}
                validationSchema={userSchema}
              >
                <AddDialogUser />
              </CustomDialog>
            }
          </Form>)}
      </Formik>

      <div className="section-container spaces mt-15">
        <OCTTable
          id="profile"
          height={500}
          data={data?.data || [{}]}
          columns={arrUsers({
            handleOpenForm,
            handleActiveUser,
            intl
          })}
          searchObject={params}
          setSearchObject={(obj) => setParams(prevState => ({ ...obj, pageNumber: obj?.pageIndex, pageSize: obj?.pageSize ?? prevState?.pageSize }))}
          fixedColumnsCount={0}
          notDelete={true}
          notEdit={true}
          noToolbar={true}
          totalPages={data?.totalPages}
          totalElements={data?.total}
          noPagination={Number(data?.total) > 10 ? false : true}
        />
      </div>
    </div>
  )
}

export default DanhMucNguoiDung;
