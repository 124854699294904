import { OCTAutocomplete, OCTKTSVG } from "@oceantech/oceantech-ui"
import { Button, Col, Row } from "react-bootstrap"
import Autocomplete from "../../component/input-field/autocomplete/Autocomplete"
import { useFormikContext } from "formik"
import { ISearchBaoCao } from "../model/model";
import { getListHuyenByTinhId, getListNgayTrongTuan, getListTinh, getListTuanByNam, getListXaByHuyenId } from "../../services";
import LabelRequired from "../../component/LabelRequired";
import { localStorageItem } from "../../utils/LocalStorage";
import { KEY_LOCALSTORAGE } from "../../auth/core/_consts";
import moment from "moment";
import { getListYear } from "../../utils/FunctionUtils";
import TextValidator from "../../component/input-field/TextValidator";
import { getDayAndWeekByThang, getDayAndWeekByYear } from "../utils/functionUtils";
import { DK_CHAN_DOAN, HINH_THUC_DIEU_TRI, LOAI_THONG_KE, LOAI_TUY_CHON, ngayCuoiThang, ngayDauThang } from "../constant/constants";
import { LIST_MONTH } from "../../utils/Constant";
import { NAM_HIEN_TAI, THANG_HIEN_TAI } from "../../../Constant";
import { Dispatch, SetStateAction, useEffect } from "react";
import DateRangePicker from './../../component/date-picker/DateRangePicker';
import { useIntl } from "react-intl";

type IProps = {
    setFormParams: Dispatch<SetStateAction<ISearchBaoCao>>
}

const SearchAdvanceForm = ({ setFormParams }: IProps) => {
    const { values, handleChange, errors, touched, setFieldValue, setValues } = useFormikContext<ISearchBaoCao>();
    const userData = localStorageItem.get(KEY_LOCALSTORAGE.USER_INFOMATION)
    const isLoaiTuyChon = values?.loaiBaoCao === LOAI_THONG_KE[2].code
    useEffect(() => {
        setFormParams(values)
    }, [values])
    const intl = useIntl()

    const handleChangWeek = async (nam: number, tuan: number | string) => {
        setFieldValue("tuan", tuan);
        try {
            const { data } = await getListNgayTrongTuan({ nam, tuan });
            setValues((prevValues: ISearchBaoCao) => ({
                ...prevValues,
                tuNgay: data?.tungay && moment(data?.tungay, "DD-MM-YYYY").format("YYYY-MM-DD"),
                denNgay: data?.denngay && moment(data?.denngay, "DD-MM-YYYY").format("YYYY-MM-DD"),
            }))
        } catch (err) {
            console.error(err);
        }
    };

    const handleChangWeekByThang = async (nam: number, thang: number | string) => {
        setValues((prevValues: ISearchBaoCao) => ({
            ...prevValues,
            tuNgay: ngayDauThang(nam, thang),
            thang: thang,
            denNgay: ngayCuoiThang(nam, thang)
        }))
    };

    const handleGetDayAndWeek = (
        nam: any,
        thang: any,
        setValues: React.Dispatch<React.SetStateAction<ISearchBaoCao>>,
        typeBaoCao?: number | null
    ) => {
        typeBaoCao === LOAI_THONG_KE[0].code
            ? getDayAndWeekByYear(nam, setValues, typeBaoCao)
            : getDayAndWeekByThang(nam, Number(thang), setValues, typeBaoCao);
    };

    return (
        <div className="spaces mt-15">
            <div className="section-container">
                <div className="d-flex align-items-center justify-content-between">
                    <div className="spaces mb-15 fs-18 fw-bold text-uppercase color-dark-red">
                        {intl.formatMessage({ id: "SEARCH" })}
                    </div>
                    <Button
                        className="button-primary"
                        type="submit"
                    >
                        <OCTKTSVG
                            path="/media/svg/icons/search.svg"
                            svgClassName="spaces h-14 w-14 color-white"
                        />
                        {intl.formatMessage({ id: "SEARCH" })}
                    </Button>
                </div>
                <Row>
                    <Col xs={12} sm={6} md={4} lg={3} className="spaces mt-5">
                        <Autocomplete
                            lable={intl.formatMessage({ id: "REPORT_CLASSIFY" })}
                            getOptionLabel={(option) => option?.name}
                            getOptionValue={(option) => option?.code}
                            options={LOAI_THONG_KE || []}
                            searchObject={{}}
                            onChange={(selectedOption) => {
                                handleGetDayAndWeek(NAM_HIEN_TAI, THANG_HIEN_TAI, setValues, selectedOption?.code);
                            }}
                            name="loaiBaoCao"
                            value={values?.loaiBaoCao}
                        />
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} className="spaces mt-5">
                        <Autocomplete
                            lable={intl.formatMessage({ id: "PROVINCE" })}
                            searchFunction={getListTinh}
                            multiCheckBox
                            getOptionLabel={(option) => option?.tenTinh}
                            getOptionValue={(option) => option?.id}
                            options={[]}
                            name='tinhIds'
                            searchObject={{}}
                            onChange={(selectedOption) => {
                                setValues({
                                  ...values,
                                  tinhIds: selectedOption,
                                  huyenIds: null,
                                  xaIds: null,
                                });
                            }}
                            isDisabled={
                                !!userData?.tinhId
                            }
                            value={values?.tinhIds}
                            errors={errors?.tinhIds}
                            touched={touched?.tinhIds}
                            valueSearch={"tenTinh"}
                        />
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} className="spaces mt-5">
                        <Autocomplete
                            lable={intl.formatMessage({ id: "DISTRICT" })}
                            searchFunction={() =>
                                getListHuyenByTinhId(values?.tinhIds?.[0]?.id as number)
                            }
                            multiCheckBox
                            getOptionLabel={(option) => option?.tenHuyen}
                            getOptionValue={(option) => option?.id}
                            options={[]}
                            name='huyenIds'
                            searchObject={{}}
                            onChange={(selectedOption) => {
                                setValues({
                                    ...values,
                                    huyenIds: selectedOption,
                                    xaIds: null
                                })
                            }}
                            isDisabled={
                                !!userData?.huyenId ||
                                Boolean(values?.tinhIds?.length !== 1)
                            }
                            value={values?.huyenIds}
                            errors={errors?.huyenIds}
                            touched={touched?.huyenIds}
                            dependencies={[values?.tinhIds]}
                            valueSearch={"tenHuyen"}
                        />
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} className="spaces mt-5">
                        <Autocomplete
                            lable={intl.formatMessage({ id: "COMMUNE" })}
                            searchFunction={() =>
                                getListXaByHuyenId(values?.huyenIds?.[0].id as number)
                            }
                            multiCheckBox
                            getOptionLabel={(option) => option?.tenXa}
                            getOptionValue={(option) => option?.xaId}
                            options={[]}
                            name='xaIds'
                            searchObject={{}}
                            onChange={(selectedOption) => {
                                setFieldValue("xaIds", selectedOption)
                            }}
                            isDisabled={
                                !!userData?.xaId ||
                                (values?.huyenIds === null
                                    ? true :
                                    Boolean(values?.huyenIds?.length !== 1))
                            }
                            value={values?.xaIds}
                            errors={errors?.xaIds}
                            touched={touched?.xaIds}
                            dependencies={[values?.huyenIds]}
                            valueSearch={"tenXa"}
                        />
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} className="spaces mt-5">
                        {
                            values?.loaiBaoCao === LOAI_THONG_KE[2].code ? (<Autocomplete
                                lable={intl.formatMessage({ id: "OPTIONS_CLASSIFY" })}
                                getOptionLabel={(option) => option?.name}
                                getOptionValue={(option) => option?.code}
                                options={LOAI_TUY_CHON}
                                searchObject={{}}
                                onChange={(selectedOption) => {
                                    setFieldValue("loaiTuyChon", selectedOption);
                                }}
                                name="loaiTuyChon"
                                value={values?.loaiTuyChon}
                            />) :
                            <Autocomplete
                                lable={intl.formatMessage({ id: "YEAR" })}
                                getOptionLabel={(option) => option?.value}
                                getOptionValue={(option) => option?.code}
                                options={getListYear(1955, moment().year())}
                                name='nam'
                                searchObject={{}}
                                onChange={(selectedOption) => {
                                    handleGetDayAndWeek(selectedOption?.value, THANG_HIEN_TAI, setValues, values?.loaiBaoCao);
                                }}
                                value={isLoaiTuyChon ? "" : values?.nam}
                                errors={errors?.nam}
                                isDisabled={isLoaiTuyChon}
                                touched={touched?.nam}
                            />
                        }
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} className="spaces mt-5">
                        {
                            values?.loaiBaoCao === LOAI_THONG_KE[0].code ? <Autocomplete
                                lable={intl.formatMessage({ id: "WEEKLY" })}
                                urlData='data'
                                getOptionLabel={(option) => option?.text}
                                getOptionValue={(option) => option?.value}
                                options={values?.listTuan || []}
                                name='tuan'
                                searchObject={{}}
                                onChange={(selectedOption) => {
                                    handleChangWeek(values.nam, selectedOption?.value);
                                }}
                                value={isLoaiTuyChon ? "" : values?.tuan}
                                errors={errors?.tuan}
                                touched={touched?.tuan}
                                isDisabled={isLoaiTuyChon}
                            />
                                : values?.loaiBaoCao === LOAI_THONG_KE[1].code ?
                                    <Autocomplete
                                        lable={intl.formatMessage({ id: "MONTH" })}
                                        getOptionLabel={(option) => option?.name}
                                        getOptionValue={(option) => option?.code}
                                        options={LIST_MONTH || []}
                                        name='thang'
                                        searchObject={{}}
                                        onChange={(selectedOption) => {
                                            handleChangWeekByThang(values.nam, selectedOption?.code);
                                        }}
                                        value={isLoaiTuyChon ? "" : values?.thang}
                                        errors={errors?.thang}
                                        touched={touched?.thang}
                                        isDisabled={isLoaiTuyChon}
                                    /> : <DateRangePicker
                                        showQuarterYearPicker={values?.loaiTuyChon?.showQuarterYearPicker}
                                        showYearPicker={values?.loaiTuyChon?.showYearPicker}
                                        showMonthYearPicker={values?.loaiTuyChon?.showMonthYearPicker}
                                        dateFormat={values?.loaiTuyChon?.dateFormat}
                                        label={intl.formatMessage({ id: "TIME_ZONE" })}
                                        startDate={values.tuNgay as any}
                                        endDate={values.denNgay as any}
                                        handleChange={([startDate, endDate]: any) => {
                                            setValues((prev) => ({ ...prev, tuNgay: startDate, denNgay: endDate }))
                                        }}
                                    />
                        }
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} className="spaces mt-5">
                        <LabelRequired
                            label={intl.formatMessage({ id: "DAY_FROM" })}
                            className="fw-500"
                        />
                        <TextValidator
                            name="tuNgay"
                            type="date"
                            onChange={handleChange}
                            value={values.tuNgay || ""}
                            errors={errors?.tuNgay}
                            touched={touched?.tuNgay}
                            disabled
                        />
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} className="spaces mt-5">
                        <LabelRequired
                            label={intl.formatMessage({ id: "DAY_TO" })}
                            className="fw-500"
                        />
                        <TextValidator
                            name="denNgay"
                            type="date"
                            onChange={handleChange}
                            value={values.denNgay || ""}
                            errors={errors?.denNgay}
                            touched={touched?.denNgay}
                            disabled
                        />
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} className="spaces mt-5">
                        <OCTAutocomplete
                            lable={intl.formatMessage({ id: "TREATMENT_CLASSIFY" })}
                            options={HINH_THUC_DIEU_TRI}
                            valueSearch="code"
                            value={values?.hinhThucDieuTri}
                            onChange={(option)=>setFieldValue("hinhThucDieuTri",option?.code)}
                        />
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} className="spaces mt-5">
                        <OCTAutocomplete
                            lable={intl.formatMessage({ id: "DIAGNOSIS_CONDITION" })}
                            options={DK_CHAN_DOAN}
                            valueSearch="code"
                            value={values?.dieuTriChanDoan}
                            onChange={(option)=>setFieldValue("dieuTriChanDoan",option?.code)}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default SearchAdvanceForm
