import { OCTAutocomplete, OCTTextValidator } from "@oceantech/oceantech-ui";
import { useFormikContext } from "formik";
import { Col, Row } from "react-bootstrap";
import { getListTinh } from "../../../services";
import { IDanhMucDiaPhuong } from "../../model/model";
import { useIntl } from "react-intl";

const FormHuyen = () => {

    const {
        values,
        handleChange,
        errors, touched,
        setValues,
    } = useFormikContext<IDanhMucDiaPhuong>()
    const intl = useIntl()

    const handleChangeTinh = (option: any) => {
        setValues(prev => ({ ...prev, tinh: option, tinhId: option?.id, huyen: null }))
    }

    return <Row>
        <Col>
            <OCTAutocomplete
                lable={intl.formatMessage({ id: "PROVINCE" })}
                menuPlacement="top"
                searchFunction={getListTinh}
                urlData='data.data'
                getOptionLabel={(option) => option?.tenTinh}
                options={[]}
                name='tinh'
                searchObject={{}}
                onChange={handleChangeTinh}
                isRequired
                value={values.tinh}
                errors={errors?.tinh}
                touched={touched?.tinh}
            />
        </Col>
        <Col>
            <OCTTextValidator
                lable={intl.formatMessage({ id: "DISTRICT" })}
                name="tenHuyen"
                isRequired
                value={values.tenHuyen}
                onChange={handleChange}
                errors={errors?.tenHuyen}
                touched={touched?.tenHuyen}
            />
        </Col>
    </Row>
}
export default FormHuyen
