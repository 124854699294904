import { OCTAutocomplete, OCTKTSVG, OCTTable } from "@oceantech/oceantech-ui"
import { useEffect, useRef, useState } from "react"
import { Button, Col, Row } from "react-bootstrap"
import { toast } from "react-toastify"
import { useLoading } from "../../../../AppContext"
import CustomDialog from "../../../component/dialogs/CustomDialog"
import { RESPONSE_STATUS_CODE } from "../../../utils/Constant"
import { arrDistrict, huyenSchema, INIT_VALUE_DIA_PHUONG, KEY_TAB, STATUS_DIA_PHUONG } from "../../constant/constants"
import { IDanhMucDiaPhuong, IResponseDanhMucDiaPhuong, ITabPropsDanhMucDiaPhuong } from "../../model/model"
import { addDanhMuc, deleteDanhMuc, exportExcel, getDanhMuc, updateDanhMuc } from "../../services/services"
import FormHuyen from "../danh-muc-dia-phuong/FormHuyen"
import SearchField from "../searchField"
import { useIntl } from "react-intl"
import { getListTinh } from "../../../services"
import { exportToExcel, exportToFile } from "../../../utils/FunctionUtils"

const DanhMucHuyen = (props: ITabPropsDanhMucDiaPhuong) => {
  const { defaultActiveKey } = props;
  const { setPageLoading } = useLoading();
  const [params, setParams] = useState({ pageNumber: 1, pageSize: 10, TenHuyen: "", tinhId: null })
  const [data, setData] = useState<IResponseDanhMucDiaPhuong>();
  const [openDialogAdd, setOpenDialogAdd] = useState(false)
  const [dataForm, setDataForm] = useState(INIT_VALUE_DIA_PHUONG)
  const [reload, setReload] = useState(false)
  const [keySearch, setKeySearch] = useState<string>("")

  useEffect(() => {
    KEY_TAB.HUYEN === defaultActiveKey && getDistricts()
  }, [params, defaultActiveKey, reload])
  const intl = useIntl()

  const getDistricts = async () => {
    try {
      setPageLoading(true)
      let response = await getDanhMuc(params, "huyen/page");
      if (response?.data?.code !== RESPONSE_STATUS_CODE.SUCCESS) return;
      setData(response?.data?.data)
    } catch (error) {
      toast.error("Đã có lỗi!")
      console.error("ERROR", error)
    } finally {
      setPageLoading(false)
    }
  }

  const handleOpenForm = (row: IDanhMucDiaPhuong) => {

    setDataForm({
      ...row,
      tinh: {
        code: row?.maTinh,
        tenTinh: row?.tenTinh
      }
    })
    setOpenDialogAdd(true)
  }

  const handleSubmit = async (values: IDanhMucDiaPhuong) => {
    const { tinh, huyen, xa, ...dataRest } = values
    try {
      setPageLoading(true)
      let response = dataRest?.id
        ? await updateDanhMuc(dataRest, `huyen/${dataRest?.id}`)
        : await addDanhMuc(dataRest, "huyen");
      if (![RESPONSE_STATUS_CODE.SUCCESS, RESPONSE_STATUS_CODE.CREATED].includes(response?.data?.code)) return;
      toast.success("Thành công!")
      setReload(prev => !prev)
      setOpenDialogAdd(false)
    } catch (error) {
      toast.error("Đã có lỗi!")
      console.error("ERROR", error)
    } finally {
      setPageLoading(false)
    }
  }

  const handleActiveDiaPhuong = async (id: string, rowData: IDanhMucDiaPhuong) => {
    try {
      setPageLoading(true)
      !rowData.isActive ? await deleteDanhMuc(`huyen/${id}`) : await updateDanhMuc({ ...rowData, isActive: STATUS_DIA_PHUONG.ACTIVE }, `huyen/${id}`)
      setReload(prev => !prev)
      toast.success("Thành công!")
    } catch (error) {
      console.error("ERROR", error)
    } finally {
      setPageLoading(false)
    }
  }

  const handlerSearch = () => {
    setParams({ ...params, TenHuyen: keySearch })
  }

  return (
    <div className="spaces mt-15 section-container">
      <Row className="d-flex align-items-end">
        <Col xs={2}>
          <OCTAutocomplete
            lable={intl.formatMessage({ id: "PROVINCE" })}
            menuPlacement="bottom"
            searchFunction={getListTinh}
            urlData='data.data'
            getOptionLabel={(option) => option?.tenTinh}
            options={[]}
            name='tinh'
            searchObject={{}}
            onChange={(option: any) => setParams({ ...params, tinhId: option?.id })}
            isRequired
          />
        </Col>
        <Col xs={10}>
          <div className="spaces d-flex justify-content-between">
            <SearchField keySearch={keySearch} setKeySearch={setKeySearch} handlerSearch={handlerSearch} />
            <div className="d-flex gap-2">
              <Button
                className="button-primary me-4"
                onClick={() => {
                  setDataForm(INIT_VALUE_DIA_PHUONG)
                  setOpenDialogAdd(true)
                }}
              >
                <OCTKTSVG path='/media/svg/icons/plus.svg' svgClassName='spaces h-14 w-14 color-white' />
                {intl.formatMessage({ id: "ADD" })}
              </Button>
              <Button
                className="button-primary"
                onClick={() => exportToFile(
                  {
                    exportAPI: () => exportExcel('huyen/export-search', params),
                    fileName: "Danh sách huyện",
                    setPageLoading
                  }
                )}
              >
                {intl.formatMessage({ id: "EXCEL_EXPORT" })}
              </Button>
            </div>
          </div>
        </Col>
      </Row> 
      <OCTTable
        id="profile"
        height={500}
        data={data?.data || []}
        columns={arrDistrict({ handleOpenForm, handleActiveDiaPhuong, intl })}
        searchObject={params}
        setSearchObject={(obj) => setParams(prevState => ({ ...obj, pageNumber: obj?.pageIndex, pageSize: obj?.pageSize ?? prevState?.pageSize }))}
        fixedColumnsCount={0}
        notDelete={true}
        notEdit={true}
        noToolbar={true}
        totalPages={data?.totalPages}
        totalElements={data?.total}
        noPagination={Number(data?.total) > 10 ? false : true}
      />
      {openDialogAdd &&
        <CustomDialog
          handleSubmit={handleSubmit}
          isShow={openDialogAdd} setIsShow={setOpenDialogAdd}
          initValue={dataForm}
          validationSchema={huyenSchema}
        >
          <FormHuyen />
        </CustomDialog>
      }
    </div>
  )
}

export default DanhMucHuyen;
