import { Col, Row } from "react-bootstrap";
import DetailAddress from "../DetailAddress";
import DanhSachDiaPhuongDB from "./DanhSachDiaPhuongDB";
import DetailMapDuBao from "./DetailMapDuBao";

interface IProps {
}

const DashboardDuBaoDetail = (props: IProps) => {
  return (
    <Row className="w-100" >
      <Col xl={2} md={4} className="detail-address-box">
        <DetailAddress ChildComponent={DanhSachDiaPhuongDB} />
      </Col>
      <Col xl={10} md={8}>
        <DetailMapDuBao />
      </Col>
    </Row>
  )
}
export default DashboardDuBaoDetail;
