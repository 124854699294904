import { Dispatch, SetStateAction, useEffect } from "react";
import { useLoading } from "../AppContext";

const useLoadGeoJson = (
  setProvinceJeoJson: Dispatch<SetStateAction<any>>,
  setDistrictJeoJson: Dispatch<SetStateAction<any>>,
  setCommuneJeoJson: Dispatch<SetStateAction<any>>
) => {
  const { setPageLoading } = useLoading();

  useEffect(() => {
    setPageLoading(true);

    Promise.all([
      import("../../_metronic/assets/geojson/gadm36_VNM_1.json"),
      import("../../_metronic/assets/geojson/gadm36_VNM_2.json"),
      import("../../_metronic/assets/geojson/gadm36_VNM_3.json"),
    ])
      .then((results) => {
        const [ProvinceGeo, DistrictGeo, CommuneGeo] = results;
        setProvinceJeoJson(ProvinceGeo);
        setDistrictJeoJson(DistrictGeo);
        setCommuneJeoJson(CommuneGeo);
      })
      .catch((error) => {
        console.error("Error loading GeoJSON files:", error);
      })
      .finally(() => {
        setPageLoading(false);
      });
  }, [setProvinceJeoJson, setDistrictJeoJson, setCommuneJeoJson, setPageLoading]);
};

export default useLoadGeoJson;
