import { IDanhMucDiaPhuong } from "../model/model"

const handlerChangeProvince = (value: IDanhMucDiaPhuong, setValues: (value: any) => void) => {
  setValues((preValue: IDanhMucDiaPhuong) => ({ ...preValue, tinhObject: value, huyenObject: null, xaObject: null, donViObject: null }))
}
const handlerChangeDistrict = (value: IDanhMucDiaPhuong, setValues: (value: any) => void) => {
  setValues((preValue: IDanhMucDiaPhuong) => ({ ...preValue, huyenObject: value, xaObject: null, donViObject: null }))
}
const handlerChangeCommune = (value: IDanhMucDiaPhuong, setValues: (value: any) => void) => {
  setValues((preValue: IDanhMucDiaPhuong) => ({ ...preValue, xaObject: value, donViObject: null }))
}
const handlerChangeClassify = (value: IDanhMucDiaPhuong, setValues: (value: any) => void) => {
  setValues((preValue: IDanhMucDiaPhuong) => ({ ...preValue, classify: value, tinhObject: null, huyenObject: null, xaObject: null, donViObject: null }))
}

const TYPE = {
  PROVINCE: "isProvince",
  DISTRICT: "isDistrict",
  COMMUNE: "isCommune",
  CLASSIFY: "classify"
}

const mapFunctions = {
  [TYPE.PROVINCE]: handlerChangeProvince,
  [TYPE.DISTRICT]: handlerChangeDistrict,
  [TYPE.COMMUNE]: handlerChangeCommune,
  [TYPE.CLASSIFY]: handlerChangeClassify,
}

const handlerChange = (value: IDanhMucDiaPhuong, setValues: (value: any) => void, type: string) => mapFunctions[type](value, setValues);

export { handlerChange, TYPE };
