import { useInitContext } from '../../../utils/InitContext'

type Props = {}

const DanhSachDiaPhuongDB = (props: Props) => {
  const { data } = useInitContext()
  const { infoDiaPhuong } = data

  return (
    <div className='bg-dark spaces'>
      {
        infoDiaPhuong?.map((item: any, index: number) => {
          return (<>
            <div id={`infor-diaphong-${index}`} className='d-flex spaces gap-6 pl-4 pr-12 py-4 mb-4 bg-black'>
              <div style={{ backgroundColor: item?.location?.color }} className='spaces w-20'></div>
              <div className='text-gray-700 d-flex flex-column spaces gap-2 flex-1 '>
                <div className='spaces d-flex justify-content-between'>
                  <span>{item?.location?.label}: <span className='text-white'>{item?.location?.name}</span></span>
                </div>
                {
                  item.statics.map((itemStatic: any, index: number) => {
                    return (<div>{itemStatic?.label}: <span className='text-white'>{itemStatic?.values?.toString()}</span></div>)
                  }
                  )
                }
              </div>
            </div>
            <div>
            </div>
          </>)
        })
      }
    </div>
  )
}

export default DanhSachDiaPhuongDB
