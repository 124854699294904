import { useEffect, useState } from "react";
import { useAppContext, useLoading } from "../../../../AppContext";
import { InitContext } from "../../../utils/InitContext";
import { IDataDashboard } from "../../model/model";
import { getInforDashboard } from "../../services/services";
import "../../dashboard.scss";
import DashboardHeader from "../DashboardHeader";
import DashboardDuBaoDetail from "./DashboardDuBaoDetail";

interface IProps { }

const DashboardDuBao = (props: IProps) => {
  const { setPageLoading } = useLoading()
  const { userData } = useAppContext();
  const [address, setAddress] = useState({
    id: null,
    isActive: null,
    maTinh: null,
    tenTinh: userData?.tenTinh,
    tinhId: null,
    huyenId: null,
    tenHuyen: userData?.tenHuyen,
    tenXa: userData?.tenXa,
    xaId: null,
    thonId: null,
    tenThon: userData?.tenThon,
    vungMienId: null,
  });
  const [data, setData] = useState({} as IDataDashboard);
  const init = async () => {
    try {
      setPageLoading(true)
      Promise.all([
        getInforDashboard({ provinceName: address?.tenTinh }, "forecast/area-info"),
        getInforDashboard({ provinceName: address?.tenTinh }, "forecast/list-area"),

      ])
        .then(results => {
          const [inforTQGS, infoDiaPhuong
          ] = results;
          setData((prev: any) =>
          ({
            ...prev,
            inforTQGS: inforTQGS?.data,
            infoDiaPhuong: infoDiaPhuong?.data,
          })
          )
        })
    } catch (error) {
      console.error(error)
    } finally {
      setTimeout(() => setPageLoading(false), 100);
    }
  }


  useEffect(() => { // expand map
    const element = document.getElementById("icon-expand");
    const elementMap = document.getElementsByClassName("tab-content");

    if (elementMap.length > 0) {
      const map = elementMap[0] as HTMLElement; // Ensure it's treated as an HTMLElement
      const elementExpand = element as HTMLElement; // Ensure it's treated as an HTMLElement

      const handleClick = () => {
        const requestFullscreen =
          map.requestFullscreen?.bind(map) ||
          (map as any).mozRequestFullScreen?.bind(map) || // Firefox
          (map as any).webkitRequestFullscreen?.bind(map) || // Chrome, Safari and Opera
          (map as any).msRequestFullscreen?.bind(map); // IE/Edge

        if (requestFullscreen) {
          requestFullscreen();
        }
      };

      elementExpand?.addEventListener('click', handleClick);

      const handleFullscreenChange = () => {
        if (!document.fullscreenElement) {
          console.log('Exited fullscreen mode');
        }
      };

      document.addEventListener('fullscreenchange', handleFullscreenChange);

      return () => {
        elementExpand.removeEventListener('click', handleClick);
        document.removeEventListener('fullscreenchange', handleFullscreenChange);
      };
    }
  }, []);

  useEffect(() => {
    init()
  }, [address])

  return (
    <InitContext.Provider value={{ address, setAddress, data }}>
      <div className="dash-board-container">
        <DashboardHeader hasIntervention={false} src='/media/logos/edengue.png' label='BẢNG THÔNG TIN Giám Sát DỊCH SỐT XUẤT HUYẾT' address='TP cần thơ' />
        <DashboardDuBaoDetail />
      </div>
    </InitContext.Provider>
  )
}

export default DashboardDuBao;
