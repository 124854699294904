import moment from "moment";

export const ConstantList = {
  ENTER_KEY: "Enter",
};
export enum SELECT_OPTION {
  SELECT_ACTION = "select-option",
  CLEAR_ACTION = "clear",
  REMOVE_ACTION = "remove-value",
}

export const PERMISSIONS = {
  THB: "EDENGUE.THB",
  ODICH: "EDENGUE.ODICH",
  BAOCAO: "EDENGUE.BAOCAO",
  DANHMUC: "EDENGUE.DANHMUC",
  DM_TINH: "EDENGUE.DANHMUC.TINH",
  DM_HUYEN: "EDENGUE.DANHMUC.HUYEN",
  DM_XA: "EDENGUE.DANHMUC.XA",
  DM_THON: "EDENGUE.DANHMUC.THON",
  DM_CO_SO: "EDENGUE.DANHMUC.COSO",
  DM_NGUOI_DUNG: "EDENGUE.DANHMUC.NGUOIDUNG",
  D_GIAM_SAT: "EDENGUE.DASHBOARD.GIAMSAT",
  D_DU_BAO: "EDENGUE.DASHBOARD.DUBAO",
}

export const PERMISSION_ABILITY = {
  VIEW: "VIEW",
  CREATE: "CREATE",
  UPDATE: "UPDATE",
  DELETE: "DELETE",
  CONFIRM: "CONFIRM",
}

export const MODULE = {
  HO_SO: "HO_SO",
  TUYEN_DUNG: "TUYEN_DUNG",
  CHAM_CONG: "CHAM_CONG",
  TAI_LIEU: "TAI_LIEU",
  DAO_TAO: "DAO_TAO",
  PHONG_HOP: "PHONG_HOP",
  CONG_VIEC: "CONG_VIEC",
  HE_THONG: "HE_THONG"
}

export const ROLE = {
  USER: "ROLE_USER",
}

export const MIN_DATE_200 = new Date().getFullYear() - 200;
export const NAM_HIEN_TAI = new Date().getFullYear();
export const THANG_HIEN_TAI = moment().format("MM");