import axios from "axios";
import { paramsConfig } from "../../../utils/ParamsUtils";
const API_PATH = process.env.REACT_APP_API_URL_D;
let URL = API_PATH + '/epidemic-curve';

export const getListDuongCongChuan = (searchObject: any) => {
  let URL_GET = URL + '/list-by-address'
  return axios.get(URL_GET, paramsConfig(searchObject));
};

export const addDuongCongChuan = (data: any) => {
    return axios.post(URL, data);
};

export const updateDuongCongChuan = (data: any) => {
    let API_URL = URL + `/${data?.id}`
    return axios.put(API_URL, data);
};

export const deleteDuongCongChuan = (id: string) => {
  let API_URL = URL + `/${id}`;
  return axios.delete(API_URL);
};

export const getDuongCongChuanById = (id: string) => {
  let API_URL =  URL + `/${id}`;
  return axios.get(API_URL);
};
