import { OCTAutocomplete } from "@oceantech/oceantech-ui";
import moment from "moment";
import { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { useIntl } from "react-intl";
import { useAppContext } from "../../../AppContext";
import LabelRequired from "../../component/LabelRequired";
import { getApiDanhMucDiaPhuong, getLoaiCoSo, LOAI_CO_SO } from "../../danh-muc/constant/constants";
import { useInitContext } from "../../utils/InitContext";
import "../dashboard.scss";

type Iprops = {
  ChildComponent: React.FC
}

const DetailAdress = ({ ChildComponent }: Iprops) => {
  const { userData } = useAppContext()
  const { setAddress } = useInitContext()
  const intl = useIntl()

  const getLabel = () => {
    let isCoSo = getLoaiCoSo(userData?.phanLoaiCoSo);
    if(isCoSo === LOAI_CO_SO.CUC) return intl.formatMessage({ id: "PROVINCE" })
    if(isCoSo === LOAI_CO_SO.TINH) return intl.formatMessage({ id: "DISTRICT" })
    if(isCoSo ===LOAI_CO_SO.HUYEN) return intl.formatMessage({ id: "COMMUNE" })
    if(isCoSo ===LOAI_CO_SO.XA) return intl.formatMessage({ id: "VILLAGE" })
    return ""
  }

  function updateClock() {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    const timeString = `${hours}:${minutes}:${seconds}`;
    document.getElementById("clock")!.innerText = timeString;
  }

  useEffect(() => {
    let timer = setInterval(updateClock, 1000);
    return () => clearInterval(timer)
  })

  return (
    <>
      <div className="spaces d-flex flex-column oveflow-auto tt-du-bao position-relative">
        <div className="w-100 bg-dark mb-3 position-sticky top-0 left-0">
          <div className="spaces text-light p-6" >
            {intl.formatMessage({ id: "TIME" })}
          </div>
          <div className="spaces p-5">
            <div className="spaces d-flex flex-column align-items-center justify-content-center">
              <div id="clock" className="time" />
              <div className="date">{moment().format("DD/MM/YYYY")} - {intl.formatMessage({ id: "WEEKLY" })} {moment().week()}</div>
            </div>
          </div>
        </div>
        <div className="spaces flex-grow-1" ><ChildComponent /></div>
      </div>
      <div className="spaces p-5">
        <Col xs={12} sm={12} lg={12}>
          <LabelRequired
            label={getLabel()}
            className="spaces white fw-500"
          />
          <OCTAutocomplete
            menuPlacement="top"
            maxMenuHeight={200}
            onChange={(selectedOption) => setAddress?.(selectedOption)}
            className="spaces h-30"
            name="tinh"
            options={[]}
            getOptionLabel={(option) => option?.tenThon || option?.tenXa || option?.tenHuyen || option?.tenTinh}
            searchFunction={(obj) => getApiDanhMucDiaPhuong(userData?.phanLoaiCoSo, { ...obj, pageNumber: 1, pageSize: 99, tinhId: userData?.tinhId, huyenId: userData?.huyenId, xaId: userData?.xaId, })}
            urlData='data.data.data'
          />
        </Col>
      </div>
    </>
  )
}

export default DetailAdress;
