import { OCTAutocomplete, OCTTextValidator } from "@oceantech/oceantech-ui";
import { useFormikContext } from "formik";
import { Col, Form, Row } from "react-bootstrap";
import { configByCoSo, TRANG_THAI, VUNG_MIEN_OPTION } from "../../constant/constants";
import { IDanhMucDiaPhuong, IDanhMucUser } from "../../model/model";
import { getListDonVi, getListHuyenByTinhId, getListTinh, getListXaByHuyenId } from "../../../services";
import { handlerChange, TYPE } from "../../utils/utils";
import { getVaiTroByUser } from "../../services/services";
import AsyncAutoComplete from "../../../component/input-field/AsyncAutoComplete";
import { useEffect, useState } from "react";
import { KEY_LOCALSTORAGE } from "../../../auth/core/_consts";
import { localStorageItem } from "../../../utils/LocalStorage";
import { useIntl } from "react-intl";

const AddDialogUser = (props: any) => {
  let { initValue } = props;

  const {
    values,
    handleChange,
    setFieldValue,
    errors, touched,
    setValues,
  } = useFormikContext<IDanhMucUser>()

  const classify = values.classify;
  const userData = localStorageItem.get(KEY_LOCALSTORAGE.USER_INFOMATION)
  const [fieldControl, setFieldControl] = useState(configByCoSo[userData?.phanLoaiCoSo])
  const intl = useIntl()

  useEffect(() => {
    if (classify?.hinhThuc) {
        setFieldControl(configByCoSo[classify?.hinhThuc])
    }
}, [classify?.hinhThuc])

  return <Row>
    <Col xs={12} sm={6} md={4} lg={4} className="spaces mt-5">
      <OCTTextValidator
        lable={intl.formatMessage({ id: "FULLNAME" })}
        name="hoTen"
        type="text"
        value={values.hoTen}
        isRequired
        onChange={handleChange}
        errors={errors?.hoTen}
        touched={touched?.hoTen}
      />
    </Col>
    <Col xs={12} sm={6} md={4} lg={4} className="spaces mt-5">
      <OCTTextValidator
        lable={intl.formatMessage({ id: "USER_NAME" })}
        name="tenDangNhap"
        type="text"
        value={values.tenDangNhap}
        isRequired
        onChange={handleChange}
        errors={errors?.tenDangNhap}
        touched={touched?.tenDangNhap}
      />
    </Col>
    <Col xs={12} sm={6} md={4} lg={4} className="spaces mt-5">
      <OCTTextValidator
        lable={intl.formatMessage({ id: "EMAIL" })}
        name="email"
        value={values.email}
        type="email"
        onChange={handleChange}
      />
    </Col>
    <Col xs={12} sm={6} md={4} lg={4} className="spaces mt-5">
      <OCTTextValidator
        lable={intl.formatMessage({ id: "PHONE_NUMBER" })}
        name="dienThoai"
        type="text"
        value={values.dienThoai}
        onChange={handleChange}
      />
    </Col>
    {(values?.isChangePassword) &&
      <>
        <Col xs={12} sm={6} md={4} lg={4} className="spaces mt-5">
          <OCTTextValidator
            lable={intl.formatMessage({ id: "PASSWORD" })}
            name="password"
            type="text"
            value={values.password}
            errors={errors?.password}
            touched={touched?.password}
            isRequired
            onChange={handleChange}
          />
        </Col>
        <Col xs={12} sm={6} md={4} lg={4} className="spaces mt-5">
          <OCTTextValidator
            lable={intl.formatMessage({ id: "RETYPE_PASSWORD" })}
            name="nhapLaiMatKhau"
            type="text"
            value={values.nhapLaiMatKhau}
            errors={errors?.nhapLaiMatKhau}
            touched={touched?.nhapLaiMatKhau}
            isRequired
            onChange={handleChange}
          />
        </Col>
      </>
    }
    <Col xs={12} sm={6} md={4} lg={4} className="spaces mt-5">
      <OCTAutocomplete
        lable={intl.formatMessage({ id: "STATUS" })}
        getOptionLabel={(option) => option?.name}
        valueSearch={"code"}
        options={TRANG_THAI}
        value={values.isActive}
        name='isActive'
        searchObject={{}}
        onChange={(value) => handlerChange(value, setValues, TYPE.CLASSIFY)}
      />
    </Col>
    <Col xs={12} sm={6} md={4} lg={4} className="spaces mt-5">
      <OCTAutocomplete
        lable={intl.formatMessage({ id: "CLASSIFY" })}
        searchFunction={getVaiTroByUser}
        urlData='data.data'
        getOptionLabel={(option) => option?.tenVaiTro}
        getOptionValue={(option) => option?.id}
        value={values.classify || values?.vaiTroId}
        options={[]}
        name='classify'
        errors={errors?.classify}
        touched={touched?.classify}
        isRequired
        searchObject={{}}
        onChange={(value) => handlerChange(value, setValues, TYPE.CLASSIFY)}
      />
    </Col>
    <Col xs={12} sm={6} md={4} lg={4} className="spaces mt-5">
      <OCTAutocomplete
        lable={intl.formatMessage({ id: "PROVINCE" })}
        searchFunction={getListTinh}
        urlData='data.data'
        getOptionLabel={(option) => option?.tenTinh}
        options={[]}
        name='tinhId'
        value={values.tinhObject}
        searchObject={{}}
        onChange={(value) => handlerChange(value, setValues, TYPE.PROVINCE)}
        dependencies={[values?.classify?.id]}
        isDisabled={!values!.classify?.id}
      />
    </Col>
    <Col xs={12} sm={6} md={4} lg={4} className="spaces mt-5">
      <OCTAutocomplete
        lable={intl.formatMessage({ id: "DISTRICT" })}
        searchFunction={() => getListHuyenByTinhId(values?.tinhObject?.id)}
        urlData='data.data'
        getOptionLabel={(option) => option?.tenHuyen}
        options={[]}
        name='huyenId'
        searchObject={{}}
        value={values.huyenObject}
        onChange={(value) => handlerChange(value, setValues, TYPE.DISTRICT)}
        dependencies={[values?.tinhObject]}
        isDisabled={!values?.tinhObject || !values?.classify?.id || fieldControl?.disabled?.huyen}
      />
    </Col>
    <Col xs={12} sm={6} md={4} lg={4} className="spaces mt-5">
      <OCTAutocomplete
        lable={intl.formatMessage({ id: "COMMUNE" })}
        searchFunction={() => getListXaByHuyenId(values?.huyenObject?.id)}
        urlData='data.data'
        getOptionLabel={(option) => option?.tenXa}
        options={[]}
        name='xaId'
        searchObject={{}}
        value={values.xaObject}
        onChange={(value) => handlerChange(value, setValues, TYPE.COMMUNE)}
        dependencies={[values?.huyenObject]}
        isDisabled={!values?.huyenObject || !values?.classify?.id || fieldControl?.disabled?.xa}
      />
    </Col>
    <Col xs={12} sm={6} md={4} lg={4} className="spaces mt-5">
      <AsyncAutoComplete
        params={{ PageNumber: 1, PageSize: 9999, tinhId: values?.tinhObject?.id, huyenId: values?.huyenObject?.id, xaId: values?.xaObject?.id, hinhThuc: values?.classify?.hinhThuc }}
        optionsResponse="data.data"
        required
        label={intl.formatMessage({ id: "WORKPLACE_STUDY" })}
        displayField='tenCoSo'
        service={getListDonVi}
        handleChange={(value) => setFieldValue("donViObject", value)}
        nameErrorMessage={errors?.donViObject as string}
        value={values.donViObject}
        touched={touched?.donViObject}
        isDisabled={!values?.classify?.id}
      />
    </Col>
    <Col xs={12} sm={6} md={4} lg={4} className="spaces mt-5 d-flex align-items-end mt-12">
      {
        initValue?.id && <Form.Check
          type="switch"
          onChange={() => setFieldValue("isChangePassword", !values?.isChangePassword)}
          checked={values?.isChangePassword}
          id="custom-switch"
          label={intl.formatMessage({ id: "CHANGE_PASSWORD" })}
        />
      }
    </Col>
  </Row>
}
export default AddDialogUser;
