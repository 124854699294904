import { useEffect, useRef, useState } from "react";
import InputSearch from "../../../component/input-field/InputSearch";
import { useIntl } from "react-intl";

type IProp = {
  keySearch: string,
  setKeySearch: (e: string) => void,
  handlerSearch: () => void,
};

const SearchField = (props: IProp) => {
  const inputSearchRef = useRef<HTMLDivElement | null>(null);
  const { keySearch, setKeySearch, handlerSearch } = props;
  const intl = useIntl()

  useEffect(() => {
    const inputElement = inputSearchRef.current!.querySelector('input');
    if (inputElement) {
      const handlerEnter = (event: KeyboardEvent) => {
        if (event.key !== 'Enter') return;
        handlerSearch()
      };
      inputElement.addEventListener('keydown', handlerEnter)
      return () => {
        inputElement.removeEventListener('keydown', handlerEnter)
      }
    }
  }, [keySearch]);

  return (
    <div className="ds-search-box">
      <div ref={inputSearchRef} className="box-search">
        <InputSearch
          placeholder={intl.formatMessage({ id: "SEARCH_BY_NAME" })}
          handleChange={(e) => setKeySearch(e.target.value)}
          className="spaces h-32 w-250"
          value={keySearch}
          handleSearch={handlerSearch}
        />
      </div>
    </div>
  );
};

export default SearchField;
