import moment from "moment"
export const DuongCongChuanColumns = (intl: any, handlerUpdate: any, setOpenConfirmDialog: any) => [
  {
      name: "#",
      field: "",
      render: (row: any, index: number, stt: number) => <span>{stt}</span>
  },
  {
    name: `${intl.formatMessage({ id: "ACTIONS" })}`,
    field: "thaoTac",
    cellStyle: {
        minWidth: "50px",
        textAlign: "center",
    },
    headerStyle: {
        minWidth: "50px",
      },
      render: (rowData: any, index: number) => {
        console.log("rowData", rowData)
          return (
              <>
                  <i
                      onClick={() => handlerUpdate(rowData?.id)}
                      className="spaces cursor-pointer mx-5 fs-17 text-primary bi bi-pencil-square fa-lg" />
                  <i
                      onClick={() => setOpenConfirmDialog({ id: rowData?.id, open: true })}
                      className="spaces cursor-pointer mx-5 bi bi-trash3-fill text-danger fs-17 fa-lg"
                  ></i>
              </>

          )
      }
},
  {
      name: `${intl.formatMessage({ id: "TYPE" })}`,
      field: "type",
      headerStyle: {
          minWidth: "120px"
      },
      cellStyle: {
          textAlign: "center",
      },
  },
  {
      name: `Time zone`,
      field: " ",
      headerStyle: {
          minWidth: "120px"
      },
      cellStyle: {
          minWidth: "200px",
          textAlign: "center",
      },
      render: (row: any, index: number, stt: number) => <span>{`${row?.fromYear} - ${row?.toYear}`}</span>
  },
  {
      name: `Account Created`,
      field: "createBy",
      headerStyle: {
          minWidth: "150px"
      },
  },
  {
      name: `Created date`,
      field: "createDate",
      headerStyle: {
          minWidth: "120px"
      },
      render: (row: any, index: number, stt: number) => <span>{moment(row?.createDate).format("DD/MM/YYYY HH:mm")}</span>
  },
]
