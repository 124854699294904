import { useIntl } from 'react-intl';
import { Link, useNavigate, useParams } from 'react-router-dom';
import "../../../../../app/modules/styles/index.scss";
import { TMenu } from '../../../../../app/pages/Homepage/listMenu';
import { MenuItem } from './MenuItem';
import { KTSVG } from '../../../../helpers';
import { checkMenuByPermissions } from '../../../../../app/modules/utils/FunctionUtils';
import { useEffect } from 'react';
import { setSubMenu } from '../../../../../app/modules/auth';
import "./index.scss";
import clsx from 'clsx';
export function MenuInner() {
  const intl = useIntl();
  const navigate = useNavigate()
  const checkedMenu = checkMenuByPermissions();

  useEffect(() => {// Ẩn menu đang mở khi scroll
    const handleScroll = () => {
      if (window.scrollY > 1) {
        let menu = document.getElementsByClassName("dropdown-menu");
        Array.from(menu)?.map(item => item.classList.remove("show"))
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleButtonClick = (name: string) => {
    setSubMenu(name)
  };

  useEffect(() => {
    const tabList:any = document.querySelector('.header-list-nav');
    const isScroll = tabList.clientWidth < tabList?.scrollWidth;
    const rightArrow = document.querySelector('.header-list-nav-container .arrow-right');
    const leftArrow = document.querySelector('.header-list-nav-container .arrow-left');
    if(isScroll) {
      rightArrow?.classList?.remove("hidden");
      const handleShowIcon = () => {
        if(tabList) {
          if(tabList.scrollLeft >= 20) {
            leftArrow?.classList?.remove("hidden");
          } else {
            leftArrow?.classList?.add("hidden");
          }

          const maxScrollValue = tabList.scrollWidth - tabList.clientWidth - 20;
          if(tabList.scrollLeft >= maxScrollValue) {
            rightArrow?.classList?.add("hidden");
          } else {
            rightArrow?.classList?.remove("hidden");
          }
        }
      }
      rightArrow?.addEventListener('click', () => {
        if(tabList) {
          tabList.scrollLeft += 100;
          handleShowIcon();
        }
      });
      leftArrow?.addEventListener('click', () => {
        if(tabList) {
          tabList.scrollLeft -= 100;
          handleShowIcon();
        }
      });
      tabList?.addEventListener('scroll', handleShowIcon);
    }
    setSubMenu();
  }, []);

  const params = useParams();

  return (
    <div className="d-flex justify-content-center align-items-center">
      <div className='header-brand'>
        <div className='header-brand-link w-100 flex flex-middle flex-center' >
          <Link to="/home" className='white spaces fs-16 fw-700 flex flex-middle' >
              E-DENGUE
          </Link>
        </div>
      </div>
      <div className="header-list-nav-container">
        <KTSVG path='/media/icons/arrow-left.svg' className="text-white arrow-left hidden" svgClassName={`position-absolute`}/>
        <div className="header-list-nav">
          {checkedMenu?.map((item: TMenu, index: number) => {
            const path = (params?.["*"]?.split("/")?.[0]) as string
            const isChild = item?.subMenu?.some(item => item.to.includes(path))
            return (
              <div className="dropdown position-relative" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                <button className={`btn ${item?.subMenu?.length ? "dropdown-toggle" : ""} text-light`} type="button" data-bs-toggle="dropdown" aria-expanded="false">
                <span className={clsx({
                    "text-warning": isChild
                })}>{item?.title}</span>
                </button>
                {item?.subMenu?.length > 0 &&
                  <ul className="dropdown-menu">
                    {
                      item.subMenu?.map(item => {
                        return <li onClick={() => navigate(item?.to)}>
                          <a className="dropdown-item" href="#">{item?.title}</a></li>
                      })
                    }
                  </ul>
                }
              </div>
            )
          })}
        </div>
        <KTSVG path='/media/icons/arrow-left.svg' className="text-white arrow-right hidden" svgClassName={`position-absolute`}/>
      </div>
    </div>
  );
}
