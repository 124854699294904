import 'leaflet/dist/leaflet.css';
import React, { useEffect, useRef, useState } from 'react';
import { GeoJSON, MapContainer, Marker, Popup, TileLayer, useMapEvents } from 'react-leaflet';
import './styles.scss';
import L, { Icon, PathOptions } from 'leaflet';
import { Feature } from 'geojson';
import { ClusteringMarkers } from './ClusteringMakers';
import markerIconPng from "leaflet/dist/images/marker-icon.png"
interface IDataPoints {
    points: [number, number][];
    maxZoomCluster: number;
    radiusCluster: number;
    renderIconCluster?: (cluster: any) => L.DivIconOptions;
    popupRender?:any
}

interface MapComponentProps {
    center?: [number, number];
    zoom?: number;
    dataPoints?: IDataPoints[];
    tileLayerUrl?: string;
    geoJsonData?: GeoJSON.FeatureCollection;
    defaultMarkerPosition?: [number, number];
    getPositionMarker?: (position: [number, number]) => void;
    handleZoomEvent?: (e: L.LeafletEvent) => void;
    handleClickFeature?: (e: L.LeafletEvent) => void;
    handleMouseOverFeature?: (e: L.LeafletEvent) => void;
    handleMouseOutFeature?: (e: L.LeafletEvent) => void;
    setStyleGeoJson?: (feature?: GeoJSON.Feature<GeoJSON.GeometryObject>) => PathOptions;
    keyGeoJson?: string;
    keyMap?: string;
    height?: string;
    width?: string;

}

const OCTMap: React.FC<MapComponentProps> = ({
    center = [21.0285, 105.8542],
    zoom = 7,
    dataPoints = [],
    tileLayerUrl = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    geoJsonData,
    defaultMarkerPosition,
    getPositionMarker,
    handleZoomEvent,
    handleClickFeature,
    handleMouseOverFeature,
    handleMouseOutFeature,
    setStyleGeoJson,
    keyGeoJson,
    keyMap,
    height = "100vh",
    width ="100vw"
}) => {
    const mapRef = useRef<any>(null);
    const [positionMarker, setPositionMarker] = useState<[number, number] | null>(null);
    const [mapLoaded, setmapLoaded] = useState(false)

    useEffect(()=> {
        defaultMarkerPosition && setPositionMarker(defaultMarkerPosition)
    }, [defaultMarkerPosition])

    useEffect(() => {
        let timerId = setTimeout(() => {
            if (!mapLoaded) return;
            const map = mapRef.current; // Lấy đối tượng bản đồ từ ref

            if (map && geoJsonData) {
                const geoJsonLayer = L.geoJSON(geoJsonData); // Tạo layer GeoJSON từ dữ liệu
                const bounds = geoJsonLayer.getBounds(); // Lấy giới hạn (bounds) của GeoJSON

                // Nếu có dữ liệu GeoJSON, áp dụng fitBounds để tính toán mức độ zoom và trung tâm
                if (bounds.isValid()) {
                    mapRef.current?.fitBounds(bounds); // Tự động điều chỉnh zoom và center để vừa khít GeoJSON
                }
            }
        }, 500);
        return () => clearTimeout(timerId)
    }, [geoJsonData, mapRef.current]);

    const IconMaker = new Icon({
        iconUrl: "./media/images/makerMap.png",
        iconAnchor: [20, 20],
        iconSize: [41, 41],
    });

    const MapEvents = () => {
        useMapEvents({
            click(e) {
                if (getPositionMarker) {
                    const newPosition: [number, number] = [e.latlng.lat, e.latlng.lng];
                    setPositionMarker(newPosition);
                    getPositionMarker?.(newPosition);
                }
            },
            zoomend: handleZoomEvent || (() => { }),
        });
        return null;
    };

    const onEachFeature = (feature: Feature, layer: L.Layer) => {
        layer.on({
            click: handleClickFeature || (() => { }),
            mouseover: handleMouseOverFeature || (() => { }),
            mouseout: handleMouseOutFeature || (() => { }),
        });
    };

    return (
        <MapContainer
            center={center}
            zoom={zoom}
            style={{ height, width }}
            attributionControl={false}
            ref={mapRef}
            key={keyMap}
            whenReady={() => setmapLoaded(true)}
        >
            <TileLayer
                url={tileLayerUrl}
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            {
                dataPoints?.map((dataPoint: IDataPoints) => {
                    return (
                        <ClusteringMarkers
                            renderIconCluster={dataPoint?.renderIconCluster}
                            dataPoints={dataPoint.points}
                            maxZoomCluster={dataPoint?.maxZoomCluster}
                            radiusCluster={dataPoint?.radiusCluster}
                            popupRender={dataPoint?.popupRender}
                        />
                    )
                })
            }
            <MapEvents />
            {positionMarker && (
                <Marker
                    icon={IconMaker}
                    position={positionMarker}
                >
                    <Popup>
                        Longitude: {positionMarker[1]}
                        <br />
                        Latitude: {positionMarker[0]}
                    </Popup>
                </Marker>
            )}
            {geoJsonData && (
                <GeoJSON

                    key={keyGeoJson}
                    data={geoJsonData}
                    onEachFeature={onEachFeature}
                    style={setStyleGeoJson}

                />
            )}
        </MapContainer>
    );
};

export default OCTMap;
