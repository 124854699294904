import { OCTTable } from "@oceantech/oceantech-ui";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { TienSuBenhColumns } from "../constants/constant";
import { TruongHopBenh } from "../model/Model";
import { getThongTinTienSuBenh } from "../servives/Services";
import clsx from "clsx";
import { useIntl } from "react-intl";

type Iprops = {
    noTitle?: boolean
}

const TienSuBenh = (props: Iprops) => {
    const { values } = useFormikContext<TruongHopBenh>()
    const [data, setdata] = useState([]);
    const intl = useIntl()
    const id = values?.truongHopBenh?.truongHopBenhId

    const getData = async () => {
        try {
            if (id) {
                const res = await getThongTinTienSuBenh(id);
                setdata(res?.data?.data?.tienSuBenhThbResps)
            }
            else {
                setdata([])
            }
        } catch (error) {
            console.error(error);
            toast.error(error as string);
        }
    }

    useEffect(() => {
        getData();
    }, [id])

    return (
        <div className={clsx({ "section-container": !props.noTitle })}>
            {!props.noTitle && <span className="spaces fs-14 text-primary text-uppercase fw-bold">{intl.formatMessage({ id: "MEDICAL_HISTORY" })}</span>}
            <OCTTable
                id="profile"
                className="spaces p-0"
                data={data}
                columns={TienSuBenhColumns(intl)}
                fixedColumnsCount={0}
                notDelete={true}
                notEdit={true}
                noToolbar={true}
                unSelectedAll={true}
                noPagination
            />
        </div>
    )
}

export default TienSuBenh
