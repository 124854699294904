import { Col, Row } from "react-bootstrap"
import { uppercase } from '../../utils/FunctionUtils';
import { useInitContext } from "../../utils/InitContext";
import { Link } from 'react-router-dom';
interface IProps {
  hasIntervention?: boolean, //true có ổ dịch, false không có ổ dịch
  src?: string,
  label?: string,
  address?: string
}
const DashboardHeader = (props: IProps) => {
  const { data } = useInitContext();
  const { inforTQGS } = data;
  return <Row className="spaces bg-dark header-dashboard m-0 d-flex align-items-center mb-10" >
    <Col className="d-flex justify-content-center" xs={12} lg={2}><Link to={'/home'}><img width={80} src={props.src} alt="" /></Link></Col>
    <Col className="d-flex justify-content-center text-align-center p-5 flex-fill" xs={12} sm={11} md={11} lg={7}>{uppercase(inforTQGS?.title?.text)}</Col>
    <Col style={{ backgroundColor: inforTQGS?.location?.color }} id="box-location" className={`d-flex justify-content-center spaces fs-20 p-12 white spaces m-10`} xs={12} lg={2}>{uppercase(inforTQGS?.location?.name)}</Col>
  </Row>
}

export default DashboardHeader;
