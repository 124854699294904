import { Form, Formik } from "formik"
import { TabMenu } from "../../../component/tabs/TabMenu"
import { tabThongTinTruongHopBenh } from "../constants/constant"
import { TruongHopBenh } from "../model/Model"
import { useIntl } from "react-intl"

type IProps = {
    dataRow: TruongHopBenh
}

const ThongTinThb = ({ dataRow }: IProps) => {
    const intl = useIntl()

    return (
        <>
            <Formik
                initialValues={dataRow}
                onSubmit={() => { }}
                enableReinitialize
            >
                {formikProps => (
                    <Form>
                        <TabMenu
                            danhsachTabs={tabThongTinTruongHopBenh(intl)}
                        />
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default ThongTinThb
