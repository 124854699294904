import { OCTAutocomplete} from "@oceantech/oceantech-ui";
import moment from "moment";
import { Link } from "react-router-dom";
import { GENDER_OPT, INITIAL_DOI_TUONG_MAC_BENH, INITIAL_TRUONG_HOP_BENH } from "../../quan-ly-truong-hop-benh/danh-sach-truong-hop-benh/constants/constant";
import { getListHoatDongChongDich } from "../../services";
import { BienPhapTrienKhai, ISearchObjModel, IThongTinODich, ODich, SoCaMac, TienSuDichTe, XetNghiem, IConfigTable } from "../models/quanLyODichModels";
import TextValidator from "../../component/input-field/TextValidator";

export const TRANG_THAI_O_DICH = [
    {
        code: 0,
        value: "Đang hoạt động"
    },
    {
        code: 1,
        value: "Đã kết thúc"
    }
]


export const dsOBenhColumns = (intl: any) => [
    {
        name: "#",
        field: "",
        render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
        name: `${intl.formatMessage({ id: "DISEASE_NAME" })}`,
        field: "benhTruyenNhiemTen",
        cellStyle: {
            minWidth: "250px",
            textAlign: "left",
        },
    },
    {
        name: `${intl.formatMessage({ id: "PLACE_HAS_OUTBREAK" })}`,
        field: "diaDiem",
        cellStyle: {
            minWidth: "250px",
            textAlign: "left",
        },
        render: (rowData: any) => {
            return (
                <Link to={`/chinh-sua-o-dich/${rowData?.oDichId}`} className="cursor-pointer text-primary">
                    {(rowData?.thonTen?.toUpperCase()  + " - ") + rowData?.xaTen + " - " + rowData?.huyenTen + " - " + rowData?.tinhTen}
                </Link>
            )
        }
    },
    {
        name: `${intl.formatMessage({ id: "STATUS" })}`,
        field: "trangThai",
        headerStyle: {
            minWidth: "120px"
        },
        render: (rowData: any) => {
            return <div className={`${rowData?.trangThai === TRANG_THAI_O_DICH[0].code ? "text-danger" : ""}`}>
                {TRANG_THAI_O_DICH.find((item: any) => item.code === rowData?.trangThai)?.value}
            </div>
        }
    },
    {
        name: `${intl.formatMessage({ id: "DATE_OF_DESEASE_ONSET" })}`,
        field: "ngayKhoiPhat",
        cellStyle: {
            minWidth: "100px",
            textAlign: "center",
        },
        render: (rowData: any) => rowData?.ngayKhoiPhat ? moment(rowData?.ngayKhoiPhat).format("DD/MM/YYYY") : null
    },
    {
        name: `${intl.formatMessage({ id: "DATE_OF_DESEASE_OVER" })}`,
        field: "ngayKetThuc",
        cellStyle: {
            minWidth: "100px",
            textAlign: "center",
        },
        render: (rowData: any) => rowData?.ngayKetThuc ? moment(rowData?.ngayKetThuc).format("DD/MM/YYYY") : null
    },
    {
        name: `${intl.formatMessage({ id: "NUMBER_PATIENT_HAS_DESEASE" })}`,
        field: "soCaMac",
        cellStyle: {
            minWidth: "100px",
            textAlign: "center",
        },
    },
    {
        name: `${intl.formatMessage({ id: "NUMBER_PATIENT_DEAD" })}`,
        field: "soCaTuVong",
        cellStyle: {
            minWidth: "100px",
            textAlign: "center",
        },
    },
    {
        name: `${intl.formatMessage({ id: "REPORT_DATE" })}`,
        field: "ngayBaoCao",
        cellStyle: {
            minWidth: "100px",
            textAlign: "center",
        },
        render: (rowData: any) => rowData?.ngayBaoCao ? moment(rowData?.ngayBaoCao).format("DD/MM/YYYY") : null
    },
    {
        name: `${intl.formatMessage({ id: "REPORT_FACILITY" })}`,
        field: "coSoBaoCaoTen",
        cellStyle: {
            minWidth: "200px",
            textAlign: "left",
        },
    },
    {
        name: `${intl.formatMessage({ id: "PROVINCE_REPORT" })}`,
        field: "tinhBaoCaoTen",
        cellStyle: {
            minWidth: "100px",
            textAlign: "center",
        },
    }
]


export const dsTienXuBenhNhanColumns = [
    {
        name: "Ngày cập nhật",
        field: "ngayCapNhat",
        headerStyle: {
            minWidth: "60px"
        },
    },
    {
        name: "Tên bệnh",
        field: "tenBenh",
        headerStyle: {
            minWidth: "60px"
        },
    },
    {
        name: "Cơ sở điều trị",
        field: "coSoDieuTri",
        headerStyle: {
            minWidth: "60px"
        },
    },
    {
        name: "Tình trạng",
        field: "tinhTrang",
        headerStyle: {
            minWidth: "60px"
        },
    },
]

export const dsSoMauXetNghiemColumns = ({ handleDeleteRow, handleChange, values, errors, touched, isFieldUpdate, intl }: any) => {
    return [
        {
            name: "#",
            field: "",
            headerStyle: {
                minWidth: "60px"
            },
            render: (row: any, index: number, stt: number) => <span>{stt}</span>
        },
        {
            name: `${intl.formatMessage({ id: "LOCAL" })}`,
            field: "diaPhuong",
            headerStyle: {
                minWidth: "60px"
            },
            render: (row: any, index: number, stt: number) => (
                <TextValidator
                    className="spaces min-h-54"
                    type="text"
                    isRequired
                    name={`xetNghiemList[${index}].tenDiaPhuong`}
                    defaultValue={values?.xetNghiemList[index]?.tenDiaPhuong}
                    onBlur={handleChange}
                    errors={errors?.xetNghiemList?.[index]?.tenDiaPhuong}
                    touched={touched?.xetNghiemList?.[index]?.tenDiaPhuong}
                    disabled={isFieldUpdate}
                />
            )
        },
        {
            name: `${intl.formatMessage({ id: "DATE" })}`,
            field: "ngay",
            headerStyle: {
                minWidth: "60px"
            },
            render: (row: any, index: number, stt: number) => (
                <TextValidator
                    type="date"
                    isRequired
                    className="spaces min-h-54"
                    name={`xetNghiemList[${index}].ngayCapNhat`}
                    defaultValue={values?.xetNghiemList[index]?.ngayCapNhat && moment(values?.xetNghiemList[index]?.ngayCapNhat).format("YYYY-MM-DD")}
                    onBlur={handleChange}
                    errors={errors?.xetNghiemList?.[index]?.ngayCapNhat}
                    touched={touched?.xetNghiemList?.[index]?.ngayCapNhat}
                    disabled={isFieldUpdate}
                />
            )
        },
        {
            name: `${intl.formatMessage({ id: "SPECIMEN_NUMBER" })}`,
            field: "soMauXN",
            headerStyle: {
                minWidth: "60px"
            },
            render: (row: any, index: number, stt: number) => (
                <TextValidator
                    type="text"
                    isRequired
                    className="spaces min-h-54"
                    name={`xetNghiemList[${index}].soXn`}
                    defaultValue={values?.xetNghiemList[index]?.soXn}
                    onBlur={handleChange}
                    errors={errors?.xetNghiemList?.[index]?.soXn}
                    touched={touched?.xetNghiemList?.[index]?.soXn}
                    disabled={isFieldUpdate}
                />
            )
        },
        {
            name: `${intl.formatMessage({ id: "SPECIMEN_PLUS" })}`,
            field: "soMau",
            headerStyle: {
                minWidth: "60px"
            },
            render: (row: any, index: number, stt: number) => (
                <TextValidator
                    type="text"
                    className="spaces min-h-54"
                    isRequired
                    name={`xetNghiemList[${index}].soDuongTinh`}
                    defaultValue={values?.xetNghiemList[index]?.soDuongTinh}
                    onBlur={handleChange}
                    rrors={errors?.xetNghiemList?.[index]?.soDuongTinh}
                    touched={touched?.xetNghiemList?.[index]?.soDuongTinh}
                    disabled={isFieldUpdate}
                />
            )
        },
        ...!isFieldUpdate ? [{
            name: `${intl.formatMessage({ id: "ACTIONS" })}`,
            field: "thaoTac",
            headerStyle: {
                minWidth: "60px"
            },
            render: (row: any, index: number) => {
                return (
                    index > 0 && <div onClick={() => { handleDeleteRow(index) }}>
                        <i className="bi bi-x-lg fs-3 text-danger cursor-pointer"></i>
                    </div>
                );
            }
        }] : [],
    ]
}

export const dsSoMacTuVongColumns = ({ handleDeleteRow, handleChangeField, values, errors, touched, isFieldUpdate, intl }: any) => {

    return [
        {
            name: "#",
            field: "",
            headerStyle: {
                minWidth: "40px"
            },
            render: (row: any, index: number, stt: number) => <span>{stt}</span>
        },
        {
            name: `${intl.formatMessage({ id: "LOCAL" })}`,
            field: "tenDiaPhuong",
            headerStyle: {
                minWidth: "60px"
            },
            render: (row: any, index: number, stt: number) => (
                <TextValidator
                    type="text"
                    isRequired
                    className="spaces min-h-54"
                    key={index}
                    name={`soCaMacList[${index}].tenDiaPhuong`}
                    defaultValue={values?.soCaMacList[index]?.tenDiaPhuong}
                    onBlur={handleChangeField}
                    errorTooltip
                    errors={errors?.soCaMacList?.[index]?.tenDiaPhuong}
                    touched={touched?.soCaMacList?.[index]?.tenDiaPhuong}
                    disabled={isFieldUpdate}
                />
            )
        },
        {
            name: `${intl.formatMessage({ id: "DATE" })}`,
            field: "ngaySinh",
            headerStyle: {
                minWidth: "60px"
            },
            cellStyle: {
                padding: "8px",
            },
            render: (row: any, index: number, stt: number) => (
                <TextValidator
                    type="date"
                    className="spaces min-h-54"
                    isRequired
                    name={`soCaMacList[${index}].ngayCapNhat`}
                    defaultValue={values?.soCaMacList[index]?.ngayCapNhat && moment(values?.soCaMacList[index]?.ngayCapNhat).format("YYYY-MM-DD")}
                    onBlur={handleChangeField}
                    errors={errors?.soCaMacList?.[index]?.ngayCapNhat}
                    touched={touched?.soCaMacList?.[index]?.ngayCapNhat}
                    disabled={isFieldUpdate}
                />
            )
        },
        {
            name:  `${intl.formatMessage({ id: "NUMBER_PATIENT_HAS_DESEASE" })}`,
            field: "tenBenhNhan",
            headerStyle: {
                width: "100px"
            },
            cellStyle: {
                padding: "8px",
            },
            render: (row: any, index: number, stt: number) => (
                <TextValidator
                    type="text"
                    className="spaces min-h-54"
                    isRequired
                    name={`soCaMacList[${index}].soMac`}
                    defaultValue={values?.soCaMacList[index]?.soMac}
                    onBlur={handleChangeField}
                    errors={errors?.soCaMacList?.[index]?.soMac}
                    touched={touched?.soCaMacList?.[index]?.soMac}
                    disabled={isFieldUpdate}
                />
            )
        },
        {
            name:  `${intl.formatMessage({ id: "NUMBER_PATIENT_DEAD" })}`,
            field: "ngaySinh",
            headerStyle: {
                width: "100px"
            },
            cellStyle: {
                padding: "8px",
            },
            render: (row: any, index: number, stt: number) => (
                <TextValidator
                    type="text"
                    isRequired
                    className="spaces min-h-54"
                    name={`soCaMacList[${index}].soChet`}
                    defaultValue={values?.soCaMacList[index]?.soChet}
                    onBlur={handleChangeField}
                    errors={errors?.soCaMacList?.[index]?.soChet}
                    touched={touched?.soCaMacList?.[index]?.soChet}
                    disabled={isFieldUpdate}
                />
            )
        },
        ...!isFieldUpdate ? [{
            name:  `${intl.formatMessage({ id: "ACTIONS" })}`,
            field: "thaoTac",
            headerStyle: {
                minWidth: "60px"
            },
            cellStyle: {
                padding: "8px",
            },
            render: (row: any, index: number) => {
                return (
                    index > 0 &&
                    <i className="bi bi-x-lg fs-3 text-danger cursor-pointer"
                        onClick={() => handleDeleteRow(index)} />
                );
            }
        }] : [],
    ]
}

export const dsBienPhapPhongChongColumns = ({ handleDeleteRow, handleChange, values, setFieldValue, errors, touched, isFieldUpdate, intl }: any) => {

    return [
        {
            name: "#",
            field: "",
            headerStyle: {
                minWidth: "60px"
            },
            render: (row: any, index: number, stt: number) => <span>{stt}</span>
        },
        {
            name: `${intl.formatMessage({ id: "ACTIONS" })}`,
            field: "hoatDong",
            headerStyle: {
                minWidth: "240px",
            },
            render: (row: any, index: number) => {
                return (
                    <div className="spaces min-h-54">
                        <OCTAutocomplete
                            searchFunction={getListHoatDongChongDich}
                            urlData='data.data'
                            getOptionLabel={(option) => option?.tenHoatDong}
                            options={[]}
                            value={values.bienPhapTrienKhaiList[index]?.hdPhongChongDich}
                            onChange={(option) => {
                                setFieldValue(`bienPhapTrienKhaiList[${index}].hdPhongChongDich`, option)
                            }}
                            searchObject={{}}
                            errors={errors?.bienPhapTrienKhaiList?.[index]?.hdPhongChongDich}
                            touched={touched?.bienPhapTrienKhaiList?.[index]?.hdPhongChongDich}
                            isDisabled={isFieldUpdate}
                        />
                    </div>
                );
            }
        },
        {
            field: "ngay",
            name: `${intl.formatMessage({ id: "DATE" })}`,
            headerStyle: {
                minWidth: "120px"
            },
            render: (row: any, index: number) => {
                return (
                    <TextValidator
                        className="flex-row min-w-80 spaces min-h-54"
                        type="date"
                        name={`bienPhapTrienKhaiList[${index}].ngayCapNhat`}
                        defaultValue={values?.bienPhapTrienKhaiList[index]?.ngayCapNhat && moment(values?.bienPhapTrienKhaiList[index]?.ngayCapNhat).format("YYYY-MM-DD")}
                        onBlur={handleChange}
                        errors={errors?.bienPhapTrienKhaiList?.[index]?.ngayCapNhat}
                        touched={touched?.bienPhapTrienKhaiList?.[index]?.ngayCapNhat}
                        disabled={isFieldUpdate}
                    />
                );
            }
        },
        {
            name: `${intl.formatMessage({ id: "OPINION_SUGGEST" })}`,
            field: "yKienDeNghi",
            headerStyle: {
                minWidth: "120px"
            },
            render: (row: any, index: number) => {
                return (
                    <TextValidator
                        type="text"
                        isRequired
                        className="spaces min-h-54"
                        name={`bienPhapTrienKhaiList[${index}].yKienDeNghi`}
                        defaultValue={values?.bienPhapTrienKhaiList[index]?.yKienDeNghi}
                        onBlur={handleChange}
                        errors={errors?.bienPhapTrienKhaiList?.[index]?.yKienDeNghi}
                        touched={touched?.bienPhapTrienKhaiList?.[index]?.yKienDeNghi}
                        disabled={isFieldUpdate}
                    />
                );
            }
        },
        ...!isFieldUpdate ? [{
            name: "",
            field: "thaoTac",
            headerStyle: {
                minWidth: "60px"
            },
            render: (row: any, index: number) => {
                return (
                    <>
                        <div onClick={() => { handleDeleteRow(index) }}><i className="bi bi-x-lg fs-3 text-danger cursor-pointer"></i></div>
                    </>
                );
            }
        }] : [],
    ]
}

export const dsBenhNhanColumns = (intl: any) => [
    {
        name: "#",
        field: "",
        headerStyle: {
            minWidth: "60px"
        },
        render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
        name: `${intl.formatMessage({ id: "FULLNAME" })}`,
        field: "hoTen",
        headerStyle: {
            minWidth: "60px"
        },
    },
    {
        name: `${intl.formatMessage({ id: "DATE_OF_BIRTH" })}`,
        field: "ngaySinh",
        headerStyle: {
            minWidth: "60px"
        },
        render: (row: any, index: number, stt: number) => <span>{moment(row?.ngaySinh).format("DD/MM/YYYY")}</span>
    },
    {
        name: `${intl.formatMessage({ id: "GENDER" })}`,
        field: "gioiTinh",
        headerStyle: {
            minWidth: "60px"
        },
        render: (row: any, index: number, stt: number) => <span>{row?.gioiTinh ? "Nữ" : "Nam"}</span>
    },
    {
        name: `${intl.formatMessage({ id: "PHONE_NUMBER" })}`,
        field: "sdt",
        headerStyle: {
            minWidth: "60px"
        },
    },
    {
        name: `${intl.formatMessage({ id: "DATE_OF_DESEASE_ONSET" })}`,
        field: "ngayKhoiPhat",
        headerStyle: {
            minWidth: "60px"
        },
        render: (row: any, index: number, stt: number) => <span>{moment(row?.ngayKhoiPhat).format("DD/MM/YYYY")}</span>
    },
    {
        name: `${intl.formatMessage({ id: "DATE_IN_HOSPITAL_OR_EXAMINE" })}`,
        field: "ngayNhapVien",
        headerStyle: {
            minWidth: "60px"
        },
        render: (row: any, index: number, stt: number) => <span>{moment(row?.ngayNhapVien).format("DD/MM/YYYY")}</span>
    },
    {
        name: `${intl.formatMessage({ id: "RECORD_FACILITY" })}`,
        field: "tenCoSoGhiNhan",
        headerStyle: {
            minWidth: "60px"
        },
    },
]

export const columnTHB = (intl: any) => [
    {
        name: "#",
        field: "",
        headerStyle: {
            minWidth: "30px"
        },
        render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
        name: `${intl.formatMessage({ id: "CODE_NUMBER" })}`,
        field: "maSo",
        headerStyle: {
            minWidth: "100px"
        },
    },
    {
        name: `${intl.formatMessage({ id: "FULLNAME" })}`,
        field: "hoTen",
        headerStyle: {
            minWidth: "60px"
        },
    },
    {
        name: `${intl.formatMessage({ id: "DATE_OF_BIRTH" })}`,
        field: "ngaySinh",
        headerStyle: {
            minWidth: "60px"
        },
        render: (row: any, index: number, stt: number) => <span>{moment(row?.ngaySinh).format("DD/MM/YYYY")}</span>
    },
    {
        name: `${intl.formatMessage({ id: "GENDER" })}`,
        field: "gioiTinh",
        headerStyle: {
            minWidth: "60px"
        },
        render: (row: any, index: number, stt: number) => <span>{GENDER_OPT(intl).find((e) => e.code === row?.gioiTinh)?.name}</span>
    },
    {
        name: `${intl.formatMessage({ id: "PHONE_NUMBER" })}`,
        field: "sdt",
        headerStyle: {
            minWidth: "60px"
        },
    },

]

export const INITIAL_CONFIG_TABLE: IConfigTable = {
    totalElement: 0,
    totalPages: 0,
    numberOfElements: 0,
}

export const SEARCH_OBJECT_INIT: ISearchObjModel = {
    keyword: null,
    tinh: null,
    huyen: null,
    xa: null,
    ngayKhoiPhatTuNgay: null,
    ngayKhoiPhatDenNgay: null,
    ngayBaoCaoTuNgay:  moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
    ngayBaoCaoDenNgay: moment().format('YYYY-MM-DD'),
    ngayKetThucTuNgay: null,
    ngayKetThucDenNgay: null,
    trangThaiId: null,
    donViBaoCao: null,
    listBenhTruyenNhiemId: null,
    pageNumber: 1,
    pageSize: 10
};

export const TRANG_THAI = [
    {
        code: 0,
        name: "Đang hoạt động"
    },
    {
        code: 1,
        name: "Kết thúc"
    }
]



const INITIAL_O_DICH: ODich = {
    ngayKetThucODich: "",
    oDichId: null,
    tinhId: null,
    tinh: null,
    huyenId: null,
    huyen: null,
    xaId: null,
    xa: null,
    benhTruyenNhiemId: 37, // benh sxh (37)
    trangThai: null,
    tenODich: "",
    truongHopBenhId: null,
    xacDinhThbDauTien: null,
    yeuToDichTe: null,
    moTaTomTat: null,
    ngayNhanBaoCao: null,
    ngayKhoiPhatThbDauTien: null,
    ngayKhoiPhatThbCuoiCung: null,
    chumCaBenh: null,
    danhSachTruongHopBenh: null,
    soMacTong: null,
    soMacXn: null,
    soMacDuongTinh: null,
    soChetTong: null,
    soChetXn: null,
    soChetDuongTinh: null,
    nhanXet: null,
    hoatDongChinh: null,
    cacYeuToNguyCo: null,
    vatTuKinhPhi: null,
    thuanLoiKhoKhan: null,
    nhanXetVaBaiHoc: null,
    yKienDeNghi: null,
    ngayBatDauXuLy: null,
    ngayHoanThanhXuLy: null,
};

export const INITIAL_SO_CA_MAC: SoCaMac = {
    oDichSoCaMacId: null,
    tinhId: null,
    huyenId: null,
    xaId: null,
    tenDiaPhuong: null,
    soMac: 0,
    soChet: 0,
    ngayCapNhat: null
};

export const INITIAL_XET_NGHIEM: XetNghiem = {
    oDichXetNghiemId: null,
    tinhId: null,
    huyenId: null,
    xaId: null,
    tenDiaPhuong: null,
    soXn: 0,
    soDuongTinh: 0,
    ngayCapNhat: null
};

export const INITIAL_BIEN_PHAP_TRIEN_KHAI: BienPhapTrienKhai = {
    oDichBienPhapTrienKhaiId: null,
    hdPhongChongDichId: null,
    hdPhongChongDich: null,
    yKienDeNghi: null,
    ngayCapNhat: null
};

export const INITIAL_TIEN_SU_DICH_TE: TienSuDichTe = {
    thoiGianBatDau: null,
    thoiGianKetThuc: null,
    tinhId: null,
    huyenId: null,
    xaId: null,
    diaDiem: null,
    diaDiemNuocNgoai: null,
    doiTuongTiepXucId: null
};

export const INITIAL_THONG_TIN_O_DICH: IThongTinODich = {
    oDich: INITIAL_O_DICH,
    isCreateNewThb: true,
    truongHopBenhId: null,
    truongHopBenh: INITIAL_TRUONG_HOP_BENH,
    doiTuongMacBenh: INITIAL_DOI_TUONG_MAC_BENH,
    soCaMacList: [INITIAL_SO_CA_MAC],
    xetNghiemList: [INITIAL_XET_NGHIEM],
    bienPhapTrienKhaiList: [INITIAL_BIEN_PHAP_TRIEN_KHAI],
    tienSuDichTeList: [],
    dsTruongHopBenh: []
};
