import { useEffect, useRef, useState } from "react"
import { OCTKTSVG, OCTTable } from "@oceantech/oceantech-ui"
import { Button } from "react-bootstrap"
import { dsBenhNhanColumns, columnTHB } from "../constants/constants"
import { dataTHB, ICheckField, ISearchObjModel, IThongTinODich } from "../models/quanLyODichModels"
import CustomDialog from "../../component/dialogs/CustomDialog"
import { getListTruongHopBenh } from "../services/services"
import { localStorageItem } from "../../utils/LocalStorage"
import { KEY_LOCALSTORAGE } from "../../auth/core/_consts"
import { useFormikContext } from "formik"
import { toast } from "react-toastify"
import { TYPE } from "../../utils/Constant"
import { IResponseData } from "../../utils/models"
import TableCustom from "../../component/table/table-custom/TableCustom"
import { useIntl } from "react-intl"

const DanhSachBenhNhanBox = ({elementAbility}: ICheckField) => {
    const userData = localStorageItem.get(KEY_LOCALSTORAGE.USER_INFOMATION);
    const { values, handleChange, errors, setFieldError, setFieldTouched, touched, setFieldValue, setValues } = useFormikContext<IThongTinODich>()
    const [openAddDialogTHB, setOpenAddDialogTHB] = useState(false);
    const [dataTHB, setDataTHB] = useState<IResponseData>({} as IResponseData);
    const [dsTruongHopBenhDaLuu, setDsTruongBenhDaLuu] = useState<dataTHB[]>([])
    const [dsTruongHopBenhTotal, setDsTruongHopBenhTotal] = useState<dataTHB[]>([])
    const [dsTruongHopBenh, setDsTruongHopBenh] = useState<dataTHB[]>([]);
    const [params, setParams] = useState({ pageNumber: 1, pageSize: 10 })
    const intl = useIntl();

    const init = async () => {
        const { tinhId, huyenId, xaId } = userData;
        const objParams = { tinhId, huyenId, xaId, TuNgayKhoiPhat: values?.oDich?.ngayKhoiPhatThbDauTien }
        let response = await getListTruongHopBenh({ ...params, ...objParams }, 'truong-hop-benh/search/o-dich');
        setDataTHB(response?.data?.data)
    }

    useEffect(() => {
        init()
    }, [params])

    const getLstTruongHopBenh = async () => {
        let response = await getListTruongHopBenh({ thbIds: values?.oDich?.danhSachTruongHopBenh }, 'truong-hop-benh/thbs')
        setDsTruongBenhDaLuu(response?.data?.data)
    }

    useEffect(() => {
        if (!values?.oDich?.danhSachTruongHopBenh) return setDsTruongHopBenhTotal([]);
        getLstTruongHopBenh();
    }, [values?.oDich?.danhSachTruongHopBenh])

    useEffect(() => {
        setValues({
            ...values,
            oDich: { ...values.oDich, danhSachTruongHopBenh: dsTruongHopBenhTotal?.map(item => item?.truongHopBenhId).join(",") }
        })
    }, [dsTruongHopBenhTotal])

    useEffect(() => {
        setDsTruongHopBenhTotal(dsTruongHopBenhDaLuu);
        setDsTruongHopBenh(dsTruongHopBenhDaLuu)
    },[dsTruongHopBenhDaLuu])

    useEffect(() => {
        if(!dsTruongHopBenh?.length) return setDsTruongHopBenhTotal([]);
        setDsTruongHopBenhTotal(dsTruongHopBenh)
    }, [dsTruongHopBenh])

    const handlerOpenDialogAddTHB = () => {
        if (errors?.oDich?.ngayKhoiPhatThbDauTien) {
            setFieldError("oDich.ngayKhoiPhatThbDauTien", intl.formatMessage({ id: "IS_REQUIREMENT" }))
            setFieldTouched("oDich.ngayKhoiPhatThbDauTien", true)
            return toast.warning("Vui lòng nhập trường ngày khởi phát ổ dịch!")
        }
        setOpenAddDialogTHB(true)
    }

    const handleSelectTHB = (row: any[]) => setDsTruongHopBenh(row)

    const updatePageData = (obj: ISearchObjModel) => {
        setParams({ pageNumber: Number(obj.pageNumber), pageSize: Number(obj.pageSize) })
    }

    return (
        <div className="section-container">
            <div className="d-flex align-items-center justify-content-between">
                <div className="spaces mb-10 fs-18 fw-bold text-uppercase color-dark-red">
                    {intl.formatMessage({ id: "PATIENTS" })}
                </div>
                {
                    elementAbility?.update && <Button
                        className="button-primary"
                        onClick={() => handlerOpenDialogAddTHB()}
                    >
                        <OCTKTSVG path='/media/svg/icons/plus.svg' svgClassName='spaces h-14 w-14 color-white' />
                        {intl.formatMessage({ id: "ADD" })}
                    </Button>
                }
            </div>
            <div className="border-top spaces pt-10">
                <TableCustom
                    id="bien-phap-phong-chong"
                    data={dsTruongHopBenhTotal}
                    columns={dsBenhNhanColumns(intl)}
                    // searchObject={searchObject}
                    // setSearchObject={setSearchObject}
                    // type={TYPE.SINGLE}
                    // fixedColumnsCount={0}
                    notDelete={true}
                    notEdit={true}
                    noToolbar={true}
                    // totalPages={dsTruongHopBenhTotal.length}
                    totalElements={dsTruongHopBenhTotal.length}
                    // numberOfElements={numberOfElements}
                    // dataChecked={dataChecked}
                    // setDataChecked={setDataChecked}
                    unSelectedAll={true}
                    noPagination={true}
                    updatePageData={() =>{}}
                />
            </div>
            {openAddDialogTHB &&
                <CustomDialog
                    handleSubmit={() => setOpenAddDialogTHB(false)}
                    isShow={openAddDialogTHB} setIsShow={setOpenAddDialogTHB}
                    isForm={false}
                    initValue={{}}
                >
                    <div className="border-top spaces pt-10">
                        <TableCustom
                            uniquePrefix="truongHopBenhId"
                            id="dsTruongHopBenh"
                            data={ dataTHB?.data || []}
                            columns={columnTHB(intl)}
                            // searchObject={searchObject}
                            // setSearchObject={setSearchObject}
                            type={TYPE.MULTILINE}
                            dataChecked={dsTruongHopBenh}
                            setDataChecked={handleSelectTHB}
                            fixedColumnsCount={0}
                            notDelete={true}
                            notEdit={true}
                            noToolbar={true}
                            totalPages={dataTHB?.totalPages}
                            totalElements={dataTHB?.total}
                            numberOfElements={dataTHB?.numberOfElements}
                            // dataChecked={dataChecked}
                            // setDataChecked={setDataChecked}
                            unSelectedAll={true}
                            // noPagination={true}
                            updatePageData={updatePageData}
                        />
                    </div>
                </CustomDialog>
            }
        </div>
    )
}

export default DanhSachBenhNhanBox;
