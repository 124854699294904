import L, { LeafletEvent } from 'leaflet';
import { useEffect, useState } from 'react';
import { Popup } from 'react-leaflet';
import { useAppContext } from '../../../../AppContext';
import OCTMap from '../../../component/oct-map/OCTMap';
import { cmpNormalizeString } from '../../../utils/FunctionUtils';
import { useInitContext } from '../../../utils/InitContext';
import AreaBar from '../AreaBar';
import { CAP_USER, configMapByHinhThuc, NAME_COMMUNE, NAME_DISTRICT, NAME_PROVINCE } from '../config';
import useLoadGeoJson from '../../../../hook/useLoadGeoJson';

function BanDoDuBao(props:any) {

  const { userData } = useAppContext()
  const { data, address, setAddress } = useInitContext()
  const [centerMap, setCenterMap] = useState<any>([21.0285, 105.8542])
  const initConfig = configMapByHinhThuc[userData?.phanLoaiCoSo]
  const [configMap, setConfigMap] = useState(initConfig)
  const [geoJsonData, setGeoJsonData] = useState<any>([])
	const [ProvinceGeoJson, setProvinceJeoJson] = useState({})
  const [DistrictGeoJson, setDistrictJeoJson] = useState({})
	const [CommuneGeoJson, setCommuneJeoJson] = useState({})

  useLoadGeoJson(setProvinceJeoJson, setDistrictJeoJson, setCommuneJeoJson)

  const getGeoJsonData = (nameTinh?: string | null, nameHuyen?: string | null, nameXa?: string | null) => {
    const geoData = !nameTinh
      ? (ProvinceGeoJson as any).features
      : (nameHuyen || nameXa)
        ? (CommuneGeoJson as any).features
        : (DistrictGeoJson as any).features;


    return geoData.filter((item: any) => {
      const isMatchTinh = nameTinh ? cmpNormalizeString(item.properties[NAME_PROVINCE], nameTinh) : true;
      const isMatchHuyen = nameHuyen ? cmpNormalizeString(item.properties[NAME_DISTRICT], nameHuyen) : true;
      const isMatchXa = nameXa ? cmpNormalizeString(item.properties[NAME_COMMUNE], nameXa) : true;

      return (isMatchTinh && isMatchHuyen && isMatchXa)
    })
  }


  const highlightFeature = (e: LeafletEvent) => {
    const layer = e.target;
    const properties = layer.feature.properties;
    const fieldName = properties[configMap.fieldViewLabel];
    const tooltipContent = `<b>${fieldName}</b>`;
    layer.setStyle({
      weight: 2,
      fillOpacity: 0.6,
    });

    if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
      layer.bringToFront();
    }

    layer.bindTooltip(tooltipContent, { permanent: false, direction: "top" }).openTooltip();

    setTimeout(() => {
      layer.closeTooltip();
    }, 3000);
  };

  const resetHighlight = (e: LeafletEvent) => {
    const layer = e.target;
    layer.setStyle({
      weight: 1,
      color: '#058bb4',
      fillOpacity: 0.8,
    })
  };

  const setStyleGeoJson = (feature: any) => {
    return {
      fillColor: data.infoDiaPhuong?.filter((item: any) => cmpNormalizeString(item?.location?.name, feature.properties[configMap.fieldViewLabel]))?.[0]?.location?.color,
      fillOpacity: 0.8,
      weight: 1,
      opacity: 1,
      color: "#058bb4"
    }
  }

  const iconRender2 = (cluster: any) => {
    return {
      html: `<div class="data-point-default" style="width:30px; border: 1px solid #058bb4; height: 30px;z-index: 2; background:${cluster?.properties?.location?.color};">${cluster?.properties?.statics?.[0]?.values?.[0] || ""} </div>`
    }
  }

  const popupRender2 = (cluster: any) => {
    return <Popup>
      <div className='text-primary mb-1 text-uppercase'>
        {cluster?.properties?.location?.label + " - " + cluster?.properties?.location?.name}
      </div>
      <div className='d-flex flex-column align-items-start'>
        {cluster?.properties?.statics.map((itemStatic: any) => {
          return (<div>{itemStatic?.label}: <span className='text-danger'>{itemStatic?.values?.[0]}</span></div>)
        })}
      </div>
    </Popup>
  }


  const getAddress = (newAddress: any, name: string) => newAddress?.[name] || userData?.[name]

  const getAddressLevel = (address: any) => {
    if (getAddress(address, "tenXa")) return CAP_USER.XA;
    if (getAddress(address, "tenHuyen")) return CAP_USER.HUYEN;
    if (getAddress(address, "tenTinh")) return CAP_USER.TINH;
    return CAP_USER.TRUNG_UONG;
  };

  const handleClickFeature = (e: LeafletEvent) => {
    const { properties } = e.target.feature;
    const tinh = properties[NAME_PROVINCE];
    const huyen = properties[NAME_DISTRICT];
    const xa = properties[NAME_COMMUNE];
    if(huyen) return;// tam thoi chi click den cap tinh
    setAddress?.((prev: any) => {
      return {
        ...prev,
        tinhId: properties?.tinhId,
        huyenId: properties?.huyenId,
        xaId: properties?.xaId,
        tenTinh: tinh,
        tenHuyen: huyen,
        tenXa: xa
      }
    })
  };


  useEffect(() => {
    setConfigMap(configMapByHinhThuc[getAddressLevel(address)]);
    const lat = data?.inforTQGS?.location?.coordinates?.[1];
    const lng = data?.inforTQGS?.location?.coordinates?.[0];
    (lat && lng) && setCenterMap([lat, lng]);
  }, [data])

  useEffect(() => {
    const currGeo = getGeoJsonData(
      getAddress(address, "tenTinh"),
      getAddress(address, "tenHuyen"),
      getAddress(address, "tenXa"));
    setGeoJsonData(currGeo);
  }, [address])

  const dataPoints = [
    {
      points: data?.infoDiaPhuong?.map((item: any) => {
        return {
          ...item,
          position: item?.location?.coordinates,
          color: item?.color
        }
      }) || [],
      radiusCluster: 0,
      maxZoomCluster: 16,
      levelZoomToShow: 7,
      renderIconCluster: iconRender2,
      popupRender: popupRender2
    }
  ]
  return (
    <>
      <div className="spaces pb-6 text-uppercase">
        <AreaBar />
      </div>
      <OCTMap
        center={centerMap}
        height='calc(100vh - 280px)'
        width='100%'
        keyGeoJson={geoJsonData}
        keyMap={`${configMap?.zoomDefault}+${centerMap}+${props?.defaultActiveKey}`}
        dataPoints={dataPoints || []}
        tileLayerUrl='https://maps.vietmap.vn/api/dm/{z}/{x}/{y}@2x.png?apikey=0344e4a08f31899b9fc0ac0db97f4685e6169cd63532164b'
        geoJsonData={geoJsonData}
        zoom={configMap?.zoomDefault}
        handleMouseOverFeature={highlightFeature}
        setStyleGeoJson={setStyleGeoJson}
        handleMouseOutFeature={resetHighlight}
        handleClickFeature={handleClickFeature}
      />
    </>
  )
}

export default BanDoDuBao
