import clsx from "clsx";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useIntl } from "react-intl";
import TabMenu from "../../../component/tabs/TabMenu";
import { useInitContext } from "../../../utils/InitContext";
import { duBaoMap, KEY_TAB_2 } from "../../constant/constants";
import "../../dashboard.scss";
import ThongTinTongQuan from "../ThongTinTongQuan";

interface IProps {}
const DetailMapDuBao = (props: IProps) => {
  const [activeTab, setActiveTab] = useState(KEY_TAB_2.DU_BAO_DICH)
  const { data } = useInitContext()
  const { inforTQGS } = data
  const intl = useIntl()

  return (
    <div className="spaces d-flex flex-column ">
        <ThongTinTongQuan listData={inforTQGS?.statics} />
        <Row className="spaces d-flex flex-wrap  mt-4 map-container">
          <Col xl={6} className="px-0 flex-grow-1 postion-parent-tabs">
            <i id="icon-expand" className="bi bi-arrow-up-right position-absolute"></i>
            <TabMenu
              className="custom-position-tabs"
              danhsachTabs={duBaoMap(intl)}
              setCurrentTab={setActiveTab}
              defaultActiveKey={activeTab}
            />
          </Col>
          <Col xl={6} >
          </Col>
        </Row>
    </div>
  )
}

export default DetailMapDuBao;
