import { Form } from "react-bootstrap";
import * as Yup from 'yup';
import CustomChart from "../components/CustomChart";
import MapTest from "../../component/oct-map/MapTest";
import DSTruongHopBenh from "../components/thong-tin-giam-sat/DSTruongHopBenh";
import BanDoGamSat from "../components/thong-tin-giam-sat/BanDoGiamSat";
import BieuDoXuHuongDich from "../components/thong-tin-giam-sat/BieuDoXuHuongDich";
import BanDoDuBao from "../components/thong-tin-du-bao/BanDoDuBao";
import BanDoODich from "../components/Map/BanDoODich";

export const KEY_TAB = {
  PATIENTS_MAP: "0",
  OUTBREAKS_MAP: "1",
};
export const KEY_TAB_2 = {
  DU_BAO_DICH: "0",
  GIAM_SAT_DICH: "1"
};

export const KEY_TAB_CHART = {
  BIEU_DO: "0",
  DS_THB: "1",
};

export const tabDetailMap = (intl: any) => [
  {
      eventKey: KEY_TAB.PATIENTS_MAP,
      title: intl.formatMessage({ id: "PATIENT_MAP" }),
      component: <BanDoGamSat/>
  },
  {
      eventKey: KEY_TAB.OUTBREAKS_MAP,
      title: intl.formatMessage({ id: "OUTBREAK_MAP" }),
      component: <BanDoODich/>
  },
];

export const duBaoMap = (intl: any) => [
  {
      eventKey: KEY_TAB_2.DU_BAO_DICH,
      title: intl.formatMessage({ id: "Cấp huyện" }),
      component: <BanDoDuBao/>
  },
  {
      eventKey: KEY_TAB_2.GIAM_SAT_DICH,
      title: intl.formatMessage({ id: "Cấp xã" }),
      component: <BanDoDuBao/>
  },
];


export const tabDetailChart = (intl: any) => [
  {
    eventKey: KEY_TAB_CHART.BIEU_DO,
    title: intl.formatMessage({ id: "Biểu đồ ca mắc" }),
    component: <BieuDoXuHuongDich />
  },
  {
    eventKey: KEY_TAB_CHART.DS_THB,
    title: intl.formatMessage({ id: "PATIENTS" }),
    component: <DSTruongHopBenh />
  },
];
