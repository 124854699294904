import { useFormikContext } from "formik"
import { VIEW_COLUMN } from "../../utils/Constant"
import { IThongTinODich } from "../models/quanLyODichModels"
import ThongTinHanhChinhTab from './../../quan-ly-truong-hop-benh/danh-sach-truong-hop-benh/components/ThongTinHanhChinhTab'
import { useIntl } from "react-intl"

const ThongTinDoiTuongBox = () => {

    const { values} = useFormikContext<IThongTinODich>()
    const existTHB = Boolean(values?.doiTuongMacBenh?.doiTuongMacBenhId)
    const intl = useIntl()

    return (
        <div className="section-container">
            <div className="spaces mb-10 fs-18 fw-bold text-uppercase color-dark-red">
            {intl.formatMessage({ id: "TARGET_INFOMATION" })}
            </div>
            <div className="border-top">
                <ThongTinHanhChinhTab viewColumn={VIEW_COLUMN.FOUR_COLUMN} onlyView={existTHB} />
            </div>
        </div>
    )
}

export default ThongTinDoiTuongBox
