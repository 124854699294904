import axios from "axios";
import { paramsConfig } from "../../utils/ParamsUtils";
import { IInforTongQuanGS, ISearchObjModel } from "../model/model";
const API_PATH = process.env.REACT_APP_API_URL;
const API_PATH_D = process.env.REACT_APP_API_URL_D;

export const getDanhMucDiaPhuong = (searchObject: ISearchObjModel, url:string) => {
    let API_URL = API_PATH + url;
    return axios.get(API_URL, paramsConfig(searchObject));
};

export const addDanhMucDiaPhuong = (data: any, url: string) => {
    let API_URL = API_PATH + url;
    return axios.post(API_URL, data);
};

export const updateDanhMucDiaPhuong = (data: any, url: string) => {
    let API_URL = API_PATH + url;
    return axios.put(API_URL, data);
};

export const deleteDanhMucDiaPhuong = (url: string) => {
    let API_URL = API_PATH + url;
    return axios.delete(API_URL);
};

export const getInforDashboardGiamSat = (searchObject: IInforTongQuanGS, url:string) => {
  let API_URL = API_PATH + url;
  return axios.get(API_URL, paramsConfig(searchObject));
};

export const getInforDashboard = (searchObject: any, url: string) => {
  let API_URL = API_PATH_D + url;
  return axios.get(API_URL, paramsConfig(searchObject));
};
