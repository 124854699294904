import { useFormikContext } from "formik"
import { ICheckField, IThongTinODich } from "../models/quanLyODichModels"
import { OCTTextValidator } from "@oceantech/oceantech-ui"
import { useIntl } from "react-intl"

const MoTaTomTatBox = ({ isFieldUpdate }: ICheckField) => {

    const { values, handleChange } = useFormikContext<IThongTinODich>()
    const intl = useIntl()

    return (
        <div className="section-container">
            <div className="spaces mb-10 fs-18 fw-bold text-uppercase color-dark-red">
                {intl.formatMessage({ id: "SUMMARY_DESCRIPTION_OUTBREAK_HAS_FOUNDED" })}
            </div>
            <div className="border-top spaces pt-10">
                <OCTTextValidator
                    type="text"
                    value={values?.oDich?.moTaTomTat}
                    name={"oDich.moTaTomTat"}
                    onChange={handleChange}
                    as="textarea"
                    rows={2}
                    disabled={isFieldUpdate}
                />
            </div>
        </div>
    )
}

export default MoTaTomTatBox
