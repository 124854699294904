import { OCTTable } from "@oceantech/oceantech-ui";
import { ChangeEvent, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { useLoading } from "../../../../AppContext";
import { FILE_TYPE } from "../../../component/FileUpload/constant";
import { RESPONSE_STATUS_CODE, TYPE } from "../../../utils/Constant";
import { exportToFile, formatDataViewTHB } from "../../../utils/FunctionUtils";
import { truongHopBenhExcelColumns } from "../../tim-kiem-truong-hop-benh/constants/constants";
import { IDataResImport, IListDataImport, TruongHopBenh } from "../model/Model";
import {
    exportFileHanhChinh,
    exportFileMau,
    fileUpload,
    importValidTHB
} from "../servives/Services";
import NhapTruongHopBenhModal from "./NhapTruongHopBenhModal";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";

type Props = {};

const NhapTuExcel = (props: Props) => {
    const { setPageLoading } = useLoading();
    const [selectedFile, setSelectedFile] = useState<any>();
    const canUpload = useRef(true);
    const [dataTHB, setDataTHB] = useState<IListDataImport>({} as IListDataImport);
    const [openEditTHB, setOpenEditTHB] = useState<boolean>(false);
    const [dataForm, setDataForm] = useState<TruongHopBenh>({} as TruongHopBenh)
    const indexRef = useRef<any>(null);
    const navigate = useNavigate();
    const intl = useIntl()

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && Array.from(e.target.files).length > 0) {
            setSelectedFile(Array.from(e.target.files)[0]);
            setDataTHB({} as IListDataImport);
            canUpload.current = true
        }
    };

    const handleDownloadFile = (
        fileName: string,
        service: Function,
        params?: any
    ) => {
        exportToFile({
            exportAPI: () => (params ? service(params) : service()),
            fileName: fileName,
            type: TYPE.EXCEL,
            setPageLoading,
        });
    };

    const handleUploadFile = async () => {
        try {
            setPageLoading(true);
            const newFile = new File([selectedFile], selectedFile.name, {
                type: selectedFile.type,
            });
            const { data } = await fileUpload(newFile);
            if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
                canUpload.current = false
                toast.success("Tải lên thành công");
                setDataTHB(data.data);
            }
        } catch (error) {
            console.error(error);
            toast.error(error as string);
        } finally {
            setPageLoading(false);
        }
    };

    const handleImportTHB = async () => {
        try {
            setPageLoading(true);
            await importValidTHB(dataTHB.listThbHopLe.map((item: IDataResImport) => item.thb) as TruongHopBenh[]);
            setDataTHB({} as IListDataImport);
            toast.success("Nhập thành công");
        } catch (error) {
            console.error(error);
            toast.error(error as string);
        } finally {
            setPageLoading(false);
        }
    };

    const handleReChange = () => {
        setSelectedFile(null);
        setDataTHB({} as IListDataImport);
        canUpload.current = true
    };

    const handleInputClick = (event: any) => {
        event.target.value = null;
    };

    const handleOpenEditTHB = (thbData: IDataResImport) => {
        indexRef.current = thbData.index;
        setDataForm(thbData.thb);
        setOpenEditTHB(true);
    };

    const handleSaveEditTHB = (thb: TruongHopBenh) => {
        const data: IDataResImport = {
            thb: thb
        }
        setDataTHB(prev => ({
            ...prev,
            listThbHopLe: [...prev.listThbHopLe, data],
            listThbSaiDuLieu: prev.listThbSaiDuLieu.filter(item => item.index !== indexRef.current),
        }))
    }

    return (
        <div className="section-container spaces mt-15">
            <div className="spaces ml-16">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item" onClick={() => navigate(-1)}>
                            <a href="#" className="d-flex gap-1 align-items-center">
                                <i className="bi bi-arrow-90deg-left fs-30"/>
                                {intl.formatMessage({ id: "BACK" })}
                            </a>
                        </li>
                    </ol>
                </nav>
            </div>
            <div className="border-bottom">
                <div className="spaces p-16 fs-18 fw-bold text-uppercase color-dark-red ">
                    <i className="bi bi-stack color-dark-red fs-4 mr-1"></i>
                    {intl.formatMessage({ id: "ADD_PATIENT_EXCEL" })}
                </div>
            </div>
            <div className="d-flex align-items-center spaces p-16 spaces gap-8">
                <div className="rotate-90 ">
                    <i className="bi bi-hand-index "></i>
                </div>
                <div className="fw-bold spaces">{intl.formatMessage({ id: "SELECT_FILE_EXCEL" })}</div>
                <Button className="button-primary">
                    <input
                        type="file"
                        hidden
                        id="upload"
                        onChange={onChange}
                        onClick={handleInputClick}
                        accept={FILE_TYPE.XLSX + "," + FILE_TYPE.XLS}
                    />
                    <i className="bi bi-plus"></i>
                    <label htmlFor="upload">{intl.formatMessage({ id: "SELECT_FILE" })}</label>
                </Button>
                <Button className="button-primary" onClick={handleReChange}>
                    <i className="bi bi-recycle"></i>
                    {intl.formatMessage({ id: "DO_AGAIN" })}
                </Button>
            </div>
            {selectedFile && (
                <div className="alert alert-primary d-flex align-items-center justify-content-between spaces mx-16">
                    <div>
                        <i className="bi bi-file-earmark-excel spaces mr-4"></i>
                        {intl.formatMessage({ id: "FILE_SELECTED" })}
                    </div>
                    <div className="min-w-175px">
                        {" "}
                        {selectedFile?.name?.length < 30
                            ? selectedFile?.name
                            : selectedFile?.name?.slice(0, 25) + "..."}
                    </div>
                    <span className="ms-2 text-gray-600 min-w-90px">
                        Size:{" "}
                        {(
                            (selectedFile?.contentSize || selectedFile.size) /
                            1024
                        )?.toFixed(2)}{" "}
                        KB
                    </span>
                    <div className="d-flex align-items-center gap-2">
                        <Button
                            disabled={!canUpload.current}
                            onClick={handleUploadFile}
                            className="button-primary"
                        >
                            <i className="bi bi-upload"></i>{intl.formatMessage({ id: "UPLOAD" })}
                        </Button>
                        <Button
                            disabled={Boolean(!dataTHB?.listThbHopLe?.length)}
                            className="button-primary"
                            onClick={handleImportTHB}
                        >
                            <i className="bi bi-floppy"></i>{intl.formatMessage({ id: "SAVE_PATIENT_VALID" })}
                        </Button>
                    </div>
                </div>
            )}
            {dataTHB?.listThbHopLe?.length > 0 && (
                <div className="spaces px-20">
                    <div className="spaces pt-8 fs-18 fw-bold text-uppercase color-dark-red ">
                        {intl.formatMessage({ id: "PATIENTS_VALID" })}
                    </div>
                    <OCTTable
                        id="ds-hop-le"
                        data={dataTHB?.listThbHopLe}
                        columns={truongHopBenhExcelColumns({intl})}
                        justFilter={true}
                        notDelete={true}
                        notEdit={true}
                        noToolbar={true}
                        unSelectedAll={true}
                        noPagination
                    />
                </div>
            )}

            {dataTHB?.listThbSaiDuLieu?.length > 0 && (
                <div className="spaces px-20 pb-12">
                    <div className="d-flex justify-content-between align-items-center mt-2">
                        <div className="spaces pt-8 fs-18 fw-bold text-uppercase color-dark-red ">
                        {intl.formatMessage({ id: "PATIENTS_INVALID" })}
                        </div>
                    </div>
                    <OCTTable
                        id="ds-hop-khong-le"
                        data={dataTHB?.listThbSaiDuLieu}
                        columns={truongHopBenhExcelColumns({
                            handleOpenEditTHB,
                            ttLoi: true,
                            intl
                        })}
                        justFilter={true}
                        notDelete={true}
                        notEdit={true}
                        noToolbar={true}
                        unSelectedAll={true}
                        noPagination
                    />
                </div>
            )}

            {dataTHB?.listThbDaTonTai?.length > 0 && (
                <div className="spaces px-20">
                    <div className="spaces pt-8 fs-18 fw-bold text-uppercase color-dark-red ">
                        Danh sách trường hợp bệnh đã tồn tại trên hệ thống
                    </div>
                    <OCTTable
                        id="ds-hop-le"
                        data={dataTHB?.listThbDaTonTai}
                        columns={truongHopBenhExcelColumns({intl})}
                        justFilter={true}
                        notDelete={true}
                        notEdit={true}
                        noToolbar={true}
                        unSelectedAll={true}
                        noPagination
                    />
                </div>
            )}
            {dataTHB?.listThbTrungDuLieu?.length > 0 && (
                <div className="spaces px-20">
                    <div className="spaces pt-8 fs-18 fw-bold text-uppercase color-dark-red ">
                        Danh sách trường hợp bệnh trùng lặp trong Excel
                    </div>
                    <OCTTable
                        id="ds-hop-le"
                        data={dataTHB?.listThbTrungDuLieu}
                        columns={truongHopBenhExcelColumns({intl})}
                        justFilter={true}
                        notDelete={true}
                        notEdit={true}
                        noToolbar={true}
                        unSelectedAll={true}
                        noPagination
                    />
                </div>
            )}

            {
                openEditTHB && (
                    <NhapTruongHopBenhModal
                        handleClose={() => setOpenEditTHB(false)}
                        dataRow={formatDataViewTHB(dataForm)}
                        getValueForm={handleSaveEditTHB}

                    />
                )
            }

            <div className="spaces px-16">
                <p>
                    <i className="bi bi-chevron-right fs-9 text-dark"></i>{intl.formatMessage({ id: "ADVICE_EXCEL" })}
                </p>
                <p>
                    <i className="bi bi-chevron-right fs-9 text-dark"></i>{intl.formatMessage({ id: "NOTE_PATIENTS" })}
                </p>
            </div>
        </div>
    );
};

export default NhapTuExcel;
