import moment from 'moment';
import React, { forwardRef } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { vi } from 'date-fns/locale';
import clsx from 'clsx';

type Props = {
    startDate: string;
    endDate: string;
    handleChange: (dates: [string | null, string | null]) => void;
    dateFormat?: string;
    label?: string;
    required?: boolean;
    showMonthYearPicker?: boolean;
    showYearPicker?: boolean;
    showQuarterYearPicker?: boolean;
    inputFormat?: string
    outputFormat?: string
};

registerLocale('vi', vi);

const CustomInput = forwardRef((props: any, ref: any) => {
    const { value, onClick, className } = props
    return (<input
        value={value}
        onClick={onClick}
        readOnly
        ref={ref}
        className={className}
    />)
});

const DateRangePicker: React.FC<Props> = ({
    startDate: startDateString,
    endDate: endDateString,
    handleChange,
    dateFormat = "yyyy/QQQ",
    required = false,
    label,
    showMonthYearPicker = false,
    showYearPicker = false,
    showQuarterYearPicker = false,
    inputFormat = "YYYY-MM-DD",
    outputFormat = "YYYY-MM-DD"
}) => {
    const startDate = moment(startDateString, inputFormat);
    const endDate = moment(endDateString, inputFormat);

    const onChange = ([newStartDate, newEndDate]: [Date | null, Date | null]) => {
        handleChange([
            moment(newStartDate).format(outputFormat),
            moment(newEndDate).isValid() ? moment(newEndDate).endOf("month").format(outputFormat) : null
        ]);
    };
    const isValid = startDate.isValid() && endDate.isValid();

    return (
        <div className="d-flex flex-column">
            {label && (
                <span>
                    {label}
                    {required && <span className='text-danger'>*</span>}
                </span>
            )}
            <DatePicker
                selected={startDate.isValid() ? startDate.toDate() : undefined}
                onChange={onChange}
                selectsRange
                className={clsx('form-control customs-input', { 'is-invalid': !isValid })}
                startDate={startDate.isValid() ? startDate.toDate() : undefined}
                endDate={endDate.isValid() ? endDate.toDate() : undefined}
                dateFormat={dateFormat}
                showMonthYearPicker={showMonthYearPicker}
                showYearPicker={showYearPicker}
                showQuarterYearPicker={showQuarterYearPicker}
                customInput={<CustomInput />}
                locale="vi"
            />
            {!isValid && <span className='text-danger'>Chọn đầy đủ khoảng thời gian</span>}
        </div>
    );
};

export default DateRangePicker;
