import { Button} from "react-bootstrap"
import TableGrouping from "../component/table/table-grouping/TableGrouping"
import {  LOAI_THONG_KE, SEARCH_OBJECT_INIT, columnBaoCaoTuan, columnTotalBaoCao, initBaoCao } from "./constant/constants"
import { useEffect, useState } from "react"
import { useLoading } from "../../AppContext"
import { exportBaoCao, getDataBaoCao } from "./services/services"
import { IBaoCao, ISearchBaoCao } from "./model/model"
import { RESPONSE_STATUS_CODE } from "../utils/Constant"
import { toast } from "react-toastify"
import FilterSearchContainer from "./components/FilterSearchContainer"
import SearchAdvanceForm from "./components/SearchAdvanceForm"
import { localStorageItem } from "../utils/LocalStorage"
import { KEY_LOCALSTORAGE } from "../auth/core/_consts"
import ModalPhieuIn from "../component/ModalPhieuIn"
import PhieuInBaoCao from "./components/PhieuInBaoCao"
import { getListHuyenByTinhId, getListTinh, getListXaByHuyenId } from "../services"
import { formatDataBaoCao, getDayAndWeekByYear } from "./utils/functionUtils"
import { exportToExcel, exportToExcelV2 } from "../utils/FunctionUtils"
import { useIntl } from "react-intl"

const BaoCaoTuan = () => {
    const [baoCaoTuanList, setBaoCaoTuanList] = useState<any>([]);
    const [searchObject, setSearchObj] = useState<ISearchBaoCao>(SEARCH_OBJECT_INIT);
    const { setPageLoading } = useLoading();
    const userData = localStorageItem.get(KEY_LOCALSTORAGE.USER_INFOMATION)
    const [openModalPhieuIn,setOpenModalPhieuIn] = useState<boolean>(false)
    const [thongTinBaoCao,setThongTinBaoCao] = useState<IBaoCao>(initBaoCao)
    const intl = useIntl()

  useEffect(() => {
    checkAndSetSearchObject();
  }, []);

  const setSearchObject = () => {
    setSearchObj({
      ...searchObject,
      tinhIds: userData?.tinhInfo ? [userData?.tinhInfo] : [],
      huyenIds: userData?.huyenInfo ? [userData?.huyenInfo] : [],
      xaIds: userData?.xaInfo ? [userData?.xaInfo] : [],
    });
  };

  const checkAndSetSearchObject = () => {
    getDayAndWeekByYear(searchObject?.nam as number, setSearchObj, searchObject?.loaiBaoCao);
    if (userData?.tinhInfo && userData?.huyenInfo && userData?.xaInfo) {
      setSearchObject();
      return;
    }

    if (userData?.tinhInfo && userData?.huyenInfo) {
      getAllXa(userData.huyenInfo.id);
      return;
    }

    if (userData?.tinhInfo) {
      getAllHuyen(userData.tinhInfo.id);
      return;
    }

    getAllTinh();
  };

  const getAllXa = async (id: number) => {
    try {
      const { data } = await getListXaByHuyenId(id);
      setSearchObj((prevValues) => ({
        ...prevValues,
        tinhIds: userData?.tinhInfo ? [userData?.tinhInfo] : null,
        huyenIds: userData?.huyenInfo ? [userData?.huyenInfo] : null,
        xaIds: data?.data || [],
      }));
    } catch (error) {
      console.error(error);
    }
  };

  const getAllHuyen = async (id: number) => {
    try {
      const { data } = await getListHuyenByTinhId(id);
      setSearchObj((prevValues) => ({
        ...prevValues,
        tinhIds: userData?.tinhInfo ? [userData?.tinhInfo] : null,
        huyenIds: data?.data || [],
      }));
    } catch (error) {
      console.error(error);
    }
  };

  const getAllTinh = async () => {
    try {
      const { data } = await getListTinh()
      setSearchObj((prevValues) => ({
        ...prevValues,
        tinhIds: data?.data || [],
      }));
    } catch (error) {
      console.error(error)
    }
  }

    const updatePageData = async (searchData: ISearchBaoCao) => {
        try {
          if(!searchData?.tuNgay || !searchData?.denNgay) return toast.warning('Nhập đầy đủ thông tin')
          setPageLoading(true);
          const searchFormatData = formatDataBaoCao(searchData)
          const { data } = await getDataBaoCao(searchFormatData)
          if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
            setBaoCaoTuanList(data?.data?.listBaoCaoDiaPhuong || []);
            setThongTinBaoCao(data?.data)
            return;
          }
          toast.warning(data?.message);
        } catch (error) {
          toast.error(error as string);
        } finally {
          setPageLoading(false);
        }
    };

    useEffect(() => {
        if (searchObject?.tuan && searchObject?.tuNgay && searchObject?.denNgay) {
            updatePageData(searchObject);
        }
    }, [searchObject]);

  const [formParams, setFormParams] = useState<any>(searchObject);

  return (
    <div className="spaces mt-15 search-container">
      <div className="position-relative spaces z-index-4">
        <FilterSearchContainer
          searchObject={searchObject}
          handleSearch={updatePageData}
        >
          <SearchAdvanceForm
            setFormParams={setFormParams}
          />
          <ModalPhieuIn
            show={openModalPhieuIn}
            handleCloseDialog={() => setOpenModalPhieuIn(false)}
            size="lg"
          >
            <PhieuInBaoCao
              thongTinBaoCao={thongTinBaoCao}
            />
          </ModalPhieuIn>
        </FilterSearchContainer>
      </div>
        <div className="section-container spaces mt-15">
          <div className="d-flex align-items-center justify-content-between my-4">
            <div className="spaces fs-18 fw-bold text-uppercase color-dark-red">
              {intl.formatMessage({ id: "LIST_OF_REPORTS" })}
            </div>
          <div className="d-flex gap-1">
            <Button
              className="button-primary"
              onClick={() => exportToExcelV2(() => exportBaoCao(formatDataBaoCao(formParams)))}
            >
              {intl.formatMessage({ id: "EXCEL_EXPORT" })}
            </Button>
            <Button
              className="button-primary"
              onClick={() => setOpenModalPhieuIn(true)}
            >
              {intl.formatMessage({ id: "REPORT_PRINT" })}
            </Button>
          </div>
        </div>
          <TableGrouping
            id="reportDetail"
            columns={columnBaoCaoTuan(intl)}
            data={baoCaoTuanList}
            className="table-custom"
            columnsTotal={columnTotalBaoCao}
            showTotalRow
          />
        </div>


    </div>
  )
}

export default BaoCaoTuan
