import { OCTKTSVG, OCTTable } from "@oceantech/oceantech-ui";
import "./styles/danhSachThb.scss";
import { RESPONSE_STATUS_CODE, TYPE } from "../../utils/Constant";
import { danhSachThbColumns, getExportedFileList,TRANG_THAI_PHAN_HOI, INIT_TRUONG_HOP_BENH, HINH_THUC_CO_SO, TU_VONG_STATUS } from "./constants/constant";
import { Button } from "react-bootstrap";
import InputSearch from "../../component/input-field/InputSearch";
import ThongTinThb from "./components/ThongTinThb";
import NhapTruongHopBenhModal from './components/NhapTruongHopBenhModal';
import { useEffect, useRef, useState } from "react";
import TimKiemTHBNangCaoModal from "./components/TimKiemTHBNangCaoModal";
import { toast } from "react-toastify";
import ModalXacNhanTHB from "./components/ModalXacNhanTHB";
import { ISearchObjectModel } from "../models/TimKiemTruongHopBenhModels";
import { assingPatientToOutbreak, createOutbreakWithId, getOutbreaks, searchThbByPage, unAssignPatient } from "../tim-kiem-truong-hop-benh/services/TimKiemThbServices";
import { IDropdownButton, TruongHopBenh } from "./model/Model";
import { deleteTruongHopBenh, getThongTinTruongHopBenh } from "./servives/Services";
import { dsOBenhColumns, SEARCH_OBJECT_INIT } from "../tim-kiem-truong-hop-benh/constants/constants";
import ConfirmDialog from "../../component/confirm-dialog/ConfirmDialog";
import DropdownButton from "../../component/button/DropdownButton";
import { convertListSearchObject, formatDataViewTHB, hasAuthority } from "../../utils/FunctionUtils";
import { localStorageItem } from "../../utils/LocalStorage";
import { KEY_LOCALSTORAGE } from "../../auth/core/_consts";
import { handleChangePagination } from "../../utils/PageUtils";
import { PERMISSION_ABILITY, PERMISSIONS } from "../../../Constant";
import { getThongTinUser } from "../../accounts/services/serviceTest";
import { useLoading } from "../../../AppContext";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import CustomDialog from "../../component/dialogs/CustomDialog";
import moment from "moment";

const DanhSachTruongHopBenh = () => {
    const userData = localStorageItem.get(KEY_LOCALSTORAGE.USER_INFOMATION);
    const { setPageLoading } = useLoading();
    const [openTruongHopBenhForm, setOpenTruongHopBenhForm] = useState<boolean>(false);
    const [openDeleteTruongHopBenh, setOpenDeleteTruongHopBenh] = useState<boolean>(false);
    const [openSearchAdvanceDialog, setOpenSearchAdvanceDialog] = useState<boolean>(false);
    const [shouldOpenXacNhanThbDialog, setShouldOpenXacNhanThbDialog] = useState<boolean>(false);
    const [truongHopBenhList, setTruongHopBenhList] = useState<any>([]);
    const [searchObject, setSearchObject] = useState<ISearchObjectModel>({
        ...SEARCH_OBJECT_INIT,
        tinh: userData?.tinhInfo ? userData?.tinhInfo : null,
        huyen: userData?.huyenInfo ? userData?.huyenInfo : null,
        xa: userData?.xaInfo ? userData?.xaInfo : null
    });
    const [dataRow, setDataRow] = useState<TruongHopBenh>(INIT_TRUONG_HOP_BENH);
    const [dataForm, setDataForm] = useState<TruongHopBenh>(dataRow);
    const [configTable, setConfigTable] = useState<any>({});
    const [configTableOutbreaks, setConfigTableOutbreaks] = useState<any>({});
    const [objectSearchOutbreaks, setObjSearchOutbreaks] = useState<any>({
        // ngayBaoCaoTuNgay: moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
        // ngayBaoCaoDenNgay: moment().format('YYYY-MM-DD'),
        tinhId: userData?.tinhId || null,
        huyenId: userData?.huyenId || null,
        xaId: userData?.xaId || null,
        pageNumber: 1,
        pageSize: 10,
        trangThaiId: 0 // Ổ dịch trạng thái đang hoạt động
    })
    const [searchKeyword, setsSearchKeyword] = useState<string>("");
    const [exportedFileList, setExportedFileList] = useState<IDropdownButton[]>([]);
    const [openDetailTHB, setOpenDetailTHB] = useState(false);
    const [inforOutbreak, setInforOutbreak] = useState<any>(null);
    const [openDialogOutbreaks, setOpenDialogOutbreaks] = useState(false);
    const [outbreaks, setOutbreaks] = useState([]);
    const [newOutbreak, setNewOutbreak] = useState<any>([])
    const [idThb, setIdThb] = useState("");
    const elementAbility = {
        add: !!hasAuthority(PERMISSIONS.THB, PERMISSION_ABILITY.CREATE),
        import: !!hasAuthority(PERMISSIONS.THB, PERMISSION_ABILITY.CREATE),
        update: !!hasAuthority(PERMISSIONS.THB, PERMISSION_ABILITY.UPDATE),
        delete: !!hasAuthority(PERMISSIONS.THB, PERMISSION_ABILITY.DELETE),
        confirm: !!hasAuthority(PERMISSIONS.THB, PERMISSION_ABILITY.CONFIRM),
    }
    const navigate = useNavigate();
    const intl = useIntl()
    const searchKeywordOutbreakRef = useRef("")

    useEffect(() => {
        if (!openDialogOutbreaks) return;
        getListOutbreak(objectSearchOutbreaks)
    },[openDialogOutbreaks, objectSearchOutbreaks])

    useEffect(() => {
        if (openDialogOutbreaks) return;
        setNewOutbreak([])
    }, [openDialogOutbreaks])

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            setObjSearchOutbreaks({ ...objectSearchOutbreaks, tenODich: searchKeywordOutbreakRef.current })
        }
      };

    const getListOutbreak = async (obj: any) => {
        let res = await getOutbreaks(obj)
        setOutbreaks(res?.data?.data?.data)
        setConfigTableOutbreaks({
            totalPages: res?.data?.data?.totalPages,
            totalElements: res?.data?.data?.total,
            numberOfElements: res?.data?.data?.numberOfElements,
        })
    }

    const getTruongHopBenhList = async (selectFirstRow?: boolean) => {
        try {
            setPageLoading(true);
            let {
              listTinhTrangHienNay,
              tinh,
              huyen,
              xa,
              coSoDieuTri,
              donViThucHienXn,
              coSoGhiNhan,
              gioiTinh,
              ngheNghiep,
              kqXetNghiem,
              phanLoaiQuanLy,
              ...newSearchObject
            } = searchObject;
            const tinhTrangHienNay = convertListSearchObject(
              listTinhTrangHienNay as any[],
              "listTinhTrangHienNay"
            );

            const searchObjTemp: any = {
                ...newSearchObject,
                ...tinhTrangHienNay,
                gioiTinh,
                ngheNghiepId: ngheNghiep?.id,
                phanLoaiQuanLy: phanLoaiQuanLy?.code,
                tinhId: tinh?.id,
                huyenId: huyen?.id,
                xaId: xa?.xaId,
                coSoGhiNhanId: coSoGhiNhan?.id,
                kqXetNghiem,
                donViThucHienXn: donViThucHienXn?.id,
                coSoDieuTriId: coSoDieuTri?.id
            }
            const { data } = await searchThbByPage(searchObjTemp);
            setTruongHopBenhList(data?.data?.data);
            setConfigTable({
                totalPages: data?.data?.totalPages,
                totalElements: data?.data?.total,
                numberOfElements: data?.data?.numberOfElements,
            })
        } catch (error) {
            console.error(error);
            toast.error(error as string);
        } finally {
            setPageLoading(false);
        }
    }

    const handleDeleteTruongHopBenh = async () => {
        const id = dataRow?.truongHopBenh?.truongHopBenhId
        try {
            setPageLoading(true);
            id && await deleteTruongHopBenh(id);
            getTruongHopBenhList(true);
            setOpenDeleteTruongHopBenh(false);
            toast.success("Xóa thành công")
        } catch (error) {
            console.error(error);
            toast.error(error as string);
        } finally {
            setPageLoading(false);
        }
    }

    const getThongTinChiTietTHB = async (id: string) => {
        try {
            setPageLoading(true);
            const rest = await getThongTinTruongHopBenh(id);
            setDataRow(formatDataViewTHB(rest.data.data));
            if(rest?.data?.data?.oDich) {
                setInforOutbreak(rest?.data?.data?.oDich?.oDich)
            } else {
                setInforOutbreak(null)
            }
        } catch (error) {

        }
        finally {
            setPageLoading(false);
        }
    }

    const handleSelectTHB = (row: any[]) => {
        setIdThb(row[0]?.truongHopBenhId)
        getThongTinChiTietTHB(row[0]?.truongHopBenhId);
        setOpenDetailTHB(true)
    }

    const handleEditTHB = () => {
        setDataForm(dataRow)
        setOpenTruongHopBenhForm(true)
    }

    useEffect(() => {
        getTruongHopBenhList(true);
    }, [searchObject])

    useEffect(()=>{
        setExportedFileList(getExportedFileList(dataRow, setPageLoading, intl));
    }, [dataRow])

    const isChuaXacNhanOrXacNhanSaiThongTin = [
        TRANG_THAI_PHAN_HOI.CHUA_XAC_NHAN,
        TRANG_THAI_PHAN_HOI.XN_SAI_THONG_TIN_HANH_CHINH,
        TRANG_THAI_PHAN_HOI.XN_SAI_THONG_TIN_CHAN_DOAN
    ].includes(dataRow?.truongHopBenh?.trangThaiPhanHoi as number);
    const isTuyenHuyenOrTuyenXa = userData?.phanLoaiCoSo === HINH_THUC_CO_SO.TuyenHuyen
    || userData?.phanLoaiCoSo === HINH_THUC_CO_SO.TuyenXa;

    const handlerChangeOutbreak = async () => {
        if (!newOutbreak?.length) return toast.warning(intl.formatMessage({ id: "WARNING_SELECT_OUTBREAK" }))
        if (newOutbreak?.[0]?.oDichId === inforOutbreak?.oDichId) {
            setOpenDialogOutbreaks(false)
            return toast.success(intl.formatMessage({ id: "SUCCESS" }))
        }
        try {
            let res = await assingPatientToOutbreak({ idODich: newOutbreak?.[0]?.oDichId, idThb: idThb })
            if (res?.data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
                setOpenDialogOutbreaks(false)
                getThongTinChiTietTHB(idThb)
                return toast.success(intl.formatMessage({ id: "SUCCESS" }))
            }
        } catch (error) {
            console.error("error", error)
            toast.warning(intl.formatMessage({ id: "ERROR" }))
        }
    }

    const handlerCreateOutbreak = async () => {
        try {
            setPageLoading(true);
            let res = await createOutbreakWithId(idThb)
            if (res?.data?.code === RESPONSE_STATUS_CODE.CREATED) {
                getThongTinChiTietTHB(idThb)
                return toast.success(intl.formatMessage({ id: "SUCCESS" }))
            }
        } catch (error) {
            console.error("error", error)
            toast.warning(intl.formatMessage({ id: "ERROR" }))
        } finally {
            setPageLoading(false);
        }
    }

    const handlerUnassignPatient = async () => {
        try {
            setPageLoading(true);
            let res = await unAssignPatient(idThb)
            if (res?.data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
                getThongTinChiTietTHB(idThb)
                return toast.success(intl.formatMessage({ id: "SUCCESS" }))
            }
        } catch (error) {
            console.error("error", error)
            toast.warning(intl.formatMessage({ id: "ERROR" }))
        } finally {
            setPageLoading(false);
        }
    }

    return (
        <div className="page-container d-flex flex-wrap spaces  ">
            <div className="left-content-container d-flex flex-1 flex-column">
                <div className="">
                    <div className="ds-header d-flex flex-wrap justify-content-between align-items-center">
                        <div className="d-flex align-items-center mb-2 gap-2">
                            <i className="spaces fs-18 bi bi-people-fill text-primary"></i>
                            <span className="title color-dark-red spaces fs-16">
                                {intl.formatMessage({ id: "PATIENTS" })}
                            </span>
                        </div>
                        {
                            elementAbility?.add &&
                            <div className="d-flex flex-wrap flex-end gap-2">
                                <Button
                                    className="button-primary"
                                    onClick={() => navigate("/nhap-tu-excel")}
                                >
                                  <i className="bi bi-file-earmark-spreadsheet"></i>
                                        {intl.formatMessage({ id: "IMPORT_PATIENT" })}
                                </Button>
                                <Button
                                    className="button-primary"
                                    onClick={() => {
                                        setDataForm(INIT_TRUONG_HOP_BENH)
                                        setOpenTruongHopBenhForm(true)
                                    }}
                                >
                                    <i className="bi bi-person-plus-fill"></i>
                                    {intl.formatMessage({ id: "ADD" })}
                                </Button>
                            </div>
                        }
                    </div>
                    <div className="ds-search-box d-flex flex-end">
                        <div className="box-search">
                            <InputSearch
                                placeholder={intl.formatMessage({ id: "SEARCHBY_NAME_PATIENTNUMBER_AND_MORE" })}
                                handleChange={(e) => setsSearchKeyword(e.target.value)}
                                className="spaces h-32"
                                value={searchKeyword}
                                handleSearch={() => {
                                    setSearchObject({ ...searchObject, keyword: searchKeyword })
                                }}
                            />
                        </div>
                        <Button
                            className="button-primary"
                            onClick={() => setOpenSearchAdvanceDialog(true)}
                        >
                            <OCTKTSVG path='/media/svg/icons/search.svg' svgClassName='spaces h-14 w-14' />
                            {intl.formatMessage({ id: "ADVANCE_SEARCH" })}
                        </Button>
                    </div>
                </div>
                <div className="ds-thb">
                    <OCTTable
                        id="danh-sach-thb"
                        data={truongHopBenhList}
                        columns={danhSachThbColumns(intl)}
                        searchObject={searchObject}
                        setSearchObject={(table) => {
                            handleChangePagination(table, setSearchObject)
                        }}
                        type={TYPE.SINGLE}
                        justFilter={true}
                        fixedColumnsCount={1}
                        setDataChecked={handleSelectTHB}
                        notDelete={true}
                        notEdit={true}
                        noToolbar={true}
                        totalPages={configTable?.totalPages}
                        totalElements={configTable?.totalElements}
                        numberOfElements={configTable?.numberOfElements}
                        uniquePrefix="truongHopBenhId"
                        unSelectedAll={true}
                    />
                </div>
                <div className="spaces px-10">
                    {intl.formatMessage({ id: "NOTE" })}:
                    <div className="d-flex align-items-center spaces mt-4 line-height-16">
                        <OCTKTSVG path="/media/svg/icons/check-circle-fill.svg" svgClassName="spaces w-16 h-16 mr-10 color-bright-cyan"/>
                        <span>{intl.formatMessage({ id: "CONFIRMED" })}</span>
                    </div>
                    <div className="d-flex align-items-center spaces mt-4 line-height-16">
                        <OCTKTSVG path="/media/svg/icons/exclamation-triangle-fill.svg" svgClassName="spaces w-16 h-16 mr-10 color-dark-orange"/>
                        <span>{intl.formatMessage({ id: "CONFIRM_PENDING" })}</span>
                    </div>
                    <div className="d-flex align-items-center spaces mt-4 line-height-16">
                        <OCTKTSVG path="/media/svg/icons/exclamation-triangle-fill.svg" svgClassName="spaces w-16 h-16 mr-10 color-dark-red"/>
                        <span>{intl.formatMessage({ id: "DATE_OVER_CONFIRM_7" })}</span>
                    </div>
                    <div className="d-flex align-items-center spaces mt-4 line-height-16">
                        <OCTKTSVG path="/media/svg/icons/question-circle-fill.svg" svgClassName="spaces w-16 h-16 mr-10 color-steel-blue"/>
                        <span>{intl.formatMessage({ id: "ADMINISTRATIVE_INFORMATION_INVALID" })}</span>
                    </div>
                    <div className="d-flex align-items-center spaces mt-4 line-height-16">
                        <OCTKTSVG path="/media/svg/icons/question-circle-fill.svg" svgClassName="spaces w-16 h-16 mr-10 color-green"/>
                        <span>{intl.formatMessage({ id: "DIAGNOSIS_INFORMATION_INVALID" })}</span>
                    </div>
                    <div className="spaces mt-4">
                        <span className="color-dark-red fs-18"> {intl.formatMessage({ id: "RED_WORD" })} </span>
                        &nbsp; {intl.formatMessage({ id: "OUTDATE_REPORT_SUBMIT_OR_CLASSIFY" })}
                    </div>
                    <div className="spaces mt-4">
                        <i className="text-primary">{intl.formatMessage({ id: "ITALICS" })}</i> &nbsp; {intl.formatMessage({ id: "DRAF_SAVE" })}
                    </div>
                </div>
            </div>

            {openTruongHopBenhForm && (
                <NhapTruongHopBenhModal
                    handleClose={() => setOpenTruongHopBenhForm(false)}
                    dataRow={dataForm}
                    updatePageData={getTruongHopBenhList}
                />
            )}

            {openSearchAdvanceDialog && (
                <TimKiemTHBNangCaoModal
                    show={openSearchAdvanceDialog}
                    searchObject={searchObject}
                    setSearchObject={setSearchObject}
                    handleClose={() => setOpenSearchAdvanceDialog(false)}
                />
            )}

            {shouldOpenXacNhanThbDialog && (
                 <ModalXacNhanTHB
                    handleClose={() => setShouldOpenXacNhanThbDialog(false)}
                    dataRow={dataRow}
                    updatePageData={getTruongHopBenhList}
                 />
            )}
            {
                openDeleteTruongHopBenh && (
                    <ConfirmDialog
                        show={openDeleteTruongHopBenh}
                        onCloseClick={() => setOpenDeleteTruongHopBenh(false)}
                        onCancelClick={() => setOpenDeleteTruongHopBenh(false)}
                        onYesClick={handleDeleteTruongHopBenh}
                        title={intl.formatMessage({ id: "DELETE_PATIENT" })}
                        message={intl.formatMessage({ id: "DELETE_CONFIRM_PATIENT" })}
                        yes={intl.formatMessage({ id: "DELETE" })}
                        cancel={intl.formatMessage({ id: "CANCLE" })}
                    />

                )
            }

            {
                openDetailTHB &&
                <CustomDialog
                    handleSubmit={() => { }}
                    isShow={openDetailTHB}
                    setIsShow={setOpenDetailTHB}
                    initValue={{} as any}
                    validationSchema={{} as any}
                    isModalWrap={true}
                    isForm={false}
                    title={intl.formatMessage({ id: "PATIENT_INFO" })}
                >
                    <div className="right-content-container flex-1 spaces min-h-425">
                        <div className={`title-wrapper d-flex justify-content-${(isTuyenHuyenOrTuyenXa && dataRow?.truongHopBenh?.trangThaiPhanHoi === TRANG_THAI_PHAN_HOI.DA_XN_DUNG) ? 'between' : 'end'} align-items-center gap-2`}>
                                {
                                    isTuyenHuyenOrTuyenXa && dataRow?.truongHopBenh?.trangThaiPhanHoi === TRANG_THAI_PHAN_HOI.DA_XN_DUNG &&
                                    <div className="spaces d-flex ms-3">
                                        <div className="fw-bold spaces mt-5 mr-10">
                                            {inforOutbreak?.oDichId ?
                                                intl.formatMessage({ id: "OUTBREAK_NAME" }) + ": " + inforOutbreak?.oDichId :
                                                intl.formatMessage({ id: "CREATE_ASSIGN_OUTBREAK" }) + ": "
                                            }
                                        </div>
                                        {inforOutbreak ?
                                            <div className="d-flex gap-2 align-items-center btn-recycle">
                                                <span onClick={() => setOpenDialogOutbreaks(true)}>
                                                    <OCTKTSVG
                                                        path={"/media/svg/icons/recycle.svg"}
                                                        svgClassName="spaces w-16 h-16 color-bright-cyan"
                                                    />
                                                </span>
                                                <span onClick={handlerUnassignPatient}>
                                                    <OCTKTSVG
                                                        path={"/media/svg/icons/close.svg"}
                                                        svgClassName="spaces w-16 h-16 color-bright-cyan"
                                                    />
                                                </span>
                                            </div> :
                                            <div className="d-flex gap-2 align-items-center btn-recycle">
                                                <span onClick={() => handlerCreateOutbreak()}>
                                                    <OCTKTSVG
                                                        path={"/media/svg/icons/plus.svg"}
                                                        svgClassName="spaces w-16 h-16 color-bright-cyan"
                                                    />
                                                </span>
                                                
                                                <span onClick={() => setOpenDialogOutbreaks(true)}>
                                                    <OCTKTSVG
                                                        path={"/media/svg/icons/recycle.svg"}
                                                        svgClassName="spaces w-16 h-16 color-bright-cyan"
                                                    />
                                                </span>
                                            </div>
                                        }
                                    </div>
                                }

                            <div className="d-flex spaces gap-10">
                                {isTuyenHuyenOrTuyenXa && isChuaXacNhanOrXacNhanSaiThongTin && (
                                    <Button className={"button-primary"} onClick={() => setShouldOpenXacNhanThbDialog(true)}>
                                        {intl.formatMessage({ id: "CONFIRM" })}
                                    </Button>
                                )}
                                {
                                    dataRow?.truongHopBenh?.trangThaiPhanHoi !== TRANG_THAI_PHAN_HOI.DA_XN_DUNG && (
                                        <>
                                            {
                                                elementAbility?.update && (
                                                    <Button
                                                        className="button-primary"
                                                        onClick={() => {
                                                            setOpenDetailTHB(false)
                                                            setDataForm(dataRow)
                                                            setOpenTruongHopBenhForm(true)
                                                        }}
                                                    >
                                                        <OCTKTSVG path='/media/svg/icons/pencil-square.svg' svgClassName='spaces h-14 w-14 color-white' />
                                                        {intl.formatMessage({ id: "UPDATE" })}
                                                    </Button>
                                                )
                                            }
                                            {
                                                elementAbility?.delete && (
                                                    <Button
                                                        className="button-delete"
                                                        onClick={() => setOpenDeleteTruongHopBenh(true)}
                                                    >
                                                        <OCTKTSVG path='/media/svg/icons/trash.svg' svgClassName='spaces h-14 w-14 color-white' />
                                                        {intl.formatMessage({ id: "DELETE" })}
                                                    </Button>
                                                )
                                            }
                                        </>
                                    )
                                }
                                <DropdownButton
                                    title={intl.formatMessage({ id: "PATIENT_REPORT" })}
                                    dropdownItems={exportedFileList}
                                />
                            </div>
                        </div>
                        <div className="tt-tabs">
                            <ThongTinThb dataRow={dataRow} />
                        </div>
                    </div>
                </CustomDialog>
            }
            {
                openDialogOutbreaks &&
                <CustomDialog
                    handleSubmit={handlerChangeOutbreak}
                    isShow={openDialogOutbreaks}
                    setIsShow={setOpenDialogOutbreaks}
                    initValue={{} as any}
                    validationSchema={{} as any}
                    isForm={false}
                    isModalWrap={true}
                    hasButtonSave={true}
                    title={intl.formatMessage({ id: "ASSIGN_OUTBREAK" })}
                >
                    <div className="ds-thb">
                        <div className="ds-search-box d-flex flex-end">
                            <div className="box-search" onKeyDown={handleKeyDown}>
                                <InputSearch
                                    placeholder={intl.formatMessage({ id: "SEARCH_BY_NAME" })}
                                    handleChange={(e) => searchKeywordOutbreakRef.current = e.target.value}
                                    className="spaces h-32  w-250"
                                    handleSearch={() => {
                                        setObjSearchOutbreaks({ ...objectSearchOutbreaks, tenODich: searchKeywordOutbreakRef.current })
                                    }}
                                />
                            </div>
                        </div>
                        <OCTTable
                            id="danh-sach-thb33"
                            data={outbreaks}
                            columns={dsOBenhColumns(intl)}
                            searchObject={objectSearchOutbreaks}
                            setSearchObject={(table) => setObjSearchOutbreaks((prev: any) => ({ ...prev, pageIndex: table?.pageIndex, pageNumber: table?.pageIndex, pageSize: table?.pageSize ?? prev?.pageSize }))}
                            type={TYPE.SINGLE}
                            justFilter={true}
                            fixedColumnsCount={1}
                            setDataChecked={e => setNewOutbreak(e)}
                            notDelete={true}
                            notEdit={true}
                            noToolbar={true}
                            totalPages={configTableOutbreaks?.totalPages}
                            totalElements={configTableOutbreaks?.totalElements}
                            numberOfElements={configTableOutbreaks?.numberOfElements}
                            uniquePrefix="truongHopBenhId"
                            unSelectedAll={true}
                        />
                    </div>
                </CustomDialog>
            }
        </div>
    )
}

export default DanhSachTruongHopBenh;
