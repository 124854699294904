import { Form, Formik } from "formik";
import {useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { CURENT_STATUS, TYPE_TEST_CODE } from "../quan-ly-truong-hop-benh/danh-sach-truong-hop-benh/config/config";
import { CO_SU_DUNG_VAXIN, LAY_MAU_XN } from "../quan-ly-truong-hop-benh/danh-sach-truong-hop-benh/constants/constant";
import { formatDataViewTHB, hasAuthority } from "../utils/FunctionUtils";
import BienPhapPhongChongBox from "./components/BienPhapPhongChongBox";
import ChanDoanBox from "./components/ChanDoanBox";
import CoSoDieuTriBox from "./components/CoSoDieuTriBox";
import DanhSachBenhNhanBox from "./components/DanhSachBenhNhanBox";
import KetThucODichBox from "./components/KetThucODichBox";
import MoTaTomTatBox from "./components/MoTaTomTatBox";
import SoMacTuVongBox from "./components/SoMacTuVongBox";
import SoMauXetNghiemBox from "./components/SoMauXetNghiemBox";
import ThongTinDoiTuongBox from "./components/ThongTinDoiTuongBox";
import ThongTinODichBox from "./components/ThongTinODichBox";
import ThongTinVeTiemUongVacXin from "./components/ThongTinVeTiemUongVacXin";
import TienXuBenhNhanBox from "./components/TienXuBenhNhanBox";
import TienXuDichTeBox from "./components/TienXuDichTeBox";
import TinhTrangBox from "./components/TinhTrangBox";
import XaPhuongQuanLyBox from "./components/XaPhuongQuanLyBox";
import { INITIAL_THONG_TIN_O_DICH } from "./constants/constants";
import { IThongTinODich } from "./models/quanLyODichModels";
import { addNewOdich, deleteOdich, editODich, getThongTinODich, ketThucODich } from "./services/services";
import "./styles/quanLyODich.scss";
import { PERMISSION_ABILITY, PERMISSIONS } from "../../Constant";
import { useLoading } from "../../AppContext";
import { localStorageItem } from "../utils/LocalStorage";
import { KEY_LOCALSTORAGE } from "../auth/core/_consts";
import { useIntl } from "react-intl";

export const ThemMoiODich = () => {
    const { id } = useParams();
    const navigate = useNavigate()
    const { setPageLoading } = useLoading()
    const [ketThucOdich, setKetThucOdich] = useState(false);
    const [dataOdich, setDataODich] = useState(INITIAL_THONG_TIN_O_DICH)
    const elementAbility = {
        end: !!hasAuthority(PERMISSIONS.ODICH, PERMISSION_ABILITY.CREATE),
        update: !!hasAuthority(PERMISSIONS.ODICH, PERMISSION_ABILITY.UPDATE),
        delete: !!hasAuthority(PERMISSIONS.ODICH, PERMISSION_ABILITY.DELETE),
    }
    const dataUser = localStorageItem.get(KEY_LOCALSTORAGE.USER_INFOMATION)
    const intl = useIntl()

    const isFieldUpdate = Boolean(id) && !hasAuthority(PERMISSIONS.ODICH, PERMISSION_ABILITY.UPDATE)

    const OdichSchema = Yup.object().shape({
        oDich: Yup.object().shape({
            tinh: Yup.object().shape({
                id: Yup.string().nullable().required(intl.formatMessage({ id: "IS_REQUIREMENT" }))
            }),
            huyen: Yup.object().shape({
                id: Yup.string().nullable().required(intl.formatMessage({ id: "IS_REQUIREMENT" }))
            }),
            xa: Yup.object().shape({
                xaId: Yup.string().nullable().required(intl.formatMessage({ id: "IS_REQUIREMENT" }))
            }),
            trangThai: Yup.string().nullable().required(intl.formatMessage({ id: "IS_REQUIREMENT" })),
            tenODich: Yup.string().required(intl.formatMessage({ id: "IS_REQUIREMENT" })).nullable(),
            ngayKhoiPhatThbDauTien: Yup.string().required(intl.formatMessage({ id: "IS_REQUIREMENT" })).nullable(),
            ...ketThucOdich && {
                ngayNhanBaoCao: Yup.string().required(intl.formatMessage({ id: "IS_REQUIREMENT" })).nullable(),
                ngayKhoiPhatThbCuoiCung: Yup.string().required(intl.formatMessage({ id: "IS_REQUIREMENT" })).nullable(),
                ngayKetThucODich: Yup.string().required(intl.formatMessage({ id: "IS_REQUIREMENT" })).nullable(),
            }
        }),
        doiTuongMacBenh: Yup.object().shape({
            hoTen: Yup.string().required(intl.formatMessage({ id: "IS_REQUIREMENT" })).nullable(),
            ngaySinh: Yup.string().nullable().required(intl.formatMessage({ id: "IS_REQUIREMENT" })),
            danToc: Yup.object().nullable().required(intl.formatMessage({ id: "IS_REQUIREMENT" })),
            ngheNghiep: Yup.object().nullable().required(intl.formatMessage({ id: "IS_REQUIREMENT" })),
            tinhHienNay: Yup.object().nullable().required(intl.formatMessage({ id: "IS_REQUIREMENT" })),
            huyenHienNay: Yup.object().nullable().required(intl.formatMessage({ id: "IS_REQUIREMENT" })),
            xaHienNay: Yup.object().nullable().required(intl.formatMessage({ id: "IS_REQUIREMENT" })),
            cmnd: Yup.string().when("haveCmnd", {
                is: true,
                then: Yup.string().nullable().required(intl.formatMessage({ id: "IS_REQUIREMENT" })),
                otherwise: Yup.string().nullable().notRequired()
            }),
            dienThoai: Yup.string().when("haveDienThoai", {
                is: true,
                then: Yup.string().nullable().required(intl.formatMessage({ id: "IS_REQUIREMENT" })),
                otherwise: Yup.string().nullable().notRequired()
            }),
            diaChiHienNay: Yup.string().nullable().required(intl.formatMessage({ id: "IS_REQUIREMENT" })),
        }),
        truongHopBenh: Yup.object().shape({
            tinhTrangHienNay: Yup.string().required(intl.formatMessage({ id: "IS_REQUIREMENT" })).nullable(),
            ngayNhapVien: Yup.string().required(intl.formatMessage({ id: "IS_REQUIREMENT" })).nullable(),
            ngayKhoiPhat: Yup.string().required(intl.formatMessage({ id: "IS_REQUIREMENT" })).nullable(),
            phanLoaiChanDoan: Yup.string().required(intl.formatMessage({ id: "IS_REQUIREMENT" })).nullable(),
            ngayThucHienXn: Yup.string().when("layMauXetNghiem", {
                is: LAY_MAU_XN,
                then: Yup.string().nullable().required(intl.formatMessage({ id: "IS_REQUIREMENT" })),
                otherwise: Yup.string().nullable().notRequired()
            }),
            loaiXetNghiemKhac: Yup.string().when("loaiXetNghiem", {
                is: TYPE_TEST_CODE.LOAI_KHAC,
                then: Yup.string().nullable().required(intl.formatMessage({ id: "IS_REQUIREMENT" })),
                otherwise: Yup.string().nullable().notRequired()
            }),
            tinhTrangKhac: Yup.string().when("tinhTrangHienNay", {
                is: `${CURENT_STATUS.TINH_TRANG_KHAC}`,
                then: Yup.string().nullable().required(intl.formatMessage({ id: "IS_REQUIREMENT" })),
                otherwise: Yup.string().nullable().notRequired()
            }),
            benhVienChuyenToi: Yup.object().when("tinhTrangHienNay", {
                is: `${CURENT_STATUS.CHUYEN_VIEN}`,
                then: Yup.object().nullable().required(intl.formatMessage({ id: "IS_REQUIREMENT" })),
                otherwise: Yup.object().nullable().notRequired()
            }),
            chanDoanRaVien: Yup.string().when("tinhTrangHienNay", {
                is: `${CURENT_STATUS.RA_VIEN}`,
                then: Yup.string().nullable().required(intl.formatMessage({ id: "IS_REQUIREMENT" })),
                otherwise: Yup.string().nullable().notRequired()
            }),
            soLanSuDung: Yup.string().when("suDungVacXin", {
                is: CO_SU_DUNG_VAXIN,
                then: Yup.string().nullable().required(intl.formatMessage({ id: "IS_REQUIREMENT" })),
                otherwise: Yup.string().nullable().notRequired()
            }),
            coSoDieuTri: Yup.object().required(intl.formatMessage({ id: "IS_REQUIREMENT" })).nullable(),
            noiPhatHien: Yup.string().required(intl.formatMessage({ id: "IS_REQUIREMENT" })).nullable(),
        }),
        soCaMacList: Yup.array().of(
            Yup.object().shape({
                tenDiaPhuong: Yup.string().required(intl.formatMessage({ id: "IS_REQUIREMENT" })).nullable(),
                ngayCapNhat: Yup.string().required(intl.formatMessage({ id: "IS_REQUIREMENT" })).nullable(),
                soMac: Yup.string().required(intl.formatMessage({ id: "IS_REQUIREMENT" })).nullable(),
                soChet: Yup.string().required(intl.formatMessage({ id: "IS_REQUIREMENT" })).nullable(),
            })),
        xetNghiemList: Yup.array().of(
            Yup.object().shape({
                tenDiaPhuong: Yup.string().required(intl.formatMessage({ id: "IS_REQUIREMENT" })).nullable(),
                ngayCapNhat: Yup.string().required(intl.formatMessage({ id: "IS_REQUIREMENT" })).nullable(),
                soDuongTinh: Yup.string().required(intl.formatMessage({ id: "IS_REQUIREMENT" })).nullable(),
                soXn: Yup.string().required(intl.formatMessage({ id: "IS_REQUIREMENT" })).nullable(),
            })),
        bienPhapTrienKhaiList: Yup.array().of(
            Yup.object().shape({
                hdPhongChongDich: Yup.object().required(intl.formatMessage({ id: "IS_REQUIREMENT" })).nullable(),
                ngayCapNhat: Yup.string().required(intl.formatMessage({ id: "IS_REQUIREMENT" })).nullable(),
                yKienDeNghi: Yup.string().required(intl.formatMessage({ id: "IS_REQUIREMENT" })).nullable(),
            })),
    });

    const formatDataSend = (data: IThongTinODich): IThongTinODich => {
        for (let keyTHB in data) {
            if (data[keyTHB as keyof IThongTinODich]?.constructor !== Object) continue;
            const nestedObject = data[keyTHB as keyof IThongTinODich] as Record<string, any>;
            for (let key in nestedObject) {
                if (nestedObject[key] === "") {
                    nestedObject[key] = null;
                }
            }
        }

        const {
            coSoDieuTri,
            coSoQuanLy,
            capDoBenh,
            donViXetNghiem,
            benhVienChuyenToi,
            donViXetNghiemObject,
            ...thbValue
        } = data.truongHopBenh;
        const { tinh, huyen, xa, ...oDichValue } = data.oDich;
        const {
            tinhHienNay,
            xaHienNay,
            huyenHienNay,
            tinhIdThuongTru,
            xaThuongTru,
            huyenThuongTru,
            danToc,
            ...dtmbValue
        } = data.doiTuongMacBenh;

        return {
            ...data,
            oDich: {
                ...oDichValue,
                tinhId: data?.oDich?.tinh?.id,
                huyenId: data?.oDich?.huyen?.id,
                xaId: data?.oDich?.xa?.xaId,
                thonId: data?.oDich?.thon?.id,

            },
            truongHopBenhId: data?.truongHopBenh?.truongHopBenhId,
            truongHopBenh: {
                ...thbValue,
                capDoBenhId: data?.truongHopBenh?.capDoBenh?.id,
                benhVienChuyenToiId: data?.truongHopBenh?.benhVienChuyenToi?.id,
                donViXetNghiem: data?.truongHopBenh?.donViXetNghiemObject?.id,
                coSoQuanLyId: data?.truongHopBenh?.coSoQuanLy?.id,
                donViCongTacNbcId: data?.truongHopBenh?.donViCongTacNbc?.id,
                coSoDieuTriId: data?.truongHopBenh?.coSoDieuTri?.id,
                benhTruyenNhiemId: data?.oDich?.benhTruyenNhiemId,
            },
            doiTuongMacBenh: {
                ...dtmbValue,
                copyDiaChiHienTai: Number(data?.doiTuongMacBenh?.copyDiaChiHienTai),
                ngheNghiepId: data?.doiTuongMacBenh?.ngheNghiep?.id,
                danTocId: data?.doiTuongMacBenh?.danToc?.id,
                tinhIdHienNay: data?.doiTuongMacBenh?.tinhHienNay?.id,
                huyenIdHienNay: data?.doiTuongMacBenh?.huyenHienNay?.id,
                xaIdHienNay: data?.doiTuongMacBenh?.xaHienNay?.xaId,
                tinhIdThuongTru: data?.doiTuongMacBenh?.tinhThuongTru?.id,
                xaIdThuongTru: data?.doiTuongMacBenh?.xaThuongTru?.xaId,
                huyenIdThuongTru: data?.doiTuongMacBenh?.huyenThuongTru?.id,
            },
            bienPhapTrienKhaiList: data?.bienPhapTrienKhaiList.map(item => {
                return {
                    ngayCapNhat: item?.ngayCapNhat,
                    yKienDeNghi: item?.yKienDeNghi,
                    hdPhongChongDichId: item?.hdPhongChongDich?.id,
                    oDichBienPhapTrienKhaiId: item?.oDichBienPhapTrienKhaiId,
                }
            }),
            soCaMacList: data?.soCaMacList.map(item => {
                return {
                    ...item,
                    tinhId: data?.oDich?.tinh?.id,
                    huyenId: data?.oDich?.huyen?.id,
                    xaId: data?.oDich?.xa?.xaId
                }
            }),
            xetNghiemList: data?.xetNghiemList.map(item => {
                return {
                    ...item,
                    tinhId: data?.oDich?.tinh?.id,
                    huyenId: data?.oDich?.huyen?.id,
                    xaId: data?.oDich?.xa?.xaId
                }
            })
        }
    }

    const formatDataView = (data: IThongTinODich) => {
        return {
            ...data,
            oDich: {
                ...data.oDich,
                tinh: {
                    id: data?.oDich?.tinhId,
                    tenTinh: data?.oDich?.tinhTen
                },
                huyen: {
                    id: data?.oDich?.huyenId,
                    tenHuyen: data?.oDich?.huyenTen
                },
                xa: {
                    xaId: data?.oDich?.xaId,
                    tenXa: data?.oDich?.xaTen
                },
                thon: {
                    id: data?.oDich?.thonId,
                    tenThon: data?.oDich?.thonTen
                },
            },

            ...formatDataViewTHB(data),
            bienPhapTrienKhaiList: data?.bienPhapTrienKhaiList.map((item) => {

                return {
                    ...item,
                    hdPhongChongDich: {
                        id: item.hdPhongChongDichId,
                        tenHoatDong: item.hdPhongChongDichTen
                    }
                }
            })
        }
    }

    const handleSubmit = async (values: IThongTinODich) => {
        try {
            setPageLoading(true);
            if (ketThucOdich && id) {
                await ketThucODich(id, formatDataSend(values).oDich);
                toast.success(intl.formatMessage({ id: "SUCCESS" }));
                return;
            }

            if (id) {
                await editODich(id, formatDataSend(values));
                toast.success(intl.formatMessage({ id: "UPDATE_SUCCESS" }));
            } else {
                await addNewOdich(formatDataSend(values));
                toast.success(intl.formatMessage({ id: "ADD_SUCCESS" }));
            }

            navigate(-1)
        }
        catch (error) {
            console.error(error);
        }
        finally {
            setPageLoading(false);
        }
    }

    const handleDeleteODich = async () => {
        try {
            setPageLoading(true);
            if (id) {
                await deleteOdich(id);
                toast.success(intl.formatMessage({ id: "DELETE_SUCCESS" }));
                navigate(-1)
            }
        }
        catch (error) {
            console.error(error);
        }
        finally {
            setPageLoading(false);
        }
    }

    const getThongTinOdich = async () => {
        if (!id) return setDataODich({
            ...INITIAL_THONG_TIN_O_DICH,
            oDich: {
                ...INITIAL_THONG_TIN_O_DICH.oDich,
                tinh: {
                    id: dataUser?.tinhId,
                    tenTinh: dataUser?.tenTinh
                },
                huyen: dataUser?.huyenId ? {
                    id: dataUser?.huyenId,
                    tenHuyen: dataUser?.tenHuyen
                } : null,
                xa: dataUser?.xaId ? {
                    id: dataUser?.xaId,
                    tenXa: dataUser?.tenXa
                } : null
            }
        })

        try {
            setPageLoading(true);
            const { data } = await getThongTinODich(id);
            setDataODich(formatDataView(data?.data));
        } catch (error) {
            console.error(error);
        } finally {
            setPageLoading(false);
        }
    }

    useEffect(() => {
        getThongTinOdich()
    }, [id])

    return (
        <Formik
            initialValues={dataOdich}
            onSubmit={handleSubmit}
            validationSchema={OdichSchema}
            enableReinitialize
        >
            {formikProps => (
                <Form>
                    <div className="spaces my-16 them-moi-o-dich-container">
                        <ThongTinODichBox isFieldUpdate={isFieldUpdate} />
                        <Row className="spaces mt-20">
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <ThongTinDoiTuongBox />
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <TienXuBenhNhanBox />
                            </Col>
                        </Row>
                        <Row className="spaces mt-20">
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <TienXuDichTeBox />
                            </Col>
                        </Row>
                        <Row className="spaces mt-20">
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <ThongTinVeTiemUongVacXin />
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <TinhTrangBox />
                            </Col>
                        </Row>
                        <Row className="spaces mt-20">
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <ChanDoanBox />
                            </Col>
                        </Row>
                        <Row className="spaces mt-20">
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <CoSoDieuTriBox />
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <XaPhuongQuanLyBox />
                            </Col>
                        </Row>
                        <Row className="spaces mt-20">
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <SoMacTuVongBox
                                    elementAbility={elementAbility}
                                    isFieldUpdate={isFieldUpdate}
                                />
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <SoMauXetNghiemBox
                                    elementAbility={elementAbility}
                                    isFieldUpdate={isFieldUpdate}
                                />
                            </Col>
                        </Row>
                        <Row className="spaces mt-20">
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <MoTaTomTatBox isFieldUpdate={isFieldUpdate} />
                            </Col>
                        </Row>
                        <Row className="spaces mt-20">
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <BienPhapPhongChongBox
                                    elementAbility={elementAbility}
                                    isFieldUpdate={isFieldUpdate}
                                />
                            </Col>
                        </Row>
                        <Row className="spaces mt-20">
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <DanhSachBenhNhanBox
                                    elementAbility={elementAbility}
                                />
                            </Col>
                        </Row>
                        <Row className="spaces mt-20">
                            <Col xs={12} sm={12} md={12} lg={12}>
                                {id && <KetThucODichBox
                                    isFieldUpdate={isFieldUpdate}
                                />}
                            </Col>
                        </Row>
                        <div className="button-group-odich">
                            {
                                elementAbility?.update && <Button
                                    className="button-primary"
                                    type="submit"
                                    onClick={() => setKetThucOdich(false)}
                                >
                                    {intl.formatMessage({ id: "SAVE" })}
                                </Button>
                            }
                            {id &&
                                <>
                                    {
                                        elementAbility?.end && <Button
                                            className="button-primary"
                                            type="submit"
                                            onClick={() => setKetThucOdich(true)}
                                        >
                                            {intl.formatMessage({ id: "OVER_CONFIRM" })}
                                        </Button>
                                    }
                                    {
                                        elementAbility?.delete && <Button
                                            className="button-primary"
                                            type="submit"
                                            onClick={handleDeleteODich}
                                        >
                                            {intl.formatMessage({ id: "DELETE" })}
                                        </Button>
                                    }
                                </>
                            }
                            {/* <Button
                                className="button-primary"
                                onClick={() => { }}
                            >
                                {intl.formatMessage({ id: "REPORT_EXPORT" })}
                            </Button> */}
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

export default ThemMoiODich
