import { Col, Row } from "react-bootstrap"
import DetailAddress from "../DetailAddress";
import DetailMapGiamSat from "./DetailMapGiamSat";
import DanhSachDiaPhươngGS from "./DanhSachDiaPhuongGS";

interface IProps {
}

const DashboardDetail = (props: IProps) => {
  return (
    <Row className="w-100 ms-0" >
      <Col xl={2} md={4} className="detail-address-box">
        <DetailAddress ChildComponent={DanhSachDiaPhươngGS} />
      </Col>
      <Col xl={10} md={8}>
        <DetailMapGiamSat />
      </Col>
    </Row>
  )
}
export default DashboardDetail;
