import moment from "moment";
import { getListNgayTrongTuan, getListTuanByNam } from "../../services";
import { ISearchBaoCao } from "../model/model";
import { ngayCuoiThang, ngayDauThang } from "../constant/constants";

export const getDayAndWeekByYear = async (year: number, setValues: Function, typeBaoCao?: number | null) => {
    try {
        const { data } = await getListTuanByNam({ nam: year });
        if (data?.length) {
            const { data: rangeDate }: any = await getListNgayTrongTuan({ nam: year, tuan: data[data?.length - 1]?.value });
            setValues((prevValues: ISearchBaoCao) => ({
                ...prevValues,
                loaiBaoCao: typeBaoCao,
                nam: year,
                tuan: data[data?.length - 1],
                thang: null,
                listTuan: data,
                tuNgay: rangeDate.tungay && moment(rangeDate.tungay, "DD-MM-YYYY").format("YYYY-MM-DD"),
                denNgay: rangeDate.denngay && moment(rangeDate.denngay, "DD-MM-YYYY").format("YYYY-MM-DD"),

            }));
        }
    } catch (error) {
        console.error(error)
    }
}

export const getDayAndWeekByThang = async (nam: number, thang: number, setValues: Function, typeBaoCao?: number | null) => {
    try {
        setValues((prevValues: ISearchBaoCao) => ({
          ...prevValues,
          nam: nam,
          thang: thang,
          tuan: null,
          loaiBaoCao: typeBaoCao,
          tuNgay: ngayDauThang(nam, thang),
          denNgay:ngayCuoiThang(nam, thang)
        }));
    } catch (error) {
        console.error(error)
    }
}

export const formatDataBaoCao = (data: ISearchBaoCao) => ({
    capDoRoot: data?.capDoRoot,
    loaiBaoCao: data?.loaiBaoCao,
    tuNgay: data?.tuNgay,
    denNgay: data?.denNgay,
    tinhIds: data?.tinhIds === null ? [] : data?.tinhIds?.map((item) => item.id) || null,
    huyenIds: data?.huyenIds === null ? [] : data?.huyenIds?.map((item) => item.id) || null,
    xaIds: data?.xaIds === null ? [] : data?.xaIds?.map((item) => item.xaId),
    hinhThucDieuTri: data?.hinhThucDieuTri,
    dieuTriChanDoan: data?.dieuTriChanDoan,
})
