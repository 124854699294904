import { Form } from "react-bootstrap";
import * as Yup from 'yup';
import DanhMucHuyen from "../components/Tabs/DanhMucHuyen";
import DanhMucTinh from "../components/Tabs/DanhMucTinh";
import DanhMucXa from "../components/Tabs/DanhMucXa";
import { IDanhMucDiaPhuong, IDanhMucUser } from "../model/model";
import { HINH_THUC_CO_SO } from "../../quan-ly-truong-hop-benh/danh-sach-truong-hop-benh/constants/constant";
import DanhMucThon from "../components/Tabs/DanhMucThon";
import { getPagedHuyen, getPagedThon, getPagedTinh, getPagedXa } from "../../services";
import { PERMISSION_ABILITY, PERMISSIONS } from "../../../Constant";

export const KEY_TAB = {
    TINH: "0",
    HUYEN: "1",
    XA: "2",
    THON: "3"
};
export const tabDanhMucDiaPhuong = (intl: any) => [
    {
        eventKey: KEY_TAB.TINH,
        title: intl.formatMessage({ id: "PROVINCE_CATEGORY" }),
        component: <DanhMucTinh />,
        permission: PERMISSIONS.DM_TINH,
        ability: PERMISSION_ABILITY.VIEW,
    },
    {
        eventKey: KEY_TAB.HUYEN,
        title: intl.formatMessage({ id: "DISTRICT_CATEGORY" }),
        component: <DanhMucHuyen />,
        permission: PERMISSIONS.DM_HUYEN,
        ability: PERMISSION_ABILITY.VIEW,
    },
    {
        eventKey: KEY_TAB.XA,
        title: intl.formatMessage({ id: "COMMUNE_CATEGORY" }),
        component: <DanhMucXa />,
        permission: PERMISSIONS.DM_XA,
        ability: PERMISSION_ABILITY.VIEW,
    },
    {
        eventKey: KEY_TAB.THON,
        title: intl.formatMessage({ id: "VILLAGE_CATEGORY" }),
        component: <DanhMucThon />,
        permission: PERMISSIONS.DM_THON,
        ability: PERMISSION_ABILITY.VIEW,
    },
];

export const arrDistrict = ({ handleOpenForm, handleActiveUser }: any) => [
    {
        name: "STT",
        field: "",
        render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
        name: "Thao tác",
        field: "thaoTac",
        cellStyle: {
            minWidth: "50px",
            textAlign: "center",
        },
        headerStyle: {
            minWidth: "50px",
        },
        render: (rowData: any, index: number) => {
            return (
                <i onClick={() => handleOpenForm(rowData)}
                    className="spaces cursor-pointer mr-10 fs-17 text-primary bi bi-pencil-square fa-lg" />
            )
        }
    },
    {
        name: "Tên Tỉnh",
        field: "tenTinh",
        cellStyle: {
            minWidth: "500px",
            textAlign: "left",
        },
        headerStyle: {
            minWidth: "500px",
        }
    },
    {
        name: "Tên Huyện",
        field: "tenHuyen",
        cellStyle: {
            minWidth: "500px",
            textAlign: "left",
        },
        headerStyle: {
            minWidth: "500px",
        }
    },
    {
        name: "Mã Huyện",
        field: "maHuyen",
        cellStyle: {
            minWidth: "250px",
            textAlign: "left",
        },
        headerStyle: {
            minWidth: "250px",
        }
    },
    {
        name: "Hoạt động",
        field: "isActive",
        cellStyle: {
            minWidth: "250px",
            textAlign: "left",
        },
        headerStyle: {
            minWidth: "250px",
        },
        render: (rowData: any, index: number) => {
            return <div className="d-flex justify-content-center">
                {
                    <Form.Check
                        type="switch"
                        onChange={() => handleActiveUser(rowData?.id, rowData)}
                        checked={!rowData?.isActive}
                        id="custom-switch"
                        label=""
                    />
                }
            </div>
        }
    },
]

export const arrProvince = ({ handleOpenForm, handleActiveDiaPhuong, intl }: any) => [
    {
        name: `${intl.formatMessage({ id: "ORDER" })}`,
        field: "",
        render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
        name: `${intl.formatMessage({ id: "ACTIONS" })}`,
        field: "thaoTac",
        cellStyle: {
            minWidth: "50px",
            textAlign: "center",
        },
        headerStyle: {
            minWidth: "50px",
        },
        render: (rowData: any, index: number) => {
            return (
                <i
                    onClick={() => handleOpenForm(rowData)}
                    className="spaces cursor-pointer mx-5 fs-17 text-primary bi bi-pencil-square fa-lg" />
            )
        }
    },
    {
        name: `${intl.formatMessage({ id: "NAME_PROVINCE" })}`,
        field: "tenTinh",
        cellStyle: {
            minWidth: "500px",
            textAlign: "left",
        },
        headerStyle: {
            minWidth: "500px",
        }
    },
    {
        name: `${intl.formatMessage({ id: "CODE_PROVINCE" })}`,
        field: "maTinh",
        cellStyle: {
            minWidth: "250px",
            textAlign: "left",
        },
        headerStyle: {
            minWidth: "250px",
        }
    },
    {
        name: `${intl.formatMessage({ id: "STATUS" })}`,
        field: "isActive",
        cellStyle: {
            minWidth: "250px",
        },
        headerStyle: {
            minWidth: "250px",
        },
        render: (rowData: any, index: number) => {
            return <div className="d-flex justify-content-center">
                <Form.Check
                    type="switch"
                    onChange={() => handleActiveDiaPhuong(rowData?.id, rowData)}
                    checked={!rowData?.isActive}
                    id="custom-switch"
                    label=""
                />
            </div>
        }
    },
]

export const arrCommune = ({ handleOpenForm, handleActiveDiaPhuong, intl }: any) => [
    {
        name: `${intl.formatMessage({ id: "ORDER" })}`,
        field: "",
        render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
        name: `${intl.formatMessage({ id: "ACTIONS" })}`,
        field: "thaoTac",
        cellStyle: {
            minWidth: "50px",
            textAlign: "center",
        },
        headerStyle: {
            minWidth: "50px",
        },
        render: (rowData: any, index: number) => {
            return (
                <>
                    {!rowData?.isActive && <i
                        onClick={() => handleOpenForm(rowData)}
                        className="spaces cursor-pointer fs-17 text-primary bi bi-pencil-square fa-lg" />}
                </>
            )
        }
    },
    {
        name: `${intl.formatMessage({ id: "NAME_PROVINCE" })}`,
        field: "tenTinh",
        cellStyle: {
            minWidth: "250px",
            textAlign: "left",
        },
        headerStyle: {
            minWidth: "250px",
        }
    },
    {
        name: `${intl.formatMessage({ id: "NAME_DISTRICT" })}`,
        field: "tenHuyen",
        cellStyle: {
            minWidth: "250px",
            textAlign: "left",
        },
        headerStyle: {
            minWidth: "250px",
        }
    },
    {
        name: `${intl.formatMessage({ id: "NAME_COMMUNE" })}`,
        field: "tenXa",
        cellStyle: {
            minWidth: "250px",
            textAlign: "left",
        },
        headerStyle: {
            minWidth: "250px",
        }
    },
    {
        name: `${intl.formatMessage({ id: "CODE_COMMUNE" })}`,
        field: "maXa",
        cellStyle: {
            minWidth: "250px",
            textAlign: "left",
        },
        headerStyle: {
            minWidth: "250px",
        }
    },
    {
        name: `${intl.formatMessage({ id: "STATUS" })}`,
        field: "isActive",
        cellStyle: {
            minWidth: "250px",
            textAlign: "left",
        },
        headerStyle: {
            minWidth: "250px",
        },
        render: (rowData: any, index: number) => {
            return <div className="d-flex justify-content-center">
                {
                    <Form.Check
                        type="switch"
                        onChange={() => handleActiveDiaPhuong(rowData?.id, rowData)}
                        checked={!rowData?.isActive}
                        id="custom-switch"
                        label=""
                    />
                }
            </div>
        }
    },
]

export const arrThon = ({ handleOpenForm, handleActiveDiaPhuong, intl }: any) => [
    {
        name: intl.formatMessage({ id: "ORDER" }),
        field: "",
        render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
        name: intl.formatMessage({ id: "ACTIONS" }),
        field: "thaoTac",
        cellStyle: {
            minWidth: "50px",
            textAlign: "center",
        },
        headerStyle: {
            minWidth: "50px",
        },
        render: (rowData: any, index: number) => {
            return (
                <>
                    {!rowData?.isActive && <i
                        onClick={() => handleOpenForm(rowData)}
                        className="spaces cursor-pointer fs-17 text-primary bi bi-pencil-square fa-lg" />}
                </>
            )
        }
    },
    {
        name: intl.formatMessage({ id: "NAME_PROVINCE" }),
        field: "tenTinh",
        cellStyle: {
            minWidth: "250px",
            textAlign: "left",
        },
        headerStyle: {
            minWidth: "250px",
        }
    },
    {
        name: intl.formatMessage({ id: "NAME_DISTRICT" }),
        field: "tenHuyen",
        cellStyle: {
            minWidth: "250px",
            textAlign: "left",
        },
        headerStyle: {
            minWidth: "250px",
        }
    },
    {
        name: intl.formatMessage({ id: "NAME_VILLAGE" }),
        field: "tenThon",
        cellStyle: {
            minWidth: "250px",
            textAlign: "left",
        },
        headerStyle: {
            minWidth: "250px",
        }
    },
    {
        name: intl.formatMessage({ id: "CODE_VILLAGE" }),
        field: "maThon",
        cellStyle: {
            minWidth: "250px",
            textAlign: "left",
        },
        headerStyle: {
            minWidth: "250px",
        }
    },
    {
        name: intl.formatMessage({ id: "STATUS" }),
        field: "isActive",
        cellStyle: {
            minWidth: "250px",
            textAlign: "left",
        },
        headerStyle: {
            minWidth: "250px",
        },
        render: (rowData: any, index: number) => {
            return <div className="d-flex justify-content-center">
                {
                    <Form.Check
                        type="switch"
                        onChange={() => handleActiveDiaPhuong(rowData?.id, rowData)}
                        checked={!rowData?.isActive}
                        id="custom-switch"
                        label=""
                    />
                }
            </div>
        }
    },
]

export const arrUsers = ({ handleOpenForm, handleActiveUser , intl}: any) => [
    {
        name: `${intl.formatMessage({ id: "ORDER" })}`,
        field: "",
        render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
        name: `${intl.formatMessage({ id: "FULLNAME" })}`,
        field: "hoTen",
        cellStyle: {
            minWidth: "150px",
            textAlign: "left",
        },
        headerStyle: {
            minWidth: "150px",
        }
    },
    {
        name: `${intl.formatMessage({ id: "USER_NAME" })}`,
        field: "tenDangNhap",
        cellStyle: {
            minWidth: "150px",
            textAlign: "left",
        },
        headerStyle: {
            minWidth: "150px",
        }
    },
    {
        name: `${intl.formatMessage({ id: "NAME_FACILITY" })}`,
        field: "tenCoSo",
        cellStyle: {
            minWidth: "250px",
            textAlign: "left",
        },
        headerStyle: {
            minWidth: "250px",
        }
    },
    {
        name: `${intl.formatMessage({ id: "PHONE_NUMBER" })}`,
        field: "dienThoai",
        cellStyle: {
            minWidth: "150px",
            textAlign: "left",
        },
        headerStyle: {
            minWidth: "150px",
        }
    },
    {
        name: `${intl.formatMessage({ id: "EMAIL" })}`,
        field: "email",
        cellStyle: {
            minWidth: "200px",
            textAlign: "left",
        },
        headerStyle: {
            minWidth: "200px",
        }
    },
    {
        name: `${intl.formatMessage({ id: "NAME_ROLE" })}`,
        field: "tenVaiTro",
        cellStyle: {
            minWidth: "150px",
            textAlign: "left",
        },
        headerStyle: {
            minWidth: "150px",
        }
    },
    {
        name: `${intl.formatMessage({ id: "STATUS" })}}`,
        field: "isActive",
        cellStyle: {
            minWidth: "50px",
            textAlign: "left",
        },
        headerStyle: {
            minWidth: "50px",
        },
        render: (rowData: any, index: number) => {
            return <div className="d-flex justify-content-center">
                {
                    <Form.Check
                        type="switch"
                        onChange={() => handleActiveUser?.(rowData?.id, rowData)}
                        checked={!rowData?.isActive}
                        id="custom-switch"
                        label=""
                    />
                }
            </div>
        }
    },
    {
        name: `${intl.formatMessage({ id: "UPDATE" })}`,
        field: "sua",
        cellStyle: {
            minWidth: "50px",
            textAlign: "center",
        },
        headerStyle: {
            minWidth: "50px",
        },
        render: (rowData: any, index: number) => {
            return (
                <>
                    {!rowData?.isActive && <i
                        onClick={() => handleOpenForm(rowData)}
                        className="spaces cursor-pointer fs-17 text-primary bi bi-pencil-square fa-lg" />}
                </>
            )
        }
    },
]

export const arrCoSo = ({ handleOpenForm, handleActiveCoSo, intl
}: any) => [
    {
        name: intl.formatMessage({ id: "ORDER" }),
        field: "",
        render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
        name: intl.formatMessage({ id: "NAME_FACILITY" }),
        field: "tenCoSo",
        cellStyle: {
            minWidth: "150px",
            textAlign: "left",
        },
        headerStyle: {
            minWidth: "150px",
        }
    },
    {
        name: intl.formatMessage({ id: "CODE_FACILITY" }),
        field: "maCoSo",
        cellStyle: {
            minWidth: "150px",
            textAlign: "left",
        },
        headerStyle: {
            minWidth: "150px",
        }
    },
    {
        name: intl.formatMessage({ id: "ADDRESS" }),
        field: "diaChi",
        cellStyle: {
            minWidth: "150px",
            textAlign: "left",
        },
        headerStyle: {
            minWidth: "150px",
        }
    },
    {
        name: intl.formatMessage({ id: "PHONE_NUMBER" }),
        field: "soDienThoai",
        cellStyle: {
            minWidth: "200px",
            textAlign: "left",
        },
        headerStyle: {
            minWidth: "200px",
        }
    },
    {
        name: intl.formatMessage({ id: "CLASSIFY" }),
        field: "tenPhanLoai",
        cellStyle: {
            minWidth: "150px",
            textAlign: "left",
        },
        headerStyle: {
            minWidth: "150px",
        }
    },
    {
        name: intl.formatMessage({ id: "PROVINCE" }),
        field: "tenTinh",
        cellStyle: {
            minWidth: "150px",
            textAlign: "left",
        },
        headerStyle: {
            minWidth: "150px",
        }
    },
    {
        name: intl.formatMessage({ id: "DISTRICT" }),
        field: "tenHuyen",
        cellStyle: {
            minWidth: "150px",
            textAlign: "left",
        },
        headerStyle: {
            minWidth: "150px",
        }
    },
    {
        name: intl.formatMessage({ id: "COMMUNE" }),
        field: "tenXa",
        cellStyle: {
            minWidth: "150px",
            textAlign: "left",
        },
        headerStyle: {
            minWidth: "150px",
        }
    },
    {
        name: intl.formatMessage({ id: "STATUS" }),
        field: "isActive",
        cellStyle: {
            minWidth: "50px",
            textAlign: "left",
        },
        headerStyle: {
            minWidth: "50px",
        },
        render: (rowData: any, index: number) => {
            return <div className="d-flex justify-content-center">
                {
                    <Form.Check
                        type="switch"
                        onChange={() => handleActiveCoSo(rowData?.id, rowData)}
                        checked={!rowData?.trangThai}
                        id="custom-switch"
                        label=""
                    />
                }
            </div>
        }
    },
    {
        name: intl.formatMessage({ id: "ACTIONS" }),
        field: "sua",
        cellStyle: {
            minWidth: "80px",
            textAlign: "center",
        },
        headerStyle: {
            minWidth: "80px",
        },
        render: (rowData: any, index: number) => {
            return (
                <>
                    {!rowData?.isActive && <i
                        onClick={() => handleOpenForm(rowData)}
                        className="spaces cursor-pointer fs-17 text-primary bi bi-pencil-square fa-lg" />}
                </>
            )
        }
    },
]


export const STATUS_DIA_PHUONG = {
    ACTIVE: 0,
    INACTIVE: 1
}

export const INIT_VALUE_DIA_PHUONG: IDanhMucDiaPhuong = {
    id: null,
    isActive: STATUS_DIA_PHUONG.ACTIVE,
    vungMienId: null,
    maTinh: null,
    tenTinh: null,
    tinhId: null,
    huyenId: null,
    tenHuyen: null,
    maHuyen: null,
    xaId: null,
    tenXa: null,
    maXa: null,
    thonId: null,
    tenThon: null,
    maThon: null,
    tinh: null,
    huyen: null,
    xa: null,
    vungMien: null,
}

export const TRANG_THAI = [
    {
        code: 0,
        name: "Đang hoạt động"
    },
    {
        code: 1,
        name: "Không hoạt động"
    }
]


export const INIT_USER: IDanhMucUser = {
    id: null,
    tenDangNhap: null,
    hoTen: null,
    email: null,
    dienThoai: null,
    password: null,
    nhapLaiMatKhau: null,
    isActive: TRANG_THAI[0].code,
    classify: null,
    tinhId: null,
    tinhObject: null,
    huyenId: null,
    huyenObject: null,
    xaId: null,
    xaObject: null,
    vungMienId: null,
    vungMienObj: null,
    donVi: null,
    donViObject: null,
    vaiTroId: null,
    tenVaiTro: null,
    isChangePassword: true,
}

export const xaSchema = Yup.object().shape({
    tinh: Yup.object().required("Bắt buộc nhập").nullable(),
    huyen: Yup.object().required("Bắt buộc nhập").nullable(),
    tenXa: Yup.string().nullable().required("Bắt buộc nhập")
})
export const thonSchema = Yup.object().shape({
    tinh: Yup.object().required("Bắt buộc nhập").nullable(),
    huyen: Yup.object().required("Bắt buộc nhập").nullable(),
    xa: Yup.object().required("Bắt buộc nhập").nullable(),
    tenThon: Yup.string().nullable().required("Bắt buộc nhập")
})

export const huyenSchema = Yup.object().shape({
    tinh: Yup.object().required("Bắt buộc nhập").nullable(),
    tenHuyen: Yup.string().nullable().required("Bắt buộc nhập")
})

export const tinhSchema = Yup.object().shape({
    vungMienId: Yup.string().required("Bắt buộc nhập").nullable(),
    tenTinh: Yup.string().nullable().required("Bắt buộc nhập")
})

const passwordRules = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

export const userSchema = Yup.object().shape({
    hoTen: Yup.string().required("Bắt buộc nhập").nullable(),
    tenDangNhap: Yup.string().required("Bắt buộc nhập").nullable(),
    password: Yup.string().when("isChangePassword", {
        is: (value: any) => value,
        then: Yup.string().required("Bắt buộc nhập")
        .matches(passwordRules, { message: 'Password ít nhất 8 ký tự gồm chữ hoa, thường, số và ký tự đặc biệt' })
        .nullable(),
    }).nullable(),
    nhapLaiMatKhau: Yup.string().when("isChangePassword", {
        is: (value: any) => value,
        then: Yup.string().required("Bắt buộc nhập")
        .oneOf([Yup.ref('password'), null], 'Không khớp với mật khẩu đã nhập')
        .nullable()
    }).nullable(),
    classify: Yup.object().required("Bắt buộc nhập").nullable(),
    donViObject: Yup.object().when("classify", {
        is: (value: any) => value,
        then: Yup.object().required("Bắt buộc nhập").nullable(),
    }).nullable(),
})

export const VUNG_MIEN_OPTION = [
    { name: "Miền Bắc", code: 1 },
    { name: "Miền Nam", code: 4 },
    { name: "Tây Nguyên ", code: 3 },
    { name: "Miền Trung", code: 2 },
]

export const CS_STATUS = [
    { name: "Có hiệu lực", code: 0 },
    { name: "Hết hiệu lực", code: 1 },
]

export const INIT_SEARCH_CO_SO = {
    tenCoSo: null,
    maCoSo: null,
    dienThoai: null,
    trangThai: null,
    hinhThuc: null,
    tinhId: null,
    huyenId: null,
    xaId: null,
    tinh: null,
    capDonViObject: null,
    huyen: null,
    xa: null,
    pageNumber:1,
    pageSize:10
}

const configTuyenTW = {
    reset: ["tinh", "huyen", "xa", "vungMienId"],
    disabled: {
        huyen: true,
        xa: true,
        vungMien: false
    },
    required: {
        tinh: true,
        huyen: false,
        xa: false
    }
};

const configTuyenTinh = {
    reset: ["tinh", "huyen", "xa", "vungMienId"],
    disabled: {
        huyen: true,
        xa: true,
        vungMien: true
    },
    required: {
        tinh: true,
        huyen: false,
        xa: false
    }
};

const configTuyenHuyen = {
    reset: ["huyen", "xa", "vungMienId"],
    disabled: {
        huyen:false,
        xa: true,
        vungMien: true
    },
    required: {
        huyen: true,
        tinh: true,
        xa: false
    }
};

const configTuyenXa = {
    reset: ["xa", "vungMienId"],
    disabled: {
        xa: false,
        huyen: false,
        vungMien: true
    },
    required: {
        xa: true,
        huyen: true,
        tinh: true
    }
};

export const configByCoSo = {
    [HINH_THUC_CO_SO.TuyenTrungUong]: configTuyenTW, //0
    [HINH_THUC_CO_SO.BenhVienTrungUong]: configTuyenTW, //1
    [HINH_THUC_CO_SO.TuyenTinh]: configTuyenTinh, //2
    [HINH_THUC_CO_SO.TrungTamCapTinh]: configTuyenTinh, //9
    [HINH_THUC_CO_SO.BenhVienTuyenTinh]: configTuyenTinh, //3
    [HINH_THUC_CO_SO.TuyenHuyen]: configTuyenHuyen, //4
    [HINH_THUC_CO_SO.BenhVienTuyenHuyen]: configTuyenHuyen, //5
    [HINH_THUC_CO_SO.TrungTamYTeHuyen]: configTuyenHuyen, //10
    [HINH_THUC_CO_SO.TuyenXa]: configTuyenXa, //6
};

export const LOAI_CO_SO = {
    CUC: "CUC",
    TINH: "TINH",
    HUYEN: "HUYEN",
    XA: "XA",
}

const handlerApiDanhMuc = {
    [LOAI_CO_SO.CUC]: getPagedTinh,
    [LOAI_CO_SO.TINH]: getPagedHuyen,
    [LOAI_CO_SO.HUYEN]: getPagedXa,
    [LOAI_CO_SO.XA]: getPagedThon,
}

export const getApiDanhMucDiaPhuong = (loaiCoSo: number, objParams: any): any => handlerApiDanhMuc[getLoaiCoSo(loaiCoSo)](objParams)

export const getLoaiCoSo = (loaiCoSo: number): any => {
    if ([HINH_THUC_CO_SO.TuyenTrungUong, HINH_THUC_CO_SO.BenhVienTrungUong].includes(loaiCoSo)) return LOAI_CO_SO.CUC
    if ([HINH_THUC_CO_SO.TuyenTinh, HINH_THUC_CO_SO.BenhVienTuyenTinh, HINH_THUC_CO_SO.TrungTamCapTinh].includes(loaiCoSo)) return LOAI_CO_SO.TINH
    if ([HINH_THUC_CO_SO.TuyenHuyen, HINH_THUC_CO_SO.BenhVienTuyenHuyen, HINH_THUC_CO_SO.TrungTamYTeHuyen].includes(loaiCoSo)) return LOAI_CO_SO.HUYEN
    if ([HINH_THUC_CO_SO.TuyenXa].includes(loaiCoSo)) return LOAI_CO_SO.XA
}

export const TYPE_DC_CHUAN = (intl: any) => [
    {
        code: "Week",
        name:  intl.formatMessage({ id: "WEEKLY" })
    },
    {
        code: "Month",
        name:  intl.formatMessage({ id: "MONTH" })
    }
]
