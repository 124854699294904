import { Form, Formik } from "formik";
import React, { Dispatch, ReactElement, SetStateAction } from "react";
import { Button, Modal } from "react-bootstrap";
import { useIntl } from "react-intl";

interface IProps {
    children?: ReactElement,
    isShow: boolean,
    setIsShow: Dispatch<SetStateAction<boolean>>
    validationSchema?: any,
    handleSubmit: (values: any) => void
    initValue: any
    title?: string,
    isForm?: boolean,
    isModalWrap?: boolean,
    hasButtonSave?: boolean
}

const CustomDialog = (props: IProps) => {
    const intl = useIntl()
    const { children,
        isShow,
        setIsShow,
        initValue,
        validationSchema,
        handleSubmit,
        title = intl.formatMessage({ id: "RECORD" }),
        isForm = true,
        isModalWrap = false,
        hasButtonSave = false
    } = props;

    return (
        <Modal
            show={isShow}
            onHide={() => setIsShow(false)}
            centered
            animation
            size="xl"
            backdrop="static"
        >
            <Modal.Header closeButton>
               <Modal.Title>
                    <span className="spaces pl-16">
                        {!isModalWrap ?
                            `${initValue?.id ? intl.formatMessage({ id: "UPDATE" }) : intl.formatMessage({ id: "ADD" }) + title}` :
                            `${title}`
                        }
                    </span>
                </Modal.Title>
            </Modal.Header>
            <Formik
                initialValues={initValue}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({}) => (
                    <Form noValidate>
                        <Modal.Body className="">
                            {React.cloneElement(children as React.ReactElement, { ...props })}
                        </Modal.Body>

                        {
                            !isModalWrap ?

                            <Modal.Footer>
                                <div className='d-flex justify-content-between w-100'>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        {isForm && <div className='fw-bold'>{intl.formatMessage({ id: "NOTE" })}<span className='text-danger'>* </span> {intl.formatMessage({ id: "IS_REQUIREMENT" })}</div>}
                                    </div>
                                    <div className="d-flex gap-2">
                                        <Button
                                            onClick={() => { }}
                                            className="button-primary"
                                            type="submit">
                                            {intl.formatMessage({ id: "SAVE" })}
                                        </Button>
                                    </div>
                                </div>
                            </Modal.Footer> : <></>
                        }
                        {hasButtonSave &&
                            <Modal.Footer>
                                <div className='d-flex justify-content-end w-100'>
                                    <div className="d-flex gap-2">
                                        <Button
                                            onClick={handleSubmit}
                                            className="button-primary"
                                            >
                                            {intl.formatMessage({ id: "SAVE" })}
                                        </Button>
                                    </div>
                                </div>
                            </Modal.Footer>
                        }
                    </Form>
                )}
            </Formik>
        </Modal>
    );
}

export default CustomDialog;
