import { OCTKTSVG, OCTTable } from "@oceantech/oceantech-ui"
import { useFormikContext } from "formik"
import { Button } from "react-bootstrap"
import { dsBienPhapPhongChongColumns, INITIAL_BIEN_PHAP_TRIEN_KHAI } from "../constants/constants"
import { ICheckField, IThongTinODich } from "../models/quanLyODichModels"
import { useIntl } from "react-intl"

const BienPhapPhongChongBox = ({ elementAbility, isFieldUpdate}: ICheckField) => {
    const { values, handleChange, setValues, setFieldValue, errors, touched } = useFormikContext<IThongTinODich>()
    const intl = useIntl()

    const handleAddRow = () => {
        setValues(prev => {
            return {
                ...prev,
                bienPhapTrienKhaiList: [
                    ...prev.bienPhapTrienKhaiList,
                    { ...INITIAL_BIEN_PHAP_TRIEN_KHAI }
                ]
            }
        });
    };

    const handleDeleteRow = (index: number) => {
        setValues(prev => {
            return { ...prev, bienPhapTrienKhaiList: prev.bienPhapTrienKhaiList.filter((item, indexItem) => indexItem !== index) }
        })
    }

    return (
        <div className="section-container">
            <div className="d-flex align-items-center justify-content-between">
                <div className="spaces mb-10 fs-18 fw-bold text-uppercase color-dark-red">
                    {intl.formatMessage({ id: "INVENTION_PREVENT_OUTBREAK_IMPLEMENTED" })}
                </div>
                {
                    elementAbility?.update && <Button
                        className="button-primary"
                        onClick={handleAddRow}
                    >
                        <OCTKTSVG path='/media/svg/icons/plus.svg' svgClassName='spaces h-14 w-14 color-white' />
                        {intl.formatMessage({ id: "ADD" })}
                    </Button>
                }
            </div>
            <div className="border-top spaces pt-10">
                <OCTTable
                    id="bien-phap-phong-chong"
                    data={values?.bienPhapTrienKhaiList}
                    columns={dsBienPhapPhongChongColumns({
                        handleDeleteRow,
                        handleChange,
                        values,
                        setFieldValue,
                        errors,
                        touched,
                        isFieldUpdate,
                        intl
                    })}
                    notDelete={true}
                    notEdit={true}
                    noToolbar={true}
                    unSelectedAll={true}
                    noPagination={true}
                />
            </div>
        </div>
    )
}

export default BienPhapPhongChongBox
