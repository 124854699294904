import React from 'react'
import { useAppContext } from '../../../AppContext'
import { useInitContext } from '../../utils/InitContext'

const AreaBar = () => {
  const { userData } = useAppContext()
  const { address, setAddress } = useInitContext();
  type KeyOfAddress = keyof typeof address

  return <>
    {
      !userData?.tenTinh && <span onClick={() => setAddress?.((prev: any) => ({ ...prev, tinhId: null, tenTinh: null, huyenId: null, tenHuyen: null, tenXa: null, xaId: null, }))} className="text-warning"> Việt Nam</span>
    }
    {['tenTinh', 'tenHuyen', 'tenXa'].map((key, index) => {
      if (!address?.[key as KeyOfAddress]) return null;

      const onClick = () => {
        if(userData?.tenTinh && !index) return;
        const newAddress = { ...address };
        const clearKeys = ['tenTinh', 'tinhId', 'tenHuyen', 'huyenId', 'tenXa', 'xaId'];
        clearKeys.slice((index + 1) * 2).forEach(k => newAddress[k as keyof typeof newAddress] = null);
        setAddress?.(newAddress);
      };

      return (
        <span key={key} onClick={onClick} className="text-warning cursor-pointer">
          {<i className="bi bi-chevron-compact-right"></i>} {address[key as KeyOfAddress]}
        </span>
      );
    })}
  </>
}

export default AreaBar
