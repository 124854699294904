import { useEffect, useState } from 'react';
import TabMenu from '../../../component/tabs/TabMenu';
import BieuDoCaMacThang from './BieuDoCaMacThang';
import BieuDoCaMacTheoDanSo from './BieuDoCaMacTheoDanSo';
import BieuDocaMacTuan from './BieudoCaMacTuan';

const BieuDoXuHuongDich = () => {
  const [activeTab, setActiveTab] = useState("0");
  const [isFS, setIsFS] = useState(false);
  const tabDetailChart = [
    {
      eventKey: "0",
      title: "Tuần",
      component: <BieuDocaMacTuan />
    },
    {
      eventKey: "1",
      title: "Tháng",
      component: <BieuDoCaMacThang />
    },
    {
      eventKey: "2",
      title: "Trên 100k dân",
      component: <BieuDoCaMacTheoDanSo />
    },
  ];

  useEffect(() => { // expand map
    const element = document.getElementById("icon-expand-chart");
    const elementMap = document.getElementsByClassName("tab-bieu-do-container");

    if (elementMap.length > 0) {
      const map = elementMap[0] as HTMLElement; // Ensure it's treated as an HTMLElement
      const elementExpand = element as HTMLElement; // Ensure it's treated as an HTMLElement

      const handleClick = () => {
        const requestFullscreen =
          map.requestFullscreen?.bind(map) ||
          (map as any).mozRequestFullScreen?.bind(map) || // Firefox
          (map as any).webkitRequestFullscreen?.bind(map) || // Chrome, Safari and Opera
          (map as any).msRequestFullscreen?.bind(map); // IE/Edge
          
          if (requestFullscreen) {
            requestFullscreen();
            setIsFS(true);
        }
      };

      elementExpand?.addEventListener('click', handleClick);

      const handleFullscreenChange = () => {
        if (!document.fullscreenElement) {
          setIsFS(false);
        }
      };

      document.addEventListener('fullscreenchange', handleFullscreenChange);

      return () => {
        elementExpand.removeEventListener('click', handleClick);
        document.removeEventListener('fullscreenchange', handleFullscreenChange);
      };
    }
  }, []);


  return (
    <div className="tab-bieu-do-container">
      <i id="icon-expand-chart" className="bi bi-arrow-up-right position-absolute"></i>
      <TabMenu
        key={`${isFS}`}
        className="tab-bieu-do"
        danhsachTabs={tabDetailChart}
        setCurrentTab={setActiveTab}
        defaultActiveKey={activeTab}
      />
    </div>
  )
}

export default BieuDoXuHuongDich
