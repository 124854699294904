import { useState } from "react"
import { useIntl } from "react-intl"
import TabMenu from "../../../component/tabs/TabMenu"
import { hasAuthority } from "../../../utils/FunctionUtils"
import { KEY_TAB, tabDanhMucDiaPhuong } from "../../constant/constants"

const DanhMucDiaPhuong = () => {
  const [activeTab, setActiveTab] = useState(KEY_TAB.TINH);
  const intl = useIntl()
  const tabDanhMuc = tabDanhMucDiaPhuong(intl).filter(item=>hasAuthority(item.permission, item.ability))
  return (
    <div className="w-100 mt-5 header-tab-center">
      <TabMenu
        danhsachTabs={tabDanhMuc}
        setCurrentTab={setActiveTab}
        defaultActiveKey={activeTab}
      />
    </div>
  )
}

export default DanhMucDiaPhuong;
