import { OCTAutocomplete, OCTTextValidator } from "@oceantech/oceantech-ui";
import { useFormikContext } from "formik";
import { Col, Row } from "react-bootstrap";
import { getListHuyenByTinhId, getListTinh } from "../../../services";
import { IDanhMucDiaPhuong } from "../../model/model";
import { useIntl } from "react-intl";

const FormXa = () => {

  const {
    values,
    handleChange,
    errors, touched,
    setValues,
  } = useFormikContext<IDanhMucDiaPhuong>()
  const intl = useIntl()

  const handleChangeTinh = (option: any) => {
    setValues(prev => ({ ...prev, tinh: option, tinhId: option?.id, huyen: null }))
  }

  const handleChangeHuyen = (option: any) => {
    setValues(prev => ({ ...prev, huyen: option,huyenId: option?.id }))
  }

  return <>
    <Row>
      <Col>
        <OCTAutocomplete
          lable={intl.formatMessage({ id: "HOME_PROVINCE_ADDRESS" })}
          menuPlacement="top"
          searchFunction={getListTinh}
          urlData='data.data'
          getOptionLabel={(option) => option?.tenTinh}
          options={[]}
          name='tinh'
          searchObject={{}}
          onChange={handleChangeTinh}
          isRequired
          value={values.tinh}
          errors={errors?.tinh}
          touched={touched?.tinh}
        />
      </Col>
      <Col>
        <OCTAutocomplete
          menuPlacement="top"
          name="huyen"
          lable={intl.formatMessage({ id: "HOME_DISTRICT_ADDRESS" })}
          searchFunction={() => getListHuyenByTinhId(values?.tinh?.id)}
          urlData='data.data'
          getOptionLabel={(option) => option?.tenHuyen}
          options={[]}
          searchObject={{}}
          value={values.huyen}
          onChange={handleChangeHuyen}
          isRequired
          isDisabled={!values?.tinh}
          errors={errors?.huyen}
          touched={touched?.huyen}
          dependencies={[values?.tinh?.id]}
        />
      </Col>
      <Col>
        <OCTTextValidator
          lable={intl.formatMessage({ id: "HOME_COMMUNE_ADDRESS" })}
          name="tenXa"
          isRequired
          value={values.tenXa}
          onChange={handleChange}
          errors={errors?.tenXa}
          touched={touched?.tenXa}
        />
      </Col>
    </Row>
  </>

}
export default FormXa
