import moment from 'moment'
import { useInitContext } from '../../../utils/InitContext';
import AreaBar from '../AreaBar';

type Props = {}

const DSTruongHopBenh = (props: Props) => {
    const { data } = useInitContext()

    return (
        <div className='bg-dark spaces p-4'>
            <div className="spaces white mb-8 text-uppercase">
                <div className="text-center">
                    DANH SÁCH CA:
                    <AreaBar/>
                </div>
            </div>

            <div className='ds-truong-hop-benh bg-color-tab'>
                {
                    data?.infoMapData?.dataThbs?.length ?
                        data?.infoMapData?.dataThbs?.map((item: any) => (
                            <div className='d-flex spaces gap-6 pl-4 pr-12 py-4 mb-4 bg-black'>
                                <div style={{ backgroundColor: item?.color }} className='spaces w-20'></div>
                                <div className='text-gray-700 d-flex flex-column spaces gap-2 flex-1'>
                                    <div className='spaces d-flex justify-content-between'>
                                        <span> Họ tên: <span className='text-uppercase text-white'>{item?.hoTen}</span></span>
                                        <span> ĐT: <span className='text-uppercase'>{item?.soDienThoai}</span></span>
                                    </div>
                                    <div>Địa chỉ: <span className='text-warning'>{item?.diaChi}</span></div>
                                    <div className='d-flex justify-content-between'>
                                        <div>Phát hiện: <span className='color-steel-blue'>{moment(item?.ngayKhoiPhat).format("DD/MM/YYYY")}</span></div>
                                        <div>Số liệu: <span className='color-steel-blue'>{item?.tenDonViBaoCao}</span></div>
                                    </div>
                                </div>
                            </div>
                        )) : <div className='text-center text-primary spaces fs-18 p-20'>Chưa phát hiện ca bệnh trong khu vực</div>
                }
            </div>
        </div>
    )
}

export default DSTruongHopBenh
