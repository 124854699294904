import { OCTAutocomplete, OCTTextValidator, } from '@oceantech/oceantech-ui';
import { useFormikContext } from 'formik';
import { Col, Row } from '../../../component/Grid';
import AsyncAutoComplete from '../../../component/input-field/AsyncAutoComplete';
import { getListCoSoDieuTri, getListDonViCongTac } from '../../../services';
import { DIA_DIEM_DIEU_TRI, DIA_DIEM_DT_KHAC } from '../constants/constant';
import { TruongHopBenh } from '../model/Model';
import { useIntl } from 'react-intl';

type Props = {
    onlyView?: boolean
}

const ThongTinGhiNhanTab = ({ onlyView }: Props) => {
    const { values, handleChange, errors, touched, setFieldValue, setValues } = useFormikContext<TruongHopBenh>()
    const intl = useIntl()

    return (
        <>
            <Row >
                <Col xl={12}>
                    <div className='fw-bold spaces mt-24'>{intl.formatMessage({ id: "REPORTER_INFORMATION" })}</div>
                </Col>
                <Col xs={6}>
                    <OCTTextValidator
                        lable={intl.formatMessage({ id: "REPORTER_NAME" })}
                        type="text"
                        isRequired
                        name="truongHopBenh.tenNguoiBaoCao"
                        value={values.truongHopBenh?.tenNguoiBaoCao}
                        onChange={handleChange}
                        errors={errors?.truongHopBenh?.tenNguoiBaoCao}
                        touched={touched?.truongHopBenh?.tenNguoiBaoCao}
                        disabled={onlyView}
                    />
                </Col>
                <Col xs={6}>
                    <OCTTextValidator
                        lable={intl.formatMessage({ id: "PHONE_NUMBER" })}
                        type="text"
                        isRequired
                        name="truongHopBenh.dienThoaiNguoiBaoCao"
                        value={values.truongHopBenh?.dienThoaiNguoiBaoCao}
                        onChange={handleChange}
                        errors={errors?.truongHopBenh?.dienThoaiNguoiBaoCao}
                        touched={touched?.truongHopBenh?.dienThoaiNguoiBaoCao}
                        disabled={onlyView}
                    />
                </Col>
                <Col xs={6}>
                    <OCTTextValidator
                        lable={intl.formatMessage({ id: "EMAIL" })}
                        type="text"
                        name="truongHopBenh.emailNguoiBaoCao"
                        value={values.truongHopBenh?.emailNguoiBaoCao}
                        onChange={handleChange}
                        errors={errors?.truongHopBenh?.emailNguoiBaoCao}
                        touched={touched?.truongHopBenh?.emailNguoiBaoCao}
                        disabled={onlyView}
                    />
                </Col>
                <Col xs={6}>
                    <AsyncAutoComplete
                        params={{}}
                        required
                        label={intl.formatMessage({ id: "WORKPLACE_STUDY" })}
                        displayField='tenCoSo'
                        service={getListDonViCongTac}
                        handleChange={(value) => setFieldValue("truongHopBenh.donViCongTacNbc", value)}
                        nameErrorMessage={errors?.truongHopBenh?.donViCongTacNbc as string}
                        value={values.truongHopBenh?.donViCongTacNbc}
                        touched={touched?.truongHopBenh?.donViCongTacNbc}
                        isDisabled={onlyView}
                    />
                </Col>
                <Col xl={6}>
                    <div className='fw-bold spaces mb-8'>{intl.formatMessage({ id: "TREATMENT_FACILITY" })}</div>
                    <Row>
                        <Col xl={4} className='ps-0'>
                            <OCTAutocomplete
                                lable={intl.formatMessage({ id: "PLACE" })}
                                options={DIA_DIEM_DIEU_TRI}
                                isRequired
                                valueSearch="code"
                                name="truongHopBenh.noiPhatHien"
                                value={values.truongHopBenh?.noiPhatHien}
                                onChange={(option) => setValues({ ...values, truongHopBenh: { ...values.truongHopBenh, noiPhatHien: option?.code, noiPhatHienKhac: null } })}
                                errors={errors?.truongHopBenh?.noiPhatHien}
                                touched={touched?.truongHopBenh?.noiPhatHien}
                                isDisabled={onlyView}
                            />
                        </Col>
                        <Col xl={8}>
                            <AsyncAutoComplete
                                params={{}}
                                required
                                label={intl.formatMessage({ id: "TREATMENT_FACILITY" })}
                                displayField='tenCoSo'
                                service={getListCoSoDieuTri}
                                handleChange={(value) => setFieldValue("truongHopBenh.coSoDieuTri", value)}
                                nameErrorMessage={errors?.truongHopBenh?.coSoDieuTri as string}
                                touched={touched?.truongHopBenh?.coSoDieuTri}
                                value={values.truongHopBenh?.coSoDieuTri}
                                isDisabled={onlyView}
                            />
                        </Col>
                        {Number(values.truongHopBenh.noiPhatHien) === DIA_DIEM_DT_KHAC && (
                            <Col xs={12} className='ps-0'>
                                <OCTTextValidator
                                    lable={intl.formatMessage({ id: "DESCRIPTION" })}
                                    type="text"
                                    isRequired
                                    name="truongHopBenh.noiPhatHienKhac"
                                    value={values.truongHopBenh?.noiPhatHienKhac}
                                    onChange={handleChange}
                                    errors={errors?.truongHopBenh?.noiPhatHienKhac}
                                    touched={touched?.truongHopBenh?.noiPhatHienKhac}
                                    disabled={onlyView}
                                />
                            </Col>
                        )}
                    </Row>
                </Col>
                <Col xl={6}>
                    <div className='fw-bold spaces mb-8'>{intl.formatMessage({ id: "MANAGEMENT_COMMUNE" })}</div>
                    <Row>
                        <Col xs={12} className='ps-0'>
                            <AsyncAutoComplete
                                params={{}}
                                displayField='tenCoSo'
                                label={intl.formatMessage({ id: "TREATMENT_FACILITY" })}
                                service={getListCoSoDieuTri}
                                handleChange={(value) => setFieldValue("truongHopBenh.coSoQuanLy", value)}
                                nameErrorMessage={errors?.truongHopBenh?.coSoQuanLy as string}
                                touched={touched?.truongHopBenh?.coSoQuanLy}
                                value={values.truongHopBenh?.coSoQuanLy}
                                isDisabled={onlyView}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}

export default ThongTinGhiNhanTab
