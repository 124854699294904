import { OCTAutocomplete, OCTTextValidator } from "@oceantech/oceantech-ui";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { KEY_LOCALSTORAGE } from "../../../auth/core/_consts";
import AsyncAutoComplete from "../../../component/input-field/AsyncAutoComplete";
import LabelRequired from "../../../component/LabelRequired";
import ModalChonToaDo from "../../../component/map/ModalChonToaDo";
import { getListHuyenByTinhId, getListTinh, getListXaByHuyenId } from "../../../services";
import { localStorageItem } from "../../../utils/LocalStorage";
import { configByCoSo, CS_STATUS, VUNG_MIEN_OPTION } from "../../constant/constants";
import { ICoSo } from "../../model/model";
import { getListDiaChi, getVaiTroByUser } from "../../services/services";
import { useIntl } from "react-intl";

const AddCoSoForm = () => {
    const {
        values,
        handleChange,
        errors,
        touched,
        setFieldValue,
        setValues,
        setFieldError
    } = useFormikContext<ICoSo>()
    const userData = localStorageItem.get(KEY_LOCALSTORAGE.USER_INFOMATION)
    const [openChonToaDo, setOpenChonToaDo] = useState(false)
    const [fieldControl, setFieldControl] = useState(configByCoSo[userData?.phanLoaiCoSo])
    const hinhThucFieldValue = values.capDonViObject?.hinhThuc
    const intl = useIntl()

    useEffect(() => {
        if (hinhThucFieldValue) {
            setFieldControl(configByCoSo[hinhThucFieldValue])
        }
    }, [hinhThucFieldValue])

    const handleChangeCapDonVi = (option: any) => {
        setFieldValue("capDonViObject", option)
        configByCoSo[option?.hinhThuc]?.reset?.map(item => setFieldValue(item, null))
    }

    return <Row>
        <Col xs={12} sm={6} md={4} lg={4} className="spaces mt-12">
            <LabelRequired
                label={intl.formatMessage({ id: "NAME_FACILITY" })}
                className="fw-500"
                isRequired
            />
            <OCTTextValidator
                name="tenCoSo"
                type="text"
                onChange={handleChange}
                value={values?.tenCoSo}
                errors={errors?.tenCoSo}
                touched={touched?.tenCoSo}
            />
        </Col>
        <Col xs={12} sm={6} md={4} lg={4} className="spaces mt-12">
            <LabelRequired
                label={intl.formatMessage({ id: "CODE_FACILITY" })}
                isRequired
                className="fw-500"
            />
            <OCTTextValidator
                name="maCoSo"
                type="email"
                onChange={handleChange}
                value={values?.maCoSo}
                errors={errors?.maCoSo}
                touched={touched?.maCoSo}
            />
        </Col>
        <Col xs={12} sm={6} md={4} lg={4} className="spaces mt-12">
            <LabelRequired
                label={intl.formatMessage({ id: "ADDRESS" })}
                className="fw-500"
            />
            <OCTTextValidator
                name="diaChi"
                type="email"
                onChange={handleChange}
                value={values?.diaChi}
            />
        </Col>
        <Col xs={12} className="spaces mt-12">
            <LabelRequired
                label={intl.formatMessage({ id: "ADDRESS" })}
                className="fw-500"
            />
            <AsyncAutoComplete
                params={{}}
                displayField='name'
                fieldSearch='keyword'
                service={getListDiaChi}
                valueField='xaId'
                handleChange={(value: any) => setFieldValue("diaChiFullObject", value)}
                value={values.diaChiFullObject}
            />
        </Col>
        <Col xs={12} sm={6} md={4} lg={4} className="spaces mt-12">
            <LabelRequired
                label={intl.formatMessage({ id: "PHONE_NUMBER" })}
                className="fw-500"
            />
            <OCTTextValidator
                name="soDienThoai"
                type="text"
                onChange={handleChange}
                value={values?.soDienThoai}
                errors={errors?.soDienThoai}
                touched={touched?.soDienThoai}
            />
        </Col>
        <Col xs={12} sm={6} md={4} lg={4} className="spaces mt-12">
            <LabelRequired
                label="Website"
                className="fw-500"
            />
            <OCTTextValidator
                name={intl.formatMessage({ id: "WEBSITE" })}
                onChange={handleChange}
                value={values?.website}
                errors={errors?.website}
                touched={touched?.website}
            />
        </Col>
        <Col xs={12} sm={6} md={4} lg={4} className="spaces mt-12">
            <LabelRequired
                label={intl.formatMessage({ id: "EMAIL" })}
                className="fw-500"
            />
            <OCTTextValidator
                name="email"
                type="text"
                onChange={handleChange}
                value={values?.email}
                touched={touched?.email}
                errors={errors?.email}
            />
        </Col>
        <Col xs={12} sm={6} md={4} lg={4} className="spaces mt-12">
            <LabelRequired
                label={intl.formatMessage({ id: "FAX" })}
                className="fw-500"
            />
            <OCTTextValidator
                name="fax"
                type="text"
                onChange={handleChange}
                value={values?.fax}
            />
        </Col>
        <Col xs={12} sm={6} md={4} lg={4} className="spaces mt-12">
            <OCTAutocomplete
                lable={intl.formatMessage({ id: "STATUS" })}
                urlData='data.data'
                getOptionLabel={(option) => option?.name}
                options={CS_STATUS}
                name='trangThai'
                valueSearch={"code"}
                value={values.trangThai}
                onChange={(option) => setFieldValue("trangThai", option?.code)}
            />
        </Col>
        <Col xs={12} sm={6} md={4} lg={4} className="spaces mt-12">
            <OCTAutocomplete
                lable={intl.formatMessage({ id: "NAME_FACILITY" })}
                urlData='data.data'
                getOptionLabel={(option) => option?.tenVaiTro}
                searchFunction={getVaiTroByUser}
                options={[]}
                isRequired
                value={values.capDonViObject}
                onChange={handleChangeCapDonVi}
                errors={errors.capDonViObject}
                touched={touched.capDonViObject}
            />
        </Col>
        <Col xs={12} sm={6} md={4} lg={4} className="spaces mt-12">
            <OCTAutocomplete
                lable={intl.formatMessage({ id: "PROVINCE" })}
                urlData='data.data'
                getOptionLabel={(option) => option?.tenTinh}
                options={[]}
                value={values.tinh}
                searchFunction={getListTinh}
                name='province'
                searchObject={{}}
                isDisabled={!!userData?.tinhId || !hinhThucFieldValue}
                onChange={(option) => setValues(prev => ({ ...prev, tinh: option, huyen: null, xa: null }))}
                errors={errors.tinh}
                touched={touched.tinh}

            />
        </Col>
        <Col xs={12} sm={6} md={4} lg={4} className="spaces mt-12">
            <OCTAutocomplete
                lable={intl.formatMessage({ id: "DISTRICT" })}
                searchFunction={() => getListHuyenByTinhId(values?.tinh?.id)}
                urlData='data.data'
                getOptionLabel={(option) => option?.tenHuyen}
                options={[]}
                value={values.huyen}
                name='district'
                searchObject={{}}
                dependencies={[values?.tinh]}
                isDisabled={!!userData?.huyenId || !values?.tinh || fieldControl?.disabled?.huyen}
                onChange={(option) => setValues({ ...values, huyen: option, xa: null })}
                touched={touched.huyen}
                errors={errors.huyen}
            />
        </Col>
        <Col xs={12} sm={6} md={4} lg={4} className="spaces mt-12">
            <OCTAutocomplete
                lable={intl.formatMessage({ id: "COMMUNE" })}
                searchFunction={() => getListXaByHuyenId(values?.huyen?.id)}
                urlData='data.data'
                getOptionLabel={(option) => option?.tenXa}
                options={[]}
                value={values.xa}
                name='commune'
                searchObject={{}}
                dependencies={[values.huyen]}
                isDisabled={!!userData?.xaId || !values?.huyen || fieldControl?.disabled?.xa}
                onChange={(option) => setValues({ ...values, xa: option })}
                touched={touched.xa}
                errors={errors.xa}

            />
        </Col>
        {
            (!fieldControl?.disabled?.vungMien && hinhThucFieldValue) && <Col xs={12} sm={6} md={4} lg={4} className="spaces mt-12">
                <OCTAutocomplete
                    lable={intl.formatMessage({ id: "NAME_AREA" })}
                    menuPlacement="top"
                    getOptionLabel={(option) => option?.name}
                    options={VUNG_MIEN_OPTION}
                    name='vungMoRongId'
                    valueSearch={"code"}
                    searchObject={{}}
                    isRequired
                    onChange={(option) => setFieldValue("vungMoRongId", option?.code)}
                    value={values.vungMoRongId}
                />
            </Col>
        }
        <Col xs={12} sm={6} md={4} lg={4} className="spaces mt-12 d-flex justify-content-between gap-20 ">

            <OCTTextValidator
                lable={intl.formatMessage({ id: "LONGITUDE" })}
                name="password"
                type="text"
                className="w-100"
                onChange={handleChange}
                value={values?.kinhDo}
            />
            <OCTTextValidator
                name="password"
                type="text"
                lable={intl.formatMessage({ id: "LATITUDE" })}
                className="w-100"
                onChange={handleChange}
                value={values?.viDo}
            />
        </Col>
        <Col xs={12} sm={6} md={4} lg={4} className="spaces mt-12 d-flex align-items-end">
            <Button className="button-primary" onClick={() => setOpenChonToaDo(true)}>{intl.formatMessage({ id: "SELECT" })}</Button>
        </Col>
        {openChonToaDo && <ModalChonToaDo
            handleClose={() => setOpenChonToaDo(false)}
        />}
    </Row>
}

export default AddCoSoForm;
