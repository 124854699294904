import { OCTAutocomplete, OCTTextValidator } from "@oceantech/oceantech-ui";
import { useFormikContext } from "formik";
import { Col, Row } from "react-bootstrap";
import { getListHuyenByTinhId, getListTinh, getListXaByHuyenId } from "../../../services";
import { IDanhMucDiaPhuong } from "../../model/model";
import { useIntl } from "react-intl";

const FormThon = () => {

  const {
    values,
    handleChange,
    errors, touched,
    setValues,
  } = useFormikContext<IDanhMucDiaPhuong>()
  const intl = useIntl()

  const handleChangeTinh = (option: any) => {
    setValues(prev => ({ ...prev, tinh: option, tinhId: option?.id, huyen: null, xa: null, xaId: null, huyenId: null }))
  }

  const handleChangeHuyen = (option: any) => {
    setValues(prev => ({ ...prev, huyen: option, huyenId: option?.id, xa: null, xaId: null }))
  }

  const handleChangeXa = (option: any) => {
    setValues(prev => ({ ...prev, xa: option, xaId: option?.id }))
  }

  return <>
    <Row>
      <Col>
        <OCTAutocomplete
          lable={intl.formatMessage({ id: "PROVINCE" })}
          menuPlacement="top"
          searchFunction={getListTinh}
          urlData='data.data'
          getOptionLabel={(option) => option?.tenTinh}
          options={[]}
          name='tinh'
          searchObject={{}}
          onChange={handleChangeTinh}
          isRequired
          value={values.tinh}
          errors={errors?.tinh}
          touched={touched?.tinh}
        />
      </Col>
      <Col>
        <OCTAutocomplete
          menuPlacement="top"
          name="huyen"
          lable={intl.formatMessage({ id: "DISTRICT" })}
          searchFunction={() => getListHuyenByTinhId(values?.tinh?.id)}
          urlData='data.data'
          getOptionLabel={(option) => option?.tenHuyen}
          options={[]}
          searchObject={{}}
          value={values.huyen}
          onChange={handleChangeHuyen}
          isRequired
          isDisabled={!values?.tinh}
          errors={errors?.huyen}
          touched={touched?.huyen}
          dependencies={[values?.tinh?.id]}
        />
      </Col>
      <Col>
        <OCTAutocomplete
          menuPlacement="top"
          name="huyen"
          lable={intl.formatMessage({ id: "COMMUNE" })}
          searchFunction={() => getListXaByHuyenId(values?.huyen?.id)}
          urlData='data.data'
          getOptionLabel={(option) => option?.tenXa}
          options={[]}
          searchObject={{}}
          value={values.xa}
          onChange={handleChangeXa}
          isRequired
          isDisabled={!values?.huyen}
          errors={errors?.xa}
          touched={touched?.xa}
          dependencies={[values?.huyen?.id]}
        />
      </Col>
      <Col>
        <OCTTextValidator
          lable={intl.formatMessage({ id: "VILLAGE" })}
          name="tenThon"
          isRequired
          value={values.tenThon}
          onChange={handleChange}
          errors={errors?.tenThon}
          touched={touched?.tenThon}
        />
      </Col>
    </Row>
  </>

}
export default FormThon
