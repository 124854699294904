import { OCTAutocomplete, OCTTextValidator, } from '@oceantech/oceantech-ui';
import { useFormikContext } from 'formik';
import { Col, Row } from '../../../component/Grid';
import AsyncAutoComplete from '../../../component/input-field/AsyncAutoComplete';
import RadioGroup from '../../../component/input-field/RadioGroup';
import { getListCoSoDieuTri, getListCoSoXetNghiem, getListDmCapDoBenh } from '../../../services';
import { CONFIG_BY_CURRENT_STATUS, CONFIG_BY_TYPE_TEST } from '../config/config';
import { CO_SU_DUNG_VAXIN, KHONG_LAY_MAU_XN, KQ_XET_NGHIEM, LAY_MAU_XN, LOAI_XET_NGHIEM, PCLD_XAC_DINH_PHONG_XET_NGHIEM, PHAN_LOAI_CHAN_DOAN, SU_DUNG_VAXIN, TINH_TRANG_HIEN_NAY, YES_NO_OPT } from '../constants/constant';
import { TruongHopBenh } from '../model/Model';
import { useIntl } from 'react-intl';

type Props = {
    onlyView?: boolean
}

const ThongTinChanDoanTab = ({ onlyView }: Props) => {
    const { values, handleChange, errors, touched, setFieldValue, setValues } = useFormikContext<TruongHopBenh>()
    const intl = useIntl()

    const handleChangePhanLoaiChanDoan = (option: any) => {
        const newValue: TruongHopBenh = {
            ...values, truongHopBenh: {
                ...values.truongHopBenh,
                phanLoaiChanDoan: option?.code,
                layMauXetNghiem: option?.code === PCLD_XAC_DINH_PHONG_XET_NGHIEM ? LAY_MAU_XN : values?.truongHopBenh?.layMauXetNghiem
            }
        }
        setValues(newValue)
    }

    const handleChangeTinhTrangHienNay = (option: any) => {
        const newValue: TruongHopBenh = {
            ...values, truongHopBenh: {
                ...values.truongHopBenh,
                tinhTrangHienNay: option?.code,
                chanDoanRaVien: "",
                benhVienChuyenToiId: null,
                benhVienChuyenToi: null,
                tinhTrangKhac: null,
                ngayRaVien: null
            }
        }
        setValues(newValue)
    }

    const handleChangeLoaiXetNghiem = (option: any) => {
        const newValue: TruongHopBenh = {
            ...values, truongHopBenh: {
                ...values.truongHopBenh,
                loaiXetNghiem: option?.code,
                dinhLoaiXetNghiemKhac: "",
                loaiXetNghiemKhac: "",
            }
        }
        setValues(newValue)
    }

    const handleChangeLayMauXN = (event: any) => {
        const newValue: TruongHopBenh = {
            ...values, truongHopBenh: {
                ...values.truongHopBenh,
                layMauXetNghiem: event?.target?.value,
                loaiXetNghiem: null,
                dinhLoaiXetNghiemKhac: "",
                loaiXetNghiemKhac: "",
                ketQuaXetNghiem: null,
                ngayThucHienXn: null,
                ngayTraKetQuaXn: null,
                donViXetNghiem: null,
                donViXetNghiemObject: null,
            }
        }
        setValues(newValue)
    }

    const configByStatus = CONFIG_BY_CURRENT_STATUS[values?.truongHopBenh?.tinhTrangHienNay as any]
    const configByTypeTest = CONFIG_BY_TYPE_TEST[values?.truongHopBenh?.loaiXetNghiem as any]
    const disabledFieldLayMauXN = values?.truongHopBenh?.phanLoaiChanDoan === PCLD_XAC_DINH_PHONG_XET_NGHIEM ? [KHONG_LAY_MAU_XN] : []

    return (
        <>
            <Row className='pt-8'>
                <Col xl={6}>
                    <OCTAutocomplete
                        lable={`${intl.formatMessage({ id: "CLINIC_CLASSIFY" })} / ${intl.formatMessage({ id: "DISEASE_CLASSIFY_IN_HOPITAL" })}`}
                        searchFunction={getListDmCapDoBenh}
                        urlData='data.data'
                        getOptionLabel={(option) => option?.tenCapDo}
                        options={[]}
                        value={values.truongHopBenh?.capDoBenh}
                        onChange={(option) => setFieldValue("truongHopBenh.capDoBenh", option)}
                        searchObject={{}}
                        isDisabled={onlyView}
                    />
                </Col>
                <Col xl={6}>
                    <OCTAutocomplete
                        lable={`${intl.formatMessage({ id: "CLINIC_CLASSIFY" })} / ${intl.formatMessage({ id: "DISEASE_CLASSIFY_OUT_HOPITAL" })}`}
                        searchFunction={getListDmCapDoBenh}
                        urlData='data.data'
                        getOptionLabel={(option) => option?.tenCapDo}
                        options={[]}
                        value={values.truongHopBenh?.capDoBenhRaVien}
                        onChange={(option) => setFieldValue("truongHopBenh.capDoBenhRaVien", option)}
                        searchObject={{}}
                        isDisabled={onlyView}
                    />
                </Col>
            </Row>
            <Row >
                <Col xl={2}>
                    <OCTAutocomplete
                        lable={intl.formatMessage({ id: "STATUS_PRESENT" })}
                        options={TINH_TRANG_HIEN_NAY(intl)}
                        valueSearch={"code"}
                        value={values.truongHopBenh?.tinhTrangHienNay}
                        onChange={handleChangeTinhTrangHienNay}
                        isRequired
                        errors={errors.truongHopBenh?.tinhTrangHienNay}
                        touched={touched.truongHopBenh?.tinhTrangHienNay}
                        isDisabled={onlyView}
                    />
                </Col>
                {configByStatus?.tinhTrangKhac && (
                    <Col xs={3}>
                        <OCTTextValidator
                            lable={intl.formatMessage({ id: "OTHER_STATUS" })}
                            type="text"
                            isRequired
                            name="truongHopBenh.tinhTrangKhac"
                            value={values.truongHopBenh?.tinhTrangKhac}
                            onChange={handleChange}
                            errors={errors?.truongHopBenh?.tinhTrangKhac}
                            touched={touched?.truongHopBenh?.tinhTrangKhac}
                            disabled={onlyView}
                        />
                    </Col>
                )}
                <Col xl={2}>
                    <OCTTextValidator
                        lable={intl.formatMessage({ id: "DATE_OF_DESEASE_ONSET" })}
                        type="date"
                        name="truongHopBenh.ngayKhoiPhat"
                        value={values.truongHopBenh?.ngayKhoiPhat}
                        onChange={handleChange}
                        isRequired={configByStatus?.ngayKhoiPhat?.require}
                        errors={errors?.truongHopBenh?.ngayKhoiPhat}
                        touched={touched?.truongHopBenh?.ngayKhoiPhat}
                        disabled={onlyView}
                    />
                </Col>
                <Col xl={2}>
                    <OCTTextValidator
                        lable={intl.formatMessage({ id: "DATE_TO_HOSPITAL" })}
                        type="date"
                        isRequired
                        name="truongHopBenh.ngayNhapVien"
                        value={values.truongHopBenh?.ngayNhapVien}
                        onChange={handleChange}
                        errors={errors?.truongHopBenh?.ngayNhapVien}
                        touched={touched?.truongHopBenh?.ngayNhapVien}
                        disabled={onlyView}
                    />
                </Col>
                <Col xl={3}>
                    <OCTTextValidator
                        lable={intl.formatMessage({ id: "DATE_OUT_HOSPITAL_DEAD" })}
                        type="date"
                        name="truongHopBenh.ngayRaVien"
                        onChange={handleChange}
                        value={values.truongHopBenh?.ngayRaVien || ""}
                        disabled={configByStatus?.ngayRaVienChuyenVienTuVong?.disabled || onlyView}
                        isRequired={configByStatus?.ngayRaVienChuyenVienTuVong?.require}
                        errors={errors?.truongHopBenh?.ngayRaVien}
                        touched={touched?.truongHopBenh?.ngayRaVien}
                    />
                </Col>
                {
                    !configByStatus?.tinhTrangKhac &&
                    <Col xl={3}>
                        {configByStatus?.chanDoanRaVien && (
                            <OCTTextValidator
                                lable={intl.formatMessage({ id: "OUT_HOSPITAL_DIAGNOSIS" })}
                                type="text"
                                isRequired
                                name="truongHopBenh.chanDoanRaVien"
                                value={values.truongHopBenh?.chanDoanRaVien}
                                onChange={handleChange}
                                errors={errors?.truongHopBenh?.chanDoanRaVien}
                                touched={touched?.truongHopBenh?.chanDoanRaVien}
                                disabled={onlyView}
                            />
                        )}
                        {configByStatus?.chuyenToi && (
                            <AsyncAutoComplete
                                params={{}}
                                required
                                displayField='tenCoSo'
                                label={intl.formatMessage({ id: "MOVE_TO" })}
                                service={getListCoSoDieuTri}
                                handleChange={(value) => setFieldValue('truongHopBenh.benhVienChuyenToi', value)}
                                nameErrorMessage={errors?.truongHopBenh?.benhVienChuyenToi as string}
                                touched={touched?.truongHopBenh?.benhVienChuyenToi}
                                value={values.truongHopBenh?.benhVienChuyenToi}
                                isDisabled={onlyView}
                            />
                        )}
                    </Col>
                }
                <Col xl={3}>
                    <OCTAutocomplete
                        lable={intl.formatMessage({ id: "DIAGNOSIS_CLASSIFY" })}
                        options={PHAN_LOAI_CHAN_DOAN}
                        valueSearch={"code"}
                        value={values?.truongHopBenh?.phanLoaiChanDoan}
                        onChange={handleChangePhanLoaiChanDoan}
                        isRequired
                        errors={errors?.truongHopBenh?.phanLoaiChanDoan}
                        touched={touched?.truongHopBenh?.phanLoaiChanDoan}
                        isDisabled={onlyView}
                    />
                </Col>
                <Col xl={3}>
                    <RadioGroup
                        name='truongHopBenh.layMauXetNghiem'
                        lable={intl.formatMessage({ id: "GET_DIAGNOSIS_SPECIMEN" })}
                        value={values?.truongHopBenh?.layMauXetNghiem}
                        radioItemList={YES_NO_OPT(intl)}
                        handleChange={handleChangeLayMauXN}
                        disabledFields={onlyView ? YES_NO_OPT(intl).map(item => item.code) : disabledFieldLayMauXN}
                    />
                </Col>
                <Col xl={3}>
                    <OCTAutocomplete
                        lable={intl.formatMessage({ id: "INJECTION_DRINK_VACCINE_INFOMATION" })}
                        options={SU_DUNG_VAXIN}
                        valueSearch={"code"}
                        value={values?.truongHopBenh?.suDungVacXin}
                        onChange={(option) => {
                            setFieldValue("truongHopBenh.suDungVacXin", option?.code)
                            setFieldValue("truongHopBenh.soLanSuDung", null)
                        }}
                    />
                </Col>
                <Col xl={3} >
                    {
                        values?.truongHopBenh?.suDungVacXin === CO_SU_DUNG_VAXIN && (
                            <OCTTextValidator
                                lable={intl.formatMessage({ id: "TOTAL_INJECTION_DRINK" })}
                                type="number"
                                name="truongHopBenh.soLanSuDung"
                                value={values?.truongHopBenh?.soLanSuDung}
                                onChange={handleChange}
                                isRequired
                                errors={errors?.truongHopBenh?.soLanSuDung}
                                touched={touched?.truongHopBenh?.soLanSuDung}
                                disabled={onlyView}
                            />
                        )
                    }
                </Col >
                {values?.truongHopBenh?.layMauXetNghiem === LAY_MAU_XN && (<>
                    <Col xl={3}>
                        <OCTAutocomplete
                            lable={intl.formatMessage({ id: "SPECIMEN_CLASSIFY" })}
                            valueSearch={"code"}
                            options={LOAI_XET_NGHIEM}
                            value={values?.truongHopBenh?.loaiXetNghiem}
                            onChange={handleChangeLoaiXetNghiem}
                            isDisabled={onlyView}
                        />
                    </Col>
                    <Col xl={3}>
                        {configByTypeTest?.dinhLoai && (
                            <OCTTextValidator
                                lable={intl.formatMessage({ id: "OTHER_SPECIMEN" })}
                                type="text"
                                name="truongHopBenh.dinhLoaiXetNghiemKhac"
                                value={values?.truongHopBenh?.dinhLoaiXetNghiemKhac}
                                onChange={handleChange}
                                disabled={onlyView}
                            />
                        )}
                    </Col>
                    <Col xl={3}>
                        {configByTypeTest?.tenXetNghiemKhac && (
                            <OCTTextValidator
                                lable={intl.formatMessage({ id: "NAME_SPECIMEN" })}
                                type="text"
                                isRequired
                                name="truongHopBenh.loaiXetNghiemKhac"
                                value={values?.truongHopBenh?.loaiXetNghiemKhac}
                                onChange={handleChange}
                                errors={errors?.truongHopBenh?.loaiXetNghiemKhac}
                                touched={touched?.truongHopBenh?.loaiXetNghiemKhac}
                                disabled={onlyView}
                            />
                        )}
                    </Col>
                    <Col xl={3} />
                    <Col xl={3}>
                        <OCTAutocomplete
                            lable={intl.formatMessage({ id: "SPECIMEN_RESULT" })}
                            options={KQ_XET_NGHIEM}
                            valueSearch={"code"}
                            value={values?.truongHopBenh?.ketQuaXetNghiem}
                            onChange={(option) => setFieldValue("truongHopBenh.ketQuaXetNghiem", option?.code)}
                            isDisabled={onlyView}
                        />
                    </Col>
                    <Col xl={3}>
                        <OCTTextValidator
                            lable={intl.formatMessage({ id: "DATE_GET_SPECIMEN" })}
                            type="date"
                            name="truongHopBenh.ngayThucHienXn"
                            isRequired
                            value={values?.truongHopBenh?.ngayThucHienXn}
                            onChange={handleChange}
                            errors={errors?.truongHopBenh?.ngayThucHienXn}
                            touched={touched?.truongHopBenh?.ngayThucHienXn}
                            disabled={onlyView}

                        />
                    </Col>
                    <Col xl={3}>
                        <OCTTextValidator
                            lable={intl.formatMessage({ id: "DATE_GET_RESULT" })}
                            type="date"
                            name="truongHopBenh.ngayTraKetQuaXn"
                            value={values?.truongHopBenh?.ngayTraKetQuaXn}
                            onChange={handleChange}
                            errors={errors?.truongHopBenh?.ngayTraKetQuaXn}
                            touched={touched?.truongHopBenh?.ngayTraKetQuaXn}
                            disabled={onlyView}
                        />
                    </Col>
                    <Col xl={3}>
                        <AsyncAutoComplete
                        params={{}}
                        displayField='tenCoSo'
                        label={intl.formatMessage({ id: "SPECIMEN_FACILITY" })}
                        service={getListCoSoXetNghiem}
                        handleChange={(value) => setFieldValue("truongHopBenh.donViXetNghiemObject", value)}
                        nameErrorMessage={errors?.truongHopBenh?.donViXetNghiemObject as string}
                        touched={touched?.truongHopBenh?.donViXetNghiemObject}
                        value={values.truongHopBenh?.donViXetNghiemObject}
                        isDisabled={onlyView}
                    />
                    </Col>
                </>)}
                <Col xl={6}>
                    <OCTTextValidator
                        lable={intl.formatMessage({ id: "ATTENTDANT_DISEASE_DIAGNOSIS" })}
                        type="text"
                        value={values?.truongHopBenh?.benhChanDoanPhu}
                        name={"truongHopBenh.benhChanDoanPhu"}
                        onChange={handleChange}
                        disabled={onlyView}
                    />
                </Col>
                <Col xl={6}>
                    <OCTTextValidator
                        lable={intl.formatMessage({ id: "COMPLICATION_DIAGNOSIS" })}
                        type="text"
                        value={values?.truongHopBenh?.chanDoanBienChung}
                        name={"truongHopBenh.chanDoanBienChung"}
                        onChange={handleChange}
                        disabled={onlyView}
                    />
                </Col>
                <Col xl={6}>
                    <OCTTextValidator
                        lable={intl.formatMessage({ id: "EPIDEMIOLOGY_HISTORY" })}
                        type="text"
                        as="textarea"
                        rows={2}
                        value={values?.truongHopBenh?.tienSuDichTe}
                        name={"truongHopBenh.tienSuDichTe"}
                        onChange={handleChange}
                        disabled={onlyView}

                    />
                </Col>
                <Col xl={6}>
                    <OCTTextValidator
                        lable={intl.formatMessage({ id: "NOTE" })}
                        type="text"
                        as="textarea"
                        rows={2}
                        value={values?.truongHopBenh?.ghiChu}
                        name={"truongHopBenh.ghiChu"}
                        onChange={handleChange}
                        disabled={onlyView}
                    />
                </Col>
            </Row>
        </>
    )
}

export default ThongTinChanDoanTab
