import { PERMISSIONS, PERMISSION_ABILITY } from "../../Constant"
import { getIntlFromLocalStorage } from "../../modules/utils/FunctionUtils";

export type TMenu = {
  title: string,
  to: string,
  name: string,
  permission: string,
  ability: string,
  subMenu: TSubMenu[],
  iconPath: string,
}

export type TSubMenu = {
  title: string,
  to: string,
  hasBullet: boolean,
  icon: string,
  permission: string,
  ability: string,
}

export const allMenu = ():TMenu[] => {
  // Sử dụng `intl` đã được tái tạo
  const intl = getIntlFromLocalStorage();
  return [
    {
      title: intl.formatMessage({ id: "CASE_MANAGEMENT" }),
      to: "/danh-sach-truong-hop-benh",
      name: "danh-sach-truong-hop-benh",
      iconPath: "/media/svg/icons/person-lines-fill.svg",
      permission: PERMISSIONS.THB,
      ability: PERMISSION_ABILITY.VIEW,
      subMenu: [
        {
          title: intl.formatMessage({ id: "PATIENTS" }),
          to: "/danh-sach-truong-hop-benh",
          hasBullet: false,
          icon: "/media/svg/icons/person-lines-fill.svg",
          permission: PERMISSIONS.THB,
          ability: PERMISSION_ABILITY.VIEW,
        },
      ],
    },
    {
      title: intl.formatMessage({ id: "OUT_BREAK_MANAGEMENT" }),
      to: "/danh-sach-o-dich",
      name: "danh-sach-o-dich",
      iconPath: "media/icons/duotune/medicine/med008.svg",
      permission: PERMISSIONS.ODICH,
      ability: PERMISSION_ABILITY.VIEW,
      subMenu: [
        {
          title: intl.formatMessage({ id: "OUTBREAKS" }),
          to: "/danh-sach-o-dich",
          hasBullet: false,
          icon: "/media/svg/icons/list-ul.svg",
          permission: PERMISSIONS.ODICH,
          ability: PERMISSION_ABILITY.VIEW,
        },
        {
          title: intl.formatMessage({ id: "ADD_OUTBREAK" }),
          to: "/them-moi-o-dich",
          hasBullet: false,
          icon: "/media/svg/icons/person-add.svg",
          permission: PERMISSIONS.ODICH,
          ability: PERMISSION_ABILITY.CREATE,
        },
      ],
    },
    {
      title: intl.formatMessage({ id: "REPORT" }),
      to: "/bao-cao-tuan",
      name: "danh-sach-o-dich",
      iconPath: "media/icons/duotune/files/fil002.svg",
      permission: PERMISSIONS.BAOCAO,
      ability: PERMISSION_ABILITY.VIEW,
      subMenu: [
        {
          title: intl.formatMessage({ id: "WEEKLY_REPORTS" }),
          to: "/bao-cao-tuan",
          hasBullet: false,
          icon: "/media/svg/icons/list-ul.svg",
          permission: PERMISSIONS.BAOCAO,
          ability: PERMISSION_ABILITY.VIEW,
        },
        {
          title: intl.formatMessage({ id: "ADD_REPORT" }),
          to: "/nhap-moi-bao-cao",
          hasBullet: false,
          icon: "/media/icons/contract.svg",
          permission: PERMISSIONS.BAOCAO,
          ability: PERMISSION_ABILITY.CREATE,
        },
      ],
    },
    {
      title: intl.formatMessage({ id: "CATEGORY" }),
      to: "/danh-muc-nguoi-dung",
      name: "danh-muc-nguoi-dung",
      iconPath: "media/icons/duotune/layouts/lay009.svg",
      permission: PERMISSIONS.THB,
      ability: PERMISSION_ABILITY.VIEW,
      subMenu: [
        {
          title: intl.formatMessage({ id: "USER_CATEGORY" }),
          to: "/danh-muc-nguoi-dung",
          hasBullet: false,
          icon: "/media/svg/icons/person-lines-fill.svg",
          permission: PERMISSIONS.DM_NGUOI_DUNG,
          ability: PERMISSION_ABILITY.VIEW,
        },
        {
          title: intl.formatMessage({ id: "LOCAL_CATEGORY" }),
          to: "/danh-muc-dia-phuong",
          hasBullet: false,
          icon: "/media/svg/icons/list-ul.svg",
          permission: PERMISSIONS.DM_XA,
          ability: PERMISSION_ABILITY.VIEW,
        },
        {
          title: intl.formatMessage({ id: "BASIC_CATEGORY" }),
          to: "/danh-muc-co-so",
          hasBullet: false,
          icon: "/media/icons/duotune/ecommerce/ecm008.svg",
          permission: PERMISSIONS.DM_CO_SO,
          ability: PERMISSION_ABILITY.VIEW,
        },
        {
          title:  intl.formatMessage({ id: "TYPE_DATA_STANDARD" }),
          to: "/nhap-duong-cong-chuan",
          hasBullet: false,
          icon: "/media/icons/duotune/ecommerce/ecm008.svg",
          permission: PERMISSIONS.THB,
          ability: PERMISSION_ABILITY.VIEW,
        },
      ],
    },
    {
      title: intl.formatMessage({ id: "DASHBOARD" }),
      to: "/dashboard",
      name: "dashboard",
      iconPath: "media/icons/duotune/graphs/gra010.svg",
      permission: PERMISSIONS.BAOCAO,
      ability: PERMISSION_ABILITY.VIEW,
      subMenu: [
        {
          title: intl.formatMessage({ id: "TRACKING" }),
          to: "/dashboard",
          hasBullet: false,
          icon: "/media/svg/icons/list-ul.svg",
          permission: PERMISSIONS.D_GIAM_SAT,
          ability: PERMISSION_ABILITY.VIEW,
        },
        {
          title: intl.formatMessage({ id: "FORECAST" }),
          to: "/dashboard-du-bao",
          hasBullet: false,
          icon: "/media/svg/icons/list-ul.svg",
          permission: PERMISSIONS.D_DU_BAO,
          ability: PERMISSION_ABILITY.VIEW,
        },
      ],
    },
  ];};
