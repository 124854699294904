import { useEffect, useState } from "react";
import { Marker, Popup, useMap, useMapEvents } from "react-leaflet";
import Supercluster, { AnyProps, PointFeature } from "supercluster";
import L from 'leaflet';

type IClusterProps = {
    dataPoints: any[];
    maxZoomCluster: number;
    radiusCluster: number;
    renderIconCluster?: (cluster: any) => L.DivIconOptions;
    popupRender?: any
}
export const ClusteringMarkers = (props: IClusterProps) => {
    const { dataPoints,
        maxZoomCluster,
        radiusCluster,
        renderIconCluster,
        popupRender
    } = props
    const map = useMap();
    const [clusteredMarkers, setClusteredMarkers] = useState<(
        Supercluster.PointFeature<Supercluster.AnyProps> |
        Supercluster.ClusterFeature<Supercluster.AnyProps>)[]>([]);

    const updateClusters = () => {
        if (!map) return;

        const bounds = map.getBounds();
        const zoom = map.getZoom();
        const boundsArray: GeoJSON.BBox = [bounds?.getWest(), bounds?.getSouth(), bounds?.getEast(), bounds?.getNorth()];

        const points: PointFeature<AnyProps>[] = dataPoints?.map(data => ({
            type: 'Feature',
            properties: { cluster: false, ...data },
            geometry: {
                type: 'Point',
                coordinates: data.position
            }
        }));


        const cluster = new Supercluster({
            radius: radiusCluster,
            maxZoom: maxZoomCluster
        });
        cluster.load((points));

        const clusters = cluster.getClusters((boundsArray), zoom);
        const newClusters = clusters.map((item: any, index: number) => {
            const listChildCluster = item?.properties?.cluster_id ? cluster?.getLeaves(item?.properties?.cluster_id, 9999) : [];
            return {
                ...item,
                properties: {
                    ...item.properties,
                    value: listChildCluster.reduce((total, feature) => {
                        return total + feature.properties.total;
                    }, 0)
                }
            }
        })
        setClusteredMarkers(newClusters);
    };

    useMapEvents({
        moveend: updateClusters,
        zoomend: updateClusters
    })

    const renderIconDefault = (cluster: any) => {
        return {
            html: `<div class="data-point-default" >${cluster?.properties?.point_count_abbreviated || ""} </div>`
        }
    }

    useEffect(() => {
        updateClusters();
    }, [map, dataPoints]);

    return (
        <>
            {clusteredMarkers.length > 0 && (
                clusteredMarkers.map((cluster: any, idx: number) => {
                    const position = cluster.geometry.coordinates;
                    return (
                        <Marker
                            key={idx}
                            position={[position[1], position[0]]}
                            icon={L.divIcon((renderIconCluster ?? renderIconDefault)(cluster))}
                        >
                            {
                                (popupRender && !cluster?.properties?.cluster) ? popupRender(cluster) : <></>
                            }
                        </Marker>
                    );
                })
            )}
        </>
    );
};
