import React, { Dispatch, SetStateAction, useContext } from "react";
import { IDataDashboard } from "../Dashboard/model/model";

type IAddress = {
  id: number | null;
  isActive: number | null;
  maTinh: string | null;
  tenTinh: string | null;
  tinhId: number | null;
  tenHuyen: string | null;
  huyenId: number | null;
  vungMienId: number | null;
  tenXa: string | null;
  xaId: number | null;
};

type IInitContext = {
  setAddress?: Dispatch<SetStateAction<any>>;
  address?: IAddress;
  data?:any
};

const initValue: IInitContext = {
  address: {
    huyenId: null,
    tenHuyen: null,
    tenXa: null,
    xaId: null,
    id: null,
    isActive: null,
    maTinh: null,
    tenTinh: null,
    tinhId: null,
    vungMienId: null,
  },
  setAddress: () => {},
  data: {} as IDataDashboard
};

const InitContext = React.createContext(initValue);
const useInitContext = () => useContext(InitContext);

export { InitContext, useInitContext };
